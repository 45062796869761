import React, { useState } from "react"
import {
  TextField,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  IconButton,
  Checkbox,
} from "@mui/material"
import Modal from "react-bootstrap/Modal"
import Select from "react-select"
import { Col, Input, Row } from "reactstrap"
import Swal from "sweetalert2"
import { HOST_URL } from "helpers/url_helper"
import { ApiGetMethod } from "helpers/withoutToken_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"

const AddLocalityCity = ({ show, handleClose, fetchlocality }) => {
  const [formData, setFormData] = useState({
    type: "",
    cityId:"",
    city: "",
    locality: "",
    state: "",
  })

  const optionGroup = [
    { label: "City", value: "City" },
    { label: "Locality", value: "Locality" },
  ]
const [cityGroup, setCityGroup] = useState([])
  const stateGroup = [
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
  ]
  const token = JSON.parse(localStorage.getItem("token"))

  const handleLocality = async() => {
    try {
        const postUrl = `${HOST_URL}api/viewLocalities`
        const response = await ApiGetMethod(postUrl)
        const cities = response.map(cities => ({label:cities.city,value:cities._id}))
        setCityGroup(cities)
        console.log("loaclities form response---", cityGroup)
      } catch (error) {
        console.error("Error:", error)
      }
  }

  const handleSelectType = (name, selectedGroup) => {
    setFormData(prevDetails => ({
      ...prevDetails,
      [name]: selectedGroup.value,
    }))

    if(name == 'type' && selectedGroup.value == 'Locality'){
        setFormData(prevDetails => ({
            ...prevDetails,
            city: '',
        }))
        handleLocality();
    }else{
        setFormData(prevDetails => ({
            ...prevDetails,
            locality: '',
        }))
    }
  }

    const handleSelectCity = (name, selectedGroup) => {
        setFormData(prevDetails => ({
            ...prevDetails,
            [name]: selectedGroup.label,
            cityId:selectedGroup.value
        }))
    }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }))
  }

  const handleSubmit = () => {
    try {
        const postUrl = `${HOST_URL}api/addLocCity`
        const data = formData
        ApiPostMethodWithToken(postUrl, data, token).then(data => {
            if(data.error == false){
                fetchlocality()
                setFormData({
                    type: "",
                    city: "",
                    locality: "",
                    state: "",
                    cityId:''
                })
                handleClose()
                Swal.fire({
                    icon: "success",
                    title: 'Success',
                    text: data.message,
                    showConfirmButton: false,
                    timer: 1500,
                })
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: data.message,
                })
            }
        })
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
        })
    }
  }

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        className="filtermodal dateFilter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Locality</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12} md={12} xs={12}>
                <label
                    htmlFor="type"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Type
                </label>
                <Select
                    id="type"
                    name="type"
                    value={{
                    label: formData.type,
                    value: formData.type,
                    }}
                    onChange={selectedValue => {
                    handleSelectType("type", selectedValue)
                    }}
                    placeholder="Select type"
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                />
            </Col>

            {formData.type === "City" && (
              <>
                <Col lg={12} md={12} xs={12} className="mt-2">
                    <label
                        htmlFor="state"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                        State
                    </label>
                    <Select
                        id="state"
                        name="state"
                        value={{
                        label: formData.state,
                        value: formData.state,
                        }}
                        onChange={selectedValue => {
                        handleSelectType("state", selectedValue)
                        }}
                        placeholder="Select state"
                        options={stateGroup}
                        classNamePrefix="select2-selection"
                    />
                </Col>

                <Col lg={12} md={12} xs={12} className="mt-2">
                    <label
                        htmlFor="setcity"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                        City
                    </label>
                    <Input
                        id="setcity"
                        name="city"
                        type="text"
                        className={`form-control`}
                        placeholder="Enter City"
                        value={formData.city}
                        onChange={handleChange}
                    />
                </Col>
              </>
            )}
            {formData.type === "Locality" && (
              <>
                <Col lg={12} md={12} xs={12} className="mt-2">
                    <label
                        htmlFor="city"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                        City
                    </label>
                    <Select
                        id="city"
                        name="city"
                        value={{
                        label: formData.city,
                        value: formData.city,
                        }}
                        onChange={selectedValue => {
                        handleSelectCity("city", selectedValue)
                        }}
                        placeholder="Select state"
                        options={cityGroup && cityGroup}
                        classNamePrefix="select2-selection"
                    />
                </Col>

                <Col lg={12} md={12} xs={12} className="mt-2">
                    <label
                        htmlFor="locality"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                        Locality
                    </label>
                    <Input
                        id="locality"
                        name="locality"
                        type="text"
                        className={`form-control`}
                        placeholder="Enter Locality"
                        value={formData.locality}
                        onChange={handleChange}
                    />
                </Col>
              </>
            )}
            <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                <Button
                    variant="contained"
                    className="commongradient addbutton submitButton"
                    onClick={handleSubmit}
                    style={{ padding: "10px 20px", marginLeft: 10 }}
                >
                    <span>Save</span>
                </Button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default AddLocalityCity
