import React, { useState, useEffect } from "react"
import { Button, ButtonGroup, Col, Input, Row } from "reactstrap"
import Select from "react-select"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { ApiGetMethod } from "helpers/withoutToken_helper"
import { toast } from "react-toastify"

const CommercialBasicDetails = React.forwardRef(
  ({ propertytype, lookingfor, postedBy, propData }, ref) => {
    // console.log("looking for---", lookingfor)
    // console.log("looking for ref---", ref)

    // search field

    const [searchCity, setSearchCity] = useState("")
    const [searchCityError, setSearchCityError] = useState("")
    const [searchState, setSearchState] = useState("")
    const [searchStateError, setSearchStateError] = useState("")
    const [localityData, setLocalityData] = useState([]);
    const [cityGroup, setCityGroup] = useState([])
    const [stateGroup, setStateGroup] = useState([])

    const fetchLocData = async () => {
      try {
        const postUrl = `${HOST_URL}api/viewLocalities`

        const response = await ApiGetMethod(postUrl)
        setLocalityData(response)
        const State = response.map(st => ({ label: st.state, value: st.state }))
        .filter((value, index, self) => 
          self.findIndex(v => v.value === value.value) === index
        );
        // const cities = response.map(cities => ({label:cities.city,value:cities._id}))
        setStateGroup(State)
        // setCityGroup(cities)
      } catch (error) {
        console.error("Error:", error)
      }
    }
        useEffect(() => {
          fetchLocData()
        }, [])

    function handleSelectState(selectedGroup) {
      setSearchState(selectedGroup.label)
      setSearchStateError("")
      setCommBasicDetails(prevDetails => ({
        ...prevDetails,
        searchState: selectedGroup.label,
      }))
      const city = localityData.filter(data=> data.state == selectedGroup.value)
      console.log("city",city);
      
      const localities1 = city.map(cities => ({label:cities.city,value:cities._id}))
      setCityGroup(localities1)
    }

    function handleSelectGroup(selectedGroup) {
      setSearchCity(selectedGroup.label)
      localStorage.setItem("cityId", selectedGroup.value)
      setSearchCityError("")
      setCommBasicDetails(prevDetails => ({
        ...prevDetails,
        searchCity: selectedGroup.label,
      }))
    }

    // property type field

    const [commPropertyType, setCommPropertyType] = useState(
      propData?.propertyDetails?.propertyType2 ||
        localStorage.getItem("PropertyType2") ||
        "Office"
    )
    const CommProArray = [
      { id: 1, value: "Office", icon: "mdi mdi-city-variant-outline" },
      { id: 2, value: "Retail Shop", icon: "mdi mdi-home-floor-0" },
      { id: 3, value: "Showroom", icon: "mdi mdi-home-outline" },
      { id: 4, value: "Warehouse", icon: "mdi mdi-package-variant-closed" },
      { id: 5, value: "Plot", icon: "mdi mdi-chart-line" },
      { id: 6, value: "Other", icon: " mdi mdi-shape-outline" },
    ]

    useEffect(() => {
      setCommBasicDetails(prevDetails => ({
        ...prevDetails,
        propertyType1: propertytype,
        lookingTo: lookingfor,
        postedBy: postedBy,
      }))
    }, [propertytype, lookingfor])

    const handleCommPropType = val => {
      setCommPropertyType(val)
      localStorage.setItem("commpropertyType2", val)
      setCommBasicDetails(prevDetails => ({
        ...prevDetails,
        propertyType2: val,
      }))
    }

    const user_id = JSON.parse(localStorage.getItem("authUser"))
    const user_Id = localStorage.getItem("user_Id")
    const Note = localStorage.getItem("note")
    const user_role = JSON.parse(localStorage.getItem("userRole"))

    const [commBasicDetails, setCommBasicDetails] = useState({
      searchState: propData?.propertyDetails?.searchState || "",
      searchCity: propData?.propertyDetails?.searchCity || "",
      propertyType2: propData?.propertyDetails?.propertyType2 || "",
      availableFrom: propData?.propertyDetails?.availableFrom || "",
      transaType: propData?.propertyDetails?.transaType || "",
      constStatus: propData?.propertyDetails?.constStatus || "",
      plotArea: propData?.propertyDetails?.plotArea || "",
      areaUnit: propData?.propertyDetails?.areaUnit || "",
      plotLength: propData?.propertyDetails?.plotLength || "",
      plotWidth: propData?.propertyDetails?.plotWidth || "",
      widthFacingRoad: propData?.propertyDetails?.widthFacingRoad || "",
      brokerageCharge: propData?.propertyDetails?.brokerageCharge || "",
      brokerageChargeCustom:
        propData?.propertyDetails?.brokerageChargeCustom || "",
      brokeragenego: propData?.propertyDetails?.brokeragenego || false,
    })

    // console.log("commercial basic details-", commBasicDetails)

    const [commBasicError, setCommBasicError] = useState({
      searchState: "",
      searchCity: "",
      propertyType2: "",
      availableFrom: "",
      brokerageChargeCustom: "",
      transaType: "",
      constStatus: "",
      plotArea: "",
      plotLength: "",
      plotWidth: "",
      widthFacingRoad: "",
    })

    const optionGroup = [
      {
        label: "Select Unit",
        options: [
          // { label: "Square Millimetres", value: "Sq.mm" },
          { label: "Square Metres", value: "m" },
          // { label: "Square Centimetres", value: "Sq.cm" },
          { label: "Square Kilometres", value: "km" },
          { label: "Square feet", value: "ft" },
          ...(commBasicDetails.propertyType2 === "Plot"
            ? [
                { label: "Acres", value: "Acres" },
                { label: "Hectares", value: "Hectares" },
                { label: "Guntha", value: "Guntha" },
              ]
            : []
          ),
        ],
      },
    ]

    const handle_change = (iName, value) => {
      setCommBasicDetails(prevDetails => ({
        ...prevDetails,
        [iName]: value,
      }))
      setCommBasicError(prevErrors => ({
        ...prevErrors,
        [iName]: "",
      }))
    }

    const [selectedLabel, setSelectedLabel] = useState()
    function handleSelectArea(selectedGroup) {
      setSelectedLabel(selectedGroup.label)
      setCommBasicDetails(prevDetails => ({
        ...prevDetails,
        areaUnit: selectedGroup.value,
      }))
    }

    const formatAmount = amount => {
      if (amount < 1000) {
        return amount
      } else if (amount >= 1000 && amount < 100000) {
        return (amount / 1000).toFixed(2) + "k"
      } else if (amount >= 100000 && amount < 10000000) {
        return (amount / 100000).toFixed(2) + "L"
      } else if (amount >= 10000000) {
        return (amount / 10000000).toFixed(2) + "Cr"
      }
    }

    const handleSubmit = async () => {
      // return true
      const newErrors = {}
      if (
        commBasicDetails.lookingTo === "Rent" ||
        commBasicDetails.lookingTo === "Sell"
      ) {
        if (commBasicDetails.searchCity.trim() === "") {
          newErrors.searchCity = "City is required"
        }
        if (commBasicDetails.searchState.trim() === "") {
          newErrors.searchState = "State is required"
        }
        if (commBasicDetails.propertyType2.trim() === "") {
          newErrors.propertyType2 = "Building type is required"
        }
        if(commBasicDetails.lookingTo === "Sell" && commBasicDetails.propertyType2 == "Plot"){
          if (commBasicDetails.constStatus == "In Future") {
            if (commBasicDetails.availableFrom.trim() === "") {
              newErrors.availableFrom = "Possession date is required"
            }
          }
          if (commBasicDetails.constStatus.trim() === "") {
            newErrors.constStatus = "Possession Status is required"
          }
          if (commBasicDetails.plotArea == "") {
            newErrors.plotArea = "Area is required"
          }
          if (commBasicDetails.plotLength == "") {
            newErrors.plotLength = "Length is required"
          }
          if (commBasicDetails.plotWidth == "") {
            newErrors.plotWidth = "Width is required"
          }
          if (commBasicDetails.widthFacingRoad == "") {
            newErrors.widthFacingRoad = "Width of facing is required"
          }
          if (commBasicDetails.transaType.trim() === "") {
            newErrors.transaType = "Transaction type is required"
          }
          if (commBasicDetails.brokerageCharge == "Yes") {
            if (commBasicDetails.brokerageChargeCustom.trim() === "") {
              newErrors.brokerageChargeCustom = "Brokerage charge is required"
            }
          }
          if (
            commBasicDetails.plotLength != "" &&
            commBasicDetails.plotWidth != "" &&
            commBasicDetails.plotArea != ""
          ) {
            const calculatedArea =
              parseFloat(commBasicDetails.plotLength) * parseFloat(commBasicDetails.plotWidth);
            if (calculatedArea !== parseFloat(commBasicDetails.plotArea)) {
              toast.error("Length and width ratio does not match the Area!..", {
                position: "top-right",
                theme: "light",
                autoClose: 1000,
              })
              newErrors.plotArea = "Length and width ratio does not match the Area";
            }
          }          
        }
      }
      setCommBasicError(newErrors)
      if (Object.values(newErrors).every(error => error === "")) {
        var status
        var response
        const token = JSON.parse(localStorage.getItem("token"))
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        const url = `${HOST_URL}property/basicdetails`
        const data = {
          propertyDetails: commBasicDetails,
          user_id: user_Id || user_id._id,
          postedBy: user_id._id,
          note: Note,
          property_id,
        }
        try {
          const resp = await ApiPostMethodWithToken(url, data, token)
          if (resp.error === false) {
            localStorage.setItem(
              "property_id",
              JSON.stringify(resp.property_id)
            )
            status = true
            response = resp
          } else {
            status = false
            response = resp
          }
        } catch (error) {
          console.error("Error submitting form:", error)
          status = false
        }
        return { status: status, message: response.message }
      } else {
        console.log("newErrors", newErrors)
        return { status: false, message: formatValidationMessage(newErrors) }
      }
    }

    function formatValidationMessage(errors) {
      const keys = Object.keys(errors).map(key => 
          errors[key].replace(' is required', '')
      );
      const joinedKeys = keys.length > 1 
          ? keys.slice(0, -1).join(', ') + ' and ' + keys[keys.length - 1] 
          : keys[0];

      return `${joinedKeys} is required.`;
    }

    React.useImperativeHandle(ref, () => ({
      handleCommBasic: () => handleSubmit(),
    }))

    return (
      <>
        <Row>
          <Col md={6}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="searchState"
                className="col-lg-12 col-form-label requiredfield font-weight-bold"
              >
                State
              </label>
              <div className="col-lg-12">
                <Select
                  id="searchState"
                  name="searchState"
                  value={{
                    label: searchState,
                    value: searchState,
                  }}
                  onChange={selectedValue => {
                    handleSelectState(selectedValue)
                  }}
                  options={stateGroup}
                  classNamePrefix="select2-selection"
                />
                {commBasicError.searchState && (
                  <span className="error">
                    {commBasicError.searchState}
                  </span>
                )}
              </div>
            </Row>
          </Col>
          <Col md={6}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="searchCity"
                className="col-lg-12 col-form-label requiredfield font-weight-bold"
              >
                Search City
              </label>
              <div className="col-lg-12">
                <Select
                  id="searchCity"
                  name="searchCity"
                  defaultValue={[searchCity]}
                  onChange={selectedValue => {
                    handleSelectGroup(selectedValue)
                  }}
                  options={cityGroup}
                  classNamePrefix="select2-selection"
                />
                {commBasicError.searchCity && (
                  <span className="error">{commBasicError.searchCity}</span>
                )}
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-12 col-form-label requiredfield font-weight-bold"
              >
                Building Type
              </label>
              <div className="col-lg-12 comm-button-group-container">
                <div>
                  {CommProArray.map(property => (
                    <Button
                      key={property.id}
                      className={
                        commPropertyType === property.value
                          ? "btnActive custom-button"
                          : "btnNormal custom-button"
                      }
                      onClick={() => handleCommPropType(property.value)}
                    >
                      <i
                        className={property.icon}
                        style={{ marginRight: "5px" }}
                      ></i>
                      {property.value}
                    </Button>
                  ))}
                </div>
                {commBasicError.propertyType2 && (
                  <span className="error">{commBasicError.propertyType2}</span>
                )}
              </div>
            </Row>
          </Col>
        </Row>
        {commBasicDetails.propertyType2 == "Plot" && commBasicDetails.lookingTo === "Sell" ? (
          <React.Fragment>
            <Row>
              <Col lg={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="transaType"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Transaction Type
                  </label>
                  <ButtonGroup>
                    <Button
                      style={{ marginRight: "5px", borderRadius: "5px" }}
                      className={
                        commBasicDetails.transaType === "New Booking"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handle_change("transaType", "New Booking")}
                    >
                      New Booking
                    </Button>
                    <Button
                      style={{ borderRadius: "5px" }}
                      className={
                        commBasicDetails.transaType === "Resale"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handle_change("transaType", "Resale")}
                    >
                      Resale
                    </Button>
                  </ButtonGroup>
                  {commBasicError.transaType && (
                    <span className="error">{commBasicError.transaType}</span>
                  )}
                </Row>
              </Col>
              <Col lg={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="constStatus"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Possession Status
                  </label>
                  <ButtonGroup>
                    <Button
                      style={{ marginRight: "5px", borderRadius: "5px" }}
                      className={
                        commBasicDetails.constStatus === "Immediate"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handle_change("constStatus", "Immediate")}
                    >
                      Immediate
                    </Button>
                    <Button
                      style={{ borderRadius: "5px" }}
                      className={
                        commBasicDetails.constStatus === "In Future"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handle_change("constStatus", "In Future")}
                    >
                      In Future
                    </Button>
                  </ButtonGroup>
                  {commBasicError.constStatus && (
                    <span className="error">{commBasicError.constStatus}</span>
                  )}
                </Row>
              </Col>
              {commBasicDetails.constStatus === "In Future" ? (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Possession Date
                    </label>
                    <div className="col-lg-12">
                      <Input
                        className="form-control"
                        name="availableFrom"
                        type="date"
                        id="availableFrom"
                        value={commBasicDetails.availableFrom}
                        onChange={(e) => handle_change("availableFrom", e.target.value)}
                      />
                      {commBasicError.availableFrom && (
                        <span className="error">
                          {commBasicError.availableFrom}
                        </span>
                      )}
                    </div>
                  </Row>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col lg={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Do you charge brokerage?
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          commBasicDetails.brokerageCharge === "Yes" ? "btnActive" : "btnNormal"
                        }
                        onClick={() => handle_change("brokerageCharge","Yes")}
                      >
                        Yes
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          commBasicDetails.brokerageCharge === "No" ? "btnActive" : "btnNormal"
                        }
                        onClick={() => handle_change("brokerageCharge","No")}
                      >
                        No
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
              {commBasicDetails.brokerageCharge == "Yes" ? (
                <React.Fragment>
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="txtFirstNameBilling"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Brokerage ( In Rupees)
                      </label>
                      <div className="col-lg-12 d-flex built-up">
                        <Input
                          className="form-control"
                          name="brokerageChargeCustom"
                          type="number"
                          id="brokerageChargeCustom"
                          value={commBasicDetails.brokerageChargeCustom}
                          onChange={(e) => handle_change("brokerageChargeCustom", e.target.value)}
                          min={0}
                          onWheel={e => e.target.blur()}
                        />
                        <span className="input-group-append">
                          <span className="input-group-text font-weight-bold">
                            ₹
                            {formatAmount(
                              commBasicDetails.brokerageChargeCustom
                            )}
                          </span>
                        </span>
                      </div>
                      {commBasicError.brokerageChargeCustom && (
                        <span className="error">
                          {commBasicError.brokerageChargeCustom}
                        </span>
                      )}
                    </Row>
                  </Col>
                  <Col lg={6} className="d-flex align-items-center">
                    <Button
                      style={{ marginRight: "5px" }}
                      className={"btnNormal mt-3"}
                      onClick={() =>
                        handle_change(
                          "brokeragenego",
                          !commBasicDetails.brokeragenego
                        )
                      }
                    >
                      <Input
                        type="checkbox"
                        className="me-1"
                        name="brokeragenego"
                        onChange={() =>
                          handle_change(
                            "brokeragenego",
                            !commBasicDetails.brokeragenego
                          )
                        }
                        checked={commBasicDetails.brokeragenego === true}
                      />
                      Brokerage Negotiable
                    </Button>
                  </Col>
                </React.Fragment>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Plot Area
                  </label>
                  <div className="col-lg-12 d-flex">
                    <Input
                      id="plotArea"
                      name="plotArea"
                      type="number"
                      className={`form-control`}
                      value={commBasicDetails.plotArea}
                      onChange={(e) => handle_change("plotArea",e.target.value)}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                  </div>
                  {commBasicError.plotArea && (
                    <span className="error">{commBasicError.plotArea}</span>
                  )}
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Area Unit
                  </label>
                  <div className="col-lg-12">
                    <Select
                      id="areaUnit"
                      name="areaUnit"
                      value={{
                        label: selectedLabel,
                        value: commBasicDetails.areaUnit,
                      }}
                      onChange={selectedValue => {
                        handleSelectArea(selectedValue)
                      }}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Length
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="plotLength"
                      name="plotLength"
                      type="number"
                      className={`form-control`}
                      // placeholder="Enter Built Up Area"
                      value={commBasicDetails.plotLength}
                      onChange={(e) => handle_change("plotLength",e.target.value)}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text">
                        {commBasicDetails?.areaUnit || "ft"}
                      </span>
                    </span>
                  </div>
                  {commBasicError.plotLength && (
                    <span className="error">{commBasicError.plotLength}</span>
                  )}
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Width
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="plotWidth"
                      name="plotWidth"
                      type="number"
                      className={`form-control`}
                      // placeholder="Enter Built Up Area"
                      value={commBasicDetails.plotWidth}
                      onChange={(e) => handle_change("plotWidth",e.target.value)}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text">
                        {commBasicDetails?.areaUnit || "ft"}
                      </span>
                    </span>
                  </div>
                  {commBasicError.plotWidth && (
                    <span className="error">{commBasicError.plotWidth}</span>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="widthFacingRoad"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Width of Facing Road
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="widthFacingRoad"
                      name="widthFacingRoad"
                      type="number"
                      className={`form-control`}
                      // placeholder= {lookingfor == 'Rent' ? "Enter monthly rent" : "Enter cost"}
                      value={commBasicDetails.widthFacingRoad}
                      onChange={(e) => handle_change("widthFacingRoad",e.target.value)}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text">mt</span>
                    </span>
                  </div>
                  {commBasicError.widthFacingRoad && (
                    <span className="error">
                      {commBasicError.widthFacingRoad}
                    </span>
                  )}
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        ) : ""}
      </>
    )
  }
)

export default CommercialBasicDetails
