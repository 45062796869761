import React, { useState, useEffect } from "react"

import { Grid, Paper, Stack, Typography } from "@mui/material"
import { Button, Col, Row } from "reactstrap"

import OtpInput from "react18-input-otp"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import displayRazorpay from "helpers/payment"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethod } from "helpers/withoutToken_helper"
import PlanModel from "pages/Plans/planModel"

const VerifyOtp = ({ show, handleClose, mobileno }) => {
  const user_id = JSON.parse(localStorage.getItem("authUser"))
  const router = useNavigate()
  // console.log("mobileno", mobileno)
  const [otpValue, setOtpValue] = useState("")
  const [planList, setPlanList] = useState([])
  const [userDetails, setUserDetails] = useState([])
  const [otpValueerr, setOtpValueErr] = useState("")
  const [resendTimer, setResendTimer] = useState(0)
  const [isResendDisabled, setIsResendDisabled] = useState(true)
  const [planmodel, setPlanModel] = useState(false)

  const handleOtpChange = value => {
    setOtpValue(value)
  }

  const handleSubmit = async () => {
    if (!otpValue.length === 6 || otpValue == "") {
      setOtpValueErr("Please Enter Valid OTP!....")
    } else {
      const url = `${HOST_URL}auth/register`

      // Corrected headers
      const headers = {
        "Content-Type": "application/json",
      }
      const data = {
        mobno: mobileno,
        otp: otpValue,
      }
      try {
        const response = await ApiPostMethod(url, data, "POST")
        // console.log("response", response)
        if (response.error === false) {
          setOtpValue("")
          setPlanList(response.plan.filter(plans => plans.addOnPlan !== true))
          setUserDetails(response.user)
          setPlanModel(true)
          // toast.success(`OTP Verified successfully!...`, {
          //     position: "top-right",
          //     theme: "light",
          // });
          // displayRazorpay(response.user, response.plan)
        } else {
          toast.error(`Error Verifying OTP!...`, {
            position: "top-right",
            theme: "light",
          })
        }
      } catch (error) {
        throw error
      }
    }
  }
  const handleCloseModal = () => {
    setOtpValue("")
    handleClose()
  }
  const closeModel = () => {
    setPlanModel(false)
    router("/Login?register=true")
  }

  useEffect(() => {
    let intervalId

    intervalId = setInterval(() => {
      setResendTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0))
    }, 1000)

    return () => clearInterval(intervalId)
  }, [resendTimer])

  useEffect(() => {
    setIsResendDisabled(resendTimer === 0)
  }, [resendTimer])

  const handleResendClick = async () => {
    try {
      const url = `${HOST_URL}auth/resendotp`

      // Corrected headers
      const headers = {
        "Content-Type": "application/json",
      }
      const data = {
        mobno: mobileno,
      }

      const response = await ApiPostMethod(url, data, "POST")
      if (response.error === false) {
        toast.success(response.message, {
          position: "top-right",
          theme: "light",
        })
        setResendTimer(30)
        setIsResendDisabled(false)
      } else {
        toast.error(response.message, {
          position: "top-right",
          theme: "light",
        })
      }
    } catch (error) {
      console.error("Error during resend:", error)
    }
  }

  return (
    <React.Fragment>
      <Modal
        style={{ marginTop: "50px" }}
        show={show}
        onHide={handleCloseModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {/* <Modal isOpen={show} toggle={handleCloseModal}> */}
        <Modal.Body style={{ margin: "auto" }}>
          <Row>
            <Col className="p-3">
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="h6" className="text-center otpheader">
                    Please enter the One-Time Password to verify your account
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    style={{ color: "#535353" }}
                    className="otpheader2"
                  >
                    A One-Time Password has been sent to {mobileno}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} style={{ margin: "auto" }}>
                <Grid>
                  <Grid container className="otp-container" item xs={12}>
                    <OtpInput
                      value={otpValue}
                      className="otp-input"
                      onChange={handleOtpChange}
                      numInputs={6}
                      containerStyle={{ justifyContent: "space-between" }}
                      inputStyle={{
                        width: "100%",
                        height: "40px",
                        margin: "8px",
                        padding: "10px",
                        border: "none",
                        borderBottom: "1px solid #000",
                        outline: "none",
                      }}
                    />
                    {otpValueerr && (
                      <span className="error">{otpValueerr}</span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className="freehousing_button addbutton  otpbtn my-3"
                      size="large"
                      type="submit"
                      style={{ width: "70%", display: "block", margin: "auto" }}
                      onClick={handleSubmit}
                    >
                      <span className="optbtntext">Validate</span>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "8px 0px 0px 0px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Typography
                        className="resentotp"
                        variant="body1"
                        sx={{
                          minWidth: 170,
                          ml: 2,
                          textDecoration: "none",
                          cursor: isResendDisabled ? "pointer" : "not-allowed",
                          margin: 0,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          if (isResendDisabled) {
                            handleResendClick()
                          }
                        }}
                        disabled={!isResendDisabled}
                      >
                        {isResendDisabled
                          ? "Resend OTP"
                          : `Resend OTP in ${resendTimer}s`}
                      </Typography>
                    </Stack>
                    <Button
                      style={{
                        color: "gray",
                        border: "none",
                        backgroundColor: "transparent",
                        textDecoration: "underline",
                      }}
                      onClick={handleCloseModal}
                    >
                      close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <PlanModel
        show={planmodel}
        closeModel={closeModel}
        planList={planList}
        userDetails={userDetails}
      />
    </React.Fragment>
  )
}

export default VerifyOtp
