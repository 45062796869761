import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import MUIDataTable from "mui-datatables"
import classNames from "classnames"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { HOST_URL } from "helpers/url_helper"
import { Grid, IconButton, TextField, Tooltip } from "@mui/material"
import { Modal } from "react-bootstrap"
import Swal from "sweetalert2"
import { ToastContainer, toast } from "react-toastify"
import CloseIcon from "@mui/icons-material/Close"

const WithdrawRequest = () => {
  const [customActiveTab, setCustomActiveTab] = useState("1")
  const [withdrawList, setWithdrawList] = useState([])
  const token = JSON.parse(localStorage.getItem("token"))
  const [errors, setErrors] = useState({})
  const [withdrawListPending, setWithdrawListPending] = useState([])
  const [withdrawListApproved, setWithdrawListApproved] = useState([])
  const [selectedRequestId, setSelectedRequestId] = useState(null)
  const [approvedUserList, setApprovedUserList] = useState([])

  // const handleApproveRequest = async (requestId) => {
  //     const url = `${HOST_URL}withdraw/requestApprove`;
  //     const data = {
  //         requestId: requestId
  //     };

  //     try {
  //         const response = await ApiPostMethodWithToken(url, data, token);
  //         if (response.error === false) {
  //             // Handle success
  //             toast.success(response.message, {
  //                 position: "top-right",
  //                 theme: "light",
  //             });
  //             // Refresh withdrawal requests after approval
  //             fetchData();
  //             toggleModal();
  //         } else {
  //             // Handle error
  //             toast.error(response.message, {
  //                 position: "top-right",
  //                 theme: "light",
  //             });
  //         }
  //     } catch (error) {
  //         console.error("Error approving withdrawal request:", error);
  //         // Handle error
  //         toast.error("Error approving withdrawal request", {
  //             position: "top-right",
  //             theme: "light",
  //         });
  //     }
  // };

  const fetchData = () => {
    const url = `${HOST_URL}withdraw/viewWithdrawals`
    const data = {}

    return ApiPostMethodWithToken(url, data, token)
      .then(response => {
        if (response.error === false) {
          const sortedData = response.withdrawalList.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
          setWithdrawList(sortedData)
          const pending = response.withdrawalList.filter(
            item => item.status === "Pending"
          )
          const approved = response.withdrawalList.filter(
            item => item.status === "Approved"
          )
          setWithdrawListPending(pending)
          setWithdrawListApproved(approved)
          setApprovedUserList(response.approvedUserList)
        } else {
          // Handle error
          console.error("Error fetching User's bank details:", response.message)
        }
      })
      .catch(error => {
        console.error("Error fetching User's bank details:", error)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  // useEffect(() => {
  //     setFormData({
  //         user_id:viewWithdrawalRequests._id,
  //         AccHoldername: viewWithdrawalRequests.AccHoldername,
  //         AccNo: viewWithdrawalRequests.AccNo,
  //         IfscCode: viewWithdrawalRequests.IfscCode,
  //         bankName: viewWithdrawalRequests.bankName,
  //         walletPoints: viewWithdrawalRequests.walletPoints,
  //     })
  // }, [])

  //Edit User's Bank Details

  const [formData, setFormData] = useState({
    AccHoldername: "",
    AccNo: "",
    IfscCode: "",
    bankName: "",
    walletPoints: "",
    note: "",
    requestId: "",
  })

  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    // Clear validation error when user starts typing
    setErrors({ ...errors, [name]: "" })
  }

  const validateForm = data => {
    let errors = {}

    // Validate Account Holder Name
    if (!data.AccHoldername.trim()) {
      errors.AccHoldername = "Account Holder Name is required"
    }

    // Validate Account Number
    if (data.AccNo == "") {
      errors.AccNo = "Account No. is required"
    } else if (!/^\d+$/.test(data.AccNo)) {
      errors.AccNo = "Account No. should contain only numbers"
    } else if (data.AccNo.length !== 11) {
      errors.AccNo = "Account No. must be only 11 digits long"
    }

    if (!data.IfscCode) {
      errors.IfscCode = "IFSC Code is required"
    }

    // Validate Bank Name
    if (!data.bankName.trim()) {
      errors.bankName = "Bank Name is required"
    } else if (!/^[A-Za-z\s]+$/.test(data.bankName.trim())) {
      errors.bankName = "Bank Name should contain only letters"
    }

    // Validate Wallet Points
    if (!data.walletPoints) {
      errors.walletPoints = "Wallet Points are required"
    } else if (!/^\d+$/.test(data.walletPoints)) {
      errors.walletPoints = "Wallet Points should contain only numbers"
    }

    // Validate Note (optional)
    if (data.note.trim() === "" && !data.note < 50) {
      errors.note = "Note Field is Required "
    }

    return errors
  }

  // const validateWithdrawRequest = (data) => {
  //     let errors = {};

  //     // Validate Account Holder Name
  //     if (!data.AccHoldername.trim()) {
  //         errors.AccHoldername = "Account Holder Name is required";
  //     }

  //     // Validate Account Number
  //     if (!data.AccNo.trim()) {
  //         errors.AccNo = "Account Number is required";
  //     }

  //     // Validate IFSC Code
  //     if (!data.IfscCode.trim()) {
  //         errors.IfscCode = "IFSC Code is required";
  //     }

  //     // Validate Bank Name
  //     if (!data.bankName.trim()) {
  //         errors.bankName = "Bank Name is required";
  //     }

  //     // Validate Wallet Points
  //     if (!data.walletPoints.trim()) {
  //         errors.walletPoints = "Wallet Points are required";
  //     } else if (!/^\d+$/.test(data.walletPoints)) {
  //         errors.walletPoints = "Wallet Points should contain only numbers";
  //     }

  //     // Validate Note (optional)
  //     if (data.note && data.note.length > 100) {
  //         errors.note = "Note should not exceed 100 characters";
  //     }

  //     return errors;
  // };

  const handleSubmit = () => {
    const validationErrors = validateForm(formData)
    if (Object.keys(validationErrors).length === 0) {
      const { requestId, ...rest } = formData
      //   console.log("Form submitted:", formData)
      const url = `${HOST_URL}withdraw/requestApprove`
      const requestData = {
        requestId: requestId,
        ...rest,
      }
      ApiPostMethodWithToken(url, requestData, token)
        .then(response => {
          if (response.error === false) {
            // Handle success
            toast.success(response.message, {
              position: "top-right",
              theme: "light",
            })
            fetchData()
            toggleModal()
          } else {
            // Handle error
            toast.error(response.message, {
              position: "top-right",
              theme: "light",
            })
          }
        })
        .catch(error => {
          //   console.log("Error:", error)
          // Log more details about the error if available
          if (error.response) {
            // console.log("Server responded with:", error.response.data)
          }
        })
    } else {
      setErrors(validationErrors)
    }
  }

  // const handleSubmit = async () => {
  //     // Validate form data
  //     const formErrors = validateWithdrawRequest(formData);

  //     // If there are validation errors, update the errors state and prevent form submission
  //     if (Object.keys(formErrors).length > 0) {
  //         setErrors(formErrors);
  //         return;
  //     }

  //     // No validation errors, proceed with submitting the form data
  //     const url = `${HOST_URL}withdraw/requestApprove`;
  //     const data = {
  //         requestId: selectedRequestId,
  //         ...formData
  //     };

  //     try {
  //         const data = await ApiPostMethodWithToken(url, data, token);
  //         if (response.error === false) {
  //             // Handle success
  //             toast.success(response.message, {
  //                 position: "top-right",
  //                 theme: "light",
  //             });
  //             fetchData();
  //             toggleModal();
  //         } else {
  //             // Handle error
  //             toast.error(response.message, {
  //                 position: "top-right",
  //                 theme: "light",
  //             });
  //         }
  //     } catch (error) {
  //         console.error("Error approving withdrawal request:", error);
  //         toast.error("Error approving withdrawal request", {
  //             position: "top-right",
  //             theme: "light",
  //         });
  //     }
  // };

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  const handleDelete = async row => {
    // Show confirmation dialog
    Swal.fire({
      title: "Are you sure want to delete?",
      text: "You will not be able to recover this User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        // staff confirmed deletion, proceed with delete operation
        // console.log("Delete clicked for row:", row)
        const url = `${HOST_URL}withdraw/deleteWithdraw`
        const data = {
          requestId: row.rowData[1],
        }

        try {
          const response = await ApiPostMethodWithToken(url, data, token)
          if (response.error === false) {
            // Update staffList state after successful deletion
            const updatedWithdrawRequest = withdrawList.filter(
              withdraw => withdraw._id !== row.rowData[1]
            )
            setWithdrawList(updatedWithdrawRequest)

            toast.success(response.message, {
              position: "top-right",
              theme: "light",
            })
          } else {
            toast.error(response.message, {
              position: "top-right",
              theme: "light",
            })
          }
        } catch (error) {
          //   console.log("Error deleting staff:", error)
          toast.error("Error deleting staff", {
            position: "top-right",
            theme: "light",
          })
        }
      }
      fetchData()
    })
  }

  const handleView = row => {
    const viewData = row.rowData[1]
    const viewWithdrawalRequests = withdrawList.find(
      withdraw => withdraw._id === viewData
    )
    // console.log(viewWithdrawalRequests)
    if (viewWithdrawalRequests) {
      setFormData({
        requestId: viewWithdrawalRequests._id,
        AccHoldername: viewWithdrawalRequests.AccHoldername,
        AccNo: viewWithdrawalRequests.AccNo,
        IfscCode: viewWithdrawalRequests.IfscCode,
        bankName: viewWithdrawalRequests.bankName,
        walletPoints: viewWithdrawalRequests.walletPoints,
        note: viewWithdrawalRequests.note,
      })
      toggleModal()
    } else {
      console.error("Withdraw Request Not Found")
    }
  }

  const columns = [
    {
      name: "SN",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1
        },
      },
    },
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      label: "Username",
      name: "username",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Acc Holder Name",
      name: "AccHoldername",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Amount",
      name: "walletPoints",
      options: {
        filter: true,
        sort: true,
      },
    },
    ...(customActiveTab === "2"
      ? [
          {
            label: "Approved Date",
            name: "updatedAt",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                const date = new Date(value);
                return date.toLocaleDateString();
              },
            },
          },
        ]
      : 
      []
    ),
    {
      label: "REQUEST DATE",
      name: "createdAt",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          const date = new Date(value)
          return date.toLocaleDateString()
        },
      },
    },
    {
      name: "Action",
      label: 'ACTION',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {customActiveTab === "2" && (
                <Tooltip title="View">
                  <button
                    onClick={() => handleView(tableMeta)}
                    className="actionview me-1"
                  >
                    <i className="mdi mdi-eye-outline"></i>
                  </button>
                </Tooltip>
              )}
              {customActiveTab === "1" && (
                <>
                  <Tooltip title="View">
                    <button
                      onClick={() => handleView(tableMeta)}
                      className="actionview me-1"
                    >
                      <i className="mdi mdi-eye-outline"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <button
                      className="actionview"
                      onClick={() => handleDelete(tableMeta)}
                    >
                      <i className="mdi mdi-delete-outline"></i>
                    </button>
                  </Tooltip>
                </>
              )}
            </>
          )
        },
      },
    },
  ]

  const options = {
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        displayRows: "Of",
      },
    },
    selectableRows: "none",
    responsive: "standard",
    download: true,
    viewColumns: false,
    filter: true,
    print: true,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg={12} className="pe-0 pt-2 pb-2">
            <Card className="border-right-top">
              <CardBody>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item xs={6} style={{ marginTop: "5px" }}>
                    <h5 className="withhead">Withdraw Request</h5>
                  </Grid>
                  <Grid item xs={6} container justifyContent="flex-end">
                    {/* <Link to="/add-property" className='commongradient addbutton' >Add Property</Link> */}
                  </Grid>
                </Grid>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Nav
                          tabs
                          className="nav-tabs-custom"
                          style={{ border: "none" }}
                        >
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1")
                              }}
                            >
                              <span className="d-xs-block d-sm-block tabs2">
                                Pending
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classNames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2")
                              }}
                            >
                              <span className="d-xs-block d-sm-block tabs2">
                                Approved
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent
                          activeTab={customActiveTab}
                          className="p-0 text-muted"
                        >
                          <TabPane tabId="1">
                            <Row>
                              <Col sm="12" className="p-1 ps-0 pe-0">
                                <MUIDataTable
                                  data={
                                    withdrawListPending && withdrawListPending
                                  }
                                  columns={columns}
                                  options={options}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                        <TabContent
                          activeTab={customActiveTab}
                          className="p-0 text-muted"
                        >
                          <TabPane tabId="2">
                            <Row>
                              <Col sm="12" className="p-1 ps-0 pe-0">
                                <MUIDataTable
                                  data={withdrawListApproved}
                                  columns={columns}
                                  options={options}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {customActiveTab === "1" ? (
        <Modal show={isModalOpen} onHide={toggleModal} centered>
          <div
            className="modalwithdraw"
            style={{
              width: "100%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: 20,
              maxHeight: "600px",
              overflow: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>View Bank Details</h5>
              <IconButton
                onClick={toggleModal}
                style={{ marginRight: "-10px" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Account Holder Name"
                      type="text"
                      id="AccHoldername"
                      name="AccHoldername"
                      value={formData.AccHoldername}
                      onChange={handleChange}
                      error={errors.AccHoldername ? true : false}
                      helperText={
                        errors.AccHoldername ? errors.AccHoldername : ""
                      }
                    />
                    {errors.AccHoldername ? true : false}
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Account Number"
                      name="AccNo"
                      value={formData.AccNo}
                      onChange={handleChange}
                      error={errors.AccNo ? true : false}
                      helperText={errors.AccNo ? errors.AccNo : ""}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="IFSC Code"
                      name="IfscCode"
                      value={formData.IfscCode}
                      onChange={handleChange}
                      error={errors.IfscCode ? true : false}
                      helperText={errors.IfscCode ? errors.IfscCode : ""}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Bank Name"
                      name="bankName"
                      value={formData.bankName}
                      onChange={handleChange}
                      error={errors.bankName ? true : false}
                      helperText={errors.bankName ? errors.bankName : ""}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Wallet Points"
                      name="walletPoints"
                      value={formData.walletPoints}
                      onChange={handleChange}
                      error={errors.walletPoints ? true : false}
                      helperText={
                        errors.walletPoints ? errors.walletPoints : ""
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Note"
                      name="note"
                      multiline
                      rows={4}
                      value={formData.note}
                      onChange={handleChange}
                      error={errors.note ? true : false}
                      helperText={errors.note ? errors.note : ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={toggleModal}
                variant="contained"
                className="commongradient addbutton"
                style={{ padding: "7px 20px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                className="commongradient addbutton"
                style={{ padding: "7px 20px" }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal show={isModalOpen} onHide={toggleModal} centered>
          <div
            className="modalwithdraw"
            style={{
              width: "100%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: 20,
              maxHeight: "600px",
              overflow: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>View Bank Details</h5>
              <IconButton
                onClick={toggleModal}
                style={{ marginRight: "-10px" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      margin="normal"
                      label="Account Holder Name"
                      name="AccHoldername"
                      value={formData.AccHoldername}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      margin="normal"
                      label="Account Number"
                      name="AccNo"
                      value={formData.AccNo}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      margin="normal"
                      label="IFSC Code"
                      name="IfscCode"
                      value={formData.IfscCode}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      margin="normal"
                      label="Bank Name"
                      name="bankName"
                      value={formData.bankName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      margin="normal"
                      label="Wallet Points"
                      name="walletPoints"
                      value={formData.walletPoints}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      margin="normal"
                      label="Note"
                      name="note"
                      multiline
                      rows={4}
                      value={formData.note}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <div style={{ margin: 'auto' }}>
                            <Button onClick={handleSubmit} variant="contained" className='commongradient addbutton' style={{ padding: '7px 20px', marginLeft: 10 }}>Submit</Button>
                            <Button onClick={toggleModal} variant="contained" className='commongradient addbutton' style={{ padding: '7px 20px', marginLeft: 10 }}>Cancel</Button>
                        </div> */}
          </div>
        </Modal>
      )}
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default WithdrawRequest
