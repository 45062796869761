import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
} from "reactstrap"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import Select from "react-select"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"

const OtherDetails = React.forwardRef(
  ({ propertytype, lookingfor, propData }, ref) => {
    // const [pgOtherD, setPgOtherD] = useState({
    //   onetimeMoveInCharges: "",
    //   mealChargesPerMonth: "",
    //   electricityChargesPerMonth: "",
    //   addAdditionalInformation: "",
    // })
    const [pgOtherD, setPgOtherD] = useState({
      onetimeMoveInCharges: propData?.otherDetails?.onetimeMoveInCharges || "",
      mealChargesPerMonth: propData?.otherDetails?.mealChargesPerMonth || "",
      electricityChargesPerMonth:
        propData?.otherDetails?.electricityChargesPerMonth || "",
      addAdditionalInformation:
        propData?.otherDetails?.addAdditionalInformation || "",
    })

    const handleChange = e => {
      const { name, value } = e.target
      setPgOtherD(prevDetails => ({
        ...prevDetails,
        [name]: value,
      }))
    }
    const [textcount, settextcount] = useState(0)
    function textareachange(event) {
      settextcount(event.target.value.length)
      const stringValue = event.target.value
      setPgOtherD(prevDetails => ({
        ...prevDetails,
        addAdditionalInformation: stringValue,
      }))
    }

    const formatAmount = amount => {
      if (amount < 1000) {
        return amount
      } else if (amount >= 1000 && amount < 100000) {
        return (amount / 1000).toFixed(2) + "k"
      } else if (amount >= 100000 && amount < 10000000) {
        return (amount / 100000).toFixed(2) + "L"
      } else if (amount >= 10000000) {
        return (amount / 10000000).toFixed(2) + "Cr"
      }
    }

    const handleSubmit = async () => {
      var status
      var response
      const url = `${HOST_URL}property/otherdetailsPG`
      const token = JSON.parse(localStorage.getItem("token"))
      const property_id = JSON.parse(localStorage.getItem("property_id"))
      const user_id = JSON.parse(localStorage.getItem("authUser"))
      const user_Id = localStorage.getItem("user_Id")
      const data = {
        user_id: user_Id || user_id._id,
        property_id: property_id,
        otherDetails: pgOtherD,
      }
      await ApiPostMethodWithToken(url, data, token).then(resp => {
        if (resp.error === false) {
          status = true
          response = resp
        } else {
          status = false
          response = resp
        }
      })
      return { status: status, message: response.message }
    }
    React.useImperativeHandle(ref, () => ({
      handlePgOtherD: () => handleSubmit(),
    }))
    return (
      <React.Fragment>
        <h5>Add Room Details</h5>
        <Form>
          <Row>
            <Col md={12}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  Onetime Move in Charges (Optional)
                </label>
                <div className="col-lg-12 d-flex built-up">
                  <Input
                    id="onetimeMoveInCharges"
                    name="onetimeMoveInCharges"
                    type="number"
                    className={`form-control`}
                    // placeholder="Enter Built Up Area"
                    value={pgOtherD.onetimeMoveInCharges}
                    onChange={handleChange}
                    min={0}
                    onWheel={e => e.target.blur()}
                  />
                  <span className="input-group-append">
                    <span className="input-group-text font-weight-bold">
                      ₹{formatAmount(pgOtherD.onetimeMoveInCharges)}
                    </span>
                  </span>
                </div>
              </Row>
            </Col>
            <Col md={12}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  Meal Charges per Month (Optional)
                </label>
                <div className="col-lg-12 d-flex built-up">
                  <Input
                    id="mealChargesPerMonth"
                    name="mealChargesPerMonth"
                    type="number"
                    className={`form-control`}
                    // placeholder="Enter Built Up Area"
                    value={pgOtherD.mealChargesPerMonth}
                    onChange={handleChange}
                    min={0}
                    onWheel={e => e.target.blur()}
                  />
                  <span className="input-group-append">
                    <span className="input-group-text font-weight-bold">
                      ₹{formatAmount(pgOtherD.mealChargesPerMonth)}
                    </span>
                  </span>
                </div>
              </Row>
            </Col>
            <Col md={12}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  Electricity Charges per Month (Optional)
                </label>
                <div className="col-lg-12 d-flex built-up">
                  <Input
                    id="electricityChargesPerMonth"
                    name="electricityChargesPerMonth"
                    type="number"
                    className={`form-control`}
                    // placeholder="Enter Built Up Area"
                    value={pgOtherD.electricityChargesPerMonth}
                    onChange={handleChange}
                    min={0}
                    onWheel={e => e.target.blur()}
                  />
                  <span className="input-group-append">
                    <span className="input-group-text font-weight-bold">
                      ₹{formatAmount(pgOtherD.electricityChargesPerMonth)}
                    </span>
                  </span>
                </div>
              </Row>
            </Col>
            <Col md={12}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  Add Additional Information (Optional)
                </label>
                <div className="col-lg-12">
                  <Input
                    type="textarea"
                    id="textarea"
                    value={pgOtherD.addAdditionalInformation}
                    onChange={e => {
                      textareachange(e)
                    }}
                    maxLength="2000"
                    rows="3"
                  />
                  <span className="badgecount d-block badge-success text-end w-100">
                    {" "}
                    {textcount} / 2000{" "}
                  </span>
                </div>
              </Row>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
)

export default OtherDetails
