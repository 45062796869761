import React, { useState } from "react"
import loginimage from "../../assets/images/loginRegister.png"
import logo from "../../assets/images/logo-dark.png"
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material"
import OtpInput from "react18-input-otp"
import { Col, Container } from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import { HOST_URL } from "helpers/url_helper"
import Hidden from "@mui/material/Hidden"
import logo11 from "../../assets/images/logo-dark.png"
import Home from "../../assets/images/Home.png"
import { Image } from "react-bootstrap"
import { useEffect } from "react"

// import { Visibility, VisibilityOff } from "@mui/icons-material"

const ForgotPassword = () => {
  const router = useNavigate()
  const [inputvalue, setInputValue] = useState({
    mobno: "",
  })
  const [inputvalueerror, setInputValueError] = useState({
    mobno: "",
  })

  const [verifyNo, setVerifyNo] = useState(false)
  const [otpVerified, setOtpVerified] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [otpValue, setOtpValue] = useState("")
  const [otpValueerr, setOtpValueErr] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState("")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleOtpChange = value => {
    setOtpValue(value)
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setInputValue(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }))
    setInputValueError(prevErrors => ({
      ...prevErrors,
      [name]: "",
    }))
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handlePasswordChange = e => {
    setNewPassword(e.target.value)
  }

  const handleConfirmPasswordChange = e => {
    setConfirmNewPassword(e.target.value)
  }

  const handleVerifyNo = async () => {
    const newErrors = {}

    if (inputvalue.mobno.trim() === "") {
      newErrors.mobno = "Mobile number is required"
    }

    setInputValueError(newErrors)
    if (Object.values(newErrors).every(error => error === "")) {
      const url = `${HOST_URL}auth/verifyno`
      // const url = "http://192.168.1.7:5000/auth/verifyno"

      const headers = {
        "Content-Type": "application/json",
      }
      await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(inputvalue),
      })
        .then(resp => resp.json())
        .then(response => {
          // console.log("response", response)
          if (response.error === false) {
            setVerifyNo(true)
            setOtpSent(true)
            toast.success(`Otp has been sent to your mobile number...`, {
              position: "top-right",
              theme: "light",
            })
            localStorage.removeItem("authUser")
          }
        })
        .catch(error => {
          throw error
        })
    } else {
      return false
    }
  }

  const handleUpdatePassword = async () => {
    const newErrors = {}

    if (inputvalue.mobno.trim() === "") {
      newErrors.mobno = "Mobile number is required"
    }
    if (newPassword.trim() === "") {
      newErrors.newPassword = "Password is required"
    }
    if (confirmNewPassword.trim() === "") {
      newErrors.confirmNewPassword = "Password is required"
    }
    if (newPassword !== confirmNewPassword) {
      newErrors.confirmNewPassword = "Passwords do not match"
    }

    setInputValueError(newErrors)
    if (Object.values(newErrors).every(error => error === "")) {
      // const url = "http://192.168.1.7:5000/auth/updatepass"
      const url = `${HOST_URL}auth/updatepass`

      const headers = {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      }

      const data = {
        mobno: inputvalue.mobno,
        newpassword: newPassword,
      }

      await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(resp => resp.json())
        .then(response => {
          // console.log("response", response)
          if (response.error === false) {
            setVerifyNo(true)
            setOtpSent(true)
            toast.success(`Your password has been updated...`, {
              position: "top-right",
              theme: "light",
            })
            router("/Login")
          }
        })
        .catch(error => {
          throw error
        })
    } else {
      return false
    }
  }

  const handleSubmit = async () => {
    if (!otpValue.length === 6 || otpValue === "") {
      setOtpValueErr("Please Enter Valid OTP!....")
    } else {
      // const url = `http://192.168.1.7:5000/auth/verifyotp`
      const url = `${HOST_URL}auth/verifyotp`

      const headers = {
        "Content-Type": "application/json",
      }
      const data = {
        mobno: inputvalue.mobno,
        otp: otpValue,
      }

      const jsonString = `{"mobno": "${data.mobno}", "otp": "${data.otp}"}`

      await fetch(url, {
        method: "POST",
        headers: headers,
        body: jsonString,
      })
        .then(resp => resp.json())
        .then(response => {
          // console.log("response", response)
          if (response.error === false) {
            localStorage.setItem("token", response.token)
            setOtpValue("")
            setOtpVerified(true)
          } else {
            toast.error(`Error Verifying OTP!...`, {
              position: "top-right",
              theme: "light",
            })
          }
        })
        .catch(error => {
          throw error
        })
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Container fluid className="container-fluid">
      <Box>
        <Grid container spacing={2}>
          {/* Left Side of Login Page */}
          <Grid item lg={6} md={6} xs={12} className="back-img">
            <div
              style={{
                marginBottom: "15px",
                marginTop: "15px",
                display: "flex",
              }}
            >
              <Image src={logo11} style={{ width: "200px" }} />
            </div>
            <Grid lg={9}>
              <h1 class="heading">Are You Looking For Your Ideal Home?</h1>
            </Grid>
            <img src={Home} alt="" class="back-img1 "></img>
          </Grid>
          {/* Left Side of Login Page End */}

          <Grid item lg={6} md={6} xs={12}>
            {/* Right side content */}
            <div className=" right-side-container">
              <Paper style={{ paddingTop: "20px", boxShadow: "none" }}>
                {/* Form content */}
                <Box style={{ padding: "20px" }}>
                  <Hidden smDown>
                    <Box
                      style={{
                        display: windowWidth > 599 ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyContent: "end",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography mr={3}>Already have an account</Typography>
                      <Button style={{ textTransform: "none" }}
                        variant="contained"
                        className="freehousing_button addbutton"
                        onClick={() => router("/Login")}
                      >
                        Login
                      </Button>
                    </Box>
                  </Hidden>
                  <Box
                    style={{
                      // padding: "35px 85px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "40px",
                    }}
                  >
                    <p className="forgetHead">
                      <Typography variant="h5" fontWeight={600}>
                        Welcome to Real Estate!
                      </Typography>
                      <Typography color={"#8E8E8E"}>Reset your password</Typography>.
                    </p>
                    <Box className="forgetMain" style={{width:"75%", marginTop: "20px" }}>
                      <div className="loginregwrap">
                        <label className="loginreglabel">Mobile Number</label>
                        <input
                          type="text"
                          id="mobno"
                          name="mobno"
                          value={inputvalue.mobno}
                          onChange={handleInputChange}
                          placeholder="Enter mobile number"
                          className="loginRegInput"
                          disabled={otpSent}
                        />
                        {inputvalueerror.mobno && (
                          <span className="error">{inputvalueerror.mobno}</span>
                        )}
                      </div>
                      {!verifyNo ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            className="freehousing_button addbutton"
                            variant="contained"
                            style={{
                              textTransform: "none",
                              width: "300px",
                              height: "40px",
                              justifyContent: "center",
                            }}
                            onClick={handleVerifyNo}
                          >
                            Send
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}


                      {verifyNo && !otpVerified ? (
                        <Box>
                          <Grid>
                            <Grid item xs={12} md={9} lg={9} style={{margin:"auto"}}>
                              <label className="d-flex justify-content-center mt-4">Please enter the One-Time Password</label>
                              <OtpInput
                                className="otp-input2"
                                value={otpValue}
                                onChange={handleOtpChange}
                                numInputs={6}
                                containerStyle={{ justifyContent: "space-between" }}
                                inputStyle={{
                                  width: "100%",
                                  margin: "8px",
                                  padding: "10px",
                                  border: "none",
                                  borderBottom: "1px solid #000",
                                  outline: "none",
                                }}
                              />
                              {otpValueerr && (
                                <span className="error">{otpValueerr}</span>
                              )}
                            </Grid>

                            <Box
                              className="login-button-container"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                              }}
                            >
                              <Button
                                className="freehousing_button"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={handleSubmit}
                                style={{
                                  textTransform: "none",
                                  width: "100%",
                                  height: "35px",
                                  justifyContent: "center",
                                }}
                              >
                                Validate
                              </Button>
                            </Box>
                          </Grid>
                        </Box>
                      ) : (
                        ""
                      )}

                      {otpVerified ? (
                        <Box>
                          <Grid>
                            <Grid item xs={12} md={9} lg={9}>
                              <div className="loginregwrap">
                                <label className="loginreglabel">
                                  New Password
                                </label>
                                <input
                                  type="password"
                                  id="newPassword"
                                  name="newPassword"
                                  value={newPassword}
                                  onChange={handlePasswordChange}
                                  placeholder="Enter new password"
                                  className="loginRegInput"
                                // InputProps={{
                                //   endAdornment: (
                                //     <InputAdornment position="end">
                                //       <IconButton
                                //         onClick={togglePasswordVisibility}
                                //       >
                                //         {showPassword ? (
                                //           <i className="mdi mdi-eye-off-outline"></i>
                                //         ) : (
                                //           <i className="mdi mdi-eye-outline"></i>
                                //         )}
                                //       </IconButton>
                                //     </InputAdornment>
                                //   ),
                                // }}
                                />
                                {inputvalueerror.newPassword && (
                                  <span className="error">
                                    {inputvalueerror.newPassword}
                                  </span>
                                )}
                              </div>
                            </Grid>

                            <Grid item xs={12} md={9} lg={9}>
                              <div className="loginregwrap">
                                <label className="loginreglabel">
                                  Confirm New Password
                                </label>
                                <input
                                  type="password"
                                  id="confirmNewPassword"
                                  name="confirmNewPassword"
                                  value={confirmNewPassword}
                                  onChange={handleConfirmPasswordChange}
                                  placeholder="Enter confirm new password"
                                  className="loginRegInput"
                                />
                                {inputvalueerror.confirmNewPassword && (
                                  <span className="error">
                                    {inputvalueerror.confirmNewPassword}
                                  </span>
                                )}
                              </div>
                            </Grid>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                              }}
                            >
                              <Button
                                className="freehousing_button"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={handleUpdatePassword}
                                style={{
                                  textTransform: "none",
                                  width: "300px",
                                  height: "40px",
                                  justifyContent: "center",
                                }}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Grid>
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box
                        style={{
                          display: windowWidth < 600 ? "flex" : "none",
                          justifyContent: "center",
                          alignItems: "center",
                          width:"60%",
                          margin:"auto",
                          marginBottom:"-10px"
                        }}
                      >
                        <div className="logindivborder"></div>
                        <span style={{ padding: "10px" }} className="orline">
                          OR
                        </span>
                        <div className="logindivborder"></div>
                      </Box>
                      <Hidden smUp>
                        <Grid style={{ marginTop: "0" }}>
                          <Box className="login-button-container">
                            <Button
                              variant="contained"
                              className="freehousing_button"
                              style={{
                                textTransform: "none",
                                width: "100%",
                                justifyContent: "center",
                                height: "35px",
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              onClick={() => router("/Login")}
                            >
                              Login
                            </Button>
                          </Box>
                        </Grid>
                      </Hidden>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Box>
      {/* <ToastContainer /> */}
    </Container>
  )
}

export default ForgotPassword
