import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
} from "reactstrap"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { ApiGetMethod, ApiPostMethod } from "helpers/withoutToken_helper"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import Select from "react-select"
import { ToastContainer, toast } from "react-toastify"

const PropertyDetails = React.forwardRef(
  ({ propertytype, lookingfor, searchCity,cityId, propData }, ref) => {
    const entryTime = [
      {
        label: "Select Time",
        options: [
          { label: "8PM", value: "8PM" },
          { label: "9PM", value: "9PM" },
          { label: "10PM", value: "10PM" },
          { label: "11PM", value: "11PM" },
        ],
      },
    ]
    const common = [
      { id: 1, value: "Living Room" },
      { id: 2, value: "Kitchen" },
      { id: 3, value: "Dining Hall" },
      { id: 4, value: "Study Room/ Library" },
      { id: 5, value: "Breakout Room" },
    ]
    const meals = [
      { id: 1, value: "Breakfast" },
      { id: 2, value: "Lunch" },
      { id: 3, value: "Dinner" },
    ]
    const user_id = JSON.parse(localStorage.getItem("authUser"))
    const user_Id = localStorage.getItem("user_Id")
    const user_role = JSON.parse(localStorage.getItem("userRole"))
    const [buildingProjects, setBuildingProjects] = useState(
      propData?.address?.buildingProjects || ""
    )
    const [buildingProjectsErr, setBuildingProjectsErr] = useState("")
    const [locality, setLocality] = useState(propData?.address?.locality || "")
    const [otherLocality, setOtherLocality] = useState(
      propData?.address?.otherLocality || ""
    )
    const [otherLocalityErr, setOtherLocalityErr] = useState("")
    const [localityErr, setLocalityErr] = useState("")
    // const [pgPropertyDetails, setPgPropertyDetails] = useState({
    //   pgdetails: {
    //     pgName: "",
    //     totalBeds: "",
    //     pgIsFor: "",
    //     bestSuitedFor: "",
    //     mealsAvailable: "",
    //     mealsOffering: [],
    //     noticePeriod: "",
    //     lockInPeriod: "",
    //     commonAreas: [],
    //   },
    //   ownerdetails: {
    //     propertyManagedBy: "",
    //     propertyManagerStaysAtProperty: "",
    //   },
    //   pgRules: {
    //     nonVegAllowed: "",
    //     oppositSexAllowed: "",
    //     anyTimeAllowed: "",
    //     lastTimeEntry: "",
    //     visitorsAllowed: "",
    //     guardianAllowed: "",
    //     drinkingAllowed: "",
    //     smokingAllowed: "",
    //   },
    // })
    const [pgPropertyDetails, setPgPropertyDetails] = useState({
      pgdetails: {
        pgName: propData?.pgPropDetails?.pgdetails?.pgName || "",
        totalBeds: propData?.pgPropDetails?.pgdetails?.totalBeds || "",
        pgIsFor: propData?.pgPropDetails?.pgdetails?.pgIsFor || "",
        bestSuitedFor: propData?.pgPropDetails?.pgdetails?.bestSuitedFor || "",
        mealsAvailable:
          propData?.pgPropDetails?.pgdetails?.mealsAvailable || "",
        mealsOffering: propData?.pgPropDetails?.pgdetails?.mealsOffering || [],
        noticePeriod: propData?.pgPropDetails?.pgdetails?.noticePeriod || "",
        lockInPeriod: propData?.pgPropDetails?.pgdetails?.lockInPeriod || "",
        commonAreas: propData?.pgPropDetails?.pgdetails?.commonAreas || [],
      },
      ownerdetails: {
        propertyManagedBy:
          propData?.pgPropDetails?.ownerdetails?.propertyManagedBy || "",
        propertyManagerStaysAtProperty:
          propData?.pgPropDetails?.ownerdetails
            ?.propertyManagerStaysAtProperty || "",
      },
      pgRules: {
        nonVegAllowed: propData?.pgPropDetails?.pgRules?.nonVegAllowed || "",
        oppositSexAllowed:
          propData?.pgPropDetails?.pgRules?.oppositSexAllowed || "",
        anyTimeAllowed: propData?.pgPropDetails?.pgRules?.anyTimeAllowed || "",
        lastTimeEntry: propData?.pgPropDetails?.pgRules?.lastTimeEntry || "",
        visitorsAllowed:
          propData?.pgPropDetails?.pgRules?.visitorsAllowed || "",
        guardianAllowed:
          propData?.pgPropDetails?.pgRules?.guardianAllowed || "",
        drinkingAllowed:
          propData?.pgPropDetails?.pgRules?.drinkingAllowed || "",
        smokingAllowed: propData?.pgPropDetails?.pgRules?.smokingAllowed || "",
      },
    })

    const [errors, setErrors] = useState({
      pgdetails: {},
      ownerdetails: {},
      pgRules: {},
    })

    const handleproject = e => {
      setBuildingProjects(e.target.value)
      setBuildingProjectsErr("")
    }
    const handlelocality = e => {
      setLocality(e.target.value)
      setLocalityErr("")
    }

    const handleChange = (name, value, category) => {
      setPgPropertyDetails(prevDetails => ({
        ...prevDetails,
        [category]: {
          ...prevDetails[category],
          [name]: value,
        },
      }))

      if (name == "totalBeds") {
        localStorage.setItem("totalBeds", value)
      }

      setErrors(prevErrors => ({
        ...prevErrors,
        [category]: {
          ...prevErrors[category],
          [name]: "",
        },
      }))
    }

    const [localities, setLocalities] = useState([])

    // console.log("is localities---", localities)

    const fetchData = async () => {
      try {
        const postUrl = `${HOST_URL}api/viewLocalities` // Replace 'your_api_endpoint_here' with your actual API endpoint

        const response = await ApiGetMethod(postUrl)
        const localities = [...response[0].localities]
        const locality = response.find(data=> data._id == cityId)
        const localities1 = locality.localities.includes("Other") ? locality.localities : ["Other", ...locality.localities];
        setLocalities(localities1)
        // setLocalities(localities)

        // console.log("loaclities form response---", response)
      } catch (error) {
        console.error("Error:", error)
      }
    }
    useEffect(() => {
      fetchData()
    }, [cityId])

    // function handleLocalityGroup(selectedGroup) {
    //   setLocality(prevDetails => ({
    //     ...prevDetails,
    //     locality: selectedGroup.value,
    //   }))
    //   setLocalityErr(prevErrors => ({
    //     ...prevErrors,
    //     locality: "",
    //   }))
    // }

    function handleLocalitySelectGroup(selectedGroup) {
      setLocality(selectedGroup.value)
      setLocalityErr("")
    }
    function handleotherloca(e) {
      setOtherLocality(e.target.value)
      setOtherLocalityErr("")
    }

    const handleCommonAreas = (name, value, category) => {
      setPgPropertyDetails(prevState => {
        const updatedPgdetails = { ...prevState.pgdetails }

        if (updatedPgdetails[name].includes(value)) {
          updatedPgdetails[name] = updatedPgdetails[name].filter(
            area => area !== value
          )
        } else {
          updatedPgdetails[name] = [...updatedPgdetails[name], value]
        }

        return { ...prevState, pgdetails: updatedPgdetails }
      })
    }

    function handleSelectGroup(selectedGroup) {
      // console.log("selectedGroup", selectedGroup)

      setPgPropertyDetails(prevDetails => ({
        ...prevDetails,
        pgRules: {
          ...prevDetails.pgRules,
          lastTimeEntry: selectedGroup.value,
        },
      }))

      setErrors(prevErrors => ({
        ...prevErrors,
        pgRules: {
          ...prevErrors.pgRules,
          lastTimeEntry: "",
        },
      }))
    }

    // console.log("handleCommonAreas", pgPropertyDetails)

    const handleLocality = async () => {
      if (otherLocality == "") {
        setOtherLocalityErr("Locality is required")
      } else {
        const url = `${HOST_URL}api/addLocality`
        const payload = {
          city: searchCity,
          locality: otherLocality,
        }
        await ApiPostMethod(url, payload).then(response => {
          if (response.error === false) {
            fetchData()
            setOtherLocality("")
            setLocality(response.locality)
            toast.success(response.message, {
              position: "top-right",
              theme: "light",
            })
          } else {
            toast.error(response.message, {
              position: "top-right",
              theme: "light",
            })
          }
        })
      }
    }

    function toReadableText(fieldName) {
      return fieldName
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, str => str.toUpperCase());
    }

    const handleSubmit = async () => {
      const newErrors = { pgdetails: {}, ownerdetails: {}, pgRules: {} }

      Object.keys(pgPropertyDetails).forEach(category => {
        Object.keys(pgPropertyDetails[category]).forEach(field => {
          if (field === "commonAreas") {
            if (pgPropertyDetails[category][field].length === 0) {
              newErrors[category][field] = `${toReadableText(field)} is required`
            }
          } else if (field == "mealsOffering") {
            if (pgPropertyDetails.pgdetails.mealsAvailable == "Yes") {
              if (pgPropertyDetails[category][field].length === 0) {
                newErrors[category][field] = `${toReadableText(field)} is required`
              }
            }
          } else if (field == "lastTimeEntry") {
            if (pgPropertyDetails.pgRules.anyTimeAllowed == "No") {
              if (pgPropertyDetails[category][field].trim() === "") {
                newErrors[category][field] = `${toReadableText(field)} is required`
              }
            }
          } else if (
            field !== "pgName" &&
            field !== "mealsOffering" &&
            field !== "lastTimeEntry" &&
            pgPropertyDetails[category][field] == ""
          ) {
            newErrors[category][field] = `${toReadableText(field)} is required`
          }
        })
      })
      if (
        Object.values(newErrors).every(
          categoryErrors => Object.values(categoryErrors).length === 0
        )
      ) {
        if (buildingProjects.trim() !== "") {
          if (locality.trim() !== "") {
            var status
            var response
            const token = JSON.parse(localStorage.getItem("token"))
            const property_id = JSON.parse(localStorage.getItem("property_id"))
            const url = `${HOST_URL}property/propdetailsPG`
            const data = {
              property_id: property_id,
              user_id: user_Id || user_id._id,
              pgPropDetails: pgPropertyDetails,
              address: {
                buildingProjects: buildingProjects,
                locality: locality,
                otherLocality: otherLocality,
              },
            }
            await ApiPostMethodWithToken(url, data, token).then(resp => {
              if (resp.error === false) {
                status = true
                response = resp
              } else {
                status = false
                response = resp
              }
            })
            return { status: status, message: response.message }
          } else {
            setLocalityErr("This field is required")
          }
        } else {
          setBuildingProjectsErr("This field is required")
        }
      } else {
        setErrors(newErrors)
        console.log("newErrors", newErrors)
        const totalErrors = {...newErrors.ownerdetails,...newErrors.pgRules,...newErrors.pgdetails}
        return { status: false, message: formatValidationMessage(totalErrors) }
      }
    }

    function formatValidationMessage(errors) {
      const keys = Object.keys(errors).map(key => 
          errors[key].replace(' is required', '')
      );
      const joinedKeys = keys.length > 1 
          ? keys.slice(0, -1).join(', ') + ' and ' + keys[keys.length - 1] 
          : keys[0];

      return `${joinedKeys} is required.`;
    }

    // console.log("error", errors)

    React.useImperativeHandle(ref, () => ({
      handlePgPropD: () => handleSubmit(),
    }))
    return (
      <React.Fragment>
        <Form>
          <h5 className="">Add Property Details</h5>
          <Row>
            <Col md={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="ageOfProperty"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Building/Project/Society
                </label>
                <div className="col-lg-12">
                  <Input
                    id="buildingProjects"
                    name="buildingProjects"
                    type="text"
                    className={`form-control`}
                    // placeholder=""
                    value={buildingProjects}
                    onChange={handleproject}
                  />
                  {buildingProjectsErr && (
                    <span className="error">{buildingProjectsErr}</span>
                  )}
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="ageOfProperty"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Locality
                </label>
                <div className="col-lg-12">
                  {/* <Input
                    id="locality"
                    name="locality"
                    type="text"
                    className={`form-control`}
                    value={locality}
                    onChange={handlelocality}
                  /> */}
                  <Select
                    id="locality"
                    name="locality"
                    value={{
                      label: locality,
                      value: locality,
                    }}
                    // value={rrentAddressDetails.locality}
                    onChange={selectedValue => {
                      handleLocalitySelectGroup(selectedValue)
                    }}
                    options={localities.map(locality => ({
                      value: locality,
                      label: locality,
                    }))}
                    classNamePrefix="select2-selection"
                  />
                  {localityErr && <span className="error">{localityErr}</span>}
                </div>
              </Row>
            </Col>
            {locality == "Other" ? (
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="otherLocality"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Your Locality
                  </label>
                  <div className="col-lg-12 d-flex">
                    <Input
                      id="otherLocality"
                      name="otherLocality"
                      type="text"
                      className={`form-control`}
                      value={otherLocality}
                      onChange={handleotherloca}
                    />
                    {user_id.role == "manager" ||
                      (user_id.role == "admin" && (
                        <Button
                          className="commongradient"
                          style={{ color: "white", marginLeft: "5px" }}
                          onClick={handleLocality}
                        >
                          Add
                        </Button>
                      ))}
                  </div>
                  {otherLocalityErr && (
                    <span className="error">{otherLocalityErr}</span>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <h5 className="mt-2">PG Details</h5>
          <Row className="">
            <Col lg={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  PG Name (Optional)
                </label>
                <div className="col-lg-12 d-flex">
                  <Input
                    id="pgName"
                    name="pgName"
                    type="text"
                    className={`form-control`}
                    // placeholder="Enter Built Up Area"
                    value={pgPropertyDetails.pgdetails.pgName}
                    onChange={e =>
                      handleChange(e.target.name, e.target.value, "pgdetails")
                    }
                  />
                </div>
              </Row>
            </Col>
            <Col lg={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Total Beds
                </label>
                <div className="col-lg-12 d-flex">
                  <Input
                    id="totalBeds"
                    name="totalBeds"
                    type="number"
                    className={`form-control`}
                    // placeholder="Enter Built Up Area"
                    value={pgPropertyDetails.pgdetails.totalBeds}
                    onChange={e =>
                      handleChange(e.target.name, e.target.value, "pgdetails")
                    }
                    onWheel={e => e.target.blur()}
                    min={0}
                  />
                </div>
                {errors.pgdetails.totalBeds && (
                  <span className="error">{errors.pgdetails.totalBeds}</span>
                )}
              </Row>
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="transaType"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  PG is for
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgdetails.pgIsFor === "Girls"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("pgIsFor", "Girls", "pgdetails")
                    }
                  >
                    Girls
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgdetails.pgIsFor === "Boys"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() => handleChange("pgIsFor", "Boys", "pgdetails")}
                  >
                    Boys
                  </Button>
                </ButtonGroup>
                {errors.pgdetails.pgIsFor && (
                  <span className="error">{errors.pgdetails.pgIsFor}</span>
                )}
              </Row>
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="bestSuitedFor"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Best suited for
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgdetails.bestSuitedFor === "Students"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("bestSuitedFor", "Students", "pgdetails")
                    }
                  >
                    Students
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgdetails.bestSuitedFor ===
                      "Professionals"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange(
                        "bestSuitedFor",
                        "Professionals",
                        "pgdetails"
                      )
                    }
                  >
                    Professionals
                  </Button>
                </ButtonGroup>
                {errors.pgdetails.bestSuitedFor && (
                  <span className="error">
                    {errors.pgdetails.bestSuitedFor}
                  </span>
                )}
              </Row>
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="transaType"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Meals Available
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgdetails.mealsAvailable === "Yes"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("mealsAvailable", "Yes", "pgdetails")
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgdetails.mealsAvailable === "No"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("mealsAvailable", "No", "pgdetails")
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
                {errors.pgdetails.mealsAvailable && (
                  <span className="error">
                    {errors.pgdetails.mealsAvailable}
                  </span>
                )}
              </Row>
            </Col>
            {pgPropertyDetails.pgdetails.mealsAvailable == "Yes" ? (
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Meals Offering
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 amenitiesgrid">
                      {meals.map(face => (
                        <Button
                          key={face.id}
                          style={{ borderRadius: "5px" }}
                          className={
                            pgPropertyDetails.pgdetails &&
                            pgPropertyDetails.pgdetails.mealsOffering &&
                            pgPropertyDetails.pgdetails.mealsOffering.includes(
                              face.value
                            )
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleCommonAreas(
                              "mealsOffering",
                              face.value,
                              "pgdetails"
                            )
                          }
                        >
                          <Input
                            type="checkbox"
                            name="inFlatFurnishing"
                            value={face.value}
                            onClick={() =>
                              handleCommonAreas(
                                "mealsOffering",
                                face.value,
                                "pgdetails"
                              )
                            }
                            style={{ opacity: 0 }}
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                    {errors.pgdetails.mealsOffering && (
                      <span className="error">
                        {errors.pgdetails.mealsOffering}
                      </span>
                    )}
                  </div>
                </Row>
              </Col>
            ) : (
              ""
            )}
            <Col lg={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Notice Period (Days)
                </label>
                <div className="col-lg-12 d-flex">
                  <Input
                    id="noticePeriod"
                    name="noticePeriod"
                    type="number"
                    className={`form-control`}
                    // placeholder="Enter Built Up Area"
                    value={pgPropertyDetails.pgdetails.noticePeriod}
                    onChange={e =>
                      handleChange(e.target.name, e.target.value, "pgdetails")
                    }
                    onWheel={e => e.target.blur()}
                    min={0}
                  />
                </div>
                {errors.pgdetails.noticePeriod && (
                  <span className="error">{errors.pgdetails.noticePeriod}</span>
                )}
              </Row>
            </Col>
            <Col lg={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Lock in Period (Days)
                </label>
                <div className="col-lg-12 d-flex">
                  <Input
                    id="lockInPeriod"
                    name="lockInPeriod"
                    type="number"
                    className={`form-control`}
                    // placeholder="Enter Built Up Area"
                    value={pgPropertyDetails.pgdetails.lockInPeriod}
                    onChange={e =>
                      handleChange(e.target.name, e.target.value, "pgdetails")
                    }
                    onWheel={e => e.target.blur()}
                    min={0}
                  />
                </div>
                {errors.pgdetails.lockInPeriod && (
                  <span className="error">{errors.pgdetails.lockInPeriod}</span>
                )}
              </Row>
            </Col>
            <Col md={12}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Common Areas
                </label>
                <div className="col-lg-12">
                  <ButtonGroup className="w-100 amenitiesgrid">
                    {common.map(face => (
                      <Button
                        key={face.id}
                        style={{ borderRadius: "5px" }}
                        className={
                          pgPropertyDetails.pgdetails &&
                          pgPropertyDetails.pgdetails.commonAreas &&
                          pgPropertyDetails.pgdetails.commonAreas.includes(
                            face.value
                          )
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleCommonAreas(
                            "commonAreas",
                            face.value,
                            "pgdetails"
                          )
                        }
                      >
                        <Input
                          type="checkbox"
                          name="inFlatFurnishing"
                          value={face.value}
                          onClick={() =>
                            handleCommonAreas(
                              "commonAreas",
                              face.value,
                              "pgdetails"
                            )
                          }
                          style={{ opacity: 0 }}
                        />
                        {face.value}
                      </Button>
                    ))}
                  </ButtonGroup>
                  {errors.pgdetails.commonAreas && (
                    <span className="error">
                      {errors.pgdetails.commonAreas}
                    </span>
                  )}
                </div>
              </Row>
            </Col>
          </Row>
          <span className="divider"></span>
          <h5 className="mt-4">Owner / Caretaker Details</h5>
          <Row>
            <Col lg={12}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="transaType"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Property Managed By
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.ownerdetails.propertyManagedBy ===
                      "Landlord"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange(
                        "propertyManagedBy",
                        "Landlord",
                        "ownerdetails"
                      )
                    }
                  >
                    Landlord
                  </Button>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.ownerdetails.propertyManagedBy ===
                      "Caretaker"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange(
                        "propertyManagedBy",
                        "Caretaker",
                        "ownerdetails"
                      )
                    }
                  >
                    Caretaker
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.ownerdetails.propertyManagedBy ===
                      "Dedicated Professional"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange(
                        "propertyManagedBy",
                        "Dedicated Professional",
                        "ownerdetails"
                      )
                    }
                  >
                    Dedicated Professional
                  </Button>
                </ButtonGroup>
                {errors.ownerdetails.propertyManagedBy && (
                  <span className="error">
                    {errors.ownerdetails.propertyManagedBy}
                  </span>
                )}
              </Row>
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="bestSuitedFor"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Property Manager stays at Property
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.ownerdetails
                        .propertyManagerStaysAtProperty === "Yes"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange(
                        "propertyManagerStaysAtProperty",
                        "Yes",
                        "ownerdetails"
                      )
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.ownerdetails
                        .propertyManagerStaysAtProperty === "No"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange(
                        "propertyManagerStaysAtProperty",
                        "No",
                        "ownerdetails"
                      )
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
                {errors.ownerdetails.propertyManagerStaysAtProperty && (
                  <span className="error">
                    {errors.ownerdetails.propertyManagerStaysAtProperty}
                  </span>
                )}
              </Row>
            </Col>
          </Row>
          <span className="divider"></span>
          <h5 className="mt-4">PG Rules</h5>
          <Row>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="bestSuitedFor"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Non Veg Allowed
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.nonVegAllowed === "Yes"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("nonVegAllowed", "Yes", "pgRules")
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.nonVegAllowed === "No"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("nonVegAllowed", "No", "pgRules")
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
                {errors.pgRules.nonVegAllowed && (
                  <span className="error">{errors.pgRules.nonVegAllowed}</span>
                )}
              </Row>
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="bestSuitedFor"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Opposite Sex Allowed
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.oppositSexAllowed === "Yes"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("oppositSexAllowed", "Yes", "pgRules")
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.oppositSexAllowed === "No"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("oppositSexAllowed", "No", "pgRules")
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
                {errors.pgRules.oppositSexAllowed && (
                  <span className="error">
                    {errors.pgRules.oppositSexAllowed}
                  </span>
                )}
              </Row>
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor=""
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Any Time Allowed
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.anyTimeAllowed === "Yes"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("anyTimeAllowed", "Yes", "pgRules")
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.anyTimeAllowed === "No"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("anyTimeAllowed", "No", "pgRules")
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
                {errors.pgRules.anyTimeAllowed && (
                  <span className="error">{errors.pgRules.anyTimeAllowed}</span>
                )}
              </Row>
            </Col>
            <Col lg={12}>
              {pgPropertyDetails.pgRules.anyTimeAllowed == "No" ? (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="ageOfProperty"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Last Time Entry
                    </label>
                    <div className="col-lg-12">
                      <Select
                        id="lastTimeEntry"
                        name="lastTimeEntry"
                        value={{
                          label: pgPropertyDetails?.pgRules?.lastTimeEntry,
                          value: pgPropertyDetails?.pgRules?.lastTimeEntry,
                        }}
                        onChange={selectedValue => {
                          handleSelectGroup(selectedValue)
                        }}
                        options={entryTime}
                        classNamePrefix="select2-selection"
                      />
                      {errors.pgRules.lastTimeEntry && (
                        <span className="error">
                          {errors.pgRules.lastTimeEntry}
                        </span>
                      )}
                    </div>
                  </Row>
                </Col>
              ) : (
                ""
              )}
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="bestSuitedFor"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Visitors Allowed
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.visitorsAllowed === "Yes"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("visitorsAllowed", "Yes", "pgRules")
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.visitorsAllowed === "No"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("visitorsAllowed", "No", "pgRules")
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
                {errors.pgRules.visitorsAllowed && (
                  <span className="error">
                    {errors.pgRules.visitorsAllowed}
                  </span>
                )}
              </Row>
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="bestSuitedFor"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Guardian Allowed
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.guardianAllowed === "Yes"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("guardianAllowed", "Yes", "pgRules")
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.guardianAllowed === "No"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("guardianAllowed", "No", "pgRules")
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
                {errors.pgRules.guardianAllowed && (
                  <span className="error">
                    {errors.pgRules.guardianAllowed}
                  </span>
                )}
              </Row>
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="bestSuitedFor"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Drinking Allowed
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.drinkingAllowed === "Yes"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("drinkingAllowed", "Yes", "pgRules")
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.drinkingAllowed === "No"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("drinkingAllowed", "No", "pgRules")
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
                {errors.pgRules.drinkingAllowed && (
                  <span className="error">
                    {errors.pgRules.drinkingAllowed}
                  </span>
                )}
              </Row>
            </Col>
            <Col lg={4}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="bestSuitedFor"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Smoking Allowed
                </label>
                <ButtonGroup>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.smokingAllowed === "Yes"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("smokingAllowed", "Yes", "pgRules")
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ borderRadius: "5px" }}
                    className={
                      pgPropertyDetails.pgRules.smokingAllowed === "No"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() =>
                      handleChange("smokingAllowed", "No", "pgRules")
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
                {errors.pgRules.smokingAllowed && (
                  <span className="error">{errors.pgRules.smokingAllowed}</span>
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
)

export default PropertyDetails
