import React, { useEffect, useState, useRef } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  Spinner,
} from "reactstrap"

import classnames from "classnames"
import { Link, useNavigate } from "react-router-dom"
import RentBasicDetails from "./ResidentialTabs/RentTabs/basicDetails"
import Rentaddressdetails from "./ResidentialTabs/RentTabs/addressdetails"
import Photouploadcomm from "./PhotoUpload/Photoupload"
import RentadvanceD from "./ResidentialTabs/RentTabs/advancedetails"
import Rentamenitiesdetails from "./ResidentialTabs/RentTabs/amenitiesdetails"
import Select from "react-select"
import { HOST_URL, REFER_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import PropertyDetails from "./ResidentialTabs/PgTabs/propertyDetails"
import PGRoomDetails from "./ResidentialTabs/PgTabs/roomdetails"
import OtherDetails from "./ResidentialTabs/PgTabs/otherDetails"
import { ToastContainer, toast } from "react-toastify"
import CommercialBasicDetails from "./CommercialTabs/basicDetails"
import CommPropDetails from "./CommercialTabs/commPropDetails"
import CommAmminities from "./CommercialTabs/commAmminities"
import { ApiGetMethod } from "helpers/withoutToken_helper"

const FormWizard = () => {
  const optionGroup = [
    {
      label: "Search City",
      options: [{ label: "Nagpur", value: "Nagpur" }],
    },
  ]
  const [cityGroup, setCityGroup] = useState([])
  const [stateGroup, setStateGroup] = useState([])
  const [localityData, setLocalityData] = useState([]);


      const fetchLocData = async () => {
      try {
        const postUrl = `${HOST_URL}api/viewLocalities`

        const response = await ApiGetMethod(postUrl)
        setLocalityData(response)
        const State = response.map(st => ({ label: st.state, value: st.state }))
        .filter((value, index, self) => 
          self.findIndex(v => v.value === value.value) === index
        );
        // const cities = response.map(cities => ({label:cities.city,value:cities._id}))
        setStateGroup(State)
        // setCityGroup(cities)
      } catch (error) {
        console.error("Error:", error)
      }
    }
        useEffect(() => {
          fetchLocData()
        }, [])
  const user_id = JSON.parse(localStorage.getItem("authUser"))
  const user_Id = localStorage.getItem("user_Id")
  const Note = localStorage.getItem("note")
  const user_role = JSON.parse(localStorage.getItem("userRole"))
  const navigate = useNavigate()
  const RentBasicD = useRef()
  const RentAddresD = useRef()
  const RentAdvanceD = useRef()
  const RentAmenD = useRef()
  const CommercialBasicD = useRef()
  const CommercialPropD = useRef()
  const CommercialAmmD = useRef()
  const CommonPhoto = useRef()
  const PgPropD = useRef()
  const PgRoomD = useRef()
  const PgOtherD = useRef()
  const storedActiveTab = localStorage.getItem("activeTab")
  const [activeTab, setactiveTab] = useState(() => {
    return storedActiveTab ? parseInt(storedActiveTab, 10) : 1
  })
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(0)
  const [isPostedBy, setIsPostedBy] = useState(
    user_role == "admin"
      ? "Freelife Housing"
      : user_role == "manager"
      ? "Agent"
      : "Owner"
  )
  const [propertyData, setPropertyData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  var propertyType1 = localStorage.getItem("propertyType1")
  const [propertyType, setPropertyType] = useState(
    propertyType1 || "Residential"
  )
  useEffect(() => {
    localStorage.setItem("propertyType1", propertyType)
  }, [propertyType])
  var lookingto = localStorage.getItem("lookingFor")
  const [lookingFor, setLookingFor] = useState(lookingto || "Rent")
  useEffect(() => {
    localStorage.setItem("lookingFor", lookingFor)
  }, [lookingFor])

  const [searchCity, setSearchCity] = useState("")
  const [searchState, setSearchState] = useState("")
  const [searchCityError, setSearchCityError] = useState("")
  const [searchStateError, setSearchStateError] = useState("")
  const [tabStatus, setTabStatus] = useState(() => {
    const storedTabStatus = JSON.parse(localStorage.getItem("tabStatus"))
    if (storedTabStatus) {
      return storedTabStatus
    } else {
      let defaultTabStatus
      if (propertyType === "Residential" && lookingFor === "PG/Co-living") {
        defaultTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Property Details", status: "Pending" },
          3: { name: "Room Details", status: "Pending" },
          4: { name: "Amenities", status: "Pending" },
          5: { name: "Other details", status: "Pending" },
          6: { name: "Photos", status: "Pending" },
        }
      } else if (propertyType === "Commercial") {
        defaultTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Property Details", status: "Pending" },
          3: { name: "Amenities", status: "Pending" },
          4: { name: "Photos", status: "Pending" },
        }
      } else {
        defaultTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Address", status: "Pending" },
          3: { name: "Photos", status: "Pending" },
          4: { name: "Advanced Details", status: "Pending" },
          5: { name: "Amenities", status: "Pending" },
        }
      }
      return defaultTabStatus
    }
  })

  const fetchData = async () => {
    setIsLoading(true)
    if (localStorage.getItem("property_id")) {
      const token = JSON.parse(localStorage.getItem("token"))
      const url = `${HOST_URL}property/propertypreview`
      const propertyId = JSON.parse(localStorage.getItem("property_id"))
      const data = {
        user_id: user_id._id,
        property_id: propertyId,
      }
      await ApiPostMethodWithToken(url, data, token).then(resp => {
        if (resp.error === false) {
          setPropertyData(resp.property_detail)
          setPropertyType(resp.property_detail.propertyDetails.propertyType1)
          setLookingFor(resp.property_detail.propertyDetails.lookingTo)
          setIsPostedBy(resp.property_detail.propertyDetails.postedBy)
          if (
            resp.property_detail.propertyDetails.lookingTo == "PG/Co-living"
          ) {
            setSearchState(resp?.property_detail?.propertyDetails?.searchState)
            setSearchCity(resp.property_detail.propertyDetails.searchCity)
          }
          setIsLoading(false)
        } else {
          setIsLoading(false)
          if (
            (resp.message == "Invalid Token" ||
              resp.message == "Unauthorized") &&
            resp.islogin === true
          ) {
            alert("Your session has expired!, Please login to continue")
            localStorage.clear()
            window.location.href = `${REFER_URL}#/Login`
          }
        }
      })
    }
  }

  useEffect(() => {
    let defaultTabStatus
    if (propertyType === "Residential" && lookingFor === "PG/Co-living") {
      defaultTabStatus = {
        1: { name: "Basic Details", status: "In Progress" },
        2: { name: "Property Details", status: "Pending" },
        3: { name: "Room Details", status: "Pending" },
        4: { name: "Amenities", status: "Pending" },
        5: { name: "Other details", status: "Pending" },
        6: { name: "Photos", status: "Pending" },
      }
    } else if (propertyType === "Commercial") {
      defaultTabStatus = {
        1: { name: "Basic Details", status: "In Progress" },
        2: { name: "Property Details", status: "Pending" },
        3: { name: "Amenities", status: "Pending" },
        4: { name: "Photos", status: "Pending" },
      }
    } else {
      defaultTabStatus = {
        1: { name: "Basic Details", status: "In Progress" },
        2: { name: "Address", status: "Pending" },
        3: { name: "Photos", status: "Pending" },
        4: { name: "Advanced Details", status: "Pending" },
        5: { name: "Amenities", status: "Pending" },
      }
    }

    setTabStatus(defaultTabStatus)
  }, [propertyType, lookingFor])

  // const handleTabList = (Status) => {
  //   let storedTabStatus
  //   if(Status == "Agricultural Land"){
  //     storedTabStatus = {
  //       1: { name: "Basic Details", status: "In Progress" },
  //       2: { name: "Address", status: "Pending" },
  //       3: { name: "Photos", status: "Pending" },
  //       4: { name: "Advanced Details", status: "Pending" },
  //     }
  //   }else{
  //     if (propertyType === "Residential" && lookingFor === "PG/Co-living") {
  //       storedTabStatus = {
  //         1: { name: "Basic Details", status: "In Progress" },
  //         2: { name: "Property Details", status: "Pending" },
  //         3: { name: "Room Details", status: "Pending" },
  //         4: { name: "Amenities", status: "Pending" },
  //         5: { name: "Other details", status: "Pending" },
  //         6: { name: "Photos", status: "Pending" },
  //       }
  //     } else if (propertyType === "Commercial") {
  //       storedTabStatus = {
  //         1: { name: "Basic Details", status: "In Progress" },
  //         2: { name: "Property Details", status: "Pending" },
  //         3: { name: "Amenities", status: "Pending" },
  //         4: { name: "Photos", status: "Pending" },
  //       }
  //     } else {
  //       storedTabStatus = {
  //         1: { name: "Basic Details", status: "In Progress" },
  //         2: { name: "Address", status: "Pending" },
  //         3: { name: "Photos", status: "Pending" },
  //         4: { name: "Advanced Details", status: "Pending" },
  //         5: { name: "Amenities", status: "Pending" },
  //       }
  //     }
  //   }
  //   setTabStatus(storedTabStatus);
  // }

  useEffect(() => {
    localStorage.setItem("tabStatus", JSON.stringify(tabStatus))
  }, [tabStatus])

  const [tabName, settabName] = useState()
  const [cityId, setCityId] = useState()

  function handleSelectState(selectedGroup) {
      setSearchState(selectedGroup.label)
      setSearchStateError("")
      const city = localityData.filter(data=> data.state == selectedGroup.value)
      console.log("city",city);
      
      const localities1 = city.map(cities => ({label:cities.city,value:cities._id}))
      setCityGroup(localities1)
  }

  function handleSelectGroup(selectedGroup) {
    setSearchCity(selectedGroup.label)
    setCityId(selectedGroup.value)
    setSearchCityError("")
  }

  async function toggleTabNext(tab) {
    var formstatus
    if (
      propertyType == "Residential" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 1
    ) {
      formstatus = await RentBasicD.current.handleRentBasic()
    } else if (
      propertyType == "Residential" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 2
    ) {
      formstatus = await RentAddresD.current.handleRentAdd()
    } else if (
      propertyType == "Residential" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 3
    ) {
      formstatus = await CommonPhoto.current.handleCimgupload()
    } else if (
      propertyType == "Residential" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 4
    ) {
      formstatus = await RentAdvanceD.current.handleRentadvanceD()
    } else if (
      propertyType == "Residential" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 5
    ) {
      formstatus = await RentAmenD.current.handleRentAmeD()
    } else if (
      propertyType == "Residential" &&
      lookingto == "PG/Co-living" &&
      activeTab == 1
    ) {
      if(searchCity.trim() == "" && searchState.trim() == ""){
        setSearchStateError("State is required")
        setSearchCityError("Search city is required")
        formstatus = { status: false, message: "Search city and State is required!.." }
      }else if (searchCity.trim() == "" ) {
        setSearchCityError("Search city is required")
        formstatus = { status: false, message: "Search city is required!.." }
      }else if(searchState.trim() == "" ){
        setSearchStateError("State is required")
        formstatus = { status: false, message: "State is required!.." }
      }else {
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        const token = JSON.parse(localStorage.getItem("token"))
        const url = `${HOST_URL}property/basicdetails`
        const data = {
          propertyDetails: {
            propertyType1: propertyType,
            lookingTo: lookingto,
            searchState: searchState,
            searchCity: searchCity,
            postedBy: isPostedBy,
          },
          user_id: user_Id || user_id._id,
          postedBy: user_id._id,
          note: Note,
          property_id,
        }
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            localStorage.setItem(
              "property_id",
              JSON.stringify(resp.property_id)
            )
            formstatus = { status: true, message: resp.message }
          } else {
            formstatus = { status: false, message: resp.message }
          }
        })
      }
    } else if (
      propertyType == "Residential" &&
      lookingto == "PG/Co-living" &&
      activeTab == 2
    ) {
      formstatus = await PgPropD.current.handlePgPropD()
    } else if (
      propertyType == "Residential" &&
      lookingto == "PG/Co-living" &&
      activeTab == 3
    ) {
      formstatus = await PgRoomD.current.handlePgRoomD()
    } else if (
      propertyType == "Residential" &&
      lookingto == "PG/Co-living" &&
      activeTab == 4
    ) {
      formstatus = await RentAmenD.current.handleRentAmeD()
    } else if (
      propertyType == "Residential" &&
      lookingto == "PG/Co-living" &&
      activeTab == 5
    ) {
      formstatus = await PgOtherD.current.handlePgOtherD()
    } else if (
      propertyType == "Residential" &&
      lookingto == "PG/Co-living" &&
      activeTab == 6
    ) {
      formstatus = await CommonPhoto.current.handleCimgupload()
    } else if (
      propertyType == "Commercial" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 1
    ) {
      formstatus = await CommercialBasicD.current.handleCommBasic()
    } else if (
      propertyType == "Commercial" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 2
    ) {
      formstatus = await CommercialPropD.current.handleCommProp()
    } else if (
      propertyType == "Commercial" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 3
    ) {
      formstatus = await CommercialAmmD.current.handleCommAmm()
    } else if (
      propertyType == "Commercial" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 4
    ) {
      formstatus = await CommonPhoto.current.handleCimgupload()
    }
    if (formstatus instanceof Promise) {
      formstatus.then(function (result) {
        if (result.status === true) {
          if (activeTab !== tab) {
            if (tab >= 1 && tab <= Object.keys(tabStatus).length) {
              toast.success(result.message, {
                position: "top-right",
                theme: "light",
              })
              setactiveTab(tab)
              toggleTabProgress(tab, Object.keys(tabStatus).length)
              setcomplpetedtab(tab)
            }
          }
        } else {
          toast.error(result.message, {
            position: "top-right",
            theme: "light",
          })
        }
      })
    } else {
      if (formstatus.status === true) {
        if (activeTab !== tab) {
          if (tab >= 1 && tab <= Object.keys(tabStatus).length) {
            if(formstatus.formtype == "uploadImg"){
              if(formstatus.uploading == "inProgress"){
                setactiveTab(tab)
                toggleTabProgress(tab, Object.keys(tabStatus).length)
                setcomplpetedtab(tab)
              }
            }else{
              toast.success(formstatus.message, {
                position: "top-right",
                theme: "light",
                autoClose: 1000,
              })
              setactiveTab(tab)
              toggleTabProgress(tab, Object.keys(tabStatus).length)
              setcomplpetedtab(tab)
            }
          }
        }
      } else {
        toast.error(formstatus.message, {
          position: "top-right",
          theme: "light",
          autoClose: 1000,
        })
      }
    }
  }
  function toggleTabPrev(tab) {
    fetchData()
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= Object.keys(tabStatus).length) {
        setactiveTab(tab)
        toggleTabProgress(tab, Object.keys(tabStatus).length)
      }
    }
  }
  function toggleTabSkip(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= Object.keys(tabStatus).length) {
        setactiveTab(tab)
      }
    }
  }

  useEffect(() => {
    const updatedTabStatus = {
      ...tabStatus,
      [activeTab]: {
        name: tabStatus[activeTab].name,
        status: getTabStatus(activeTab),
      },
    }

    if (activeTab > 1) {
      for (let i = 1; i < activeTab; i++) {
        updatedTabStatus[i] = { name: tabStatus[i].name, status: "Completed" }
      }
    }
    setTabStatus(updatedTabStatus)
    toggleTabProgress(activeTab, Object.keys(tabStatus).length)
    settabName(tabStatus[activeTab].name)
  }, [activeTab])

  function getTabStatus(tab) {
    const storedActiveTab = localStorage.getItem("activeTab")
    if (tab < parseInt(storedActiveTab, 10)) {
      return "Completed"
    } else if (tab === activeTab) {
      return "In Progress"
    } else {
      return "Pending"
    }
  }
  function setcomplpetedtab(tab) {
    const storedActiveTab = localStorage.getItem("activeTab")
    if (tab > parseInt(storedActiveTab, 10)) {
      localStorage.setItem("activeTab", tab)
    } else if (storedActiveTab === null || storedActiveTab == "") {
      localStorage.setItem("activeTab", tab)
    }
  }
  function toggleTabProgress(tab, totalLength) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= totalLength) {
        const progressStep = 100 / (totalLength - 1)
        const progressValue = Math.round((tab - 1) * progressStep)
        console.log("progressValue", progressValue)
        setactiveTabProgress(tab)

        setprogressValue(progressValue)
        // setactiveTabProgress(tab)

        // if (tab === 1) {
        //   setprogressValue(0)
        // }
        // if (tab === 2) {
        //   setprogressValue(20)
        // }
        // if (tab === 3) {
        //   setprogressValue(40)
        // }
        // if (tab === 4) {
        //   setprogressValue(60)
        // }
      }
    }
  }

  const handlePropertyTypeChange = value => {
    setPropertyType(value)
    if (value == "Commercial") {
      setLookingFor("Rent")
    }
  }

  const handleListingTypeChange = value => {
    setLookingFor(value)
    if(value == 'PG/Co-living'){
      fetchLocData()
    }
  }

  const handlepostedBy = val => {
    console.log("setIsposted", val);
    
    setIsPostedBy(val)
    console.log("setIsposted././", isPostedBy);

  }

  const postedBy = [
    { id: "1", value: "Agent" },
    { id: "2", value: "Broker" },
    { id: "3", value: "Owner" },
  ]
  if (user_role === "admin" || user_role === "manager") {
    postedBy.push({
      id: "4",
      value: "Freelife Housing",
    })
  }

  const submithandle = async () => {
    var formstatus = ""
    if (
      propertyType == "Residential" &&
      (lookingto == "Rent" || lookingto == "Sell") &&
      activeTab == 5
    ) {
      formstatus = await RentAmenD.current.handleRentAmeD()
    } else if (lookingto == "PG/Co-living" && activeTab == 6) {
      formstatus = await CommonPhoto.current.handleCimgupload()
    } else if (propertyType == "Commercial" && activeTab == 4) {
      formstatus = await CommonPhoto.current.handleCimgupload()
    }
    if (formstatus instanceof Promise) {
      formstatus.then(function (result) {
        if (result.status === true) {
          const property_id = JSON.parse(localStorage.getItem("property_id"))
          if (property_id != null) {
            localStorage.removeItem("property_id")
          }
          localStorage.removeItem("activeTab")
          localStorage.removeItem("tabStatus")
          localStorage.removeItem("lookingFor")
          localStorage.removeItem("PropertyType2")
          localStorage.removeItem("propertyType1")
          localStorage.removeItem("commpropertyType2")
          localStorage.removeItem("ResidentialType2")
          localStorage.removeItem("totalBeds")
          toast.success(result.message, {
            position: "top-right",
            theme: "light",
            autoClose: 1000,
          })
          navigate(`/listing-status?property_id=${property_id}`)
        } else {
          toast.error(result.message, {
            position: "top-right",
            theme: "light",
            autoClose: 1000,
          })
        }
      })
    } else {
      if (formstatus.status === true) {
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        if (property_id != null) {
          localStorage.removeItem("property_id")
        }
        localStorage.removeItem("activeTab")
        localStorage.removeItem("tabStatus")
        localStorage.removeItem("lookingFor")
        localStorage.removeItem("PropertyType2")
        localStorage.removeItem("propertyType1")
        localStorage.removeItem("commpropertyType2")
        localStorage.removeItem("ResidentialType2")
        localStorage.removeItem("user_Id")
        localStorage.removeItem("note")
        localStorage.removeItem("totalBeds")
        if(formstatus.formtype == "uploadImg"){
          if(formstatus.uploading == "inProgress"){
            navigate(`/listing-status?property_id=${property_id}`)
          }else{
            toast.success(formstatus.message + " Redirecting to listing page", {
              position: "top-right",
              theme: "light",
              autoClose: 1000,
              onClose: () => {
                window.location.reload()
              },
            })
          }
        }else{
          toast.success(formstatus.message + " Redirecting to listing page", {
            position: "top-right",
            theme: "light",
            autoClose: 1000,
            onClose: () => {
              navigate(`/listing-status?property_id=${property_id}`)
            },
          })
        }
      } else {
        toast.error(formstatus.message, {
          position: "top-right",
          theme: "light",
          autoClose: 1000,
        })
      }
    }
  }
  document.title = "Freelife Housing"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="p-0">
          <Row>
            <Col lg={4} md={12} xs={12} className="p-2">
              <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                <div className="steps clearfix border-right-top">
                  <div className="progressbar p-2">
                    <h5>Post your property</h5>
                    <h6 style={{ fontWeight: 300 }}>
                      Sell or rent your property
                    </h6>
                    <div className="d-flex justify-content-center align-items-center">
                      <Progress
                        color="primary"
                        value={progressValue}
                        className="w-100"
                      />{" "}
                      <span>{progressValue}%</span>
                    </div>
                  </div>
                  <ul className="listTabs">
                    {Object.keys(tabStatus).map(tab => (
                      <NavItem
                        key={tab}
                        className={classnames({
                          current: activeTab === Number(tab),
                        })}
                      >
                        <NavLink
                          className={classnames({
                            current: activeTab === Number(tab),
                          })}
                        >
                          <span
                            className={
                              tabStatus[tab].status == "Completed"
                                ? "number completetab"
                                : tabStatus[tab].status == "In Progress"
                                ? "number activetab"
                                : "number"
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {tabStatus[tab].status == "Completed" ? (
                              <i className="ti-check"></i>
                            ) : (
                              ""
                            )}
                          </span>
                          <div className="steptext w-100">
                            <p className="tabstatus p-0 m-0">
                              {tabStatus[tab].name}
                            </p>
                            <span
                              className={
                                tabStatus[tab].status == "Completed"
                                  ? "completestatus"
                                  : tabStatus[tab].status == "In Progress"
                                  ? "activestatus"
                                  : ""
                              }
                            >
                              {tabStatus[tab].status}
                            </span>
                          </div>
                        </NavLink>
                      </NavItem>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={8} md={12} xs={12} className="p-0 pt-2 pb-2">
              <Card>
                <CardBody>
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    <div className="content clearfix">
                      {isLoading ? (
                        <>
                          <Col xs={12} className="p-0 pt-2 pb-2 text-center">
                            <Spinner className="ms-2" color="primary" />
                          </Col>
                        </>
                      ) : (
                        <TabContent activeTab={activeTab} className="body p-0">
                          <TabPane tabId={1}>
                            <h5>Add Basic Details</h5>
                            <Form>
                              <Row>
                                <Col md={12}>
                                  <Row className="mb-3 d-block">
                                    <label
                                      htmlFor="txtFirstNameBilling"
                                      className="col-lg-12 col-form-label font-weight-bold"
                                    >
                                      Property Posted By
                                    </label>
                                    <div className="col-lg-12">
                                      <ButtonGroup className="w-100 ">
                                        <div>
                                          {postedBy.map(property => (
                                            <Button
                                              style={{
                                                width: "190px",
                                                marginRight: "5px",
                                                marginBottom: "5px",
                                                padding: "10px",
                                                boxSizing: "border-box",
                                              }}
                                              key={property.id}
                                              className={
                                                isPostedBy === property.value
                                                  ? "btnActivepostedby custom-button customProp"
                                                  : "btnNormal custom-button customProp"
                                              }
                                              onClick={() =>
                                                handlepostedBy(property.value)
                                              }
                                            >
                                              <i
                                                className={property.icon}
                                                style={{ marginRight: "5px" }}
                                              ></i>
                                              <span className="custombtnProp">
                                                {property.value}
                                              </span>
                                            </Button>
                                          ))}
                                        </div>
                                      </ButtonGroup>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <Row className="mb-3 d-block">
                                    <label
                                      htmlFor="txtFirstNameBilling"
                                      className="col-lg-12 col-form-label font-weight-bold"
                                    >
                                      Property Type
                                    </label>
                                    <div className="col-lg-12">
                                      <ButtonGroup className="w-100">
                                        <Button
                                          style={{
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                          className={
                                            propertyType === "Residential"
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handlePropertyTypeChange(
                                              "Residential"
                                            )
                                          }
                                        >
                                          Residential
                                        </Button>
                                        <Button
                                          style={{
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                          className={
                                            propertyType === "Commercial"
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handlePropertyTypeChange(
                                              "Commercial"
                                            )
                                          }
                                        >
                                          Commercial
                                        </Button>
                                      </ButtonGroup>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <Row className="mb-3 d-block">
                                    <label
                                      htmlFor="txtFirstNameBilling"
                                      className="col-lg-12 col-form-label font-weight-bold"
                                    >
                                      Looking For
                                    </label>
                                    <div className="col-lg-12">
                                      <ButtonGroup className="w-100">
                                        <Button
                                          style={{
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                          className={
                                            lookingFor === "Rent"
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handleListingTypeChange("Rent")
                                          }
                                        >
                                          Rent
                                        </Button>
                                        <Button
                                          style={{
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                          className={
                                            lookingFor === "Sell"
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handleListingTypeChange("Sell")
                                          }
                                        >
                                          Sell
                                        </Button>
                                        {propertyType == "Residential" ? (
                                          <Button
                                            style={{ borderRadius: "5px" }}
                                            className={
                                              lookingFor === "PG/Co-living"
                                                ? "btnActive"
                                                : "btnNormal"
                                            }
                                            onClick={() =>
                                              handleListingTypeChange(
                                                "PG/Co-living"
                                              )
                                            }
                                          >
                                            PG/Co-living
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                      </ButtonGroup>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                              {propertyType === "Residential" &&
                              (lookingFor == "Rent" || lookingFor == "Sell") ? (
                                <RentBasicDetails
                                  propertytype={propertyType}
                                  lookingfor={lookingFor}
                                  postedBy={isPostedBy}
                                  propData={propertyData && propertyData}
                                  ref={RentBasicD}
                                  // handleTabStatus={handleTabList} 
                                />
                              ) : propertyType === "Commercial" &&
                                (lookingFor == "Rent" ||
                                  lookingFor == "Sell") ? (
                                <CommercialBasicDetails
                                  propertytype={propertyType}
                                  lookingfor={lookingFor}
                                  postedBy={isPostedBy}
                                  propData={propertyData && propertyData}
                                  ref={CommercialBasicD}
                                />
                              ) : (
                                <Row>
                                  <Col md={6}>
                                    <Row className="mb-3 d-block">
                                      <label
                                        htmlFor="searchState"
                                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                                      >
                                        State
                                      </label>
                                      <div className="col-lg-12">
                                        <Select
                                          id="searchState"
                                          name="searchState"
                                          value={{
                                            label: searchState,
                                            value: searchState,
                                          }}
                                          onChange={selectedValue => {
                                            handleSelectState(selectedValue)
                                          }}
                                          options={stateGroup}
                                          classNamePrefix="select2-selection"
                                        />
                                        {searchStateError && (
                                          <span className="error">
                                            {searchStateError}
                                          </span>
                                        )}
                                      </div>
                                    </Row>
                                  </Col>
                                  <Col md={6}>
                                    <Row className="mb-3 d-block">
                                      <label
                                        htmlFor="searchCity"
                                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                                      >
                                        Search City
                                      </label>
                                      <div className="col-lg-12">
                                        <Select
                                          id="searchCity"
                                          name="searchCity"
                                          value={{
                                            label: searchCity,
                                            value: searchCity,
                                          }}
                                          onChange={selectedValue => {
                                            handleSelectGroup(selectedValue)
                                          }}
                                          options={cityGroup}
                                          classNamePrefix="select2-selection"
                                        />
                                        {searchCityError && (
                                          <span className="error">
                                            {searchCityError}
                                          </span>
                                        )}
                                      </div>
                                    </Row>
                                  </Col>
                                </Row>
                              )}
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>
                            {propertyType === "Residential" &&
                            (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <Rentaddressdetails
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                ref={RentAddresD}
                              />
                            ) : propertyType === "Commercial" &&
                              (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <CommPropDetails
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                searchState={searchState}
                                searchCity={searchCity}
                                propData={propertyData && propertyData}
                                ref={CommercialPropD}
                              />
                            ) : (
                              <PropertyDetails
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                searchState={searchState}
                                searchCity={searchCity}
                                cityId={cityId}
                                propData={propertyData && propertyData}
                                ref={PgPropD}
                              />
                            )}
                          </TabPane>
                          <TabPane tabId={3}>
                            {propertyType === "Residential" &&
                            (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <Photouploadcomm
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                tabname={tabName}
                                toggleTabNext={toggleTabSkip}
                                propData={propertyData && propertyData}
                                ref={CommonPhoto}
                              />
                            ) : propertyType === "Commercial" &&
                              (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <CommAmminities
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                ref={CommercialAmmD}
                              />
                            ) : (
                              <PGRoomDetails
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                ref={PgRoomD}
                              />
                            )}
                          </TabPane>
                          <TabPane tabId={4}>
                            {propertyType === "Residential" &&
                            (lookingto == "Rent" || lookingto == "Sell") ? (
                              <RentadvanceD
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                ref={RentAdvanceD}
                              />
                            ) : propertyType === "Commercial" &&
                              (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <Photouploadcomm
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                tabname={tabName}
                                toggleTabNext={toggleTabSkip}
                                propData={propertyData && propertyData}
                                ref={CommonPhoto}
                              />
                            ) : (
                              <Rentamenitiesdetails
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                ref={RentAmenD}
                              />
                            )}
                          </TabPane>
                          <TabPane tabId={5}>
                            {propertyType === "Residential" && (lookingto == "Rent" || lookingto == "Sell") ? (
                              <Rentamenitiesdetails
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                ref={RentAmenD}
                              />
                            ) : (
                              <OtherDetails
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                ref={PgOtherD}
                              />
                            )}
                          </TabPane>
                          {propertyType == "Residential" &&
                          lookingto == "PG/Co-living" ? (
                            <TabPane tabId={6}>
                              <Photouploadcomm
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                tabname={tabName}
                                toggleTabNext={toggleTabSkip}
                                propData={propertyData && propertyData}
                                ref={CommonPhoto}
                              />
                            </TabPane>
                          ) : (
                            ""
                          )}
                        </TabContent>
                      )}
                    </div>
                    <div className="actions clearfix">
                      <ul style={{ display: "flex", alignItems: "center" }}>
                        <li
                          style={{ paddingLeft: "25px" }}
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            className="previousStep"
                            onClick={() => {
                              toggleTabPrev(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        {activeTab === Object.keys(tabStatus).length ? (
                          <li
                            className={
                              activeTab === Object.keys(tabStatus).length
                                ? "next"
                                : "next"
                            }
                          >
                            <Link
                              to="#"
                              className="commongradient"
                              onClick={() => {
                                submithandle()
                              }}
                            >
                              Submit
                            </Link>
                          </li>
                        ) : (
                          <li
                            style={{ paddingRight: "25px" }}
                            className={
                              activeTab === Object.keys(tabStatus).length
                                ? "next"
                                : "next"
                            }
                          >
                            <Link
                              to="#"
                              className="commongradient"
                              onClick={() => {
                                toggleTabNext(activeTab + 1)
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default FormWizard
