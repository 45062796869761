import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"

const RentamenitiesdetailsEdit = React.forwardRef(
  ({ propertytype, lookingfor, propData, propertyId }, ref) => {
    const flatfurnish = [
      { id: 1, value: "Dining Table", icon: "" },
      { id: 2, value: "Washing Machine", icon: "" },
      { id: 3, value: "Sofa", icon: "" },
      { id: 4, value: "Microwave", icon: "" },
      { id: 5, value: "Stove", icon: "" },
      { id: 6, value: "Refrigerator", icon: "" },
      { id: 7, value: "Water Purifier", icon: "" },
      { id: 8, value: "Gas Pipeline", icon: "" },
      { id: 9, value: "AC", icon: "" },
      { id: 10, value: "Bed", icon: "" },
      { id: 11, value: "TV", icon: "" },
      { id: 12, value: "Cupboard", icon: "" },
      { id: 13, value: "Geyser", icon: "" },
    ]
    const Societyamen = [
      { id: 1, value: "Lift", icon: "" },
      { id: 2, value: "CCTV", icon: "" },
      { id: 3, value: "GYM", icon: "" },
      { id: 4, value: "Garden", icon: "" },
      { id: 5, value: "Kids Area", icon: "" },
      { id: 6, value: "Sports", icon: "" },
      { id: 7, value: "Swimming Pool", icon: "" },
      { id: 8, value: "Intercom", icon: "" },
      { id: 9, value: "Gated Community", icon: "" },
      { id: 10, value: "Club House", icon: "" },
      { id: 11, value: "Community Hall", icon: "" },
      { id: 12, value: "Regular Water Supply", icon: "" },
      { id: 13, value: "Power Backup", icon: "" },
      { id: 14, value: "Pet Allowed", icon: "" },
      { id: 15, value: "Security Guard", icon: "" },
      { id: 16, value: "Terrace Garden", icon: "" },
      { id: 17, value: "Regular Cleaning Staff", icon: "" },
    ]
    const Securityamen = [
      { id: 1, value: "CCTV", icon: "" },
      { id: 2, value: "Gated Community", icon: "" },
      { id: 3, value: "Security", icon: "" },
      { id: 4, value: "Biometric", icon: "" },
    ]
    const Topamen = [
      { id: 1, value: "GYM", icon: "" },
      { id: 2, value: "Lift", icon: "" },
      { id: 3, value: "Regular Water Supply", icon: "" },
      { id: 4, value: "Swimming Pool", icon: "" },
      { id: 5, value: "Reserved Parking", icon: "" },
      { id: 6, value: "Power Backup", icon: "" },
    ]
    const Servicesamen = [
      { id: 1, value: "Laundry", icon: "" },
      { id: 2, value: "Housekeeping", icon: "" },
      { id: 3, value: "Internet/WiFi Connectivity", icon: "" },
    ]
    const FurnisInProp = [
      { id: 1, value: "Refrigerator", icon: "" },
      { id: 2, value: "Washing Machine", icon: "" },
      { id: 3, value: "Microwave", icon: "" },
      { id: 4, value: "Water Purifier", icon: "" },
      { id: 5, value: "TT Table", icon: "" },
      { id: 6, value: "TV", icon: "" },
      { id: 7, value: "Coffee Machine", icon: "" },
      { id: 8, value: "Snacks Machine", icon: "" },
    ]

    const PlotAmminities = [
      { id: 1, value: "Wire fencing", icon: "" },
      { id: 2, value: "Wall fencing", icon: "" },
      { id: 3, value: "Tar road", icon: "" },
      { id: 4, value: "Cement road", icon: "" },
      { id: 5, value: "Moorum road", icon: "" },
      { id: 6, value: "Raw road", icon: "" },
      // { id: 7, value: "Borewell", icon: "" },
      // { id: 8, value: "NMC water", icon: "" },
      // { id: 9, value: "Shade", icon: "" },
      { id: 7, value: "Level", icon: "" },
      { id: 8, value: "Unlevel", icon: "" },
    ]

    const waterAmmenities = [
      { id: 1, value: "Borewell", icon: "" },
      { id: 2, value: "Well", icon: "" },
      { id: 3, value: "Talab", icon: "" },
      { id: 4, value: "Nahar", icon: "" },
      { id: 5, value: "River", icon: "" },
    ]

    const [rrentAmeniDetails, setrrentAmeniDetails] = useState(
      propData?.amenities || {}
    )

    const handleChange = (inputName, value) => {
      setrrentAmeniDetails(prevState => {
        const updatedAmenities = { ...prevState }
        if (updatedAmenities[inputName]) {
          if (updatedAmenities[inputName].includes(value)) {
            updatedAmenities[inputName] = updatedAmenities[inputName].filter(
              item => item !== value
            )
          } else {
            updatedAmenities[inputName] = [
              ...updatedAmenities[inputName],
              value,
            ]
          }
        } else {
          updatedAmenities[inputName] = [value]
        }
        return updatedAmenities
      })
    }
        
    const handleElecConnection = val => {
      setrrentAmeniDetails(prevState => ({
        ...prevState,
        electricConnection: val
      }))
    }

    const handleSubmit = async () => {
      var status
      if (Object.keys(rrentAmeniDetails).length <= 0) {
        status = true
        return { status: status }
      } else {
        const url = `${HOST_URL}property/amenitiesdetails`
        const token = JSON.parse(localStorage.getItem("token"))
        const user_id = JSON.parse(localStorage.getItem("authUser"))
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        const data = {
          user_id: propData?.user_id,
          property_id: propertyId || property_id,
          amenities: rrentAmeniDetails,
        }
        var response
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            status = true
            response = resp
          } else {
            status = false
            response = resp
          }
        })
        return { status: status, message: response.message }
      }
    }
    React.useImperativeHandle(ref, () => ({
      handleRentAmeD: () => handleSubmit(),
    }))
    return (
      <React.Fragment>
        {propData?.propertyDetails?.propertyType2 === "Plot" || propData?.propertyDetails?.propertyType2 === "Agricultural Land" ? (
          <Row>
            <Col md={12}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  Edit Amenities
                </label>
                <div className="col-lg-12">
                  <ButtonGroup className="w-100 amenitiesgrid">
                    {PlotAmminities.map(face => (
                      <Button
                        key={face.id}
                        style={{ borderRadius: "5px" }}
                        className={
                          rrentAmeniDetails.PlotAmenities &&
                          rrentAmeniDetails.PlotAmenities.includes(face.value)
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("PlotAmenities", face.value)
                        }
                      >
                        <Input
                          type="checkbox"
                          name="PlotAmenities"
                          value={face.value}
                          onChange={() =>
                            handleChange("PlotAmenities", face.value)
                          }
                          // checked={rrentAmeniDetails.flatFurnishing.includes(face.value)}
                          style={{ opacity: 0, position: "absolute" }}
                        />
                        {face.value}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </Row>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  Water Sources
                </label>
                <div className="col-lg-12">
                  <ButtonGroup className="w-100 amenitiesgrid">
                    {waterAmmenities.map(face => (
                      <Button
                        key={face.id}
                        style={{ borderRadius: "5px" }}
                        className={
                          rrentAmeniDetails.waterAmenities &&
                          rrentAmeniDetails.waterAmenities.includes(face.value)
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("waterAmenities", face.value)
                        }
                      >
                        <Input
                          type="checkbox"
                          name="waterAmmenities"
                          value={face.value}
                          onChange={() =>
                            handleChange("waterAmenities", face.value)
                          }
                          // checked={rrentAmeniDetails.flatFurnishing.includes(face.value)}
                          style={{ opacity: 0, position: "absolute" }}
                        />
                        {face.value}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </Row>
              <Col lg={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Electric Connection
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentAmeniDetails.electricConnection === "Yes" ? "btnActive" : "btnNormal"
                        }
                        onClick={() => handleElecConnection("Yes")}
                      >
                        Yes
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentAmeniDetails.electricConnection === "No" ? "btnActive" : "btnNormal"
                        }
                        onClick={() => handleElecConnection("No")}
                      >
                        No
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Col>
          </Row>
        ) : propData?.propertyDetails?.lookingTo == "PG/Co-living" ? (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Security Amenities
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 amenitiesgrid">
                      {Securityamen.map(face => (
                        <Button
                          key={face.id}
                          style={{ borderRadius: "5px" }}
                          className={
                            rrentAmeniDetails.securityAmenities &&
                            rrentAmeniDetails.securityAmenities.includes(
                              face.value
                            )
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange("securityAmenities", face.value)
                          }
                        >
                          <Input
                            type="checkbox"
                            name="securityAmenities"
                            value={face.value}
                            onChange={() =>
                              handleChange("securityAmenities", face.value)
                            }
                            // checked={rrentAmeniDetails.flatFurnishing.includes(face.value)}
                            style={{ opacity: 0, position: "absolute" }}
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Furnishings in Property
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 amenitiesgrid">
                      {FurnisInProp.map(face => (
                        <Button
                          key={face.id}
                          style={{ borderRadius: "5px" }}
                          className={
                            rrentAmeniDetails.furnishingsInProperty &&
                            rrentAmeniDetails.furnishingsInProperty.includes(
                              face.value
                            )
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange("furnishingsInProperty", face.value)
                          }
                        >
                          <Input
                            type="checkbox"
                            name="furnishingsInProperty"
                            value={face.value}
                            onChange={() =>
                              handleChange("furnishingsInProperty", face.value)
                            }
                            // checked={rrentAmeniDetails.flatFurnishing.includes(face.value)}
                            style={{ opacity: 0, position: "absolute" }}
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Services
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 amenitiesgrid">
                      {Servicesamen.map(face => (
                        <Button
                          key={face.id}
                          style={{ borderRadius: "5px" }}
                          className={
                            rrentAmeniDetails.services &&
                            rrentAmeniDetails.services.includes(face.value)
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() => handleChange("services", face.value)}
                        >
                          <Input
                            type="checkbox"
                            name="services"
                            value={face.value}
                            onChange={() =>
                              handleChange("services", face.value)
                            }
                            // checked={rrentAmeniDetails.flatFurnishing.includes(face.value)}
                            style={{ opacity: 0, position: "absolute" }}
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Top Amenities
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 amenitiesgrid">
                      {Topamen.map(face => (
                        <Button
                          key={face.id}
                          style={{ borderRadius: "5px" }}
                          className={
                            rrentAmeniDetails.topAmenities &&
                            rrentAmeniDetails.topAmenities.includes(face.value)
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange("topAmenities", face.value)
                          }
                        >
                          <Input
                            type="checkbox"
                            name="topAmenities"
                            value={face.value}
                            onChange={() =>
                              handleChange("topAmenities", face.value)
                            }
                            // checked={rrentAmeniDetails.flatFurnishing.includes(face.value)}
                            style={{ opacity: 0, position: "absolute" }}
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Flat Furnishing
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 amenitiesgrid">
                      {flatfurnish.map(face => (
                        <Button
                          key={face.id}
                          style={{ borderRadius: "5px" }}
                          className={
                            rrentAmeniDetails.flatFurnishing &&
                            rrentAmeniDetails.flatFurnishing.includes(
                              face.value
                            )
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange("flatFurnishing", face.value)
                          }
                        >
                          <Input
                            type="checkbox"
                            name="flatFurnishing"
                            value={face.value}
                            onChange={() =>
                              handleChange("flatFurnishing", face.value)
                            }
                            // checked={rrentAmeniDetails.flatFurnishing.includes(face.value)}
                            style={{ opacity: 0, position: "absolute" }}
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Society Amenities
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 amenitiesgrid">
                      {Societyamen.map(face => (
                        <Button
                          key={face.id}
                          style={{ borderRadius: "5px" }}
                          className={
                            rrentAmeniDetails.societyAmenities &&
                            rrentAmeniDetails.societyAmenities.includes(
                              face.value
                            )
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange("societyAmenities", face.value)
                          }
                        >
                          <Input
                            type="checkbox"
                            name="societyAmenities"
                            value={face.value}
                            onChange={() =>
                              handleChange("societyAmenities", face.value)
                            }
                            style={{ opacity: 0, position: "absolute" }}
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
)

export default RentamenitiesdetailsEdit
