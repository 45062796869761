import React, { useState, useEffect } from "react"
import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import {
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  ModalBody,
  ModalHeader,
  Row,
  TabPane,
  Table,
} from "reactstrap"
import MUIDataTable from "mui-datatables"
import { Button, IconButton } from "@mui/material"
import AddStaffDetail from "./AddStaffDetail"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { HOST_URL } from "helpers/url_helper"
import Modal from "react-bootstrap/Modal"
import Swal from "sweetalert2"
import { toast } from "react-toastify"

const Staff = () => {
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [operation, setOperation] = useState()
  const [staffList, setStaffList] = useState([])
  const [staffData, setStaffData] = useState([])
  const [editStaff, setEditStaff] = useState({})
  const token = JSON.parse(localStorage.getItem("token"))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [isModalOpen3, setIsModalOpen3] = useState(false)
  const [registeredUsers, setRegisteredUsers] = useState([])
  const [postedProperty, setPostedProperty] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const navigate = useNavigate()

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const toggleModal2 = () => {
    setIsModalOpen2(!isModalOpen2)
  }

  const toggleModal3 = () => {
    setIsModalOpen3(!isModalOpen3)
  }

  const handleOpen = (operation, row) => {
    setOperation(operation)
    if (operation === "Add Staff") {
      setEditStaff({})
      setOpen(true) // Clear any existing user data for adding a new user
    } else {
      // console.log("Row Data", row)
      const url = `${HOST_URL}staff/viewSingleStaff`
      const data = {
        staff_id: row.rowData[1],
      }

      ApiPostMethodWithToken(url, data, token)
        .then(data => {
          if (data.error === false) {
            setEditStaff(data)
            setOpen(true)
            // console.log("Edited Staff Data", data.Staff)
          }
        })
        .catch(error => console.log(`Error: ${error}`))
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const fetchData = async () => {
    const url = `${HOST_URL}staff/getAllStaff`
    const data = {}
    try {
      const response = await ApiPostMethodWithToken(url, data, token)
      if (response.error === false) {
        // Sort staffList in descending order based on the timestamp (assuming a field named "createdAt")
        const sortedStaffList = response.allStaff.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        setStaffList(sortedStaffList)
      } else {
        // Handle error
        console.error("Error fetching staff list:", response.message)
      }
    } catch (error) {
      console.error("Error fetching staff list:", error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      name: "SN",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1
        },
      },
    },
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobno",
      label: "Mobile No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "propertypostedcount",
      label: "PROPERTY POST COUNT",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: "pointer",width:'max-content' }}
              onClick={() => handlePropertyClick(tableMeta)}
            >
              {value}
            </span>
          )
        },
      },
    },
    {
      name: "userregisteredcount",
      label: "USER REGISTERED COUNT",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleUserClick(tableMeta)}
            >
              {value}
            </span>
          )
        },
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="View">
                <button
                  className="actionview me-1"
                  onClick={() => handleView(tableMeta)}
                >
                  <i className="mdi mdi-eye-outline"></i>
                </button>
              </Tooltip>
              <Tooltip title="Edit">
                <button
                  className="actionview me-1"
                  onClick={() => handleOpen("Edit Staff", tableMeta)}
                >
                  <i className="mdi mdi-clipboard-edit-outline"></i>
                </button>
              </Tooltip>
              <Tooltip title="Delete">
                <button
                  className="actionview"
                  onClick={() => handleDelete(tableMeta)}
                >
                  <i className="mdi mdi-delete-outline"></i>
                </button>
              </Tooltip>
            </>
          )
        },
      },
    },
  ]

  const handleView = row => {
    // console.log("row", row)
    const url = `${HOST_URL}staff/viewSingleStaff`
    const data = {
      staff_id: row.rowData[1],
    }
    ApiPostMethodWithToken(url, data, token)
      .then(data => {
        if (data.error === false) {
          setStaffData(data.Staff)
          setIsModalOpen(data.Staff)
        }
      })
      .catch(error => console.log(`Error: ${error}`))
  }

  const handleDelete = async row => {
    // Show confirmation dialog
    Swal.fire({
      title: "Are you sure want to delete?",
      text: "You will not be able to recover this staff!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        // staff confirmed deletion, proceed with delete operation
        // console.log("Delete clicked for row:", row.rowData[1])
        const url = `${HOST_URL}staff/deleteStaff`
        const data = {
          staff_id: row.rowData[1],
        }

        try {
          const response = await ApiPostMethodWithToken(url, data, token)
          if (response.error === false) {
            // Update staffList state after successful deletion
            const updatedStaffList = staffList.filter(
              staff => staff._id !== row.rowData[1]
            )
            setStaffList(updatedStaffList)

            toast.success(response.message, {
              position: "top-right",
              theme: "light",
            })
          } else {
            toast.error(response.message, {
              position: "top-right",
              theme: "light",
            })
          }
        } catch (error) {
          // console.log("Error deleting staff:", error)
          toast.error("Error deleting staff", {
            position: "top-right",
            theme: "light",
          })
        }
      }
    })
  }

  const handleUserClick = async row => {
    // console.log("User clicked for ID:", row.rowData[1])
    const url = `${HOST_URL}staff/viewSingleStaff` //here i have to
    const data = {
      staff_id: row.rowData[1],
    }
    await ApiPostMethodWithToken(url, data, token)
      .then(resp => {
        // console.log(resp)
        setRegisteredUsers(resp.User_list) // Assuming leadsDate contains registered users' data// i have to write uesrList array instead of leadDate
        // console.log("Registered Users:", registeredUsers)

        toggleModal2()
      })
      .catch(error => console.log(`Error: ${error}`))
  }

  const handlePropertyClick = async row => {
    // console.log("User clicked for ID:", row.rowData[1])
    const url = `${HOST_URL}staff/viewSingleStaff` //here i have to
    const data = {
      staff_id: row.rowData[1],
    }
    await ApiPostMethodWithToken(url, data, token)
      .then(resp => {
        // console.log(resp)
        setPostedProperty(resp.Property_list) // Assuming leadsDate contains registered users' data// i have to write uesrList array instead of leadDate
        // console.log("Posted Property:", registeredUsers)

        toggleModal3()
      })
      .catch(error => console.log(`Error: ${error}`))
  }

  const handlePropertyTypeClick = propertyId => {
    // Navigate to property list page with property ID as query parameter
    navigate(`/listing-status?property_id=${propertyId}`)
  }

  const handleUserRegisteredClick = userName => {
    navigate(`/users?name=${encodeURIComponent(userName)}`)
  }

  const options = {
    filter: true,
    //filterType: 'checkbox',
    selectableRows: "none",
    responsive: "standard",
  }

  const handleDate = (date) => {
    const createdAt = new Date(date);
    const dd = String(createdAt.getDate()).padStart(2, '0');
    const mm = String(createdAt.getMonth() + 1).padStart(2, '0');
    const yyyy = createdAt.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content staff-list">
          <Row>
            <Col lg={12} className="pe-0 pt-2 pb-2">
              <Card
                className="border-right-top"
                style={{ borderRadius: "12px 0px 0px 0px" }}
              >
                <CardBody className="pt-3" style={{ margin: "0px 19px" }}>
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <h5>Staff</h5>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                      <Link
                        onClick={() => handleOpen("Add Staff")}
                        style={{ padding: "8px 36px", marginTop: "3px" }}
                        className="commongradient addbutton addbtn"
                      >
                        Add Staff
                      </Link>
                    </Grid>
                    {/* <Grid item xs={6} container justifyContent="flex-end">
                      <Link>
                        <Button className='commongradient addbutton' style={{ padding: '5px 30px', color: 'white' }} ></Button>
                      </Link>
                    </Grid> */}
                  </Grid>
                  <TabPane tabId="2" style={{ margin: "0px 20px" }}>
                    <Row>
                      <Col sm="12" className="p-1 ps-0 pe-0">
                        <MUIDataTable
                          title=""
                          data={staffList}
                          columns={columns}
                          options={options}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal show={isModalOpen} onHide={toggleModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Staff Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={12} style={{marginBottom:20}}>
                      <strong style={{ fontWeight: 800, marginRight: 20 }}>Name:</strong>
                      <span>{staffData.name}</span>
                      
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12} style={{marginBottom:20}}>
                      <strong style={{ fontWeight: 800, marginRight: 20 }}>Email:</strong>
                      <span>{staffData.email}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={12} style={{marginBottom:20}}>
                      <strong style={{ fontWeight: 800, marginRight: 20 }}>Mobile No.:</strong>
                      <span>{staffData.mobno}</span>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12} style={{marginBottom:20}}>
                      <strong style={{ fontWeight: 800, marginRight: 20 }}>Gender:</strong>
                      <span>{staffData.gender}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
          <Button variant="primary" onClick={toggleModal}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        </Modal>

        {/* Edit Staff */}
        {editStaff && (
          <AddStaffDetail
            open={open}
            handleClose={handleClose}
            fetchData={fetchData}
            operation={operation}
            staffData={editStaff && editStaff}
          />
        )}

        <Modal show={isModalOpen2} onHide={toggleModal2} centered>
          <Modal.Header closeButton>
            <Modal.Title>Registered Users</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{height: "auto", overflowY: "auto"}}>
            <>
              {registeredUsers.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Mobile No.</TableCell>
                        <TableCell>Email</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {registeredUsers.map((user, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <span
                              onClick={() =>
                                handleUserRegisteredClick(user.name)
                              }
                            >
                              <TableRow>{user.name}</TableRow>
                            </span>
                          </TableCell>
                          <TableCell>{user.mobno}</TableCell>
                          <TableCell>{user.email}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="h6" textAlign="center">
                  User not registered
                </Typography>
              )}
            </>
          </Modal.Body>
        </Modal>
        <Modal show={isModalOpen3} onHide={toggleModal3} centered>
          <Modal.Header closeButton>
            <Modal.Title>Posted Property</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{height: "auto", overflowY: "auto"}}>
            <>
              {postedProperty.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Property Type</TableCell>
                        <TableCell>Looking To</TableCell>
                        <TableCell>Lead</TableCell>
                        <TableCell>createdAt</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {postedProperty.map((property, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <span
                              onClick={() =>
                                handlePropertyTypeClick(property._id)
                              }
                            >
                              <TableRow>
                                {property.propertyDetails.propertyType1}
                              </TableRow>
                              <TableRow>
                                {property.propertyDetails.propertyType2}
                              </TableRow>
                            </span>
                          </TableCell>
                          <TableCell>
                            {property.propertyDetails.lookingTo}
                          </TableCell>
                          <TableCell>{property.lead}</TableCell>
                          <TableCell>
                            {handleDate(property.createdAt)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="h6" textAlign="center">
                  Property not posted
                </Typography>
              )}
            </>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </div>
  )
}

export default Staff
