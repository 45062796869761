import React, { useEffect, useState } from "react"
import MUIDataTable from "mui-datatables"
import { Card, Container, Grid, Typography } from "@mui/material"
import { CardBody, Col, Row, TabPane } from "reactstrap"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { HOST_URL } from "helpers/url_helper"
import { Link } from "react-router-dom"
import DateFilter from "pages/reportFilter/dateFilter"

const TransactionViewTable = () => {
  const token = JSON.parse(localStorage.getItem("token"))
  const [transacList, setTransactionList] = useState([])
  const [showModel, setShowModel] = useState(false)

  useEffect(() => {
    const url = `${HOST_URL}transac/viewTransaction`
    const data = {}
    ApiPostMethodWithToken(url, data, token)
      .then(data => {
        if (data.error === false) {
          setTransactionList(data.transac_List)
          // console.log("Transaction List", transacList)
        }
      })
      .catch(error => console.log(`Error: ${error}`))
  }, [])

  const columns = [
    {
      name: "SN",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1
        },
      },
    },
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "userName",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "planName",
      label: "Plan Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "planAmount",
      label: "Plan Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => {
          if (value) {
            return <span>&#8377; {value}</span> // Display the rupee sign followed by the plan amount
          } else {
            return "" // Return empty string if plan amount is not available
          }
        },
      },
    },
    {
      name: "planDuration",
      label: "PLAN DURATION",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return `${value} Days` // Add 'days' to the duration
        },
      },
    },
    {
      name: "createdAt",
      label: "TNX DATE",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          // Extract only the date part from the datetime string
          const date = new Date(value)
          return date.toLocaleDateString()
        },
      },
    },
    {
      name: "expiryDate",
      label: "EXPIRY DATE",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          // Extract only the date part from the datetime string
          const date = new Date(value)
          return date.toLocaleDateString()
        },
      },
    },
    {
      name: "paymentMode",
      label: "Payment Mode",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "actions",
    //   label: "Actions",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           <button className="actionview me-1" onClick={() => handleView(tableMeta)}>
    //             <i className="mdi mdi-eye-outline"></i>
    //           </button>
    //           <button className="actionview me-1" onClick={() => handleOpen('Edit Staff', tableMeta)}>
    //             <i className="mdi mdi-clipboard-edit-outline"></i>
    //           </button>
    //           <button
    //             className="actionview"
    //             onClick={() => handleDelete(tableMeta)}
    //           >
    //             <i className="mdi mdi-delete-outline"></i>
    //           </button>
    //         </>
    //       )
    //     },
    //   },
    // },
  ]

  const options = {
    filter: true,
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    rowsPerPageOptions: [5, 10, 20],
    rowsPerPage: 5,
    pagination: true,
    search: true,
    viewColumns: false,
  }

  const handleOpen = () => {
    setShowModel(true)
  }

  const hideModel = () => {
    setShowModel(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg={12} className="pe-0 pt-2 pb-2">
            <Card
              className="border-right-top"
              style={{ borderRadius: "12px 0px 0px 0px" }}
            >
              <CardBody style={{ margin: "15px" }}>
                <Grid className="d-flex justify-content-between">
                  <h5
                    className=" waves-effect"
                    style={{ margin: "10px 0px 0px 5px" }}
                  >
                    Transaction
                  </h5>
                  <Link
                    onClick={handleOpen}
                    style={{ padding: "8px 30px" }}
                    className="commongradient addbutton addbtn"
                  >
                    Transaction Report
                  </Link>
                </Grid>
                <TabPane tabId="2" style={{ margin: "0px 20px" }}>
                  <MUIDataTable
                    title=""
                    data={transacList}
                    columns={columns}
                    options={options}
                  />
                </TabPane>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <DateFilter
        show={showModel}
        handleClose={hideModel}
        type={"Transaction"}
      />
    </React.Fragment>
  )
}

export default TransactionViewTable
