import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  CardTitle,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
  Label,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import { HOST_URL } from "helpers/url_helper"
import image from "../../../assets/images/loader.gif"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import "react-toastify/dist/ReactToastify.css"
import "react-dropzone-uploader/dist/styles.css"
import Dropzone from "react-dropzone-uploader"
import { toast, ToastContainer } from "react-toastify"
import imageCompression from 'browser-image-compression';

const Photouploadcomm = React.forwardRef(
  (
    { propertytype, lookingfor, tabname, toggleTabNext, propData, propertyId },
    ref
  ) => {
    const user_id = JSON.parse(localStorage.getItem("authUser"))
    const dropzoneRef = useRef(null)
    const isAfterSubmit = useRef(false)
    const [uploadLoader, setuploadLoader] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [uploadedFilesF, setUploadedFilesF] = useState([])
    const [uploadedFilesV, setUploadedFilesV] = useState([])
    const [youtubeVLink, setYoutubeVLink] = useState(
      propData?.propVideoLink || ""
    )
    const [propVideo, setPropVideo] = useState(propData?.propVideo || [])
    const [propimg, setPropimg] = useState({
      images: propData?.photos?.images || [],
      floorPlan: propData?.photos?.floorPlan || [],
    })

  const [maxImages, setMaxImages] = useState(20);
  useEffect(() => {
    const existingImages = propimg?.images?.length || propData?.photos?.images.length || 0;
    setMaxImages(Math.max(0, 20 - existingImages));
  }, [propimg, propData]);

    const handleLink = e => {
      setYoutubeVLink(e.target.value)
    }
    const getUploadParams = async ({ file, meta }) => {
      const body = new FormData()
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      }
      try {
        if (file.type.startsWith("image/")) {
          const compressedFile = await imageCompression(file, options);
          body.append("images", compressedFile, file.name)
          body.append("imageType", "propImg")
          return { url: `${HOST_URL}api/upload/propImg`, body }
        }else{
          body.append("images", file)
          body.append("imageType", "propImg")
          return { url: `${HOST_URL}api/upload/propImg`, body }
        }
      } catch (error) {
        console.log(error);
      }
    }
    const handleChangeStatus = async ({ meta, file, xhr, restart }, status) => {
      // console.log(status, meta, file)

      if (xhr && status === "done") {
        const response = JSON.parse(xhr.response)
        // console.log("xhr.response", response)

        if(response.files.length > maxImages){
          toast.error(`You can upload up to ${maxImages > 0 ? maxImages : 0} more image(s).`)
        }

        if (response && response.files && response.files.length > 0) {
          const originalFilename = meta.name
          const modifiedFilename = response.files[0].filename

          setUploadedFiles(prevFiles => [
            ...prevFiles,
            { original: originalFilename, modified: modifiedFilename },
          ])
        }
        // console.log("dropzoneRef.current", dropzoneRef.current)
      } else if (status === "removed" && !isAfterSubmit.current) {
        const originalFilename = meta.name
        const modifiedFilename = uploadedFiles.find(
          file => file.original === originalFilename
        )?.modified
        // setMaxImages(prevMax => prevMax < 20 ? Math.max(0, prevMax + 1) : prevMax);
        if (modifiedFilename) {
          // handleImageDelete(modifiedFilename)
          handleImageDelete(modifiedFilename, "images", "selectedimg")
        }
      } else if (
        xhr &&
        xhr.status !== 200 &&
        (status === "error-upload" || status === "exception_upload")
      ) {
        // console.log(`Retrying upload for '${meta.name}'`)
        restart()
      }
    }
    const handleStatusFloorplan = async (
      { meta, file, xhr, restart },
      status
    ) => {
      // console.log(status, meta, file)

      if (xhr && status === "done") {
        const response = JSON.parse(xhr.response)
        // console.log("xhr.response", response)

        if (response && response.files && response.files.length > 0) {
          const originalFilename = meta.name
          const modifiedFilename = response.files[0].filename

          setUploadedFilesF(prevFiles => [
            ...prevFiles,
            { original: originalFilename, modified: modifiedFilename },
          ])
        }
      } else if (status === "removed" && !isAfterSubmit.current) {
        const originalFilename = meta.name
        const modifiedFilename = uploadedFilesF.find(
          file => file.original === originalFilename
        )?.modified

        if (modifiedFilename) {
          // handleImageDelete(modifiedFilename)
          handleImageDelete(modifiedFilename, "images", "selectedimg")
        }
      } else if (
        xhr &&
        xhr.status !== 200 &&
        (status === "error-upload" || status === "exception_upload")
      ) {
        // console.log(`Retrying upload for '${meta.name}'`)
        restart()
      }
    }
    const handleVideoStatus = async ({ meta, file, xhr, restart }, status) => {
      // console.log(status, meta, file)

      if (xhr && status === "done") {
        const response = JSON.parse(xhr.response)
        // console.log("xhr.response", response)

        if (response && response.files && response.files.length > 0) {
          const originalFilename = meta.name
          const modifiedFilename = response.files[0].filename

          setUploadedFilesV(prevFiles => [
            ...prevFiles,
            { original: originalFilename, modified: modifiedFilename },
          ])
        }
      } else if (status === "removed" && !isAfterSubmit.current) {
        const originalFilename = meta.name
        const modifiedFilename = uploadedFilesV.find(
          file => file.original === originalFilename
        )?.modified

        if (modifiedFilename) {
          handleVideoDelete(modifiedFilename, "selectedVideo")
        }
      } else if (
        xhr &&
        xhr.status !== 200 &&
        (status === "error-upload" || status === "exception_upload")
      ) {
        // console.log(`Retrying upload for '${meta.name}'`)
        restart()
      }
    }

    const handleImageDelete = async (filename, category, imagetype) => {
      try {
        const propId = JSON.parse(localStorage.getItem("property_id"))
        const response = await fetch(`${HOST_URL}api/delete/${filename}`, {
          method: "DELETE",
          body: JSON.stringify({ propId }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        // console.log("response", response.json());
        const result = await response.json();
        if (result.error == true && result.isExist == false) {
          if (imagetype == "selectedimg") {
            setUploadedFiles(prevFiles =>
              prevFiles.filter(file => file.modified !== filename)
            )
            setUploadedFilesF(prevFiles =>
              prevFiles.filter(file => file.modified !== filename)
            )
          } else {
            if(category == "images"){
              setMaxImages(prevMax => prevMax < 20 ? Math.max(0, prevMax + 1) : prevMax);
            }
            setPropimg(prevImages => ({
              ...prevImages,
              [category]: prevImages[category].filter(img => img !== filename),
            }))
          }
        }else if(!response.ok){
          throw new Error(`Deletion failed with status ${result.status}`)
        }
        if (imagetype == "selectedimg") {
          setUploadedFiles(prevFiles =>
            prevFiles.filter(file => file.modified !== filename)
          )
          setUploadedFilesF(prevFiles =>
            prevFiles.filter(file => file.modified !== filename)
          )
        } else {
          setPropimg(prevImages => ({
            ...prevImages,
            [category]: prevImages[category].filter(img => img !== filename),
          }))
        }
      } catch (error) {
        console.error("Error deleting image", error)
      }
    }
    const handleVideoDelete = async (filename, videoType) => {
      try {
        const extractFileName = url => {
          const parts = url.split("/")
          const filename = parts[parts.length - 1]
          return filename
        }
        const propId = propData._id
        const fileName = extractFileName(filename)
        const response = await fetch(`${HOST_URL}api/delete/${fileName}`, {
          method: "DELETE",
          body: JSON.stringify({ propId }),
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (!response.ok) {
          throw new Error(`Deletion failed with status ${response.status}`)
        }
        if (videoType == "propVideo") {
          setPropVideo(prevFiles => prevFiles.filter(file => file !== fileName))
        } else {
          setUploadedFilesV(prevFiles =>
            prevFiles.filter(file => file.modified !== fileName)
          )
        }
      } catch (error) {
        console.error("Error deleting image", error)
      }
    }

    const renderpreimage = (image, category) => (
      <Col
        lg={2}
        key={image}
        style={{ textAlign: "center", margin: "20px" }}
        className="position-relative preImage"
      >
        <img
          src={`${HOST_URL}public/img/propertyimg/${image}`}
          alt="Uploaded"
          width={"100%"}
          height={"100%"}
          style={{ borderRadius: "5px" }}
        />
        <i
          className="mdi mdi-trash-can-outline img_del"
          onClick={() => handleImageDelete(image, category, "propimg")}
        ></i>
      </Col>
    )
    const renderprevideo = video => (
      <React.Fragment>
        <label htmlFor="">Property Video</label>
        <Col
          lg={6}
          key={video}
          style={{ textAlign: "center" }}
          className="position-relative"
        >
          <video controls style={{ width: "100%", borderRadius: "5px" }}>
            <source
              src={`${HOST_URL}public/img/propertyimg/${video}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <i
            className="mdi mdi-trash-can-outline img_del"
            onClick={() => handleVideoDelete(video, "propVideo")}
          ></i>
        </Col>
      </React.Fragment>
    )
    const handleSubmit = async () => {
      const extractModified = files => files.map(file => file.modified)
      var status
      const combinedImages = {
        images: [...propimg.images, ...extractModified(uploadedFiles)],
        floorPlan: [...propimg.floorPlan, ...extractModified(uploadedFilesF)],
      }
      const video = [...propVideo, ...extractModified(uploadedFilesV)]

      if (
        Object.keys(combinedImages).every(
          key => combinedImages[key].length === 0
        )
      ) {
        return { status: false, message: "Upload at least one image!.." }
      } else {
        const token = JSON.parse(localStorage.getItem("token"))
        const user_Id = localStorage.getItem("user_Id")
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        const data = {
          user_id: propData?.user_id || user_Id || user_id._id,
          property_id: propertyId || property_id,
          propertyimg: combinedImages,
          propVideo: video,
          propVideoLink: youtubeVLink,
        }
        const url = `${HOST_URL}upload/uploadfile`
        var response = {
          formtype:"uploadImg",
          status:true,
          message: "Image uploading in progress. Do not reload or close this window. You can continue to the next step.",
          uploading:'inProgress'
        }
        const toastId = toast("Image uploading in progress. Do not reload or close this window. You can continue to the next step.",{ 
          position: "top-right",
          theme: "light",
          autoClose: false,
          isLoading:true,
          closeButton:false
        });
        isAfterSubmit.current = true
        const filesToRemove = dropzoneRef?.current?.files.slice()
        const removeFileWithDelay = async () => {
          const file = filesToRemove.pop()
          if (file) {
            file.remove()
            await new Promise(resolve => setTimeout(resolve, 100))
            removeFileWithDelay()
          } else {
            isAfterSubmit.current = false
          }
        }
        ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            setUploadedFiles([])
            toast.update(toastId, {
              type:'success',
              render: resp.message ,
              isLoading: false,
              position: "top-right",
              theme: "light",
              autoClose: 1000,
              onClose: () => {
                const currentUrl = window.location.href;
                const [route, queryString] = currentUrl.split('#/')[1]?.split('?') || [];

                if (route === "listing-status") {
                    const urlParams = new URLSearchParams(queryString);
                    if (!urlParams.has('reloadRequired')) {
                        urlParams.set('reloadRequired', 'true');
                        const newUrl = `${window.location.origin}${window.location.pathname}#/listing-status?${urlParams.toString()}`;
                        window.location.href = newUrl;
                    }
                }
              },
            })
            return { formtype:"uploadImg", status: true, message: resp.message,uploading:'completed' }
          } else {
            return { formtype:"uploadImg", status: false, message: resp.message,uploading:'failed' }
          }
        })
        return { formtype:response.formtype, status: response.status, message: response.message, uploading:response.uploading }
      }
    }

    React.useImperativeHandle(ref, () => ({
      handleCimgupload: () => handleSubmit(),
    }))

    return (
      <React.Fragment>
        {uploadLoader && (
          <React.Fragment>
            <div className="loaderClass d-flex justify-content-center align-items-center">
              <div className="col-lg-2 text-center">
                <img
                  src={image}
                  alt=""
                  style={{ width: "100px", marginBottom: "10px" }}
                />
                <h6>Uploading image/video please wait!</h6>
              </div>
            </div>
          </React.Fragment>
        )}
        <React.Fragment>
          <h5>Add Photos</h5>
          <div className="page-content" style={{ paddingBottom: "10px" }}>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="photoCard" style={{ width: "100%" }}>
                    <Col
                      lg={12}
                      className="d-flex flex-wrap"
                      style={{ maxHeight: "200px", overflow: "auto" }}
                    >
                      {propimg.images &&
                        propimg.images.map(image =>
                          renderpreimage(image, "images")
                        )}
                    </Col>
                    <div className="mb-5">
                      <label>Property Images</label>
                      <Dropzone
                        ref={dropzoneRef}
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        maxFiles={maxImages}
                        accept="image/*"
                        inputContent="Drag 'n' drop some files here, or click to select"
                        files
                      />
                    </div>

                    <Col
                      lg={12}
                      className="d-flex flex-wrap"
                      style={{ maxHeight: "200px", overflow: "auto" }}
                    >
                      {propimg.floorPlan &&
                        propimg.floorPlan.map(image =>
                          renderpreimage(image, "floorPlan")
                        )}
                    </Col>
                    <div className="mb-5">
                      <label>FloorPlan</label>
                      <Dropzone
                        ref={dropzoneRef}
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleStatusFloorplan}
                        accept="image/*"
                        maxFiles={1}
                        multiple={false}
                        inputContent="Drop a File or Click to Browse"
                      />
                    </div>
                    <div>
                      {propVideo.length <= 0 ? (
                        <React.Fragment>
                          <label>Property Video</label>
                          <Dropzone
                            ref={dropzoneRef}
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleVideoStatus}
                            accept="video/*"
                            maxFiles={1}
                            multiple={false}
                            inputContent="Drop a File or Click to Browse"
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {propVideo &&
                            propVideo.map((video, index) => (
                              <div key={index} className="mb-2">
                                {renderprevideo(video)}
                              </div>
                            ))}
                        </React.Fragment>
                      )}
                      {propData &&
                        (user_id.role == "admin" ||
                          user_id.role == "manager") && (
                          <Col md={6}>
                            <Row className="mb-3 d-block">
                              <label
                                htmlFor="propVideoLink"
                                className="col-lg-12 col-form-label font-weight-bold"
                              >
                                Youtube Video Link
                              </label>
                              <div className="col-lg-12">
                                <Input
                                  id="propVideoLink"
                                  name="propVideoLink"
                                  type="text"
                                  className={`form-control`}
                                  value={youtubeVLink}
                                  onChange={handleLink}
                                />
                              </div>
                            </Row>
                          </Col>
                        )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      </React.Fragment>
    )
  }
)

export default Photouploadcomm
