import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import "react-toastify/dist/ReactToastify.css"

const RentadvanceDEdit = React.forwardRef(
  ({ propertytype, lookingfor, propData, propertyId }, ref) => {
    var lookingto = localStorage.getItem("lookingFor")
    const facingarray = [
      { id: 1, value: "North" },
      { id: 2, value: "East" },
      { id: 3, value: "West" },
      { id: 4, value: "South" },
      { id: 5, value: "North-East" },
      { id: 6, value: "North-West" },
      { id: 7, value: "South-East" },
      { id: 8, value: "South-West" },
    ]
    const openSidesarray = [
      { id: 1, value: "1" },
      { id: 2, value: "2" },
      { id: 3, value: "3" },
      { id: 4, value: "4" },
      { id: 5, value: "4+" },
    ]
    const [rrentAdvanDetails, setrrentAdvanDetails] = useState({
      parkingCharges: propData?.advanceDetails?.parkingCharges || "",
      paintingCharges: propData?.advanceDetails?.paintingCharges || "",
      cornerProperty: propData?.advanceDetails?.cornerProperty || "",
      constructionStarted: propData?.advanceDetails?.constructionStarted || "",
      facing: propData?.advanceDetails?.facing || "",
      address: propData?.advanceDetails?.address || "",
      servantRoom: propData?.advanceDetails?.servantRoom || "",
      propertyDescription: propData?.advanceDetails?.propertyDescription || "",
      reraId: propData?.advanceDetails?.reraId || "",
      boundaryWallPresent: propData?.advanceDetails?.boundaryWallPresent || "",
      noOfOpenSides: propData?.advanceDetails?.noOfOpenSides || "",
      floorsAllowedForConstruction:
        propData?.advanceDetails?.floorsAllowedForConstruction || "",
    })
    const [rrentAdvanError, setrrentAdvanError] = useState({
      parkingCharges: "",
      paintingCharges: "",
      facing: "",
      address: "",
      servantRoom: "",
      propertyDescription: "",
      cornerProperty: "",
      constructionStarted: "",
    })
    const [textcount, settextcount] = useState(0)
    const handleChange = (name, value) => {
      // const { name, value } = e.target;
      setrrentAdvanDetails(prevDetails => ({
        ...prevDetails,
        [name]: value,
      }))
      setrrentAdvanError(prevErrors => ({
        ...prevErrors,
        [name]: "",
      }))
    }
    function textareachange(event) {
      settextcount(event.target.value.length)
      handleChange("propertyDescription", event.target.value)
    }

    function isObjectEmpty(obj) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== "") {
          return false
        }
      }
      return true
    }

    const handleSubmit = async () => {
      var status
      const isEmpty = isObjectEmpty(rrentAdvanDetails)
      if (isEmpty) {
        status = true
        return { status: status }
      } else {
        const url = `${HOST_URL}property/advancedetails`
        const token = JSON.parse(localStorage.getItem("token"))
        const user_id = JSON.parse(localStorage.getItem("authUser"))
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        const data = {
          user_id: propData?.user_id,
          property_id: propertyId || property_id,
          advanceDetails: rrentAdvanDetails,
        }
        var response
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            status = true
            response = resp
          } else {
            status = false
            response = resp
          }
        })
        return { status: status, message: response.message }
      }
    }
    React.useImperativeHandle(ref, () => ({
      handleRentadvanceD: () => handleSubmit(),
    }))
    return (
      <React.Fragment>
        <h5>Add Advance Details</h5>
        {lookingfor == "Rent" ? (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Parking Charges
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.parkingCharges === "Include In Rent"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("parkingCharges", "Include In Rent")
                        }
                      >
                        <Input
                          type="radio"
                          name="parkingCharges"
                          value="Include In Rent"
                          onChange={() =>
                            handleChange("parkingCharges", "Include In Rent")
                          }
                          checked={
                            rrentAdvanDetails.parkingCharges ===
                            "Include In Rent"
                          }
                          style={{ opacity: 0 }}
                        />
                        Include In Rent
                      </Button>
                      <Button
                        style={{ borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.parkingCharges === "Separate"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("parkingCharges", "Separate")
                        }
                      >
                        <Input
                          type="radio"
                          name="parkingCharges"
                          value="Separate"
                          onChange={() =>
                            handleChange("parkingCharges", "Separate")
                          }
                          checked={
                            rrentAdvanDetails.parkingCharges === "Separate"
                          }
                          style={{ opacity: 0 }}
                        />
                        Separate
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Painting Charges
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.paintingCharges === "None"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handleChange("paintingCharges", "None")}
                      >
                        <Input
                          type="radio"
                          name="paintingCharges"
                          value="None"
                          onChange={() =>
                            handleChange("paintingCharges", "None")
                          }
                          checked={rrentAdvanDetails.paintingCharges === "None"}
                          style={{ opacity: 0 }}
                        />
                        None
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.paintingCharges === "As Per Cost"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("paintingCharges", "As Per Cost")
                        }
                      >
                        <Input
                          type="radio"
                          name="paintingCharges"
                          value="As Per Cost"
                          onChange={() =>
                            handleChange("paintingCharges", "As Per Cost")
                          }
                          checked={
                            rrentAdvanDetails.paintingCharges === "As Per Cost"
                          }
                          style={{ opacity: 0 }}
                        />
                        As Per Cost
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.paintingCharges === "1 Month"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("paintingCharges", "1 Month")
                        }
                      >
                        <Input
                          type="radio"
                          name="paintingCharges"
                          value="1 Month"
                          onChange={() =>
                            handleChange("paintingCharges", "1 Month")
                          }
                          checked={
                            rrentAdvanDetails.paintingCharges === "1 Month"
                          }
                          style={{ opacity: 0 }}
                        />
                        1 Month
                      </Button>
                      <Button
                        style={{ borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.paintingCharges === "Custom"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("paintingCharges", "Custom")
                        }
                      >
                        <Input
                          type="radio"
                          name="paintingCharges"
                          value="Custom"
                          onChange={() =>
                            handleChange("paintingCharges", "Custom")
                          }
                          checked={
                            rrentAdvanDetails.paintingCharges === "Custom"
                          }
                          style={{ opacity: 0 }}
                        />
                        Custom
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          ""
        )}
        {(propData?.propertyDetails?.propertyType2 == "Plot" || propData?.propertyDetails?.propertyType2 == "Agricultural Land") &&
        lookingfor == "Sell" ? (
          <Row>
            <Col md={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  Corner Property?
                </label>
                <div className="col-lg-12">
                  <ButtonGroup className="w-100">
                    <Button
                      style={{ marginRight: "5px", borderRadius: "5px" }}
                      className={
                        rrentAdvanDetails.cornerProperty === "Yes"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handleChange("cornerProperty", "Yes")}
                    >
                      <Input
                        type="radio"
                        name="cornerProperty"
                        value="Yes"
                        onChange={() => handleChange("cornerProperty", "Yes")}
                        checked={rrentAdvanDetails.cornerProperty === "Yes"}
                        style={{ opacity: 0 }}
                      />
                      Yes
                    </Button>
                    <Button
                      style={{ borderRadius: "5px" }}
                      className={
                        rrentAdvanDetails.cornerProperty === "No"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handleChange("cornerProperty", "No")}
                    >
                      <Input
                        type="radio"
                        name="cornerProperty"
                        value="No"
                        onChange={() => handleChange("cornerProperty", "No")}
                        checked={rrentAdvanDetails.cornerProperty === "No"}
                        style={{ opacity: 0 }}
                      />
                      No
                    </Button>
                  </ButtonGroup>
                </div>
              </Row>
            </Col>
            {propData?.propertyDetails?.propertyType2 != "Agricultural Land" ? (
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Construction Started?
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.constructionStarted === "Yes"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handleChange("constructionStarted", "Yes")}
                      >
                        <Input
                          type="radio"
                          name="constructionStarted"
                          value="Yes"
                          onChange={() =>
                            handleChange("constructionStarted", "Yes")
                          }
                          checked={
                            rrentAdvanDetails.constructionStarted === "Yes"
                          }
                          style={{ opacity: 0 }}
                        />
                        Yes
                      </Button>
                      <Button
                        style={{ borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.constructionStarted === "No"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handleChange("constructionStarted", "No")}
                      >
                        <Input
                          type="radio"
                          name="constructionStarted"
                          value="No"
                          onChange={() =>
                            handleChange("constructionStarted", "No")
                          }
                          checked={rrentAdvanDetails.constructionStarted === "No"}
                          style={{ opacity: 0 }}
                        />
                        No
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            ):''}
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col md={12}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-12 col-form-label font-weight-bold"
              >
                Facing
              </label>
              <div className="col-lg-12">
                <ButtonGroup className="w-100 grid-columns">
                  {facingarray.map(face => (
                    <Button
                      key={face.id}
                      style={{ borderRadius: "5px" }}
                      className={
                        rrentAdvanDetails.facing === face.value
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handleChange("facing", face.value)}
                    >
                      <Input
                        type="radio"
                        name="facing"
                        value={face.value}
                        onChange={() => handleChange("facing", face.value)}
                        checked={rrentAdvanDetails.facing === face.value}
                        style={{ opacity: 0 }}
                      />
                      {face.value}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="address"
                className="col-lg-12 col-form-label font-weight-bold"
              >
                Address
              </label>
              <div className="col-lg-12">
                <Input
                  id="address"
                  name="address"
                  type="text"
                  className={`form-control btnNormal`}
                  // placeholder="Enter property age"
                  value={rrentAdvanDetails.address}
                  onChange={e => handleChange("address", e.target.value)}
                />
              </div>
            </Row>
          </Col>
        </Row>

        {(propData?.propertyDetails?.propertyType2 == "Plot" || propData?.propertyDetails?.propertyType2 == "Agricultural Land") &&
        lookingfor == "Sell" ? (
          <React.Fragment>
            <Row>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Boundary Wall Present?
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.boundaryWallPresent === "Yes"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("boundaryWallPresent", "Yes")
                        }
                      >
                        <Input
                          type="radio"
                          name="boundaryWallPresent"
                          value="Yes"
                          onChange={() =>
                            handleChange("boundaryWallPresent", "Yes")
                          }
                          checked={
                            rrentAdvanDetails.boundaryWallPresent === "Yes"
                          }
                          style={{ opacity: 0 }}
                        />
                        Yes
                      </Button>
                      <Button
                        style={{ borderRadius: "5px" }}
                        className={
                          rrentAdvanDetails.boundaryWallPresent === "No"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("boundaryWallPresent", "No")
                        }
                      >
                        <Input
                          type="radio"
                          name="boundaryWallPresent"
                          value="No"
                          onChange={() =>
                            handleChange("boundaryWallPresent", "No")
                          }
                          checked={
                            rrentAdvanDetails.boundaryWallPresent === "No"
                          }
                          style={{ opacity: 0 }}
                        />
                        No
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    No. of open sides
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      {openSidesarray.map(bhk => (
                        <Button
                          key={bhk.id}
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            rrentAdvanDetails.noOfOpenSides === bhk.value
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange("noOfOpenSides", bhk.value)
                          }
                        >
                          {bhk.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
              {propData?.propertyDetails?.propertyType2 != "Agricultural Land" ? (
                <Col md={12}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="floorsAllowedForConstruction"
                      className="col-lg-12 col-form-label font-weight-bold"
                    >
                      Floors Allowed for Construction
                    </label>
                    <div className="col-lg-12">
                      <Input
                        id="floorsAllowedForConstruction"
                        name="floorsAllowedForConstruction"
                        type="number"
                        className={`form-control btnNormal`}
                        // placeholder="Enter property age"
                        value={rrentAdvanDetails.floorsAllowedForConstruction}
                        onChange={e =>
                          handleChange(
                            "floorsAllowedForConstruction",
                            e.target.value
                          )
                        }
                        onWheel={e => e.target.blur()}
                        min={0}
                      />
                    </div>
                  </Row>
                </Col>
              ):''}
            </Row>
          </React.Fragment>
        ) : (
          <Row>
            <Col md={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="txtFirstNameBilling"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  Servant Room
                </label>
                <div className="col-lg-12">
                  <ButtonGroup className="w-100">
                    <Button
                      style={{ marginRight: "5px", borderRadius: "5px" }}
                      className={
                        rrentAdvanDetails.servantRoom === "true"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handleChange("servantRoom", "true")}
                    >
                      <Input
                        type="radio"
                        name="servantRoom"
                        value="true"
                        onChange={() => handleChange("servantRoom", "true")}
                        checked={rrentAdvanDetails.servantRoom === "true"}
                        style={{ opacity: 0 }}
                      />
                      Yes
                    </Button>
                    <Button
                      style={{ borderRadius: "5px" }}
                      className={
                        rrentAdvanDetails.servantRoom === "false"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handleChange("servantRoom", "false")}
                    >
                      <Input
                        type="radio"
                        name="servantRoom"
                        value="false"
                        onChange={() => handleChange("servantRoom", "false")}
                        checked={rrentAdvanDetails.servantRoom === "false"}
                        style={{ opacity: 0 }}
                      />
                      No
                    </Button>
                  </ButtonGroup>
                </div>
              </Row>
            </Col>
          </Row>
        )}
        {lookingfor == "Sell" ? (
          <Row>
            <Col md={12}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="reraId"
                  className="col-lg-12 col-form-label font-weight-bold"
                >
                  RERA ID
                </label>
                <div className="col-lg-12">
                  <Input
                    id="reraId"
                    name="reraId"
                    type="text"
                    className={`form-control btnNormal`}
                    // placeholder="Enter property age"
                    value={rrentAdvanDetails.reraId}
                    onChange={e => handleChange("reraId", e.target.value)}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col md={12}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-12 col-form-label font-weight-bold"
              >
                Property Description
              </label>
              <div className="col-lg-12">
                <Input
                  type="textarea"
                  id="textarea"
                  className="btnNormal"
                  value={rrentAdvanDetails.propertyDescription}
                  name="propertyDescription"
                  onChange={e => {
                    textareachange(e)
                  }}
                  maxLength="2000"
                  rows="3"
                  // placeholder="Offers must be seperated by comma ,"
                />
                <span className="badgecount d-block badge-success text-end w-100">
                  {" "}
                  {textcount} / 2000{" "}
                </span>
              </div>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
)

export default RentadvanceDEdit
