import React from "react"
import { Box, Grid, Paper, Typography } from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Button, Col, Container, Input } from "reactstrap"
import VerifyOtp from "./Otpmodal"
import { HOST_URL } from "helpers/url_helper"
import Home from "../../assets/images/Home.png"
import { Image } from "react-bootstrap"
import logo11 from "../../assets/images/logo-dark.png"
import CloseIcon from "@mui/icons-material/Close"

export default function CustomRegister() {
  const [showVotpModal, setShowVotpModal] = useState(false)
  const location = useLocation()
  const logintoast = new URLSearchParams(location.search).get("referCode")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [frontImage, setFrontImage] = useState("")
  const [backImage, setBackImage] = useState(null)
  const [panImage, setPanImage] = useState(null)
  const [profileImage, setProfileImage] = useState(null)
  const [inputvalue, setInputValue] = useState({
    name: "",
    email: "",
    mobno: "",
    password: "",
    gender: "",
    profileImage: "",
    panImage: "",
    backImage: "",
    frontImage: frontImage,
    referId: logintoast || "123",
  })

  const [inputvalueerror, setInputValueError] = useState({
    name: "",
    email: "",
    mobno: "",
    password: "",
    gender: "",
    profileImage: "",
    panImage: "",
    backImage: "",
    frontImage: "",
  })

  const subscriptionEndDate = new Date("2024-12-30");
  const isDateWithinSubscriptionPeriod = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to 00:00:00:00
    return today <= subscriptionEndDate;
  };

  const getRemainingDays = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const timeDifference = subscriptionEndDate - today;
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysRemaining;
  };

  const handleImageUpload = async (file, fieldId) => {
    if (!isDateWithinSubscriptionPeriod()) {
      console.log("Renewal Required...!");
      router("/Login")  
    }else{
      const formData = new FormData()
      formData.append("images", file)
      const url = `${HOST_URL}api/upload/userImg`
      await fetch(url, {
        method: "POST",
        body: formData,
      })
        .then(response => response.json())
        .then(response => {
          if (fieldId == "profileImg") {
            setProfileImage(
              `${HOST_URL}public/img/tempimg/${response.files[0].filename}`
            )
            setInputValue(prevdata => ({
              ...prevdata,
              profileImage: response.files[0].filename,
            }))
          }
          if (fieldId == "panImg") {
            setPanImage(
              `${HOST_URL}public/img/tempimg/${response.files[0].filename}`
            )
            setInputValue(prevdata => ({
              ...prevdata,
              panImage: response.files[0].filename,
            }))
          }
          if (fieldId == "aadhFrontImg") {
            setFrontImage(
              `${HOST_URL}public/img/tempimg/${response.files[0].filename}`
            )
            setInputValue(prevdata => ({
              ...prevdata,
              frontImage: response.files[0].filename,
            }))
          }
          if (fieldId == "aadhBackImg") {
            setBackImage(
              `${HOST_URL}public/img/tempimg/${response.files[0].filename}`
            )
            setInputValue(prevdata => ({
              ...prevdata,
              backImage: response.files[0].filename,
            }))
          }
        })
        .catch(error => {
          console.error("Error uploading image:", error)
          throw error
        })
    }
  }

  const handleImageDelete = async (filename, fieldId) => {
    try {
      const response = await fetch(`${HOST_URL}api/delete/${filename}`, {
        method: "DELETE",
        body: JSON.stringify({ imageType: "userImage" }),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (!response.ok) {
        throw new Error(`Deletion failed with status ${response.status}`)
      }

      if (fieldId == "profileImg") {
        setProfileImage("")
        setInputValue(prevdata => ({
          ...prevdata,
          profileImage: "",
        }))
        document.getElementById("profileImage").value = ""
      }
      if (fieldId == "panImg") {
        setPanImage("")
        setInputValue(prevdata => ({
          ...prevdata,
          panImage: "",
        }))
        document.getElementById("panImage").value = ""
      }
      if (fieldId == "aadhFrontImg") {
        setFrontImage("")
        setInputValue(prevdata => ({
          ...prevdata,
          frontImage: "",
        }))
        document.getElementById("frontImage").value = ""
      }
      if (fieldId == "aadhBackImg") {
        setBackImage("")
        setInputValue(prevdata => ({
          ...prevdata,
          backImage: "",
        }))
        document.getElementById("backImage").value = ""
      }
    } catch (error) {
      console.error("Error deleting image", error)
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setInputValue(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }))
    setInputValueError(prevErrors => ({
      ...prevErrors,
      [name]: "",
    }))
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit(event)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress)
    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [])
  const handleModalClose = () => {
    setShowVotpModal(false)
  }

  const handleModalShow = () => {
    setShowVotpModal(true)
  }
  const router = useNavigate()

  const validateForm = () => {
    let valid = true
    const newErrors = {}

    if (inputvalue.name.trim() === "") {
      newErrors.name = "Name is required"
      valid = false
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(inputvalue.email)) {
      newErrors.email = "Invalid email address"
      valid = false
    }

    const mobnoRegex = /^\d{10}$/
    if (!mobnoRegex.test(inputvalue.mobno)) {
      newErrors.mobno = "Invalid mobile number"
      valid = false
    }
    if (!/^[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(inputvalue.password)) {
      newErrors.password =
        "Password must be between 8 and 20 characters long and can contain letters, numbers, and special characters (!@#$%^&*)"
      valid = false
    }

    if (!profileImage) {
      newErrors.profileImage = "Profile Image is required"
      valid = false
    }

    if (!panImage) {
      newErrors.panImage = "PAN Card Image is required"
      valid = false
    }

    if (!backImage) {
      newErrors.backImage = "Back Image is required"
      valid = false
    }

    if (!frontImage) {
      newErrors.frontImage = "Front Image is required"
      valid = false
    }

    if (inputvalue.gender === "") {
      newErrors.gender = "Gender is required"
      valid = false
    }
    if (inputvalue.referId === "") {
      newErrors.referId = "ReferId is required"
      valid = false
    }

    setInputValueError(newErrors)
    return valid
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const data = {
      ...inputvalue,
      role: "user",
    }

    if (!isDateWithinSubscriptionPeriod()) {
      console.log("Renewal Required...!");
      router("/Login")  
    }else{
      if (validateForm()) {
        const url = `${HOST_URL}auth/sendotp`

        const headers = {
          "Content-Type": "application/json",
        }
        await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        })
          .then(resp => resp.json())
          .then(response => {
            if (response.error === false) {
              toast.success(`${response.message}!...`, {
                position: "top-right",
                theme: "light",
              })
              handleModalShow()
            } else {
              toast.error(`${response.message}!...`, {
                position: "top-right",
                theme: "light",
              })
            }
          })
          .catch(error => {
            throw error
          })
      }
    }
  }

  return (
    <Container fluid className="container-fluid">
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} xs={12} className="back-img backimg2">
            <div
              style={{
                marginBottom: "15px",
                marginTop: "15px",
                display: "flex",
              }}
              className="logoImg"
            >
              <Image src={logo11} style={{ width: "220px" }} />
            </div>
            <Grid lg={9}>
              <h1 class="heading">Are You Looking For Your Ideal Home?</h1>
            </Grid>
            <img src={Home} alt="" class="back-img2 "></img>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <div className=" right-side-container">
              <Paper style={{ paddingTop: "20px", boxShadow: "none" }}>
                <Box
                  style={{
                    display: windowWidth > 899 ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    justifyContent: "end",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography mr={3}>Already have an account</Typography>
                  <Button
                    variant="contained"
                    className="freehousing_button addbutton"
                    onClick={() => router("/Login")}
                  >
                    Login
                  </Button>
                </Box>
                <Box
                  style={{ padding: "0px 15px", width: "75%", margin: "auto" }}
                  className="mainBox"
                >
                  <div
                    style={{
                      marginBottom: "25px",
                      marginTop: "15px",
                      display: "flex",
                      display: "none",
                    }}
                    className="logoImg"
                  >
                    <img
                      src="favicon.ico"
                      alt="Favicon"
                      class="text-center headimg me-1"
                    />
                    <span className="text-dark head1">FREELIFE </span>
                    <span class=" head2 mx-1"> HOUSING</span>
                  </div>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    className="variantH5"
                  >
                    Welcome to FreeLife Housing!
                  </Typography>
                  <Typography color={"#8E8E8E"}>
                    Register your account
                  </Typography>
                  <Box style={{ marginTop: "20px" }}>
                    <div className="loginregwrap">
                      <label className="loginreglabel p-0 font-weight-bold">
                        Full Name
                      </label>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        className={`form-control loginRegInput`}
                        placeholder="Enter your full name"
                        value={inputvalue.name}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                      />

                      {inputvalueerror.name && (
                        <span className="error">{inputvalueerror.name}</span>
                      )}
                    </div>
                    <div className="loginregwrap">
                      <label className="loginreglabel p-0 font-weight-bold">
                        Email
                      </label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        className={`form-control loginRegInput`}
                        placeholder="Enter your email id"
                        value={inputvalue.email}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                      />
                      {inputvalueerror.email && (
                        <span className="error">{inputvalueerror.email}</span>
                      )}
                    </div>

                    <div className="loginregwrap">
                      <label className="loginreglabel font-weight-bold">
                        Mobile Number
                      </label>
                      <Input
                        type="number"
                        id="mobno"
                        name="mobno"
                        onChange={handleInputChange}
                        value={inputvalue.mobno}
                        placeholder="Enter your mobile number"
                        className="form-control loginRegInput"
                        onKeyPress={handleKeyPress}
                        onWheel={e => e.target.blur()}
                      />
                      {inputvalueerror.mobno && (
                        <span className="error">{inputvalueerror.mobno}</span>
                      )}
                    </div>
                    <div className="loginregwrap">
                      <label className="loginreglabel font-weight-bold">
                        Password
                      </label>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        onChange={handleInputChange}
                        value={inputvalue.password}
                        placeholder="Set your password for account"
                        className="loginRegInput form-control"
                        onKeyPress={handleKeyPress}
                      />
                      {inputvalueerror.password && (
                        <span className="error">
                          {inputvalueerror.password}
                        </span>
                      )}
                    </div>

                    <div className="loginregwrap">
                      <label className="loginreglabel font-weight-bold">
                        PAN Card Image
                      </label>
                      <div className="d-flex align-items-center">
                        <Input
                          type="file"
                          id="panImage"
                          name="panImage"
                          accept="image/*"
                          onChange={e =>
                            handleImageUpload(e.target.files[0], "panImg")
                          }
                          className="form-control loginRegInput"
                        />
                      </div>
                      {inputvalueerror.panImage && (
                        <span className="error">
                          {inputvalueerror.panImage}
                        </span>
                      )}
                      <div className="d-flex">
                        {panImage && (
                          <div
                            className="preview-container"
                            style={{
                              position: "relative",
                              display: "inline-block",
                              background:'ghostwhite', padding:'10px',borderRadius:'5px',marginTop:'10px'
                            }}
                          >
                            <img
                              style={{
                                height: "100px",
                              }}
                              src={panImage}
                              alt="Profile Image Preview"
                              className="image-preview"
                            />
                            <Button
                              onClick={() =>
                                handleImageDelete(inputvalue.panImage, "panImg")
                              }
                              variant="text"
                              size="small"
                              style={{
                                color: "#000000",
                                position: "absolute",
                                padding: "0px",
                                top: "11px",
                                right: "11px",
                              }}
                            >
                              <CloseIcon />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="loginregwrap">
                      <label className="loginreglabel font-weight-bold">
                        Profile Picture
                      </label>
                      <div className="d-flex align-items-center">
                        <Input
                          type="file"
                          id="profileImage"
                          name="profileImage"
                          accept="image/*"
                          onChange={e =>
                            handleImageUpload(e.target.files[0], "profileImg")
                          }
                          className="form-control loginRegInput"
                        />
                      </div>
                      {inputvalueerror.profileImage && (
                        <span className="error">
                          {inputvalueerror.profileImage}
                        </span>
                      )}
                      <div className="d-flex">
                        {profileImage && (
                          <div
                            className="preview-container"
                            style={{
                              position: "relative",
                              display: "inline-block",
                              background:'ghostwhite', padding:'10px',borderRadius:'5px',marginTop:'10px'
                            }}
                          >
                            <img
                              style={{
                                height: "100px",
                              }}
                              src={profileImage}
                              alt="Profile Image Preview"
                              className="image-preview"
                            />
                            <Button
                              onClick={() =>
                                handleImageDelete(
                                  inputvalue.profileImage,
                                  "profileImg"
                                )
                              }
                              variant="text"
                              size="small"
                              style={{
                                color: "#000000",
                                position: "absolute",
                                padding: "0px",
                                top: "11px",
                                right: "11px",
                              }}
                            >
                              <CloseIcon />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="loginregwrap">
                      <label className="loginreglabel font-weight-bold">
                        Aadhaar Card Image
                      </label>
                      <div className="d-flex align-items-center">
                        <span className="px-2">Front</span>
                        <Input
                          type="file"
                          id="frontImage"
                          name="frontImage"
                          accept="image/*"
                          onChange={e =>
                            handleImageUpload(e.target.files[0], "aadhFrontImg")
                          }
                          className="form-control loginRegInput"
                        />
                        <span className="px-2">Back</span>
                        <Input
                          type="file"
                          id="backImage"
                          name="backImage"
                          accept="image/*"
                          onChange={e =>
                            handleImageUpload(e.target.files[0], "aadhBackImg")
                          }
                          className="form-control loginRegInput"
                        />
                      </div>
                      {inputvalueerror.frontImage && (
                        <span className="error">
                          {inputvalueerror.frontImage}
                        </span>
                      )}
                      {inputvalueerror.backImage && (
                        <span className="error">
                          {inputvalueerror.backImage}
                        </span>
                      )}
                      <div className="d-flex">
                        <div className="preview-container">
                          {frontImage && (
                            <div
                              className="image-container"
                              style={{
                                position: "relative",
                                display: "inline-block",
                                background:'ghostwhite', padding:'10px',borderRadius:'5px',marginTop:'10px'
                              }}
                            >
                              <img
                                style={{
                                  height: "100px",
                                }}
                                src={frontImage}
                                alt="Front Image Preview"
                                className="image-preview"
                              />
                              <Button
                                onClick={() =>
                                  handleImageDelete(
                                    inputvalue.frontImage,
                                    "aadhFrontImg"
                                  )
                                }
                                variant="text"
                                size="small"
                                style={{
                                  color: "#000000",
                                  position: "absolute",
                                  padding: "0px",
                                  top: "11px",
                                  right: "11px",
                                }}
                              >
                                <CloseIcon style={{ color: "error" }} />
                              </Button>
                            </div>
                          )}
                          {backImage && (
                            <div
                              className="image-container"
                              style={{
                                position: "relative",
                                display: "inline-block",
                                background:'ghostwhite', padding:'10px',borderRadius:'5px',marginTop:'10px'
                              }}
                            >
                              <img
                                style={{
                                  height: "100px",
                                }}
                                src={backImage}
                                alt="Back Image Preview"
                                className="image-preview"
                              />
                              <Button
                                onClick={() =>
                                  handleImageDelete(
                                    inputvalue.backImage,
                                    "aadhBackImg"
                                  )
                                }
                                variant="text"
                                size="small"
                                style={{
                                  color: "#000000",
                                  position: "absolute",
                                  padding: "0px",
                                  top: "11px",
                                  right: "11px",
                                }}
                              >
                                <CloseIcon />
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="loginregwrap">
                      <label className="font-weight-bold">Gender</label>
                      <div className="d-flex">
                        <label
                          htmlFor="gender_male"
                          className="d-flex align-items-center p-2 pt-0 pb-0"
                        >
                          <Input
                            type="radio"
                            id="gender_male"
                            name="gender"
                            value="Male"
                            onChange={handleInputChange}
                            className="loginRegInput form-control"
                            onKeyPress={handleKeyPress}
                          />
                          <h6 className="m-0 ps-1">Male</h6>
                        </label>
                        <label
                          htmlFor="gender_fema"
                          className="d-flex align-items-center p-2 pt-0 pb-0"
                        >
                          <Input
                            type="radio"
                            id="gender_fema"
                            name="gender"
                            value="Female"
                            onChange={handleInputChange}
                            className="loginRegInput form-control"
                            onKeyPress={handleKeyPress}
                          />
                          <h6 className="m-0 ps-1">Female</h6>
                        </label>
                        {inputvalueerror.gender && (
                          <span className="error">
                            {inputvalueerror.gender}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="loginregwrap">
                      <label className="loginreglabel font-weight-bold">
                        ReferId
                      </label>
                      <Input
                        type="text"
                        id="referId"
                        name="referId"
                        onChange={handleInputChange}
                        value={inputvalue.referId}
                        placeholder="Enter Refer code"
                        className="loginRegInput form-control"
                        onKeyPress={handleKeyPress}
                      />
                      {inputvalueerror.referId && (
                        <span className="error">{inputvalueerror.referId}</span>
                      )}
                    </div>
                  </Box>
                  <Button
                    variant="contained"
                    className="freehousing_button addbutton"
                    style={{
                      textTransform: "none",
                      width: "100%",
                      margin: "15px 0",
                    }}
                    onClick={event => handleSubmit(event)}
                  >
                    Register
                  </Button>
                  <div>
                    <Box
                      style={{
                        display: windowWidth < 899 ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="logindivborder"></div>
                      <span style={{ padding: "10px" }} className="orline">
                        OR
                      </span>
                      <div className="logindivborder"></div>
                    </Box>
                    <Button
                      variant="contained"
                      className="freehousing_button addbutton"
                      onClick={() => router("/Login")}
                      style={{
                        display: windowWidth < 899 ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "none",
                        width: "100%",
                        margin: "15px 0",
                      }}
                    >
                      Login
                    </Button>
                  </div>
                </Box>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Box>
      <VerifyOtp
        show={showVotpModal}
        handleClose={handleModalClose}
        mobileno={inputvalue.mobno}
      />
      {/* <ToastContainer /> */}
    </Container>
  )
}
