import {
  Box,
  // Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  paperClasses,
} from "@mui/material"
import loginimage from "../../assets/images/loginRegister.png"
import logo from "../../assets/images/logo-dark.png"
import Home from "../../assets/images/Home.png"
import logo11 from "../../assets/images/logo-dark.png"
//   import SendIcon from "@mui/icons-material/Send";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { post } from "helpers/api_helper"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Button, Col, Container, Form, Row } from "reactstrap"
import { HOST_URL, NEXT_URL } from "helpers/url_helper"
import { ApiPostMethod } from "helpers/withoutToken_helper"
import { Image } from "react-bootstrap"

const Login = () => {
  const router = useNavigate()
  const location = useLocation()
  const userRole = JSON.parse(localStorage.getItem("userRole"))
  useEffect(() => {
    if (location.pathname == "/Login") {
      if (localStorage.getItem("authUser")) {
        if (userRole == "admin" || userRole == "manager") {
          router("/Dashboard")
        } else {
          router("/userPropertyList")
          // window.location.href = `${NEXT_URL}userstorage?token`;
        }
      }
    }
  }, [])
  const logintoast = new URLSearchParams(location.search).get("register")
  // console.log(logintoast)
  useEffect(() => {
    if (logintoast && logintoast === "true") {
      toast.success(`User Registered Successfully!...`, {
        position: "top-right",
        theme: "light",
        onClose: () => {
          router("/Login")
        },
      })
    }
  }, [logintoast])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  // const [loginButtonPosition, setLoginButtonPosition] = useState("above")

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 900) {
  //       setLoginButtonPosition("below")
  //     } else {
  //       setLoginButtonPosition("above")
  //     }
  //   }

  //   window.addEventListener("resize", handleResize)

  //   // Cleanup function to remove the event listener
  //   return () => window.removeEventListener("resize", handleResize)
  // }, [])

  const [inputvalue, setInputValue] = useState({
    mobno: "",
    password: "",
  })
  const [inputvalueerror, setInputValueError] = useState({
    mobno: "",
    password: "",
  })
  // const handleInputChange = e => {
  //   const { name, value } = e.target
  //   setInputValue(prevDetails => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }))
  //   setInputValueError(prevErrors => ({
  //     ...prevErrors,
  //     [name]: "",
  //   }))
  // }

  const handleInputChange = e => {
    const { name, value } = e.target
    setInputValue(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }))
    setInputValueError(prevErrors => ({
      ...prevErrors,
      [name]: "",
    }))
  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      // Call your login function here when Enter key is pressed
      handleLogin()
    }
  }
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  }

  // const handleLogin = () =>{
  //     const newErrors = {};

  //     if (inputvalue.mobno.trim() === '') {
  //       newErrors.mobno = 'Mobile number is required';
  //     }
  //     if (inputvalue.password.trim() === '') {
  //       newErrors.password = 'Password is required';
  //     }

  //     setInputValueError(newErrors);
  //     if (Object.values(newErrors).every((error) => error === '')) {
  //         const url = "http://13.232.214.52:5000/auth/login";
  //         return fetch(url, {
  //             method: "POST",
  //             header: {'content-type' : 'multipart/form-data'},
  //             body: inputvalue
  //           })
  //           .then((resp) => resp.json())
  //           .then(response => {
  //             console.log("response", response.date);
  //         })
  //         .catch(error => {
  //             throw error;
  //         });
  //     //   return true;
  //     }else{
  //       return false
  //     }
  // };
  const handleLogin = async () => {
    const newErrors = {}

    if (inputvalue.mobno.trim() === "") {
      newErrors.mobno = "Mobile number is required"
    }
    if (inputvalue.password.trim() === "") {
      newErrors.password = "Password is required"
    }

    setInputValueError(newErrors)

    if (Object.values(newErrors).every(error => error === "")) {
      // const url = "https://expressapi.freelifehousing.com/auth/login"
      const url = `${HOST_URL}auth/login`

      // Corrected headers
      const headers = {
        "Content-Type": "application/json",
      }
      try {
        const response = await ApiPostMethod(url, inputvalue, "POST")
        // console.log("response", response)
        if (response.error === false) {
          localStorage.setItem("authUser", JSON.stringify(response.user))
          localStorage.setItem("userRole", JSON.stringify(response.user.role))
          localStorage.setItem("token", JSON.stringify(response.token))
          setInputValue({
            mobno: "",
            password: "",
          })
          toast.success(`User logged in successfully!...`, {
            position: "top-right",
            theme: "light",
          })
          if (
            response.user.role == "admin" ||
            response.user.role == "manager"
          ) {
            router("/Dashboard?authuser=true")
          } else {
            // router("/userPropertyList");
            window.location.href = `${NEXT_URL}userstorage?token=${response.token}&&username=${response.user.name}&&user_id=${response.user._id}`
            // window.location.href = NEXT_URL;
          }
        } else {
          toast.error(response.message, {
            position: "top-right",
            theme: "light",
          })
          router("/Login")
        }
      } catch (error) {
        throw error
      }
    } else {
      return false
    }
  }

  return (
    <Container fluid className="container-fluid">
      <Box>
        <Grid container spacing={2}>
          {/* Left Side of Login Page */}
          <Grid item lg={6} md={6} xs={12} className="back-img">
            <div
              style={{
                marginBottom: "15px",
                marginTop: "15px",
                display: "flex",
              }}
            >
              <Image src={logo11} style={{ width: "200px" }} />
            </div>
            <Grid lg={9}>
              <h1 class="heading">Are You Looking For Your Ideal Home?</h1>
            </Grid>
            <img src={Home} alt="" class="back-img1 "></img>
          </Grid>
          {/* Left Side of Login Page End */}

          <Grid item lg={6} md={6} xs={12}>
            {/* Right side content */}
            <div className=" right-side-container">
              <Paper style={{ paddingTop: "20px", boxShadow: "none" }}>
                {/* Form content */}
                <Box
                  style={{
                    display: windowWidth > 899 ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    justifyContent: "end",
                    marginBottom: "30px",
                  }}
                >
                  <Typography mr={3}>Don’t have an account</Typography>
                  <Button
                    variant="contained"
                    className="freehousing_button addbutton registerbtn"
                    onClick={() => router("/registerpage")}
                  >
                    Register
                  </Button>
                </Box>
                <Box
                  style={{ padding: "0px 15px", width: "75%", margin: "auto" }}
                  className="mainBox"
                >
                  <div
                    style={{
                      marginBottom: "25px",
                      marginTop: "15px",
                      display: "none",
                    }}
                    className="logoImg"
                  >
                    <Image src={logo11} style={{ width: "200px" }} />
                  </div>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    className="variantH5"
                  >
                    Welcome Back to FreeLife Housing!
                  </Typography>
                  <Typography color="#8E8E8E" className="variant">
                    Log in to your account
                  </Typography>
                  <Box style={{ marginTop: "20px" }}>
                    {/* Form inputs */}
                    <div className="loginregwrap">
                      <label className="loginreglabel font-weight-bold">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        id="mobno"
                        name="mobno"
                        value={inputvalue.mobno}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress} // Listen for Enter key press
                        className="loginRegInput"
                      />
                      {inputvalueerror.mobno && (
                        <span className="error">{inputvalueerror.mobno}</span>
                      )}
                    </div>
                    <div className="loginregwrap">
                      <label className="loginreglabel font-weight-bold">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={inputvalue.password}
                        onChange={handleInputChange}
                        placeholder="Enter password"
                        className="loginRegInput"
                        onKeyPress={handleKeyPress} // Listen for Enter key press
                      />
                      {inputvalueerror.password && (
                        <span className="error">
                          {inputvalueerror.password}
                        </span>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      {/* <FormGroup>
                        <FormControlLabel
                          control={<Checkbox style={{ color: "#8E8E8E" }} />}
                          label="Remember me"
                          style={{ color: "#8E8E8E" }}
                        />
                      </FormGroup> */}
                      <Button
                        style={{ textTransform: "none", color: "#9e9e9e" }}
                        onClick={() => router("/forgotpassword")}
                        className="forgetbtn"
                      >
                        <small>Forgot Password?</small>
                      </Button>
                    </div>
                  </Box>
                  <Button
                    variant="contained"
                    className="freehousing_button addbutton"
                    style={{
                      textTransform: "none",
                      width: "100%",
                      margin: "15px 0",
                    }}
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                  <Box
                    style={{
                      display: windowWidth < 899 ? "flex" : "none",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="logindivborder"></div>
                    <span style={{ padding: "10px" }} className="orline">
                      OR
                    </span>
                    <div className="logindivborder"></div>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      // loginButtonPosition === "below" ? "block" : "none",
                      alignItems: "center",
                      justifyContent: "end",
                      textTransform: "none",
                      width: "100%",
                      margin: "10px 0",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="freehousing_button addbutton"
                      onClick={() => router("/registerpage")}
                      style={{
                        display: windowWidth > 899 ? "none" : "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "none",
                        width: "100%",
                        margin: "15px 0",
                      }}
                    >
                      Register
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Box>
      {/* <ToastContainer /> */}
    </Container>
  )
}

export default Login
