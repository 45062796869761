import React, { useEffect, useState } from "react"
import {
  TextField,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  Checkbox,
  Select,
} from "@mui/material"
import Modal from "react-bootstrap/Modal"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { Col, Input, Label, Row } from "reactstrap"
import CloseIcon from "@mui/icons-material/Close"
import Swal from "sweetalert2"
import { MenuItem } from "@mui/material"

const AddStaffDetail = ({
  open,
  handleClose,
  operation,
  staffData,
  fetchData,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    mobno: "",
    email: "",
    password: "",
    gender: "",
    status: "",
  })

  useEffect(() => {
    if (open) {
      setFormData({
        name: "",
        mobno: "",
        email: "",
        password: "",
        gender: "",
        changePassword: false,
        status: "",
      })
      setErrors({})
    }
  }, [open])

  useEffect(() => {
    if (staffData && staffData.Staff && operation === "Edit Staff") {
      setFormData({
        staff_id: staffData.Staff._id,
        name: staffData.Staff.name,
        mobno: staffData.Staff.mobno,
        email: staffData.Staff.email,
        password: "",
        gender: staffData.Staff.gender,
        changePassword: false,
        status: staffData.Staff.status,
      })
    } else {
      setFormData({
        user_id: "",
        name: "",
        mobno: "",
        email: "",
        password: "",
        gender: "",
        changePassword: false,
        status: "",
      })
    }
  }, [staffData, operation])
  const [errors, setErrors] = useState({})
  const token = JSON.parse(localStorage.getItem("token"))

  const handleChange = e => {
    const { name, value } = e.target
    if (name === "password" && operation === "Edit Staff") {
      setFormData({ ...formData, [name]: value })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  const handleTogglePasswordChange = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      changePassword: !prevFormData.changePassword,
      password: !prevFormData.changePassword ? "" : prevFormData.password,
    }))
  }

  const handleSubmit = () => {
    const validationErrors = validateForm(formData)
    console.log("validationErrors", validationErrors);
    
    if (Object.keys(validationErrors).length === 0) {
      const url = `${HOST_URL}staff/${operation === "Add Staff" ? "addStaff" : "editStaff"
        }`
      const data = formData
      ApiPostMethodWithToken(url, data, token)
        .then(data => {
          if (data.error === false) {
            fetchData()
            handleClose()
            Swal.fire({
              icon: "success",
              title:
                operation === "Add Staff"
                  ? "Staff Added Successfully"
                  : "Staff Edited Successfully",
              showConfirmButton: false,
              timer: 1500,
            })
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "An error occurred!",
            })
          }
        })
        .catch(error => console.log(`Error: ${error}`))
    } else {
      setErrors(validationErrors)
    }
  }

  const validateForm = data => {
    let errors = {}

    if (!data.name.trim()) {
      errors.name = "Name is required"
    }

    if (!data.mobno.trim()) {
      errors.mobno = "Mobile No. is required"
    } else if (!/^\d+$/.test(data.mobno)) {
      errors.mobno = "Mobile No. should contain only numbers"
    } else if (data.mobno.length !== 10) {
      errors.mobno = "Mobile No. must be exactly 10 digits long"
    }

    if (!data.email.trim()) {
      errors.email = "Email is required"
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid"
    }

    if ((operation === "Add Staff" || (operation === "Edit Staff" && formData.changePassword)) && !/^[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(data.password)) {
      errors.password = "Password must be between 8 and 20 characters long and can contain letters, numbers, and special characters (!@#$%^&*)";
    }

    if (!data.gender) {
      errors.gender = "Gender is required"
    }

    if (operation == "Edit Staff" && !data.status) {
      errors.status = "Status is required"
    }

    return errors
  }

  return (
    <Modal show={open} onHide={handleClose} centered>
      <div style={{ padding: 20, maxHeight: "450px", overflowX: "auto" }}>
        <div
          className="modal-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>{operation === "Add Staff" ? "Add Staff" : "Edit Staff"}</h5>
          <IconButton onClick={handleClose} style={{ marginRight: "-10px" }}>
            <CloseIcon />
          </IconButton>
        </div>

        <Row>
          <Col lg={6} md={6} xs={12}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={errors.name ? true : false}
              helperText={errors.name}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <TextField
              label="Mobile No."
              name="mobno"
              type="number"
              value={formData.mobno}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={errors.mobno ? true : false}
              helperText={errors.mobno}
              onWheel={e => e.target.blur()}
            />
          </Col>
        </Row>

        <Row>
          {operation === "Add Staff" && (
            <Col lg={6} md={6} xs={12}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={errors.email ? true : false}
                helperText={errors.email}
              />
            </Col>
          )}
          {operation === "Edit Staff" ? (
            <>
              <Col lg={12} md={12} xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.email ? true : false}
                  helperText={errors.email}
                />
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Label style={{ fontWeight: 800 }}>Status</Label>
                <Select
                  aria-label="status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Deactive">Deactive</MenuItem>
                </Select>
              </Col>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginLeft: "-8px"
                }}
                lg={6}
                md={6}
                xs={12}
                className="genders"
              >
                <Col lg={12} md={12} xs={2}>
                  <Typography>
                    <Label >Gender:</Label>
                  </Typography>
                </Col>
                <Col lg={12} md={12} xs={10}>
                  <RadioGroup className="radiogrp"
                    aria-labelledby="gender-label"
                    name="gender"
                    value={formData.gender}
                    onClick={handleChange}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                      whiteSpace: "nowrap",
                    }}
                    error={errors.gender ? true : false}
                  >
                    <FormControlLabel
                      className="maleGen"
                      value="Male"
                      control={<Radio />}
                      label="Male"
                      style={{ marginBottom: "0px" }}
                    />
                    <FormControlLabel
                      className="femaleGen"
                      value="Female"
                      control={<Radio />}
                      label="Female"
                      style={{ marginBottom: "0px" }}
                    />
                  </RadioGroup>
                </Col>
              </Col>
            </>
          ) : (
            <Col lg={6} md={6} xs={12}>
              <TextField
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={errors.password ? true : false}
                helperText={errors.password}
                type={"password"}
              />
            </Col>
          )}

        </Row>

        {operation === "Add Staff" ? (
          <Row>
            <Col
              style={{ display: "flex", alignItems: "center" }}
              lg={6}
              md={6}
              xs={12}
              className="genders"
            >
              <Typography>
                <Label>Gender:</Label>
              </Typography>
              <RadioGroup
                aria-labelledby="gender-label"
                name="gender"
                value={formData.gender}
                onClick={handleChange}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                  whiteSpace: "nowrap",
                }}
                error={errors.gender ? true : false}
              >
                <FormControlLabel
                  className="maleGen"
                  value="Male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  className="femaleGen"
                  value="Female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <>
          <Row style={{ display: "flex", alignItems: "center" }}>
            {operation === "Edit Staff" && (
              <>
                <Col lg={6} md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleTogglePasswordChange}
                        color="primary"
                      />
                    }
                    label="Change Password"
                  />
                </Col>
              </>
            )}
            {operation === "Edit Staff" && formData.changePassword && (
              <Col lg={6} md={6} xs={12}>
                <TextField
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  type={"password"}
                />
              </Col>
            )}
          </Row>
        </>
        {errors.gender && (
          <div style={{ color: "red", fontSize: "12px" }}>{errors.gender}</div>
        )}

        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            variant="contained"
            className="commongradient addbutton submitButton"
            onClick={handleSubmit}
            style={{ padding: "10px 20px", marginLeft: 10 }}
          >
            <span>Submit</span>
          </Button>
          <Button
            variant="contained"
            className="commongradient addbutton submitButton"
            onClick={handleClose}
            style={{ padding: "10px 20px", marginLeft: 10 }}
          >
            <span>Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddStaffDetail
