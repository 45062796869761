import React, { useState } from "react"
import {
  TextField,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
  FormHelperText,
  IconButton,
} from "@mui/material"
import Modal from "react-bootstrap/Modal"
import CloseIcon from "@mui/icons-material/Close"
import { useEffect } from "react"
import Swal from "sweetalert2"
import { Col, Row } from "react-bootstrap"
import { HOST_URL } from "helpers/url_helper"
import { OutlinedInput } from "@mui/material"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddBlog2 = ({ open, handleClose, operation, blogData, fetchData }) => {
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [imageUploaded, setImageUploaded] = useState(false)
  const [imageDeleted, setImageDeleted] = useState(false)

  const [formData, setFormData] = useState({
    blogImg: {},
    category: "",
    description: "",
    title: "",
    youtubeLink: "",
    type: "",
  })

  useEffect(() => {
    if (!open) {
      setFormData({
        blogImg: {},
        category: "",
        description: "",
        title: "",
        youtubeLink: "",
        type: "",
      })
      setErrors({})
    } else {
      if (blogData && blogData.blogList && operation === "Edit Blog") {
        setFormData({
          blogId: blogData.blogList._id,
          blogImg: blogData.blogList.blogImg,
          category: blogData.blogList.category,
          description: blogData.blogList.description,
          title: blogData.blogList.title,
          type: blogData.blogList.type,
          youtubeLink: blogData.blogList.youtubeLink,
        })
      } else {
        setFormData({
          blogId: "",
          blogImg: {},
          category: "",
          description: "",
          title: "",
          youtubeLink: "",
          type: "",
        })
      }
    }
  }, [open, blogData, operation])

  const [errors, setErrors] = useState({})
  const token = JSON.parse(localStorage.getItem("token"))

  const handleChange = e => {
    const { name, value, type } = e.target

    if (name === "type") {
      const selectedType = value === "Image" ? "Image" : "YouTube"
      setFormData(prevState => ({
        ...prevState,
        type: selectedType,
        blogImg: selectedType === "YouTube" ? {} : prevState.blogImg,
      }))
    } else if (type === "file") {
      const file = e.target.files[0]
      setFormData(prevState => ({
        ...prevState,
        [name]: file,
      }))
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleQuillChange = (value) => {
    handleChange({
      target: {
        name: 'description',
        value,
      },
    });
  };


  const validateForm = data => {
    let errors = {}
    if (!data.title.trim()) {
      errors.title = "Title is required"
    }
    if (data.type === "Image") {
      if (
        !data.blogImg ||
        (typeof data.blogImg === "object" && !data.blogImg.name)
      ) {
        errors.blogImg = "Blog Image is required"
      }
    }

    if (!data.category) {
      errors.category = "Category is required"
    }

    if (!data.description.trim()) {
      errors.description = "Description is required"
    }

    if (!data.type) {
      errors.type = "Type is required"
    }
    return errors
  }

  const handleDelete = () => {
    setFormData(prevState => ({
        ...prevState,
        blogImg:null
      }))
    setShowDeleteButton(false)
  }

  const handleEditDelete = async () => {
    if (formData.blogImg instanceof File) {
      setFormData(prevState => ({
        ...prevState,
        blogImg:null
      }))
      setImageDeleted(true)
    } else {
      console.log("formData.blogImg",formData.blogImg);
      const filename = formData.blogImg.split("/").pop()
      setFormData(prevState => ({
        ...prevState,
        blogImg:null
      }))
      setImageDeleted(true)
      await handleImageDelete(filename, "blogImage")
    }
  }

  const handleImageDelete = async (filename, imageType) => {
    try {
      const response = await fetch(`${HOST_URL}api/delete/${filename}`, {
        method: "DELETE",
        body: JSON.stringify({ imageType }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (!response.ok) {
        throw new Error(`Deletion failed with status ${response.status}`)
      }
    } catch (error) {
      console.error("Error deleting image", error)
    }
  }

  const handleEditImageChange = event => {
    const { name, files } = event.target
    if (files && files[0]) {
      const reader = new FileReader()
      reader.onload = e => {
        setFormData({ ...formData, [name]: e.target.result })
      }
      reader.readAsDataURL(files[0])
    }
  }

    const handleSubmit = () => {
    const validationErrors = validateForm(formData)
    if (Object.keys(validationErrors).length === 0) {

      const formDataToSend = new FormData()
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key])
      })

      let url = ""
      let method = ""
      if (operation === "Add Blog") {
        url = `${HOST_URL}blog/addBlog`
        method = "POST"
      } else if (operation === "Edit Blog" && formData.blogId) {
        url = `${HOST_URL}blog/editBlog`
        method = "POST"
      } else {
        console.error("Invalid operation or missing blog ID for editing.")
        return
      }

      const options = {
        method: method,
        headers: {
          Authorization: token,
        },
        body: formDataToSend,
      }

      fetch(url, options)
        .then(response => response.json())
        .then(data => {
          if (!data.error) {
            setFormData(prevState => ({
              ...prevState,
              blogImg: data.blog.blogImg,
            }))
            fetchData()
            handleClose()
            Swal.fire({
              icon: "success",
              title:
                operation === "Add Blog"
                  ? "Blog Added Successfully"
                  : "Blog Edited Successfully",
              showConfirmButton: false,
              timer: 1500,
            })
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data.message || "An error occurred!",
            })
          }
        })
        .catch(error => {
          console.error("Error:", error)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "An error occurred!",
          })
        })
    } else {
      setErrors(validationErrors)
    }
  }

  return (
    <Modal show={open} onHide={handleClose} centered>
      <div className="modalBlog2" style={{ padding: "0 20px 20px 20px" }}>
        <div
          className="modal-header"
          style={{
            marginBottom: 10,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>{operation === "Add Blog" ? "Add Blog" : "Edit Blog"}</h5>
          <IconButton onClick={handleClose} style={{ marginRight: "-10px" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <Row
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col lg={4} md={4} sm={4} xs={12}>
            <h5>Category :</h5>
          </Col>
          <Col lg={8} md={8} sm={8} xs={12}>
            <RadioGroup
              row
              aria-label="Category"
              name="category"
              value={formData.category}
              onChange={handleChange}
            >
              <FormControlLabel value="news" control={<Radio />} label="News" />
              <FormControlLabel
                value="article"
                control={<Radio />}
                label="Article"
              />
            </RadioGroup>
            {errors.category && (
              <FormHelperText style={{ color: "rgb(224 52 52)" }}>
                {errors.category}
              </FormHelperText>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              error={!!errors.title}
              helperText={errors.title}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} xs={12}>
            {/* <TextField
              fullWidth
              margin="normal"
              label="Description"
              name="description"
              multiline
              rows={4}
              value={formData.description}
              onChange={handleChange}
              error={!!errors.description}
              helperText={errors.description}
            /> */}
            <label for="description" className="form-control-label mt-2" ><h5>Description :</h5></label>
            <ReactQuill
              id="description"
              theme="snow"
              value={formData.description}
              onChange={handleQuillChange}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ['bold', 'italic', 'underline'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link', 'image'],
                  ['clean'],
                ],
              }}
              formats={[
                'header',
                'bold',
                'italic',
                'underline',
                'list',
                'bullet',
                'link',
                'image',
              ]}
            />
            {errors.description && (
              <div style={{ color: 'red', fontSize: '0.8rem', marginTop: '5px' }}>
                {errors.description}
              </div>
            )}
          </Col>
        </Row>
        {operation === "Add Blog" ? (
          <>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col lg={3} md={3} sm={4} xs={12}>
                <h5 className="">Type :</h5>
              </Col>
              <Col lg={9} md={9} sm={8} xs={12}>
                <RadioGroup
                  row
                  aria-label="Type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  error={!!errors.type}
                  helperText={errors.type}
                >
                  <FormControlLabel value="Image" control={<Radio />} label="Image" />
                  <FormControlLabel value="YouTube" control={<Radio />} label="YouTube" />
                </RadioGroup>
              </Col>
              {errors.type && (
                <FormHelperText style={{ color: "rgb(224 52 52)" }}>
                  {errors.type}
                </FormHelperText>
              )}
            </Row>
            {formData.type === "Image" && (
              <>
                <label htmlFor="fileInput" className="fileInputLabel">
                  Upload Image
                </label>
                <OutlinedInput
                  id="fileInput"
                  name="blogImg"
                  type="file"
                  onChange={handleChange}
                  fullWidth
                  className="uploadImageField"
                />
                {errors.blogImg && (
                  <FormHelperText style={{ color: "rgb(224 52 52)" }}>
                    {errors.blogImg}
                  </FormHelperText>
                )}
                {showDeleteButton && formData.blogImg && (
                  <>
                    <img
                      src={formData.blogImg}
                      alt=""
                      style={{ maxWidth: "50px", maxHeight: "50px" }}
                    />
                    <button className="border-0" onClick={handleDelete}>
                      <i className="mdi mdi-delete-outline"></i>
                    </button>
                  </>
                )}
              </>
            )}
            {formData.type === "YouTube" && (
              <>
                <TextField
                  label="YouTube Link"
                  name="youtubeLink"
                  value={formData.youtubeLink}
                  onChange={handleChange}
                  fullWidth
                />
              </>
            )}
          </>
        ) : (
          <>
            <Row>
              <Col lg={3} md={3} sm={4} xs={12}>
                <h5 className="">Type :</h5>
              </Col>
              <Col lg={9} md={9} sm={8} xs={12}>
                <RadioGroup
                  row
                  aria-label="Type"
                  name="type"
                  value={formData.type}
                  onChange={e => {
                    setFormData({ ...formData, type: e.target.value })
                    setShowDeleteButton(false)
                    setImageUploaded(false)
                  }}
                >
                  <FormControlLabel value="Image" control={<Radio />} label="Image" />
                  <FormControlLabel value="YouTube" control={<Radio />} label="YouTube" />
                  <FormControlLabel value="" control={<Radio />} style={{ display: "none" }} />
                </RadioGroup>
              </Col>
            </Row>
            {formData.type === "Image" ? (
              <>
                {formData.blogImg && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        position: "relative",
                      }}
                    >
                      <img
                        src={
                          formData.blogImg instanceof File
                            ? URL.createObjectURL(formData.blogImg)
                            : formData.blogImg
                        }
                        alt=""
                        style={{
                          width: "160px",
                          height: "100px",
                          borderRadius: "4px",
                        }}
                      />

                      <button
                        className="border-0"
                        onClick={handleEditDelete}
                        style={{
                          borderRadius: "50%",
                          position: "absolute",
                          left: "22.5%",
                        }}
                      >
                        <i
                          className="mdi mdi-delete-outline"
                          style={{ color: "red", fontSize: "17px" }}
                        ></i>
                      </button>
                    </div>
                  </>
                )}

                {!formData.blogImg && (
                  <>
                    <label htmlFor="fileInput" className="fileInputLabel">
                      Upload Image
                    </label>
                    <OutlinedInput
                      id="fileInput"
                      name="blogImg"
                      type="file"
                      onChange={handleChange}
                      fullWidth
                      className="uploadImageField"
                    />
                    {errors.blogImg && (
                      <FormHelperText style={{ color: "rgb(224 52 52)" }}>
                        {errors.blogImg}
                      </FormHelperText>
                    )}
                  </>
                )}
              </>
            ) : (
              <TextField
                label="YouTube Link"
                name="youtubeLink"
                value={formData.youtubeLink}
                onChange={handleChange}
                fullWidth
                error={!!errors.youtubeLink}
                helperText={errors.youtubeLink}
              />
            )}
          </>
        )}

        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            variant="contained"
            className="commongradient addbutton"
            onClick={handleSubmit}
            style={{ padding: "10px 20px", marginLeft: 10, color: "white" }}
          >
            <span>Submit</span>
          </Button>
          <Button
            variant="contained"
            className="commongradient addbutton"
            onClick={handleClose}
            style={{ padding: "10px 20px", marginLeft: 10, color: "white" }}
          >
            <span>Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddBlog2
