import React, { useState, useEffect } from "react"
import { Button, ButtonGroup, Col, Form, Input, Row } from "reactstrap"
import Select from "react-select"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { ApiGetMethod, ApiPostMethod } from "helpers/withoutToken_helper"
import { ToastContainer, toast } from "react-toastify"

const CommPropDetailsEdit = React.forwardRef(
  ({ propertytype, lookingfor, propData, propertyId }, ref) => {
    // console.log("commmercial property dataa edit--", propData)
    const propertyType2Value = localStorage.getItem("PropertyType2")
    // console.log("commercial property type 2 value edit--", propertyType2Value)
    const PossesionStatus = [
      { id: 1, value: "Ready to move" },
      { id: 2, value: "Under Construction" },
    ]

    const total_Floor = [
      {
        options: [
          { label: "Ground Floor", value: "Ground Floor" },
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" },
          { label: "5", value: "5" },
          { label: "6", value: "6" },
          { label: "7", value: "7" },
          { label: "8", value: "8" },
          { label: "9", value: "9" },
          { label: "10", value: "10" },
          { label: "11", value: "11" },
          { label: "12", value: "12" },
          { label: "13", value: "13" },
          { label: "14", value: "14" },
          { label: "15", value: "15" },
          { label: "16", value: "16" },
          { label: "17", value: "17" },
          { label: "18", value: "18" },
          { label: "19", value: "19" },
          { label: "20", value: "20" },
        ],
      },
    ]

    const ZoneArray = [
      { id: 1, value: "Industrial" },
      { id: 2, value: "Commercial" },
      { id: 3, value: "Residential" },
      { id: 5, value: "Open Spaces" },
      { id: 6, value: "Agricultural zone" },
      { id: 4, value: "Special economic zone" },
      { id: 7, value: "Others" },
    ]
    
    const facingarray = [
      { id: 1, value: "North" },
      { id: 2, value: "East" },
      { id: 3, value: "West" },
      { id: 4, value: "South" },
      { id: 5, value: "North-East" },
      { id: 6, value: "North-West" },
      { id: 7, value: "South-East" },
      { id: 8, value: "South-West" },
    ]
    const openSidesarray = [
      { id: 1, value: "1" },
      { id: 2, value: "2" },
      { id: 3, value: "3" },
      { id: 4, value: "4" },
      { id: 5, value: "4+" },
    ]

    const [otherLocation, setotherLocation] = useState(false)

    const [locationHubArray, setLocationHubArray] = useState([])
    useEffect(() => {
      if (propertyType2Value === "Office") {
        setLocationHubArray([
          { id: 1, value: "IT Park" },
          { id: 2, value: "Business Park" },
          { id: 3, value: "Other" },
        ])
      } else {
        setLocationHubArray([
          { id: 1, value: "Mall" },
          { id: 2, value: "Commercial Project" },
          { id: 3, value: "Residential Project" },
          { id: 4, value: "Retail Complex/Building" },
          { id: 5, value: "Market/High Street" },
          { id: 6, value: "Other" },
        ])
      }
    }, [propertyType2Value])

    const suitableForArray = [
      { id: 1, value: "Jewellery" },
      { id: 2, value: "Gym" },
      { id: 3, value: "Grocery" },
      { id: 4, value: "Clinic" },
      { id: 5, value: "Footwear" },
      { id: 6, value: "Electronics" },
      { id: 7, value: "Clothing" },
      { id: 8, value: "Others" },
    ]

    const [showCarpet, setShowCarpet] = useState(false)
    const [bareShellSelected, setBareShellSelected] = useState(false)

    const propCondArray = [
      { id: 1, value: "Ready to use" },
      { id: 2, value: "Bare shell" },
    ]

    const OwnerShipArray = [
      { id: 1, value: "Freehold" },
      { id: 2, value: "Leasehold" },
      { id: 3, value: "Cooperative society" },
      { id: 4, value: "Power of attorney" },
    ]

    const constStatusArray = [
      { id: 1, value: "No walls" },
      { id: 2, value: "Brick walls" },
      { id: 3, value: "Cemented walls" },
      { id: 4, value: "Plastered walls" },
    ]

    const flooringArray = [
      { id: 1, value: "Marble" },
      { id: 2, value: "Concrete" },
      { id: 3, value: "Granite" },
      { id: 4, value: "Ceramic" },
      { id: 5, value: "Cement" },
      { id: 6, value: "Other" },
    ]

    const YesNoArray = [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ]

    const optionGroup = [
      {
        label: "Select Floor",
        options: [
          { label: "-2", value: "-2" },
          { label: "-1", value: "-1" },
          { label: "Ground Floor", value: "Ground Floor" },
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" },
          { label: "5", value: "5" },
        ],
      },
    ]

    const locatedNearArray = [
      { id: 1, value: "Entrance" },
      { id: 2, value: "Elevator" },
      { id: 3, value: "Stairs" },
      { id: 4, value: "Other" },
    ]

    const formatDate = dateString => {
      if (!dateString) return ""
      const date = new Date(dateString)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const day = String(date.getDate()).padStart(2, "0")
      return `${year}-${month}-${day}`
    }

    function generateFloorOptions(optionGroup, totalFloors) {
      const options = []

      // Add fixed options (-2, -1, Ground) regardless of totalFloors
      options.push({ value: "-2", label: "-2" })
      options.push({ value: "-1", label: "-1" })
      options.push({ value: "Ground Floor", label: "Ground Floor" })

      // Add dynamic options based on totalFloors
      for (let i = 1; i <= totalFloors; i++) {
        options.push({ value: `${i}`, label: `${i}` })
      }

      return options
    }
    const user_id = JSON.parse(localStorage.getItem("authUser"))

    const [showAgeOfProperty, setShowAgeOfProperty] = useState(false)
    const [showConstruction, setShowConstruction] = useState(false)
    const [commPropDetails, setcommPropDetails] = useState({
      locality: propData?.commPropDetails?.locality,
      otherLocality: propData?.commPropDetails?.otherLocality,
      businessParkName: propData?.commPropDetails?.businessParkName,
      projectName: propData?.commPropDetails?.projectName,
      mallMarketName: propData?.commPropDetails?.mallMarketName,
      possesionStatus: propData?.commPropDetails?.possesionStatus,
      availableFrom: formatDate(propData?.commPropDetails?.availableFrom),
      ageOfProperty: propData?.commPropDetails?.ageOfProperty,
      zoneType: propData?.commPropDetails?.zoneType,
      suitableFor: propData?.commPropDetails?.suitableFor || [],
      locationHub: propData?.commPropDetails?.locationHub,
      otherLocationHub: propData?.commPropDetails?.otherLocationHub,
      propertyCondition: propData?.commPropDetails?.propertyCondition,
      builtUpArea: propData?.commPropDetails?.builtUpArea,
      CarpetArea: propData?.commPropDetails?.CarpetArea,
      entranceWidth: propData?.commPropDetails?.entranceWidth,
      ceilingHeight: propData?.commPropDetails?.ceilingHeight,
      locatedNear: propData?.commPropDetails?.locatedNear,
      otherLocatedNear: propData?.commPropDetails?.otherLocatedNear,
      ownership: propData?.commPropDetails?.ownership,
      constructionStatus: propData?.commPropDetails?.constructionStatus,
      flooring: propData?.commPropDetails?.flooring,
      monthlyRent: propData?.commPropDetails?.monthlyRent,
      numberOfStaircase: propData?.commPropDetails?.numberOfStaircase,
      yourFloor: propData?.commPropDetails?.yourFloor,
      passengersLift: propData?.commPropDetails?.passengersLift,
      serviceLift: propData?.commPropDetails?.serviceLift,
      privateParking: propData?.commPropDetails?.privateParking,
      publicParking: propData?.commPropDetails?.publicParking,
      minimunNumberOfSeats: propData?.commPropDetails?.minimunNumberOfSeats,
      maximumNumberOfSeats: propData?.commPropDetails?.maximumNumberOfSeats,
      numberOfCabins: propData?.commPropDetails?.numberOfCabins,
      NumberOfMeetingRooms: propData?.commPropDetails?.NumberOfMeetingRooms,
      privateWashroom: propData?.commPropDetails?.privateWashroom,
      publicWashroom: propData?.commPropDetails?.publicWashroom,
      conferenceRoom: propData?.commPropDetails?.conferenceRoom,
      waterCharge: propData?.commPropDetails?.waterCharge,
      electricityCharge: propData?.commPropDetails?.electricityCharge,
      taxGovtCharge: propData?.commPropDetails?.taxGovtCharge,
      upscChargeIncluded: propData?.commPropDetails?.upscChargeIncluded,
      negotiable: propData?.commPropDetails?.negotiable,
      plotArea: propData?.commPropDetails?.plotArea,
      possessionDate: propData?.commPropDetails?.possessionDate,
      price: propData?.commPropDetails?.price,
      preRented: propData?.commPropDetails?.preRented,
      currentRentPerMonth: propData?.commPropDetails?.currentRentPerMonth,
      leaseYears: propData?.commPropDetails?.leaseYears,
      expectedReturnOnInvestment:
        propData?.commPropDetails?.expectedReturnOnInvestment,
      lockInPeriod: propData?.commPropDetails?.lockInPeriod,
      expectedRentIncrease: propData?.commPropDetails?.expectedRentIncrease,
      securityDeposit: propData?.commPropDetails?.securityDeposit,
      totalFloors: propData?.commPropDetails?.totalFloors,
    })
    // console.log("commercial prop details edit", commPropDetails)

    const [commPropDetailsErr, setcommPropDetailsErr] = useState({
      locality: "",
      otherLocality: "",
      ownership: "",
      monthlyRent: "",
      possesionStatus: "",
      locationHub: "",
      yourFloor: "",
      availableFrom: "",
      ageOfProperty: "",
      builtUpArea: "",
      CarpetArea: "",
      suitableFor: "",
      entranceWidth: "",
      ceilingHeight: "",
      zoneType: "",
      locatedNear: "",
      otherLocatedNear: "",
      passengersLift: "",
      serviceLift: "",
      otherLocationHub: "",
      constructionStatus: "",
      projectName: "",
      possessionDate: "",
      maximumNumberOfSeats: "",
      publicWashroom: "",
      price: "",
      preRented: "",
      currentRentPerMonth: "",
      leaseYears: "",
      expectedReturnOnInvestment: "",
      minimunNumberOfSeats: "",
      numberOfCabins: "",
      NumberOfMeetingRooms: "",
      privateWashroom: "",
      conferenceRoom: "",
    })
    
    const [advanceDetails, setAdvanceDetails] = useState({
      cornerProperty: propData?.advanceDetails?.cornerProperty || "",
      constructionStarted: propData?.advanceDetails?.constructionStarted || "",
      facing: propData?.advanceDetails?.facing || "",
      address: propData?.advanceDetails?.address || "",
      reraId: propData?.advanceDetails?.reraId || "",
      boundaryWallPresent: propData?.advanceDetails?.boundaryWallPresent || "",
      noOfOpenSides: propData?.advanceDetails?.noOfOpenSides || "",
      floorsAllowedForConstruction:
        propData?.advanceDetails?.floorsAllowedForConstruction || "",
    })

    const [localities, setLocalities] = useState([])

    const fetchData = async () => {
      try {
        const cityid = localStorage.getItem('cityId')
        const postUrl = `${HOST_URL}api/viewLocalities`

        const response = await ApiGetMethod(postUrl)
        const localities = [...response[0].localities]
        // const locality = response.find(data=> data._id == cityid)
        // const localities1 = locality.localities.includes("Other") ? locality.localities : ["Other", ...locality.localities];
        // setLocalities(localities1)
        if(cityid){
          const locality = response.find(data=> data._id == cityid)
          const localities1 = locality.localities.includes("Other") ? locality.localities : ["Other", ...locality.localities];
          setLocalities(localities1)
        }else{
          const locality = response.find(data=> data.city == propData?.propertyDetails?.searchCity)
          const localities1 = locality.localities.includes("Other") ? locality.localities : ["Other", ...locality.localities];
          setLocalities(localities1)
        }
      } catch (error) {
        console.error("Error:", error)
      }
    }
    useEffect(() => {
      fetchData()
    }, [propData])

    function handleLocalityGroup(selectedGroup) {
      setcommPropDetails(prevDetails => ({
        ...prevDetails,
        locality: selectedGroup.value,
      }))
      setcommPropDetailsErr(prevErrors => ({
        ...prevErrors,
        locality: "",
      }))
    }

    useEffect(() => {
      setcommPropDetails(prevDetails => ({
        ...prevDetails,
        propertyType1: propertytype,
        lookingTo: lookingfor,
      }))
    }, [propertytype, lookingfor])

    // console.log("commercial commPropDetails", commPropDetails)

    const handleChange = (name, value) => {
      setcommPropDetails(prevDetails => ({
        ...prevDetails,
        [name]: value,
      }))

      if (name === "possesionStatus") {
        setShowAgeOfProperty(value === "Ready to move")
        setShowConstruction(value === "Under Construction" || value == "Ready to move")
      }
      if (name === "locationHub") {
        setotherLocation(value === "Other")
      }
      if (name === "propertyCondition") {
        setShowCarpet(value === "Ready to use")
        setBareShellSelected(value === "Bare shell")
      }
      if (
        name === "CarpetArea" &&
        parseInt(value) >= parseInt(commPropDetails.builtUpArea)
      ) {
        setcommPropDetailsErr(prevErrors => ({
          ...prevErrors,
          CarpetArea: "Carpet area must be less than built-up area",
        }))
      } else {
        setcommPropDetailsErr(prevErrors => ({
          ...prevErrors,
          CarpetArea: "",
        }))
      }
    }

    const handleLocality = async () => {
      if (commPropDetails.otherLocality == "") {
        setcommPropDetailsErr({
          otherLocality: "Locality is required",
        })
      } else {
        const url = `${HOST_URL}api/addLocality`
        const payload = {
          city: propData?.propertyDetails?.searchCity,
          locality: commPropDetails.otherLocality,
        }
        await ApiPostMethod(url, payload).then(response => {
          if (response.error === false) {
            fetchData()
            setcommPropDetails(prevDetails => ({
              ...prevDetails,
              otherLocality: "",
              locality: response.locality,
            }))
            toast.success(response.message, {
              position: "top-right",
              theme: "light",
            })
          } else {
            toast.error(response.message, {
              position: "top-right",
              theme: "light",
            })
          }
        })
      }
    }

    const handle_Change = (name, value) => {
      setcommPropDetails(prevState => {
        const updatedSuitableFor = { ...prevState }
        if (updatedSuitableFor[name]) {
          if (updatedSuitableFor[name].includes(value)) {
            updatedSuitableFor[name] = updatedSuitableFor[name].filter(
              item => item !== value
            )
          } else {
            updatedSuitableFor[name] = [
              ...updatedSuitableFor[name],
              value,
            ]
          }
        } else {
          updatedSuitableFor[name] = [value]
        }
        return updatedSuitableFor
      })
    }

    const handleAdvanceData = (name, value) => {
      setAdvanceDetails(prevDetails => ({
        ...prevDetails,
        [name]: value,
      }))
    }

    const handleSubmit = async () => {
      const newErrors = {}
      // if (
      //   lookingfor == "Sell" &&
      //   propertyType2Value !== "Office" &&
      //   propertyType2Value !== "Plot" &&
      //   propertyType2Value !== "Other" &&
      //   propertyType2Value !== "Warehouse" &&
      //   commPropDetails.locationHub !== "Other"
      // ) {
      //   if (commPropDetails.price.trim() === "") {
      //     newErrors.price = "Price is required"
      //   }
      // }
      if (
        lookingfor == "Sell"
        // &&
        // (propertyType2Value == "Office" ||
        //   propertyType2Value == "Warehouse" ||
        //   propertyType2Value == "Plot")
      ) {
        if (commPropDetails.price == null || commPropDetails.price == "") {
          newErrors.price = "Price is required"
        }
      }
      if (
        lookingfor == "Sell" &&
        (propertyType2Value == "Retail Shop" ||
          propertyType2Value == "Showroom" ||
          propertyType2Value == "Other") &&
        commPropDetails.locationHub !== "Other"
      ) {
        if (commPropDetails.price == null || commPropDetails.price == "") {
          newErrors.price = "Price is required"
        }
      }

      if (lookingfor == "Sell" && propertyType2Value !== "Plot") {
        if (commPropDetails.possesionStatus === "Ready to move") {
          if (
            commPropDetails.ageOfProperty == null ||
            commPropDetails.ageOfProperty == ""
          ) {
            newErrors.ageOfProperty = "Age of property is required"
          }
          if (
              commPropDetails.possessionDate == null ||
              commPropDetails.possessionDate == ""
            ) {
              newErrors.possessionDate = "Possesion Date is required"
            }
        } else if (commPropDetails.possesionStatus == "Under Construction") {
            if (
              commPropDetails.possessionDate == null ||
              commPropDetails.possessionDate == ""
            ) {
              newErrors.possessionDate = "Possesion Date is required"
            }
        }
      }

      if (commPropDetails.propertyCondition == "Ready to use") {
        if (commPropDetails.maximumNumberOfSeats === "") {
          newErrors.maximumNumberOfSeats = "Maximum seats are required"
        }
        if (commPropDetails.publicWashroom === "") {
          newErrors.publicWashroom = "Public Washroom is required"
        }
        if (commPropDetails.minimunNumberOfSeats === "") {
          newErrors.minimunNumberOfSeats = "Minimun seats are required"
        }
        if (commPropDetails.numberOfCabins === "") {
          newErrors.numberOfCabins = "Cabins are required"
        }
        if (commPropDetails.NumberOfMeetingRooms === "") {
          newErrors.NumberOfMeetingRooms = "Meeting rooms required"
        }
        if (commPropDetails.privateWashroom === "") {
          newErrors.privateWashroom = "Private washroom is required"
        }
        if (commPropDetails.conferenceRoom === "") {
          newErrors.conferenceRoom = "Conference room is required"
        }
      }

      if (lookingfor == "Sell" && propertyType2Value !== "Plot") {
      }
      if (commPropDetails.locality.trim() === "") {
        newErrors.locality = "locality is required"
      }
      if (commPropDetails.ownership.trim() === "") {
        newErrors.ownership = "ownership is required"
      }

      if (propertyType2Value !== "Plot") {
        if (commPropDetails.possesionStatus.trim() === "") {
          newErrors.possesionStatus = "Possession status is required"
        }

        // Check if locationHub is selected when propertyType2Value is not "Plot"
        if (commPropDetails.locationHub.trim() === "") {
          newErrors.locationHub = "Location hub is required"
        }

        if (commPropDetails.locationHub === "Other") {
          if (commPropDetails.otherLocationHub.trim() === "") {
            newErrors.otherLocationHub = "Other Location Hub is required"
          }
        }

        if (
          commPropDetails.totalFloors == null ||
          commPropDetails.totalFloors == ""
        ) {
          newErrors.totalFloors = "Total Floor is required"
        }
        // If possesionStatus is selected, check for additional requirements
        if (
          lookingfor === "Rent" &&
          commPropDetails.possesionStatus === "Under Construction"
        ) {
          if (commPropDetails.availableFrom === "") {
            newErrors.availableFrom = "Available from date is required"
          }
        } else if (
          lookingfor === "Rent" &&
          commPropDetails.possesionStatus === "Ready to move"
        ) {
          if (
            commPropDetails.ageOfProperty == null ||
            commPropDetails.ageOfProperty == ""
          ) {
            newErrors.ageOfProperty = "Age of property is required"
          }
          if (
            commPropDetails.availableFrom == null ||
            commPropDetails.availableFrom == ""
          ) {
            newErrors.availableFrom = "Available from date is required"
          }
        }
      }

      if (propertyType2Value !== "Plot" && propertyType2Value !== "Office") {
        if (
          commPropDetails.builtUpArea == null ||
          commPropDetails.builtUpArea == ""
        ) {
          newErrors.builtUpArea = "Built up area is required"
        }
        if (
          commPropDetails.CarpetArea == null ||
          commPropDetails.CarpetArea == ""
        ) {
          newErrors.CarpetArea = "Carpet Area is required"
        } else if (
          parseInt(commPropDetails.CarpetArea) >=
          parseInt(commPropDetails.builtUpArea)
        ) {
          newErrors.CarpetArea = "Carpet area must be less than built-up area"
        }
      }

      if (
        lookingfor == "Rent" &&
        propertyType2Value !== "Plot"
        // propertyType2Value !== "Office"
      ) {
        if (commPropDetails.monthlyRent === "") {
          newErrors.monthlyRent = "Expected Rent is required"
        }
      }

      if (
        lookingfor == "Rent" &&
        propertyType2Value == "Plot"
      ) {
        if (commPropDetails.plotArea === "") {
          newErrors.plotArea = "Plot Area required"
        }
        if (commPropDetails.availableFrom === "") {
          newErrors.availableFrom = "Available From required"
        }
      }
      if (
        lookingfor == "Sell" &&
        propertyType2Value == "Plot"
        // propertyType2Value !== "Office" &&
        // propertyType2Value !== "Other" &&
        // propertyType2Value !== "Warehouse" &&
        // propertyType2Value !== "Showroom"
      ) {
        if (
          commPropDetails.plotArea == null ||
          commPropDetails.plotArea == ""
        ) {
          newErrors.plotArea = "Plot Area required"
        }
      }

      if (
        propertyType2Value !== "Retail Shop" &&
        propertyType2Value !== "Showroom"
      ) {
        if (commPropDetails.zoneType.trim() === "") {
          newErrors.zoneType = "Zone Type is required"
        }
      }

      if (
        propertyType2Value !== "Office" &&
        propertyType2Value !== "Plot" &&
        propertyType2Value !== "Other" &&
        propertyType2Value !== "Warehouse"
      ) {
        if (commPropDetails.suitableFor.length <= 0) {
          newErrors.suitableFor = "Suitable For is required"
        }
        if (
          commPropDetails.CarpetArea == null ||
          commPropDetails.CarpetArea == ""
        ) {
          newErrors.CarpetArea = "Carpet Area is required"
        }
        if (
          commPropDetails.entranceWidth == null ||
          commPropDetails.entranceWidth == ""
        ) {
          newErrors.entranceWidth = "Entrance Width is required"
        }
        if (
          commPropDetails.ceilingHeight == null ||
          commPropDetails.ceilingHeight == ""
        ) {
          newErrors.ceilingHeight = "Ceiling Height is required"
        }
        if (commPropDetails.locatedNear == "") {
          newErrors.locatedNear = "Located Near is required"
        }
        if (commPropDetails.otherLocatedNear == "") {
          if (commPropDetails.locatedNear == "Other") {
            newErrors.otherLocatedNear = "Located Near is required"
          }
        }
      }

      if (
        propertyType2Value !== "Retail Shop" &&
        propertyType2Value !== "Plot" &&
        propertyType2Value !== "Office" &&
        propertyType2Value !== "Warehouse" &&
        propertyType2Value !== "Showroom"
      ) {
        if (commPropDetails.projectName.trim() === "") {
          newErrors.projectName = "Project Name is required"
        }
      }

      if (
        propertyType2Value !== "Retail Shop" &&
        propertyType2Value !== "Plot" &&
        propertyType2Value !== "Other" &&
        propertyType2Value !== "Warehouse" &&
        propertyType2Value !== "Showroom"
      ) {
        if (
          commPropDetails.passengersLift == null ||
          commPropDetails.passengersLift == ""
        ) {
          newErrors.passengersLift = "Passengers Liftis required"
        }
        if (
          commPropDetails.serviceLift == null ||
          commPropDetails.serviceLift == ""
        ) {
          newErrors.serviceLift = "Service Lift is required"
        }
        // if (commPropDetails.locatedNear.trim() === "") {
        //   newErrors.locatedNear = "Located Near is required"
        // }
        // if (commPropDetails.constructionStatus.trim() === "") {
        //   newErrors.constructionStatus = "Construction Status is required"
        // }
        if (commPropDetails.propertyCondition.trim() === "") {
          newErrors.propertyCondition = "Property Condition is required"
        }

        if (
          commPropDetails.passengersLift == null ||
          commPropDetails.passengersLift == ""
        ) {
          newErrors.passengersLift = "Passengers Lift is required"
        }
        if (
          commPropDetails.serviceLift == null ||
          commPropDetails.serviceLift == ""
        ) {
          newErrors.serviceLift = "Service Lift is required"
        }

        if (commPropDetails.propertyCondition === "Ready to use") {
          if (
            commPropDetails.builtUpArea == null ||
            commPropDetails.builtUpArea == ""
          ) {
            newErrors.builtUpArea = "Built up area is required"
          }
          if (
            commPropDetails.CarpetArea == null ||
            commPropDetails.CarpetArea == ""
          ) {
            newErrors.CarpetArea = "Carpet Area is required"
          } else if (
            parseInt(commPropDetails.CarpetArea) >=
              parseInt(commPropDetails.builtUpArea) &&
            !newErrors.CarpetArea // Add this condition to check if the error is not already present
          ) {
            newErrors.CarpetArea = "Carpet area must be less than built-up area"
          }
        } else if (commPropDetails.propertyCondition === "Bare shell") {
          if (
            commPropDetails.builtUpArea == null ||
            commPropDetails.builtUpArea == ""
          ) {
            newErrors.builtUpArea = "Built Up Area is required"
          }
          if (commPropDetails.constructionStatus === "") {
            newErrors.constructionStatus = "Construction Status is required"
          }
        }
      }

      if (lookingfor == "Sell") {
        if (commPropDetails.preRented.trim() === "") {
          newErrors.preRented = "Pre Rented is required"
        }
        if (commPropDetails.preRented === "Yes") {
          if (
            commPropDetails.currentRentPerMonth == null ||
            commPropDetails.currentRentPerMonth == ""
          ) {
            newErrors.currentRentPerMonth = "Cureent Rent is required"
          }
          if (
            commPropDetails.leaseYears === null ||
            commPropDetails.leaseYears === ""
          ) {
            newErrors.leaseYears = "Lease years is required"
          }
        } else if (commPropDetails.preRented === "No") {
          if (
            commPropDetails.expectedReturnOnInvestment == null ||
            commPropDetails.expectedReturnOnInvestment == ""
          ) {
            newErrors.expectedReturnOnInvestment = "Expected Return is required"
          }
        }
      }

      setcommPropDetailsErr(newErrors)
      if (Object.values(newErrors).every(error => error === "")) {
        // console.log("Form submitted:", commPropDetails)
        var status
        var response
        const token = JSON.parse(localStorage.getItem("token"))
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        // const user_id = JSON.parse(localStorage.getItem("authUser"))
        const url = `${HOST_URL}property/commPropDetails`
        const data = {
          property_id: propertyId || property_id,
          user_id: propData?.user_id,
          commPropDetails: commPropDetails,
          advanceDetails: advanceDetails,
        }
        // console.log("comm prop deatils api data", commPropDetails)
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            status = true
            response = resp
          } else {
            status = false
            response = resp
          }
        })
        return { status: status, message: response.message }
      } else {
        // console.log("error in api calling")
        return { status: false, message: formatValidationMessage(newErrors) }
      }
    }

    function formatValidationMessage(errors) {
      const keys = Object.keys(errors).map(key => 
          errors[key].replace(' is required', '')
      );
      const joinedKeys = keys.length > 1 
          ? keys.slice(0, -1).join(', ') + ' and ' + keys[keys.length - 1] 
          : keys[0];

      return `${joinedKeys} is required.`;
    }

    const formatAmount = amount => {
      if (amount < 1000) {
        return amount
      } else if (amount >= 1000 && amount < 100000) {
        return (amount / 1000).toFixed(1) + "k"
      } else {
        return (amount / 100000).toFixed(1) + "L"
      }
    }

    React.useImperativeHandle(ref, () => ({
      handleCommProp: () => handleSubmit(),
    }))

    return (
      <>
        <React.Fragment>
          <Form>
            <h5 className="">Add Property Details</h5>
            <Row>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="locality"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Locality
                  </label>
                  <div className="col-lg-12">
                    <Select
                      id="locality"
                      name="locality"
                      value={{
                        value: commPropDetails.locality,
                        label: commPropDetails.locality,
                      }}
                      // value={rrentAddressDetails.locality}
                      onChange={selectedValue => {
                        handleLocalityGroup(selectedValue)
                      }}
                      options={localities.map(locality => ({
                        value: locality,
                        label: locality,
                      }))}
                      classNamePrefix="select2-selection"
                    />
                    {commPropDetailsErr.locality && (
                      <span className="error">
                        {commPropDetailsErr.locality}
                      </span>
                    )}
                  </div>
                </Row>
              </Col>
              {commPropDetails.locality == "Other" ? (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="otherLocality"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Locality
                    </label>
                    <div className="col-lg-12 d-flex">
                      <Input
                        id="otherLocality"
                        name="otherLocality"
                        type="text"
                        className={`form-control`}
                        value={commPropDetails.otherLocality}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                      {user_id.role == "manager" ||
                        (user_id.role == "admin" && (
                          <Button
                            className="commongradient"
                            style={{ color: "white", marginLeft: "5px" }}
                            onClick={handleLocality}
                          >
                            Add
                          </Button>
                        ))}
                    </div>
                    {commPropDetailsErr.otherLocality && (
                      <span className="error">
                        {commPropDetailsErr.otherLocality}
                      </span>
                    )}
                  </Row>
                </Col>
              ) : (
                ""
              )}
              {propertyType2Value !== "Other" &&
                propertyType2Value !== "Warehouse" &&
                propertyType2Value !== "Plot" &&
                propertyType2Value !== "Retail Shop" &&
                propertyType2Value !== "Showroom" && (
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="buissParkName"
                        className="col-lg-12 col-form-label  font-weight-bold"
                      >
                        IT Park/Business Park NAME
                      </label>
                      <div className="col-lg-12">
                        <Input
                          id="businessParkName"
                          name="businessParkName"
                          type="text"
                          className={`form-control`}
                          value={commPropDetails.businessParkName}
                          onChange={e =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </Row>
                  </Col>
                )}
              {propertyType2Value !== "Office" &&
                propertyType2Value !== "Warehouse" &&
                propertyType2Value !== "Plot" &&
                propertyType2Value !== "Office" && (
                  <>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="buissParkName"
                          // className="col-lg-12 col-form-label requiredfield  font-weight-bold"
                          className={
                            propertyType2Value == "Other"
                              ? "col-lg-12 col-form-label requiredfield  font-weight-bold"
                              : "col-lg-12 col-form-label font-weight-bold"
                          }
                        >
                          Project Name
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="projectName"
                            name="projectName"
                            type="text"
                            className={`form-control`}
                            value={commPropDetails.projectName}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                          {commPropDetailsErr.projectName && (
                            <span className="error">
                              {commPropDetailsErr.projectName}
                            </span>
                          )}
                        </div>
                      </Row>
                    </Col>
                  </>
                )}
              {propertyType2Value !== "Office" &&
                propertyType2Value !== "Warehouse" &&
                propertyType2Value !== "Plot" &&
                propertyType2Value !== "Other" && (
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="buissParkName"
                        className="col-lg-12 col-form-label  font-weight-bold"
                      >
                        Mall Market Name
                      </label>
                      <div className="col-lg-12">
                        <Input
                          id="mallMarketName"
                          name="mallMarketName"
                          type="text"
                          className={`form-control`}
                          value={commPropDetails.mallMarketName}
                          onChange={e =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </Row>
                  </Col>
                )}
            </Row>
            {lookingfor === "Sell" && propertyType2Value !== "Plot" && (
              <>
                <span className="divider"></span>
                <h6 className="mt-4">POSSESION INFO</h6>
              </>
            )}

            {propertyType2Value !== "Plot" && (
              <>
                <Row>
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="possStatus"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Possesion Status
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100">
                          {PossesionStatus.map(possesion => (
                            <Button
                              key={possesion.id}
                              className={
                                commPropDetails.possesionStatus ===
                                possesion.value
                                  ? "btnActive"
                                  : "btnNormal"
                              }
                              style={{ marginRight: "5px" }}
                              onClick={() =>
                                handleChange("possesionStatus", possesion.value)
                              }
                            >
                              {possesion.value}
                            </Button>
                          ))}
                        </ButtonGroup>
                        {commPropDetailsErr.possesionStatus && (
                          <span className="error">
                            {commPropDetailsErr.possesionStatus}
                          </span>
                        )}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </>
            )}

            <Row>
              {lookingfor === "Rent" && (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="availableFrom"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Available
                    </label>
                    <div className="col-lg-12">
                      <Input
                        id="availableFrom"
                        name="availableFrom"
                        type="date"
                        className={`form-control`}
                        value={commPropDetails.availableFrom}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                      {commPropDetailsErr.availableFrom && (
                        <span className="error">
                          {commPropDetailsErr.availableFrom}
                        </span>
                      )}
                    </div>
                  </Row>
                </Col>
              )}

              {lookingfor === "Sell" &&
                propertyType2Value !== "Plot" &&
                showConstruction && (
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="possessionDate"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Possession Date
                      </label>
                      <div className="col-lg-12">
                        <Input
                          id="possessionDate"
                          name="possessionDate"
                          type="date"
                          className={`form-control`}
                          value={commPropDetails.possessionDate}
                          onChange={e =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                        {commPropDetailsErr.possessionDate && (
                          <span className="error">
                            {commPropDetailsErr.possessionDate}
                          </span>
                        )}
                      </div>
                    </Row>
                  </Col>
                )}

              {propertyType2Value !== "Plot" && showAgeOfProperty && (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="ageOfProperty"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Age of Property (in years)
                    </label>
                    <div className="col-lg-12">
                      <Input
                        id="ageOfProperty"
                        name="ageOfProperty"
                        type="number"
                        className={`form-control`}
                        value={commPropDetails.ageOfProperty}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        onWheel={e => e.target.blur()}
                      />
                      {commPropDetailsErr.ageOfProperty && (
                        <span className="error">
                          {commPropDetailsErr.ageOfProperty}
                        </span>
                      )}
                    </div>
                  </Row>
                </Col>
              )}
            </Row>
            <span className="divider"></span>
            <h6 className="mt-4">ABOUT THE PROPERTY </h6>
            {propertyType2Value !== "Retail Shop" &&
              propertyType2Value !== "Showroom" && (
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="zoneType"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Zone Type
                  </label>
                  <div className="col-lg-12 comm-button-group-container">
                    <div>
                      {ZoneArray.map(property => (
                        <Button
                          key={property.id}
                          className={
                            commPropDetails.zoneType === property.value
                              ? "btnActive custom-button"
                              : "btnNormal custom-button"
                          }
                          onClick={() =>
                            handleChange("zoneType", property.value)
                          }
                        >
                          {property.value}
                        </Button>
                      ))}
                    </div>
                    {commPropDetailsErr.zoneType && (
                      <span className="error">
                        {commPropDetailsErr.zoneType}
                      </span>
                    )}
                  </div>
                </Row>
              )}

            {propertyType2Value !== "Office" &&
              propertyType2Value !== "Retail Shop" &&
              propertyType2Value !== "Showroom" &&
              propertyType2Value !== "Other" &&
              propertyType2Value !== "Warehouse" && (
                <Col md={12}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="builtUpArea"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Plot Area
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        id="plotArea"
                        name="plotArea"
                        type="number"
                        className={`form-control`}
                        value={commPropDetails.plotArea}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        onWheel={e => e.target.blur()}
                        min={0}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text">Sq.ft</span>
                      </span>
                    </div>
                    {commPropDetailsErr.plotArea && (
                      <span className="error">
                        {commPropDetailsErr.plotArea}
                      </span>
                    )}
                  </Row>
                </Col>
              )}

            {propertyType2Value !== "Office" &&
              propertyType2Value !== "Warehouse" &&
              propertyType2Value !== "Plot" &&
              propertyType2Value !== "Other" && (
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="zoneType"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Suitable For
                  </label>
                  <div className="col-lg-12 comm-button-group-container">
                    <div>
                      {suitableForArray.map(property => (
                        <Button
                          key={property.id}
                          className={
                            commPropDetails.suitableFor.includes(property.value)
                              ? "btnActive custom-button"
                              : "btnNormal custom-button"
                          }
                          onClick={() =>
                            handle_Change("suitableFor", property.value)
                          }
                        >
                          {property.value}
                        </Button>
                      ))}
                    </div>
                    {commPropDetailsErr.suitableFor && (
                      <span className="error">
                        {commPropDetailsErr.suitableFor}
                      </span>
                    )}
                  </div>
                </Row>
              )}
            {propertyType2Value !== "Plot" && (
              <Row className="mb-3 d-block">
                <label
                  htmlFor="locationHub"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Location Hub
                </label>
                <div className="col-lg-12 comm-button-group-container">
                  {/* <ButtonGroup> */}
                  {locationHubArray.map(property => (
                    <Button
                      key={property.id}
                      className={
                        commPropDetails.locationHub === property.value
                          ? "btnActive  custom-button"
                          : "btnNormal  custom-button"
                      }
                      style={{ marginRight: "5px", borderRadius: "5px" }}
                      onClick={() =>
                        handleChange("locationHub", property.value)
                      }
                    >
                      {property.value}
                    </Button>
                  ))}

                  {/* </ButtonGroup> */}
                </div>
                {commPropDetailsErr.locationHub && (
                  <span className="error">
                    {commPropDetailsErr.locationHub}
                  </span>
                )}
              </Row>
            )}
            <Row>
              {otherLocation && (
                <Col md={12}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="otherLocationHub"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Other (Location Hub)
                    </label>
                    <div className="col-lg-12 d-flex">
                      <Input
                        id="otherLocationHub"
                        name="otherLocationHub"
                        type="text"
                        className={`form-control`}
                        value={commPropDetails.otherLocationHub}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        min={0}
                      />
                    </div>
                    {commPropDetailsErr.otherLocationHub && (
                      <span className="error">
                        {commPropDetailsErr.otherLocationHub}
                      </span>
                    )}
                  </Row>
                </Col>
              )}
            </Row>

            <Row>
              {propertyType2Value == "Office" && (
                <Row className="mb-3 d-block propertyCondition">
                  <label
                    htmlFor=""
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Property Condition
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      {propCondArray.map(property => (
                        <Button
                          key={property.id}
                          className={
                            commPropDetails.propertyCondition === property.value
                              ? "btnActive "
                              : "btnNormal "
                          }
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          onClick={() =>
                            handleChange("propertyCondition", property.value)
                          }
                        >
                          {property.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                  {commPropDetailsErr.propertyCondition && (
                    <span className="error">
                      {commPropDetailsErr.propertyCondition}
                    </span>
                  )}
                </Row>
              )}
              {propertyType2Value !== "Plot" && (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="builtUpArea"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Built Up Area
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        id="builtUpArea"
                        name="builtUpArea"
                        type="number"
                        className={`form-control`}
                        value={commPropDetails.builtUpArea}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        onWheel={e => e.target.blur()}
                        min={0}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text">Sq.ft</span>
                      </span>
                    </div>
                    {commPropDetailsErr.builtUpArea && (
                      <span className="error">
                        {commPropDetailsErr.builtUpArea}
                      </span>
                    )}
                  </Row>
                </Col>
              )}

              {commPropDetails.propertyCondition == "Ready to use" && (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="CarpetArea"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Carpet Area
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        id="CarpetArea"
                        name="CarpetArea"
                        type="number"
                        className={`form-control`}
                        value={commPropDetails.CarpetArea}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        onWheel={e => e.target.blur()}
                        min={0}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text">Sq.ft</span>
                      </span>
                    </div>
                    {commPropDetailsErr.CarpetArea && (
                      <span className="error">
                        {commPropDetailsErr.CarpetArea}
                      </span>
                    )}
                  </Row>
                </Col>
              )}
              {propertyType2Value !== "Office" &&
                propertyType2Value !== "Plot" && (
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="CarpetArea"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Carpet Area
                      </label>
                      <div className="col-lg-12 d-flex built-up">
                        <Input
                          id="CarpetArea"
                          name="CarpetArea"
                          type="number"
                          className={`form-control`}
                          value={commPropDetails.CarpetArea}
                          onChange={e =>
                            handleChange(e.target.name, e.target.value)
                          }
                          onWheel={e => e.target.blur()}
                        />
                        <span className="input-group-append">
                          <span className="input-group-text">Sq.ft</span>
                        </span>
                      </div>
                      {commPropDetailsErr.CarpetArea && (
                        <span className="error">
                          {commPropDetailsErr.CarpetArea}
                        </span>
                      )}
                    </Row>
                  </Col>
                )}
              {propertyType2Value !== "Office" &&
                propertyType2Value !== "Warehouse" &&
                propertyType2Value !== "Plot" &&
                propertyType2Value !== "Other" && (
                  <>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="entranceWidth"
                          className="col-lg-12 col-form-label requiredfield font-weight-bold"
                        >
                          Entrance width in feet
                        </label>
                        <div className="col-lg-12 d-flex built-up">
                          <Input
                            id="entranceWidth"
                            name="entranceWidth"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.entranceWidth}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                          />
                          <span className="input-group-append">
                            <span className="input-group-text">ft</span>
                          </span>
                        </div>
                        {commPropDetailsErr.entranceWidth && (
                          <span className="error">
                            {commPropDetailsErr.entranceWidth}
                          </span>
                        )}
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="ceilingHeight"
                          className="col-lg-12 col-form-label requiredfield font-weight-bold"
                        >
                          Ceiling height in feet
                        </label>
                        <div className="col-lg-12 d-flex built-up">
                          <Input
                            id="ceilingHeight"
                            name="ceilingHeight"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.ceilingHeight}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                          />
                          <span className="input-group-append">
                            <span className="input-group-text">ft</span>
                          </span>
                        </div>
                        {commPropDetailsErr.ceilingHeight && (
                          <span className="error">
                            {commPropDetailsErr.ceilingHeight}
                          </span>
                        )}
                      </Row>
                    </Col>
                      <Col md={12}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="possStatus"
                            className="col-lg-12 col-form-label requiredfield font-weight-bold"
                          >
                            Located Near
                          </label>
                          <div className="col-lg-12">
                            <ButtonGroup className="w-100">
                              {locatedNearArray.map(possesion => (
                                <Button
                                  key={possesion.id}
                                  className={
                                    commPropDetails.locatedNear ===
                                    possesion.value
                                      ? "btnActive"
                                      : "btnNormal"
                                  }
                                  style={{ marginRight: "5px",borderRadius:"5px" }}
                                  onClick={() =>
                                    handleChange("locatedNear", possesion.value)
                                  }
                                >
                                  {possesion.value}
                                </Button>
                              ))}
                            </ButtonGroup>
                          </div>
                          {commPropDetailsErr.locatedNear && (
                            <span className="error">
                              {commPropDetailsErr.locatedNear}
                            </span>
                          )}
                        </Row>
                      </Col>
                      {commPropDetails.locatedNear == "Other" ? (
                        <Col md={12}>
                          <Row className="mb-3 d-block">
                            <label
                              htmlFor="possStatus"
                              className="col-lg-12 col-form-label requiredfield font-weight-bold"
                            >
                              Located Near
                            </label>
                            <div className="col-lg-12">
                              <Input
                                id="otherLocatedNear"
                                name="otherLocatedNear"
                                type="text"
                                placeholder="Enter here"
                                className={`form-control`}
                                value={commPropDetails.otherLocatedNear}
                                onChange={e =>
                                  handleChange(e.target.name, e.target.value)
                                }
                                onWheel={e => e.target.blur()}
                              />
                            </div>
                            {commPropDetailsErr.otherLocatedNear && (
                              <span className="error">
                                {commPropDetailsErr.otherLocatedNear}
                              </span>
                            )}
                          </Row>
                        </Col>
                      ):""}
                  </>
                )}
            </Row>
            <Row className="mb-3 d-block">
              <label
                htmlFor="Ownership"
                className="col-lg-12 col-form-label requiredfield font-weight-bold"
              >
                Ownership
              </label>
              <div className="col-lg-12">
                <ButtonGroup className="w-100 ownerShips">
                  {OwnerShipArray.map(property => (
                    <Button
                      key={property.id}
                      className={
                        commPropDetails.ownership === property.value
                          ? "btnActive "
                          : "btnNormal "
                      }
                      style={{ marginRight: "5px", borderRadius: "5px" }}
                      onClick={() => handleChange("ownership", property.value)}
                    >
                      {property.value}
                    </Button>
                  ))}
                </ButtonGroup>
                {commPropDetailsErr.ownership && (
                  <span className="error">{commPropDetailsErr.ownership}</span>
                )}
              </div>
            </Row>
            {bareShellSelected && (
              <>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="constStatus"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Construction Status
                  </label>

                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      {constStatusArray.map(property => (
                        <Button
                          key={property.id}
                          className={
                            commPropDetails.constructionStatus ===
                            property.value
                              ? "btnActive "
                              : "btnNormal "
                          }
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          onClick={() =>
                            handleChange("constructionStatus", property.value)
                          }
                        >
                          {property.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                    {commPropDetailsErr.constructionStatus && (
                      <span className="error">
                        {commPropDetailsErr.constructionStatus}
                      </span>
                    )}
                  </div>
                </Row>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="flooring"
                    className="col-lg-12 col-form-labelfont-weight-bold"
                  >
                    Flooring
                  </label>

                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      {flooringArray.map(property => (
                        <Button
                          key={property.id}
                          className={
                            commPropDetails.flooring === property.value
                              ? "btnActive "
                              : "btnNormal "
                          }
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          onClick={() =>
                            handleChange("flooring", property.value)
                          }
                        >
                          {property.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </>
            )}

            <span className="divider"></span>
            <h6 className="mt-5">FINANCIALS </h6>
            {/* {lookingfor === "Sell" &&
              propertyType2Value !== "Office" &&
              propertyType2Value !== "Plot" &&
              propertyType2Value !== "Warehouse" &&
              otherLocation && (
                <Row>
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="ageOfProperty"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Expected Rent
                      </label>
                      <div className="col-lg-12 d-flex built-up">
                        <Input
                          id="monthlyRent"
                          name="monthlyRent"
                          type="number"
                          className={`form-control`}
                          value={commPropDetails.monthlyRent}
                          onChange={e =>
                            handleChange(e.target.name, e.target.value)
                          }
                          onWheel={e => e.target.blur()}
                          min={0}
                        />
                        <span className="input-group-append">
                          <span className="input-group-text font-weight-bold">
                            ₹{formatAmount(commPropDetails.monthlyRent)}
                          </span>
                        </span>
                      </div>
                      {commPropDetailsErr.monthlyRent && (
                        <span className="error">
                          {commPropDetailsErr.monthlyRent}
                        </span>
                      )}
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="securityDeposit"
                        className="col-lg-12 col-form-label  font-weight-bold"
                      >
                        Security Deposit
                      </label>
                      <div className="col-lg-12 d-flex d-flex built-up">
                        <Input
                          id="securityDeposit"
                          name="securityDeposit"
                          type="number"
                          className={`form-control`}
                          value={commPropDetails.securityDeposit}
                          onChange={e =>
                            handleChange(e.target.name, e.target.value)
                          }
                          onWheel={e => e.target.blur()}
                          min={0}
                        />
                        <span className="input-group-append">
                          <span className="input-group-text font-weight-bold">
                            ₹{formatAmount(commPropDetails.securityDeposit)}
                          </span>
                        </span>
                        {/* {buildingProjectsErr && (
                    <span className="error">{buildingProjectsErr}</span>
                  )} */}
            {/* </div>
                    </Row>
                  </Col>
                </Row>
              )} */}

            {lookingfor === "Rent" && lookingfor !== "Sell" && (
              <Row>
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="ageOfProperty"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Expected Rent
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        id="monthlyRent"
                        name="monthlyRent"
                        type="number"
                        className={`form-control`}
                        value={commPropDetails.monthlyRent}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        onWheel={e => e.target.blur()}
                        min={0}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text font-weight-bold">
                          ₹{formatAmount(commPropDetails.monthlyRent)}
                        </span>
                      </span>
                    </div>
                    {commPropDetailsErr.monthlyRent && (
                      <span className="error">
                        {commPropDetailsErr.monthlyRent}
                      </span>
                    )}
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="securityDeposit"
                      className="col-lg-12 col-form-label  font-weight-bold"
                    >
                      Security Deposit
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        id="securityDeposit"
                        name="securityDeposit"
                        type="number"
                        className={`form-control`}
                        value={commPropDetails.securityDeposit}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        onWheel={e => e.target.blur()}
                        min={0}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text font-weight-bold">
                          ₹{formatAmount(commPropDetails.securityDeposit)}
                        </span>
                      </span>
                      {/* {buildingProjectsErr && (
                    <span className="error">{buildingProjectsErr}</span>
                  )} */}
                    </div>
                  </Row>
                </Col>
              </Row>
            )}

            {/* {lookingfor === "Sell" &&
              propertyType2Value !== "Office" &&
              propertyType2Value !== "Plot" &&
              propertyType2Value !== "Warehouse" &&
              !otherLocation && (
                <Row>
                  <Col md={12}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="price"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Price
                      </label>
                      <div className="col-lg-12 d-flex built-up">
                        <Input
                          id="price"
                          name="price"
                          type="number"
                          className={`form-control`}
                          value={commPropDetails.price}
                          onChange={e =>
                            handleChange(e.target.name, e.target.value)
                          }
                          onWheel={e => e.target.blur()}
                          min={0}
                        />
                        <span className="input-group-append">
                          <span className="input-group-text font-weight-bold">
                            ₹{formatAmount(commPropDetails.price)}
                          </span>
                        </span>
                      </div>
                      {commPropDetailsErr.price && (
                        <span className="error">
                          {commPropDetailsErr.price}
                        </span>
                      )}
                    </Row>
                  </Col>
                </Row>
              )} */}

            {lookingfor === "Sell" && (
              // propertyType2Value !== "Retail Shop" &&
              // propertyType2Value !== "Showroom" &&
              // propertyType2Value !== "Other" &&
              <Row>
                <Col md={12}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="price"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Price
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        id="price"
                        name="price"
                        type="number"
                        className={`form-control`}
                        value={commPropDetails.price}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        onWheel={e => e.target.blur()}
                        min={0}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text font-weight-bold">
                          ₹{formatAmount(commPropDetails.price)}
                        </span>
                      </span>
                    </div>
                    {commPropDetailsErr.price && (
                      <span className="error">{commPropDetailsErr.price}</span>
                    )}
                  </Row>
                </Col>
              </Row>
            )}

            <Row>
              <Col md={4}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="Negotiable"
                    className="col-lg-12 col-form-label  font-weight-bold"
                  >
                    Negotiable
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      {YesNoArray.map(property => (
                        <Button
                          key={property.id}
                          className={
                            commPropDetails.negotiable === property.value
                              ? "btnActive "
                              : "btnNormal "
                          }
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          onClick={() =>
                            handleChange("negotiable", property.value)
                          }
                        >
                          {property.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
              {propertyType2Value !== "Retail Shop" &&
                propertyType2Value !== "Warehouse" &&
                propertyType2Value !== "Showroom" &&
                propertyType2Value !== "Plot" && (
                  <Col md={4}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="DgAndUps"
                        className="col-lg-12 col-form-label  font-weight-bold"
                      >
                        DG & UPS Charge included?
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100">
                          {YesNoArray.map(property => (
                            <Button
                              key={property.id}
                              className={
                                commPropDetails.upscChargeIncluded ===
                                property.value
                                  ? "btnActive "
                                  : "btnNormal "
                              }
                              style={{
                                marginRight: "5px",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                handleChange(
                                  "upscChargeIncluded",
                                  property.value
                                )
                              }
                            >
                              {property.value}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                )}
              {lookingfor !== "Sell" &&
                propertyType2Value !== "Retail Shop" && (
                  <Col md={4}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="ElectricityCharge"
                        className="col-lg-12 col-form-label  font-weight-bold"
                      >
                        Electricity charges included?
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100">
                          {YesNoArray.map(property => (
                            <Button
                              key={property.id}
                              className={
                                commPropDetails.electricityCharge ===
                                property.value
                                  ? "btnActive"
                                  : "btnNormal"
                              }
                              style={{
                                marginRight: "5px",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                handleChange(
                                  "electricityCharge",
                                  property.value
                                )
                              }
                            >
                              {property.value}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                )}

              {lookingfor !== "Rent" &&
                propertyType2Value == "Retail Shop" &&
                propertyType2Value == "Showroom" && (
                  <Col md={4}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="WaterCharge"
                        className="col-lg-12 col-form-label  font-weight-bold"
                      >
                        Tax & Govt. charge included?
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100">
                          {YesNoArray.map(property => (
                            <Button
                              key={property.id}
                              className={
                                commPropDetails.taxGovtCharge === property.value
                                  ? "btnActive "
                                  : "btnNormal "
                              }
                              style={{
                                marginRight: "5px",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                handleChange("taxGovtCharge", property.value)
                              }
                            >
                              {property.value}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                )}

              {lookingfor === "Rent" &&
                propertyType2Value !== "Retail Shop" &&
                propertyType2Value !== "Plot" &&
                propertyType2Value !== "Showroom" &&
                propertyType2Value !== "Warehouse" && (
                  <Col md={4}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="WaterCharge"
                        className="col-lg-12 col-form-label  font-weight-bold"
                      >
                        Water charges included?
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100">
                          {YesNoArray.map(property => (
                            <Button
                              key={property.id}
                              className={
                                commPropDetails.waterCharge === property.value
                                  ? "btnActive "
                                  : "btnNormal "
                              }
                              style={{
                                marginRight: "5px",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                handleChange("waterCharge", property.value)
                              }
                            >
                              {property.value}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                )}
            </Row>
            {lookingfor === "Rent" && (
              <Row>
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="totalFloors"
                      className="col-lg-12 col-form-label font-weight-bold"
                    >
                      Lock-in Period (In Days)
                    </label>
                    <div className="col-lg-12">
                      <Input
                        id="lockInPeriod"
                        name="lockInPeriod"
                        type="number"
                        className={`form-control`}
                        value={commPropDetails.lockInPeriod}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        onWheel={e => e.target.blur()}
                        min={0}
                      />
                    </div>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="yourFloor"
                      className="col-lg-12 col-form-label  font-weight-bold"
                    >
                      Expected Rent Increase
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        id="expectedRentIncrease"
                        name="expectedRentIncrease"
                        type="number"
                        className={`form-control`}
                        value={commPropDetails.expectedRentIncrease}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                        onWheel={e => e.target.blur()}
                        min={0}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text font-weight-bold">
                          ₹{formatAmount(commPropDetails.expectedRentIncrease)}
                        </span>
                      </span>
                    </div>
                  </Row>
                </Col>
              </Row>
            )}

            {propertyType2Value !== "Plot" && (
              <>
                <span className="divider"></span>
                <h6 className="mt-5">FLOORS AVAILABLE </h6>

                <Row>
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="totalFloors"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Total Floors
                      </label>
                      <div className="col-lg-12">
                        {/* <Input
                          id="totalFloors"
                          name="totalFloors"
                          type="number"
                          className={`form-control`}
                          value={commPropDetails.totalFloors}
                          onChange={e =>
                            handleChange(e.target.name, e.target.value)
                          }
                          onWheel={e => e.target.blur()}
                        /> */}
                        <Select
                          id="totalFloors"
                          name="totalFloors"
                          value={{
                            label: commPropDetails.totalFloors,
                            value: commPropDetails.totalFloors,
                          }}
                          onChange={selectedValue => {
                            handleChange("totalFloors", selectedValue.value)
                          }}
                          options={total_Floor}
                          classNamePrefix="select2-selection"
                        />
                        {commPropDetailsErr.totalFloors && (
                          <span className="error">
                            {commPropDetailsErr.totalFloors}
                          </span>
                        )}
                      </div>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="yourFloor"
                        className="col-lg-12 col-form-label  font-weight-bold"
                      >
                        Your Floor
                      </label>
                      <div className="col-lg-12">
                        <Select
                          id="yourFloor"
                          name="yourFloor"
                          options={generateFloorOptions(
                            optionGroup,
                            commPropDetails.totalFloors
                          )}
                          onChange={option =>
                            handleChange("yourFloor", option.value)
                          }
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>
              </>
            )}

            {propertyType2Value !== "Retail Shop" &&
              propertyType2Value !== "Plot" &&
              propertyType2Value !== "Showroom" &&
              propertyType2Value !== "Warehouse" &&
              propertyType2Value !== "Other" && (
                <>
                  <span className="divider"></span>

                  <h6 className="mt-5">LIFTS & STAIRCASES</h6>
                  <Row>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="staircase"
                          className="col-lg-12 col-form-label font-weight-bold"
                        >
                          Number of staircase
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="numberOfStaircase"
                            name="numberOfStaircase"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.numberOfStaircase}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                            min={0}
                          />
                        </div>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="passengersLift"
                          className="col-lg-12 col-form-label requiredfield  font-weight-bold"
                        >
                          Passengers Lifts
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="passengersLift"
                            name="passengersLift"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.passengersLift}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                            min={0}
                          />
                          {commPropDetailsErr.passengersLift && (
                            <span className="error">
                              {commPropDetailsErr.passengersLift}
                            </span>
                          )}
                        </div>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="serviceLift"
                          className="col-lg-12 col-form-label requiredfield  font-weight-bold"
                        >
                          Service Lifts
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="serviceLift"
                            name="serviceLift"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.serviceLift}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                            min={0}
                          />
                          {commPropDetailsErr.serviceLift && (
                            <span className="error">
                              {commPropDetailsErr.serviceLift}
                            </span>
                          )}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            {propertyType2Value !== "Warehouse" &&
              propertyType2Value !== "Plot" && (
                <>
                  <span className="divider"></span>
                  <h6 className="mt-5">PARKING</h6>
                  <Row>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="privateParking"
                          className="col-lg-12 col-form-label font-weight-bold"
                        >
                          Private Parking
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="privateParking"
                            name="privateParking"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.privateParking}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                            min={0}
                          />
                        </div>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="publicParking"
                          className="col-lg-12 col-form-label  font-weight-bold"
                        >
                          Public Parking
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="publicParking"
                            name="publicParking"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.publicParking}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                            min={0}
                          />
                          {/* {buildingProjectsErr && (
                    <span className="error">{buildingProjectsErr}</span>
                  )} */}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}

            {propertyType2Value !== "Plot" && commPropDetails.propertyCondition == "Ready to use" && (
              <>
                <span className="divider"></span>
                <h6 className="mt-5">FACILITIES</h6>
                <Row>
                  {propertyType2Value === "Office" && (
                    <>
                      <Col md={6}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="serviceLift"
                            className="col-lg-12 col-form-label requiredfield font-weight-bold"
                          >
                            Min. Number of Seat
                          </label>
                          <div className="col-lg-12">
                            <Input
                              id="minimunNumberOfSeats"
                              name="minimunNumberOfSeats"
                              type="number"
                              className={`form-control`}
                              value={commPropDetails.minimunNumberOfSeats}
                              onChange={e =>
                                handleChange(e.target.name, e.target.value)
                              }
                              onWheel={e => e.target.blur()}
                            />
                            {commPropDetailsErr.minimunNumberOfSeats && (
                              <span className="error">
                                {commPropDetailsErr.minimunNumberOfSeats}
                              </span>
                            )}
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="maximumNumberOfSeats"
                            className="col-lg-12 col-form-label requiredfield requiredfield font-weight-bold"
                          >
                            Max. Number of Seats
                          </label>
                          <div className="col-lg-12">
                            <Input
                              id="maximumNumberOfSeats"
                              name="maximumNumberOfSeats"
                              type="number"
                              className={`form-control`}
                              value={commPropDetails.maximumNumberOfSeats}
                              onChange={e =>
                                handleChange(e.target.name, e.target.value)
                              }
                              onWheel={e => e.target.blur()}
                            />
                            {commPropDetailsErr.maximumNumberOfSeats && (
                              <span className="error">
                                {commPropDetailsErr.maximumNumberOfSeats}
                              </span>
                            )}
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="numberOfCabins"
                            className="col-lg-12 col-form-label requiredfield  font-weight-bold"
                          >
                            Number of Cabins
                          </label>
                          <div className="col-lg-12">
                            <Input
                              id="numberOfCabins"
                              name="numberOfCabins"
                              type="number"
                              className={`form-control`}
                              value={commPropDetails.numberOfCabins}
                              onChange={e =>
                                handleChange(e.target.name, e.target.value)
                              }
                              onWheel={e => e.target.blur()}
                            />
                            {commPropDetailsErr.numberOfCabins && (
                              <span className="error">
                                {commPropDetailsErr.numberOfCabins}
                              </span>
                            )}
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="NumberOfMeetingRooms"
                            className="col-lg-12 col-form-label requiredfield font-weight-bold"
                          >
                            Number of Meeting Rooms
                          </label>
                          <div className="col-lg-12">
                            <Input
                              id="NumberOfMeetingRooms"
                              name="NumberOfMeetingRooms"
                              type="text"
                              className={`form-control`}
                              value={commPropDetails.NumberOfMeetingRooms}
                              onChange={e =>
                                handleChange(e.target.name, e.target.value)
                              }
                            />
                            {commPropDetailsErr.NumberOfMeetingRooms && (
                              <span className="error">
                                {commPropDetailsErr.NumberOfMeetingRooms}
                              </span>
                            )}
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="conferenceRoom"
                            className="col-lg-12 col-form-label requiredfield font-weight-bold"
                          >
                            Conference Room
                          </label>
                          <div className="col-lg-12">
                            <Input
                              id="conferenceRoom"
                              name="conferenceRoom"
                              type="text"
                              className={`form-control`}
                              value={commPropDetails.conferenceRoom}
                              onChange={e =>
                                handleChange(e.target.name, e.target.value)
                              }
                            />
                            {commPropDetailsErr.conferenceRoom && (
                              <span className="error">
                                {commPropDetailsErr.conferenceRoom}
                              </span>
                            )}
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="privateWashroom"
                            className="col-lg-12 col-form-label requiredfield font-weight-bold"
                          >
                            Private Washroom
                          </label>
                          <div className="col-lg-12">
                            <Input
                              id="privateWashroom"
                              name="privateWashroom"
                              type="text"
                              className={`form-control`}
                              value={commPropDetails.privateWashroom}
                              onChange={e =>
                                handleChange(e.target.name, e.target.value)
                              }
                            />
                            {commPropDetailsErr.privateWashroom && (
                              <span className="error">
                                {commPropDetailsErr.privateWashroom}
                              </span>
                            )}
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="publicWashroom"
                            className="col-lg-12 col-form-label requiredfield font-weight-bold"
                          >
                            Public Washroom
                          </label>
                          <div className="col-lg-12">
                            <Input
                              id="publicWashroom"
                              name="publicWashroom"
                              type="text"
                              className={`form-control`}
                              value={commPropDetails.publicWashroom}
                              onChange={e =>
                                handleChange(e.target.name, e.target.value)
                              }
                            />
                            {commPropDetailsErr.publicWashroom && (
                              <span className="error">
                                {commPropDetailsErr.publicWashroom}
                              </span>
                            )}
                          </div>
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>
              </>
            )}

            {propertyType2Value !== "Office" &&
              propertyType2Value !== "Plot" && (
                <>
                  <span className="divider"></span>
                  <h6 className="mt-5">FACILITIES</h6>
                  <Row>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="privateWashroom"
                          className="col-lg-12 col-form-label requiredfield font-weight-bold"
                        >
                          Private Washroom
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="privateWashroom"
                            name="privateWashroom"
                            type="text"
                            className={`form-control`}
                            value={commPropDetails.privateWashroom}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                          {commPropDetailsErr.privateWashroom && (
                            <span className="error">
                              {commPropDetailsErr.privateWashroom}
                            </span>
                          )}
                        </div>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="publicWashroom"
                          className="col-lg-12 col-form-label requiredfield font-weight-bold"
                        >
                          Public Washroom
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="publicWashroom"
                            name="publicWashroom"
                            type="text"
                            className={`form-control`}
                            value={commPropDetails.publicWashroom}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                          {commPropDetailsErr.publicWashroom && (
                            <span className="error">
                              {commPropDetailsErr.publicWashroom}
                            </span>
                          )}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}

            {lookingfor === "Sell" && (
              <>
                <span className="divider"></span>
                <h6 className="mt-5">Other Details</h6>
                <Row>
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="WaterCharge"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Is it pre-leased/pre-rented?
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100">
                          {YesNoArray.map(property => (
                            <Button
                              key={property.id}
                              className={
                                commPropDetails.preRented === property.value
                                  ? "btnActive "
                                  : "btnNormal "
                              }
                              style={{
                                marginRight: "5px",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                handleChange("preRented", property.value)
                              }
                            >
                              {property.value}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                      {commPropDetailsErr.preRented && (
                        <span className="error">
                          {commPropDetailsErr.preRented}
                        </span>
                      )}
                    </Row>
                  </Col>
                </Row>
                {commPropDetails.preRented === "Yes" && (
                  <Row>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="passengersLift"
                          className="col-lg-12 col-form-label requiredfield  font-weight-bold"
                        >
                          Current Rent Per Month
                        </label>
                        <div className="col-lg-12 d-flex built-up">
                          <Input
                            id="currentRentPerMonth"
                            name="currentRentPerMonth"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.currentRentPerMonth}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                            min={0}
                          />
                          <span className="input-group-append">
                            <span className="input-group-text font-weight-bold">
                              ₹
                              {formatAmount(
                                commPropDetails.currentRentPerMonth
                              )}
                            </span>
                          </span>
                        </div>
                        {commPropDetailsErr.currentRentPerMonth && (
                          <span className="error">
                            {commPropDetailsErr.currentRentPerMonth}
                          </span>
                        )}
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="leaseYears"
                          className="col-lg-12 col-form-label requiredfield  font-weight-bold"
                        >
                          Lease Years
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="leaseYears"
                            name="leaseYears"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.leaseYears}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                            min={0}
                          />
                          {commPropDetailsErr.leaseYears && (
                            <span className="error">
                              {commPropDetailsErr.leaseYears}
                            </span>
                          )}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                )}
                {commPropDetails.preRented === "No" && (
                  <Row>
                    <Col md={12}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="expectedReturnOnInvestment"
                          className="col-lg-12 col-form-label requiredfield  font-weight-bold"
                        >
                          Expected Return on Investment
                        </label>
                        <div className="col-lg-12 d-flex">
                          <Input
                            id="expectedReturnOnInvestment"
                            name="expectedReturnOnInvestment"
                            type="number"
                            className={`form-control`}
                            value={commPropDetails.expectedReturnOnInvestment}
                            onChange={e =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onWheel={e => e.target.blur()}
                            min={0}
                          />
                          <span className="input-group-append">
                            <span className="input-group-text">%per annum</span>
                          </span>
                          {commPropDetailsErr.expectedReturnOnInvestment && (
                            <span className="error">
                              {commPropDetailsErr.expectedReturnOnInvestment}
                            </span>
                          )}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                )}
              </>
            )}
            {propertyType2Value == "Plot" && lookingfor == "Sell" ? (
              <React.Fragment>
                  <Row>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="txtFirstNameBilling"
                          className="col-lg-12 col-form-label font-weight-bold"
                        >
                          Corner Property?
                        </label>
                        <div className="col-lg-12">
                          <ButtonGroup className="w-100">
                            <Button
                              style={{ marginRight: "5px", borderRadius: "5px" }}
                              className={
                                advanceDetails.cornerProperty === "Yes"
                                  ? "btnActive"
                                  : "btnNormal"
                              }
                              onClick={() => handleAdvanceData("cornerProperty", "Yes")}
                            >
                              <Input
                                type="radio"
                                name="cornerProperty"
                                value="Yes"
                                onChange={() => handleAdvanceData("cornerProperty", "Yes")}
                                checked={advanceDetails.cornerProperty === "Yes"}
                                style={{ opacity: 0 }}
                              />
                              Yes
                            </Button>
                            <Button
                              style={{ borderRadius: "5px" }}
                              className={
                                advanceDetails.cornerProperty === "No"
                                  ? "btnActive"
                                  : "btnNormal"
                              }
                              onClick={() => handleAdvanceData("cornerProperty", "No")}
                            >
                              <Input
                                type="radio"
                                name="cornerProperty"
                                value="No"
                                onChange={() => handleAdvanceData("cornerProperty", "No")}
                                checked={advanceDetails.cornerProperty === "No"}
                                style={{ opacity: 0 }}
                              />
                              No
                            </Button>
                          </ButtonGroup>
                        </div>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="txtFirstNameBilling"
                          className="col-lg-12 col-form-label font-weight-bold"
                        >
                          Construction Started?
                        </label>
                        <div className="col-lg-12">
                          <ButtonGroup className="w-100">
                            <Button
                              style={{ marginRight: "5px", borderRadius: "5px" }}
                              className={
                                advanceDetails.constructionStarted === "Yes"
                                  ? "btnActive"
                                  : "btnNormal"
                              }
                              onClick={() => handleAdvanceData("constructionStarted", "Yes")}
                            >
                              <Input
                                type="radio"
                                name="constructionStarted"
                                value="Yes"
                                onChange={() =>
                                  handleAdvanceData("constructionStarted", "Yes")
                                }
                                checked={
                                  advanceDetails.constructionStarted === "Yes"
                                }
                                style={{ opacity: 0 }}
                              />
                              Yes
                            </Button>
                            <Button
                              style={{ borderRadius: "5px" }}
                              className={
                                advanceDetails.constructionStarted === "No"
                                  ? "btnActive"
                                  : "btnNormal"
                              }
                              onClick={() => handleAdvanceData("constructionStarted", "No")}
                            >
                              <Input
                                type="radio"
                                name="constructionStarted"
                                value="No"
                                onChange={() =>
                                  handleAdvanceData("constructionStarted", "No")
                                }
                                checked={advanceDetails.constructionStarted === "No"}
                                style={{ opacity: 0 }}
                              />
                              No
                            </Button>
                          </ButtonGroup>
                        </div>
                      </Row>
                    </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="txtFirstNameBilling"
                        className="col-lg-12 col-form-label font-weight-bold"
                      >
                        Facing
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100 grid-columns">
                          {facingarray.map(face => (
                            <Button
                              key={face.id}
                              style={{ borderRadius: "5px" }}
                              className={
                                advanceDetails.facing === face.value
                                  ? "btnActive"
                                  : "btnNormal"
                              }
                              onClick={() => handleAdvanceData("facing", face.value)}
                            >
                              <Input
                                type="radio"
                                name="facing"
                                value={face.value}
                                onChange={() => handleAdvanceData("facing", face.value)}
                                checked={advanceDetails.facing === face.value}
                                style={{ opacity: 0 }}
                              />
                              {face.value}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="address"
                        className="col-lg-12 col-form-label font-weight-bold"
                      >
                        Address
                      </label>
                      <div className="col-lg-12">
                        <Input
                          id="address"
                          name="address"
                          type="text"
                          className={`form-control btnNormal`}
                          value={advanceDetails.address}
                          onChange={e => handleAdvanceData("address", e.target.value)}
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row>
                      <Col md={6}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="txtFirstNameBilling"
                            className="col-lg-12 col-form-label font-weight-bold"
                          >
                            Boundary Wall Present?
                          </label>
                          <div className="col-lg-12">
                            <ButtonGroup className="w-100">
                              <Button
                                style={{ marginRight: "5px", borderRadius: "5px" }}
                                className={
                                  advanceDetails.boundaryWallPresent === "Yes"
                                    ? "btnActive"
                                    : "btnNormal"
                                }
                                onClick={() =>
                                  handleAdvanceData("boundaryWallPresent", "Yes")
                                }
                              >
                                <Input
                                  type="radio"
                                  name="boundaryWallPresent"
                                  value="Yes"
                                  onChange={() =>
                                    handleAdvanceData("boundaryWallPresent", "Yes")
                                  }
                                  checked={
                                    advanceDetails.boundaryWallPresent === "Yes"
                                  }
                                  style={{ opacity: 0 }}
                                />
                                Yes
                              </Button>
                              <Button
                                style={{ borderRadius: "5px" }}
                                className={
                                  advanceDetails.boundaryWallPresent === "No"
                                    ? "btnActive"
                                    : "btnNormal"
                                }
                                onClick={() =>
                                  handleAdvanceData("boundaryWallPresent", "No")
                                }
                              >
                                <Input
                                  type="radio"
                                  name="boundaryWallPresent"
                                  value="No"
                                  onChange={() =>
                                    handleAdvanceData("boundaryWallPresent", "No")
                                  }
                                  checked={
                                    advanceDetails.boundaryWallPresent === "No"
                                  }
                                  style={{ opacity: 0 }}
                                />
                                No
                              </Button>
                            </ButtonGroup>
                          </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mb-3 d-block">
                          <label
                            htmlFor="txtFirstNameBilling"
                            className="col-lg-12 col-form-label font-weight-bold"
                          >
                            No. of open sides
                          </label>
                          <div className="col-lg-12">
                            <ButtonGroup className="w-100">
                              {openSidesarray.map(bhk => (
                                <Button
                                  key={bhk.id}
                                  style={{ marginRight: "5px", borderRadius: "5px" }}
                                  className={
                                    advanceDetails.noOfOpenSides === bhk.value
                                      ? "btnActive"
                                      : "btnNormal"
                                  }
                                  onClick={() =>
                                    handleAdvanceData("noOfOpenSides", bhk.value)
                                  }
                                >
                                  {bhk.value}
                                </Button>
                              ))}
                            </ButtonGroup>
                          </div>
                        </Row>
                      </Col>
                    <Col md={12}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="floorsAllowedForConstruction"
                          className="col-lg-12 col-form-label font-weight-bold"
                        >
                          Floors Allowed for Construction
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="floorsAllowedForConstruction"
                            name="floorsAllowedForConstruction"
                            type="number"
                            className={`form-control btnNormal`}
                            value={advanceDetails.floorsAllowedForConstruction}
                            onChange={e =>
                              handleAdvanceData(
                                "floorsAllowedForConstruction",
                                e.target.value
                              )
                            }
                            min={0}
                            onWheel={e => e.target.blur()}
                          />
                        </div>
                      </Row>
                    </Col>
                </Row>
                  <Row>
                    <Col md={12}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="reraId"
                          className="col-lg-12 col-form-label font-weight-bold"
                        >
                          RERA ID
                        </label>
                        <div className="col-lg-12">
                          <Input
                            id="reraId"
                            name="reraId"
                            type="text"
                            className={`form-control btnNormal`}
                            value={advanceDetails.reraId}
                            onChange={e => handleAdvanceData("reraId", e.target.value)}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
              </React.Fragment>
            ):''}
          </Form>
        </React.Fragment>
      </>
    )
  }
)

export default CommPropDetailsEdit
