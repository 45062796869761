import React from "react"
import { loadScript } from "./util"
import { useState } from "react"
import Swal from "sweetalert2"
import { HOST_URL } from "./url_helper"
import { ApiPostMethod } from "./withoutToken_helper"
const displayRazorpay = async (user_id, plan, closeModel) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

  if (!res) {
    alert("Razorpay SDK failed to load. Please check your internet connection.")
    return
  }

  let options = {
    //   key: "rzp_test_c7KR6i0W3tAoMQ",
    key: "rzp_live_MSMflnVe2GhGBU",
    amount: plan.planOfferAmount * 100,
    user: user_id.name,
    description: "Property Plan",

    image: "",
    //   order_id: order_id, // Generate the order ID from your backend
    renter_id: user_id.name,
    handler: async function (response) {
      var data = {
        transactionId: response.razorpay_payment_id,
        userId: user_id._id,
        userName: user_id.name,
        planId: plan._id,
        planName: plan.planName,
        planAmount: plan.planOfferAmount,
        planDuration: plan.planDuration,
        paymentMode: "Online",
        addOnPlan: plan.addOnPlan,
      }

      const url = `${HOST_URL}payment`
      await ApiPostMethod(url, data).then(response => {
        if (response.error === false) {
          Swal.fire({
            icon: "success",
            title: "Payment Successful",
            showConfirmButton: false,
            timer: 1500,
          })
          closeModel()
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "An error occurred!",
          })
        }
      })
      const inv_url = `${HOST_URL}/api/paymail`
      const inv_data = {
        c_name: user_id.name,
        c_mobno: user_id.mobno,
        c_email: user_id.email,
        p_name: plan.planName,
        p_amount: plan.planOfferAmount,
      }
      await ApiPostMethod(inv_url, inv_data).then(response => {
        if (response.error === false) {
          console.log("success", response.message)
        } else {
          console.log("error", response.message)
        }
      })
    },
    prefill: {
      name: user_id.name,
      email: user_id.email,
      contact: user_id.mobno,
    },
    notes: {
      "Customer Name": user_id.name,
    },
    theme: {
      color: "#f70d02",
    },
  }

  const paymentObject = new window.Razorpay(options)
  paymentObject.open()
}

export default displayRazorpay
