import React, { useState } from "react"
import MUIDataTable from "mui-datatables"
import {
  Card,
  CardBody,
  Col,
  Row,
  TabPane,
  Button,
} from "reactstrap"
import {
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material"
import AddBlog2 from "./AddBlog2"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { HOST_URL } from "helpers/url_helper"
import { useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import CloseIcon from "@mui/icons-material/Close"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import parse from 'html-react-parser';

const Blog2 = () => {
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [operation, setOperation] = useState()
  const [blogList, setBlogList] = useState([])
  const [blogData, setBlogData] = useState([])
  const [editBlog, setEditBlog] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const token = JSON.parse(localStorage.getItem("token"))

  const fetchData = async () => {
    const url = `${HOST_URL}blog/viewAllBlog`
    const data = {}
    try {
      const response = await ApiPostMethodWithToken(url, data, token)
      if (response.error === false) {
        const sortedBlogList = response.BlogList.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        setBlogList(sortedBlogList)
      } else {
        console.error("Error fetching Blog list:", response.message)
      }
    } catch (error) {
      console.error("Error fetching Blog list:", error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleOpen = (operation, row) => {
    setOperation(operation)
    if (operation === "Add Blog") {
      setEditBlog({})
      setOpen(true)
    } else {
      const url = `${HOST_URL}blog/viewSingleBlog`
      const data = {
        blogId: row.rowData[1],
      }

      ApiPostMethodWithToken(url, data, token).then(data => {
        if (data.error === false) {
          setEditBlog(data)
          setOpen(true)
        }
      })
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen2 = () => {
    setOpen2(true)
  }

  const handleClose2 = () => {
    setOpen2(false)
  }

  const handleView = row => {
    const blogId = row.rowData[1]
    const url = `${HOST_URL}blog/viewSingleBlog`
    const data = { blogId }

    ApiPostMethodWithToken(url, data, token).then(response => {
      if (response.error === false) {
        setBlogData(response.blogList)
        setIsModalOpen(true)
      } else {
        console.error("Error fetching single blog:", response.message)
      }
    })
  }

  const handleDelete = async row => {
    try {
      const result = await Swal.fire({
        title: "Are you sure want to delete?",
        text: "You will not be able to recover this blog!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })

      if (result.isConfirmed) {
        const url = `${HOST_URL}blog/deleteBlog`
        const data = {
          blogId: row.rowData[1],
        }

        const response = await ApiPostMethodWithToken(url, data, token)

        if (response.error === false) {
          const updatedBlogList = blogList.filter(
            blog => blog._id !== row.rowData[1]
          )
          setBlogList(updatedBlogList)
          toast.success(response.message, {
            position: "top-right",
            theme: "light",
          })
        } else {
          toast.error(response.message, {
            position: "top-right",
            theme: "light",
          })
        }
      }
    } catch (error) {
      toast.error("Error deleting blog", {
        position: "top-right",
        theme: "light",
      })
    }
  }

  const columns = [
    {
      name: "SN",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1
        },
      },
    },
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "blogImg",
      label: "IMAGE/VIDEO",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const blog = blogList[tableMeta.rowIndex]
          return blog.type === "Image" ? (
            <img
              src={blog.blogImg}
              alt="Blog Image"
              style={{ maxWidth: "50px", maxHeight: "50px" }}
            />
          ) : (
            <a
              href={blog.youtubeLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Watch On YouTube
            </a>
          )
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "DESCRIPTION",
      options: {
        filter: true,
        sort: false,
        customBodyRender: description =>
          parse(typeof description === "string" && description.length > 100
            ? `${description.slice(0, 20)}...`
            : description)
      },
    },
    {
      name: "type",
      label: "TYPE",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="View">
                <button
                  className="actionview me-1"
                  onClick={() => handleView(tableMeta)}
                >
                  <i className="mdi mdi-eye-outline"></i>
                </button>
              </Tooltip>
              <Tooltip title="Edit">
                <button
                  className="actionview me-1"
                  onClick={() => handleOpen("Edit Blog", tableMeta)}
                >
                  <i className="mdi mdi-clipboard-edit-outline"></i>
                </button>
              </Tooltip>
              <Tooltip title="Delete">
                <button
                  className="actionview"
                  onClick={() => handleDelete(tableMeta)}
                >
                  <i className="mdi mdi-delete-outline"></i>
                </button>
              </Tooltip>
            </>
          )
        },
      },
    },
  ]

  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
  }

  return (
    <React.Fragment>
      <div className="page-content Blog-list">
        <Row>
          <Col lg={12} className="pe-0 pt-2 pb-2">
            <Card
              className="border-right-top"
              style={{ borderRadius: "12px 0px 0px 0px" }}
            >
              <CardBody className="pt-3">
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={6}>
                    <h5>Blog</h5>
                  </Grid>
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Button
                      onClick={() => handleOpen("Add Blog")}
                      style={{ padding: "8px 36px", marginTop: "3px" }}
                      className="commongradient addbutton addbtn"
                    >
                      Add Blog
                    </Button>
                  </Grid>
                </Grid>
                <TabPane tabId="2" style={{ margin: "0px 20px" }}>
                  <Row>
                    <Col sm="12" className="p-1 ps-0 pe-0">
                      <MUIDataTable
                        title=""
                        data={blogList}
                        columns={columns}
                        options={options}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal show={isModalOpen} onHide={toggleModal} centered>
        <div
          className="modalblog"
          style={{
            width: "100%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 20,
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5>Blog Details</h5>
            <IconButton onClick={toggleModal} style={{ marginRight: "-10px" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <Row style={{ marginBottom: 20 }}>
                <Col lg={4} md={4} xs={12} className="blogTitle">
                  {blogData.blogImg ? (
                    <>
                      <strong style={{ fontWeight: 800, marginRight: 20 }}>
                        Blog Image:
                      </strong>
                      <img
                        src={blogData.blogImg}
                        alt="Blog"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </>
                  ) : (
                    <>
                      <strong style={{ fontWeight: 800, marginRight: 20 }}>
                        Blog Youtube:
                      </strong>
                      <a
                        href={blogData.youtubeLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Watch on YouTube
                      </a>
                    </>
                  )}
                </Col>

                <Col lg={8} md={8} xs={12}>
                  <strong style={{ fontWeight: 800, marginRight: 20 }}>
                    Title:
                  </strong>
                  <span>{blogData.title}</span>
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col lg={4} md={4} xs={12} className="blogTitle">
                  <strong style={{ fontWeight: 800, marginRight: 20 }}>
                    Category:
                  </strong>
                  <span>{blogData.category}</span>
                </Col>
                <Col lg={8} md={8} xs={12}>
                  <strong style={{ fontWeight: 800, marginRight: 20 }}>
                    Description:
                  </strong>
                  <span style={{ textAlign: "justify" }}>
                     {parse(typeof blogData.description === "string" ? blogData.description : "")}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col lg={4} md={4} xs={12} className="blogTitle">
                  <strong style={{ fontWeight: 800, marginRight: 20 }}>
                    Type:
                  </strong>
                  <span>{blogData.type}</span>
                </Col>
                <Col lg={8} md={8} xs={12}>
                  <strong style={{ fontWeight: 800, marginRight: 20 }}>
                    Youtube Link:
                  </strong>
                  <a
                    href={blogData.youtubeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Watch on Youtube
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      {editBlog && (
        <AddBlog2
          open={open}
          handleClose={handleClose}
          fetchData={fetchData}
          operation={operation}
          blogData={editBlog}
        />
      )}
    </React.Fragment>
  )
}

export default Blog2