import { useLocation, useNavigate, useParams } from "react-router-dom"

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

var HOSTURL
var NEXTURL
var REFERURL
if (window.location.hostname === "localhost") {
  // HOSTURL = "http://192.168.1.12:5000/"
  HOSTURL = "http://localhost:5000/"
  //  HOSTURL = "https://expressapi.freelifehousing.com/"
  NEXTURL = "http://localhost:3000/"
  // NEXTURL = "http://192.168.1.13:3000/"
  REFERURL = "http://localhost:4000/"
  // REFERURL = "http://192.168.1.9:4000/"
} else {
  HOSTURL = "https://expressapi.freelifehousing.com/"
  // HOSTURL = "http://43.205.195.150:5000/"
  NEXTURL = "https://freelifehousing.com/"
  REFERURL = "https://app.freelifehousing.com/"
}
export const HOST_URL = HOSTURL
export const NEXT_URL = NEXTURL
export const REFER_URL = REFERURL
