import React, { useEffect, useState } from "react"
import MUIDataTable from "mui-datatables"
import {
  Breadcrumbs,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { Button, CardBody, Col, Row } from "reactstrap"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"
import CloseIcon from "@mui/icons-material/Close"
import displayRazorpay from "helpers/payment"

const PlanViewTable = () => {
  const [planList, setPlanList] = useState([])
  const [planData, setPlanData] = useState([])
  const [errors, setErrors] = useState({})
  const token = JSON.parse(localStorage.getItem("token"))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [open, setOpen] = useState(false)

  const user_role = JSON.parse(localStorage.getItem("userRole"))
  const authUser = JSON.parse(localStorage.getItem("authUser"))

  const [formData, setFormData] = useState({
    planName: "",
    planActualAmount: "",
    planOfferAmount: "",
    planDuration: "",
  })

  // console.log("plan data==", planList)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (planData && planData.planList) {
      setFormData({
        planId: planData.planList._id,
        planName: planData.planList.planName,
        planActualAmount: planData.planList.planActualAmount,
        planOfferAmount: planData.planList.planOfferAmount,
        planDuration: planData.planList.planDuration,
      })
    }
  }, [planData])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = () => {
    const validationErrors = validateForm(formData)
    if (Object.keys(validationErrors).length === 0) {
      const url = `${HOST_URL}plan/editPLan`
      const data = formData
      ApiPostMethodWithToken(url, data, token)
        .then(data => {
          if (data.error === false) {
            fetchData()
            toggleModal()
            setErrors('')
          }
        })
        .catch(error => console.log(`Error: ${error}`))
    } else {
      setErrors(validationErrors)
    }
  }

  const validateForm = data => {
    let errors = {}

    if (!data.planName.trim()) {
      errors.planName = "Plan Name is required"
    }

    if (!data.planActualAmount) {
      errors.planActualAmount = "Actual Amount is required"
    } else if (isNaN(data.planActualAmount) || data.planActualAmount < 0) {
      errors.planActualAmount = "Please enter a valid number for Actual Amount"
    }

    if (!data.planOfferAmount) {
      errors.planOfferAmount = "Offer Amount is required"
    } else if (isNaN(data.planOfferAmount) || data.planOfferAmount < 0) {
      errors.planOfferAmount = "Please enter a valid number for Offer Amount"
    }

    if (!data.planDuration) {
      errors.planDuration = "Duration is required"
    } else if (isNaN(data.planDuration.split(" ")[0]) || data.planDuration < 0) {
      errors.planDuration = "Please enter a valid number for Duration"
    }

    return errors
  }

  const fetchData = async () => {
    const url = `${HOST_URL}plan/viewAllPLan`
    const data = {}
    try {
      const response = await ApiPostMethodWithToken(url, data, token)
      if (response.error === false) {
        if (authUser.role == "admin") {
          setPlanList(response.planList)
        } else {
          setPlanList(
            response.planList.filter(plans => plans.addOnPlan !== true)
          )
        }
      } else {
        console.error("Error fetching user list:", response.message)
      }
    } catch (error) {
      console.error("Error fetching user list:", error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleBuyPlan = plan => {
    displayRazorpay(authUser, plan)
  }

  const handleOpen = row => {
    const url = `${HOST_URL}plan/viewSinglePlan`
    const data = {
      planId: row,
    }
    ApiPostMethodWithToken(url, data, token)
      .then(data => {
        if (data.error === false) {
          setPlanData(data)
          setIsModalOpen(true)
        }
      })
      .catch(error => console.log(`Error: ${error}`))
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Row>
            <Col lg={12} className="pt-2 pb-2">
              <div class="page-title-box">
                <h6
                  class="page-title"
                  style={{
                    position: "fixed",
                    marginTop: "-10px",
                    marginLeft: "12px",
                  }}
                >
                  Plan
                </h6>
              </div>
              <Row>
                <Col
                  lg={12}
                  className="pt-2 pb-2 d-flex justify-content-around align-items-center flex-wrap"
                >
                  {planList.map(plan => (
                    <Card
                      className="pricing-box"
                      style={{
                        width: "300px",
                        height: "auto",
                        marginBottom: "15px",
                      }}
                    >
                      <CardBody className="p-4">
                        <Grid lg={4} md={4} xs={12}>
                          <div className="d-flex mt-2">
                            <div className="flex-shrink-0 align-self-center">
                              <i
                                style={{ fontSize: "25px" }}
                                className="mdi mdi-home-city-outline"
                              ></i>
                            </div>
                            <div className="flex-grow-1 ms-auto text-end">
                              <h4>{plan.planName}</h4>
                              {/* <p className="text-muted mb-0">{props.pricing.description}</p> */}
                            </div>
                          </div>
                          <p>
                            <div className="pricing-features mt-3 pt-2">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "16px",
                                }}
                              >
                                <h5 style={{ marginRight: "20px" }}>
                                  Actual Amount :{" "}
                                </h5>
                                <span style={{ fontSize: "15px" }}>
                                  {plan.planActualAmount}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "16px",
                                }}
                              >
                                <h5 style={{ marginRight: "20px" }}>
                                  Offer Amount :{" "}
                                </h5>
                                <span style={{ fontSize: "15px" }}>
                                  {plan.planOfferAmount}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "16px",
                                }}
                              >
                                <h5 style={{ marginRight: "20px" }}>
                                  Duration :{" "}
                                </h5>
                                <span style={{ fontSize: "15px" }}>
                                  {plan.planDuration.split(" ")[0]} Days
                                </span>
                              </div>
                            </div>
                          </p>
                          <div className="text-center mt-3">
                            <h1 className="mb-0">
                              <sup>
                                <small>
                                  <IconButton>
                                    <CurrencyRupeeIcon
                                      style={{ color: "black" }}
                                    />
                                  </IconButton>
                                </small>
                              </sup>
                              {plan.planOfferAmount}/
                              <span className="font-size-16">
                                {plan.planDuration.split(" ")[0]} Days
                              </span>
                            </h1>
                          </div>
                          {user_role !== "user" && (
                            <div className="d-grid mt-5">
                              <Link
                                to="#"
                                className="btn commongradient addbutton btn-block waves-effect waves-light"
                                onClick={() => handleOpen(plan._id)}
                              >
                                Edit Plan
                              </Link>
                            </div>
                          )}
                          {user_role == "user" && (
                            <div className="d-grid mt-5">
                              <Link
                                to="#"
                                className="btn commongradient addbutton btn-block waves-effect waves-light"
                                onClick={() => handleBuyPlan(plan)}
                              >
                                Buy Plan
                              </Link>
                            </div>
                          )}
                        </Grid>
                      </CardBody>
                    </Card>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Modal show={isModalOpen} onHide={toggleModal} centered>
          <div className="plan-modal-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>Edit Plan</h5>
              <IconButton
                onClick={toggleModal}
                style={{ marginRight: "-10px" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Divider />
            <Row>
              <Col lg={6} md={6} xs={12}>
                <TextField
                  label="Plan Name"
                  name="planName"
                  value={formData.planName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.planName ? true : false}
                  helperText={errors.planName}
                />
              </Col>
              <Col lg={6} md={6} xs={12}>
                <TextField
                  label="Plan Actual Amount"
                  name="planActualAmount"
                  type="text"
                  value={formData.planActualAmount}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.planActualAmount ? true : false}
                  helperText={errors.planActualAmount}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} xs={12}>
                <TextField
                  label="Plan Offer Amount"
                  name="planOfferAmount"
                  value={formData.planOfferAmount}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.planOfferAmount ? true : false}
                  helperText={errors.planOfferAmount}
                />
              </Col>
              <Col lg={6} md={6} xs={12}>
                <TextField
                  label="Plan Duration"
                  name="planDuration"
                  value={formData.planDuration.split(" ")[0]}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.planDuration ? true : false}
                  helperText={errors.planDuration}
                />
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <Button
                variant="contained"
                className="commongradient addbutton"
                onClick={toggleModal}
                style={{ padding: "10px 20px" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="commongradient addbutton"
                onClick={handleSubmit}
                style={{ padding: "10px 20px" }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    </div>
  )
}

export default PlanViewTable
