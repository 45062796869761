import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
} from "reactstrap"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import Swal from "sweetalert2"

const DateFilter = ({ show, handleClose, type }) => {
  const [filterDate, setFilterDate] = useState({
    fromDate: "",
    toDate: "",
  })
  const [filterDateError, setFilterDateError] = useState({
    fromDate: "",
    toDate: "",
  })
  const [buttonText, setbuttonText] = useState("Generate Report")
  const handleChange = e => {
    const { name, value } = e.target
    setFilterDate(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }))
    setFilterDateError(prevErrors => ({
      ...prevErrors,
      [name]: "",
    }))
  }

  const handleModalClose = () => {
    setFilterDate({
      fromDate: "",
      toDate: "",
    })
    setFilterDateError({
      fromDate: "",
      toDate: "",
    })
    handleClose()
  }

  // const handleReport = async () => {
  //   try {
  //     const newErrors = {}
  //     if (filterDate.fromDate.trim() === "") {
  //       newErrors.fromDate = "From date is required"
  //     }
  //     if (filterDate.toDate.trim() === "") {
  //       newErrors.toDate = "To date is required"
  //     }
  //     setFilterDateError(newErrors)
  //     if (Object.values(newErrors).every(error => error === "")) {
  //       setbuttonText("Please Wait!..")
  //       const token = JSON.parse(localStorage.getItem("token"))
  //       const url = `${HOST_URL}report/excelReport`
  //       const data = {
  //         ...filterDate,
  //         reportFor: type,
  //       }
  //       await ApiPostMethodWithToken(url, data, token).then(resp => {
  //         if (resp.error === false) {
  //           const downloadLink = document.createElement("a")
  //           downloadLink.href = resp.url
  //           downloadLink.download = "report.xlsx"
  //           document.body.appendChild(downloadLink)
  //           downloadLink.click()
  //           document.body.removeChild(downloadLink)
  //           Swal.fire({
  //             title: `${type} Report!`,
  //             text: resp.message,
  //             icon: "success",
  //           })
  //           setFilterDate({
  //             fromDate: "",
  //             toDate: "",
  //           })
  //           handleClose()
  //           setbuttonText("Generate Report")
  //         }
  //       })
  //     } else {
  //       console.log("newErrors", newErrors)
  //     }
  //   } catch (error) {
  //     console.error("filter error", error)
  //   }
  // }

  const handleReport = async () => {
    try {
      const newErrors = {}
      if (filterDate.fromDate.trim() === "") {
        newErrors.fromDate = "From date is required"
      }
      if (filterDate.toDate.trim() === "") {
        newErrors.toDate = "To date is required"
      }
      setFilterDateError(newErrors)
      if (Object.values(newErrors).every(error => error === "")) {
        setbuttonText("Please Wait!..")
        const token = JSON.parse(localStorage.getItem("token"))
        const url = `${HOST_URL}report/excelReport`
        const data = {
          ...filterDate,
          reportFor: type,
        }
        const response = await ApiPostMethodWithToken(url, data, token)
        if (response.error === false && response.url) {
          window.location.href = response.url
          Swal.fire({
            title: `${type} Report!`,
            text: response.message,
            icon: "success",
          })
          setFilterDate({
            fromDate: "",
            toDate: "",
          })
          handleClose()
          setbuttonText("Generate Report")
        } else {
          Swal.fire({
            title: `${type} Report!`,
            text: response.message,
            icon: "info",
          })
          setFilterDate({
            fromDate: "",
            toDate: "",
          })
          handleClose()
          setbuttonText("Generate Report")
        }
      } else {
        // console.log("newErrors", newErrors)
      }
    } catch (error) {
      console.error("filter error", error)
    }
  }

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleModalClose}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        className="filtermodal dateFilter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dateBody">
          <Row className="mb-3 d-block">
            <label
              htmlFor="txtFirstNameBilling"
              className="col-lg-12 col-form-label requiredfield font-weight-bold"
            >
              From Date
            </label>
            <div className="col-lg-12">
              <Input
                className="form-control"
                name="fromDate"
                type="date"
                id="fromDate"
                value={filterDate.fromDate}
                onChange={handleChange}
              />
              {filterDateError.fromDate && (
                <span className="error">{filterDateError.fromDate}</span>
              )}
            </div>
          </Row>
          <Row className="mb-3 d-block">
            <label
              htmlFor="txtFirstNameBilling"
              className="col-lg-12 col-form-label requiredfield font-weight-bold"
            >
              TO Date
            </label>
            <div className="col-lg-12">
              <Input
                className="form-control"
                name="toDate"
                type="date"
                id="toDate"
                value={filterDate.toDate}
                onChange={handleChange}
                min={filterDate.fromDate}
              />
              {filterDateError.toDate && (
                <span className="error">{filterDateError.toDate}</span>
              )}
            </div>
          </Row>
          <Row
            className="justify-content-between"
            style={{ padding: "0px 12px" }}
          >
            <Button
              className="hoverbtn addbutton addbtn w-auto"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="commongradient addbutton addbtn w-auto"
              onClick={handleReport}
            >
              {buttonText}
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default DateFilter
