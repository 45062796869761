// import { HOST_URL } from "helpers/url_helper"
// import { ApiPostMethodWithToken } from "helpers/withToken_helper"
// import React, { useState } from "react"
// import {
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Form,
//   Input,
//   Row,
//   Label,
// } from "reactstrap"
// import Dropzone from "react-dropzone"

// const ImageUpload = React.forwardRef(
//   (
//     { propertytype, lookingfor, tabname, toggleTabNext, propData, propertyId },
//     ref
//   ) => {
//     // console.log("propData", propData)
//     // const preimage = {
//     //   floorPlan: propData?.photos?.floorPlan || [],
//     //   images: propData?.photos?.images || [],
//     // }
//     const [propimg, setPropimg] = useState({
//       images: propData?.photos?.images || [],
//       floorPlan: propData?.photos?.floorPlan || [],
//     })
//     const [selectedImages, setSelectedImages] = useState({
//       images: [],
//       floorPlan: [],
//     })
//     const [uploadProgress, setUploadProgress] = useState({})
//     var selectedfrom
//     const uploadImage = async (file, category) => {
//       const formData = new FormData()
//       formData.append("images", file)

//       try {
//         const response = await fetch(`${HOST_URL}api/upload`, {
//           method: "POST",
//           body: formData,
//           onUploadProgress: progressEvent => {
//             const progress = Math.round(
//               (progressEvent.loaded / progressEvent.total) * 100
//             )
//             setUploadProgress(prevProgress => ({
//               ...prevProgress,
//               [file.name]: progress,
//             }))
//           },
//         })

//         if (response.ok) {
//           const data = await response.json()
//           setSelectedImages(prevImages => ({
//             ...prevImages,
//             [category]: [...prevImages[category], data.files[0].filename],
//           }))
//           setUploadProgress(prevProgress => {
//             const { [file.name]: removedProgress, ...rest } = prevProgress
//             return rest
//           })
//           selectedfrom = true
//         }
//       } catch (error) {
//         console.error("Error uploading image", error)
//       }
//     }

//     const handleImageUpload = (category, files) => {
//       for (let i = 0; i < files.length; i++) {
//         const file = files[i]

//         setUploadProgress(prevProgress => ({
//           ...prevProgress,
//           [file.name]: 0,
//         }))

//         uploadImage(file, category)
//       }
//     }

//     const handleImageDelete = async (filename, category, imagetype) => {
//       try {
//         const response = await fetch(`${HOST_URL}api/delete/${filename}`, {
//           method: "DELETE",
//         })

//         if (!response.ok) {
//           throw new Error(`Deletion failed with status ${response.status}`)
//         }
//         if (imagetype == "selectedimg") {
//           setSelectedImages(prevImages => ({
//             ...prevImages,
//             [category]: prevImages[category].filter(img => img !== filename),
//           }))
//         } else {
//           setPropimg(prevImages => ({
//             ...prevImages,
//             [category]: prevImages[category].filter(img => img !== filename),
//           }))
//         }
//       } catch (error) {
//         console.error("Error deleting image", error)
//       }
//     }
//     // const renderImageWithControls = (image, category) => (
//     //   <Col
//     //     lg={2}
//     //     key={image}
//     //     style={{ textAlign: "center", margin: "20px" }}
//     //     className="position-relative"
//     //   >
//     //     <img
//     //       src={`${HOST_URL}public/img/propertyimg/${image}`}
//     //       alt="Uploaded"
//     //       width={"100%"}
//     //       height={"100%"}
//     //       style={{ borderRadius: "5px" }}
//     //     />
//     //     <i
//     //       className="mdi mdi-trash-can-outline img_del"
//     //       onClick={() => handleImageDelete(image, category)}
//     //     ></i>
//     //   </Col>
//     // )

//     const renderImageWithControls = (image, category) => (
//       <Col
//         lg={2}
//         key={image}
//         style={{ textAlign: "center", margin: "20px" }}
//         className="position-relative"
//       >
//         <img
//           src={`${HOST_URL}public/img/tempimg/${image}`}
//           alt="Uploaded"
//           width={"100%"}
//           height={"100%"}
//           style={{ borderRadius: "5px" }}
//         />
//         <i
//           className="mdi mdi-trash-can-outline img_del"
//           onClick={() => handleImageDelete(image, category, "selectedimg")}
//         ></i>
//       </Col>
//     )
//     const renderpreimage = (image, category) => (
//       <Col
//         lg={2}
//         key={image}
//         style={{ textAlign: "center", margin: "20px" }}
//         className="position-relative"
//       >
//         <img
//           src={`${HOST_URL}public/img/propertyimg/${image}`}
//           alt="Uploaded"
//           width={"100%"}
//           height={"100%"}
//           style={{ borderRadius: "5px" }}
//         />
//         <i
//           className="mdi mdi-trash-can-outline img_del"
//           onClick={() => handleImageDelete(image, category, "propimg")}
//         ></i>
//       </Col>
//     )

//     const handleImages = async () => {
//       var status
//       const combinedImages = {
//         images: [...propimg.images, ...selectedImages.images],
//         floorPlan: [...propimg.floorPlan, ...selectedImages.floorPlan],
//       }

//       if (
//         Object.keys(combinedImages).every(
//           key => combinedImages[key].length === 0
//         )
//       ) {
//         return { status: false, message: "Upload at least one image!.." }
//       } else {
//         const token = JSON.parse(localStorage.getItem("token"))
//         const user_id = JSON.parse(localStorage.getItem("authUser"))
//         const property_id = JSON.parse(localStorage.getItem("property_id"))
//         const data = {
//           user_id: propData?.user_id || user_id._id,
//           property_id: propertyId || property_id,
//           propertyimg: combinedImages,
//         }
//         const url = `${HOST_URL}upload/uploadfile`
//         var response
//         await ApiPostMethodWithToken(url, data, token).then(resp => {
//           if (resp.error === false) {
//             status = true
//             response = resp
//           } else {
//             status = false
//             response = resp
//           }
//         })
//         return { status: status, message: response.message }
//       }
//     }

//     React.useImperativeHandle(ref, () => ({
//       handleCimgupload: () => handleImages(),
//     }))
//     // console.log("selected images", selectedImages)
//     return (
//       <React.Fragment>
//         <h5>Add Photos</h5>
//         <div className="page-content">
//           <Container fluid={true}>
//             <Row>
//               <Col className="col-12">
//                 <Card>
//                   <CardBody>
//                     <div className="mb-5">
//                       <Form>
//                         <Dropzone
//                           onDrop={acceptedFiles => {
//                             handleImageUpload("images", acceptedFiles)
//                           }}
//                         >
//                           {({ getRootProps, getInputProps }) => (
//                             <div className="dropzone uploadgradient">
//                               <div
//                                 className="dz-message needsclick"
//                                 {...getRootProps()}
//                               >
//                                 <input {...getInputProps()} />
//                                 <div className="mb-3">
//                                   <i
//                                     className="mdi mdi-image-multiple-outline"
//                                     style={{ fontSize: "50px", color: "grey" }}
//                                   ></i>
//                                 </div>
//                                 <h4 style={{ color: "rgba(0, 102, 171, 1)" }}>
//                                   Add Photo.
//                                 </h4>
//                                 <h6 style={{ color: "black" }}>
//                                   Drag 'n' drop some files here, or click to
//                                   select files
//                                 </h6>
//                                 <h6 style={{ color: "black" }}>
//                                   Upload 50 photos of max size 10 mb in format
//                                   jpg, jpeg & png.
//                                 </h6>
//                               </div>
//                             </div>
//                           )}
//                         </Dropzone>
//                         <Row className="mt-4">
//                           <Label>Floor PLan</Label>
//                           <Input
//                             type="file"
//                             name="image"
//                             id="image"
//                             onChange={e =>
//                               handleImageUpload("floorPlan", e.target.files)
//                             }
//                           />
//                         </Row>
//                         <div
//                           className="dropzone-previews mt-3"
//                           id="file-previews"
//                         >
//                           <Col
//                             lg={12}
//                             className="d-flex flex-wrap"
//                             style={{ maxHeight: "200px", overflow: "auto" }}
//                           >
//                             {propimg.images &&
//                               propimg.images.map(image =>
//                                 renderpreimage(image, "images")
//                               )}
//                             {propimg.floorPlan &&
//                               propimg.floorPlan.map(image =>
//                                 renderpreimage(image, "floorPlan")
//                               )}
//                             {selectedImages.images &&
//                               selectedImages.images.map(image =>
//                                 renderImageWithControls(image, "images")
//                               )}
//                             {selectedImages.floorPlan &&
//                               selectedImages.floorPlan.map(image =>
//                                 renderImageWithControls(image, "floorPlan")
//                               )}
//                           </Col>
//                           {Object.entries(uploadProgress).map(
//                             ([fileName, progress]) => (
//                               <Col
//                                 key={fileName}
//                                 style={{ textAlign: "center", margin: "20px" }}
//                               >
//                                 <p>
//                                   Upload Progress for {fileName}: {progress}%
//                                 </p>
//                               </Col>
//                             )
//                           )}
//                         </div>
//                         {/* <div className="text-center mt-4">
//                           <button
//                             type="button"
//                             className="btn btn-primary waves-effect waves-light commongradient"
//                             onClick={handleImages}
//                           >
//                             Upload Photos
//                           </button>
//                         </div> */}
//                       </Form>
//                     </div>
//                   </CardBody>
//                 </Card>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//       </React.Fragment>
//     )
//   }
// )

// export default ImageUpload

import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Row,
  Label,
} from "reactstrap"
import Dropzone from "react-dropzone"
import temp from "../../../assets/images/logo-sm.png"

const ImageUpload = React.forwardRef(
  (
    { propertytype, lookingfor, tabname, toggleTabNext, propData, propertyId },
    ref
  ) => {
    // console.log("propData", propData)
    // const preimage = {
    //   floorPlan: propData?.photos?.floorPlan || [],
    //   images: propData?.photos?.images || [],
    // }
    const [propimg, setPropimg] = useState({
      images: propData?.photos?.images || [],
      floorPlan: propData?.photos?.floorPlan || [],
    })
    const [selectedImages, setSelectedImages] = useState({
      images: [],
      floorPlan: [],
    })
    const [uploadProgress, setUploadProgress] = useState({})

    var selectedfrom
    const uploadImage = async (file, category) => {
      const formData = new FormData()
      formData.append("images", file)

      try {
        const response = await fetch(`${HOST_URL}api/upload`, {
          method: "POST",
          body: formData,
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            )
            setUploadProgress(prevProgress => ({
              ...prevProgress,
              [file.name]: progress,
            }))
          },
        })

        const data = await response.json()
        if (data.error === false) {
          setSelectedImages(prevImages => ({
            ...prevImages,
            [category]: [...prevImages[category], data.files[0].filename],
          }))
          setUploadProgress(prevProgress => {
            const { [file.name]: removedProgress, ...rest } = prevProgress
            return rest
          })
          selectedfrom = true
        }
      } catch (error) {
        console.error("Error uploading image", error)
      }
    }


    useEffect(() => {
      const intervalId = setInterval(() => {
        var images = document.getElementsByClassName("uploadedImg")

        var srcArray = []

        for (var i = 0; i < images.length; i++) {
          srcArray.push(images[i].src)
          images[i].src = images[i].src
        }

        // console.log(srcArray)
      }, 5000)

      return () => clearInterval(intervalId)
    }, [])

    const [isImagesUploaded, setIsImagesUploaded] = useState(false);

    const handleImageUpload = async (category, files) => {
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        setUploadProgress(prevProgress => ({
          ...prevProgress,
          [file.name]: 0,
        }));

        const uploadPromise = uploadImage(file, category);
        uploadPromises.push(uploadPromise);

        uploadPromise.then(() => {
          setUploadProgress(prevProgress => ({
            ...prevProgress,
            [file.name]: 100,
          }));
        });
      }

      try {
        await Promise.all(uploadPromises);
        setIsImagesUploaded(true);
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    };

    console.log("isImagesUploaded:", isImagesUploaded); // Add a log to check isImagesUploaded value

    const handleImageDelete = async (filename, category, imagetype) => {
      try {
        const response = await fetch(`${HOST_URL}api/delete/${filename}`, {
          method: "DELETE",
        })

        if (!response.ok) {
          throw new Error(`Deletion failed with status ${response.status}`)
        }
        if (imagetype == "selectedimg") {
          setSelectedImages(prevImages => ({
            ...prevImages,
            [category]: prevImages[category].filter(img => img !== filename),
          }))
        } else {
          setPropimg(prevImages => ({
            ...prevImages,
            [category]: prevImages[category].filter(img => img !== filename),
          }))
        }
      } catch (error) {
        console.error("Error deleting image", error)
      }
    }
    // const renderImageWithControls = (image, category) => (
    //   <Col
    //     lg={2}
    //     key={image}
    //     style={{ textAlign: "center", margin: "20px" }}
    //     className="position-relative"
    //   >
    //     <img
    //       src={`${HOST_URL}public/img/propertyimg/${image}`}
    //       alt="Uploaded"
    //       width={"100%"}
    //       height={"100%"}
    //       style={{ borderRadius: "5px" }}
    //     />
    //     <i
    //       className="mdi mdi-trash-can-outline img_del"
    //       onClick={() => handleImageDelete(image, category)}
    //     ></i>
    //   </Col>
    // )

    const renderImageWithControls = (image, category) => (
      <Col
        lg={2}
        key={image}
        style={{ textAlign: "center", margin: "20px" }}
        className="position-relative"
      >
        <img
          src={`${HOST_URL}public/img/tempimg/${image}`}
          alt="Uploaded"
          width={"100%"}
          height={"100%"}
          style={{ borderRadius: "5px" }}
          className="uploadedImg"
        />
        <i
          className="mdi mdi-trash-can-outline img_del"
          onClick={() => handleImageDelete(image, category, "selectedimg")}
        ></i>
      </Col>
    )
    const renderpreimage = (image, category) => (
      <Col
        lg={2}
        key={image}
        style={{ textAlign: "center", margin: "20px" }}
        className="position-relative"
      >
        <img
          src={`${HOST_URL}public/img/propertyimg/${image}`}
          alt="Uploaded"
          width={"100%"}
          height={"100%"}
          style={{ borderRadius: "5px" }}
        />
        <i
          className="mdi mdi-trash-can-outline img_del"
          onClick={() => handleImageDelete(image, category, "propimg")}
        ></i>
      </Col>
    )

    const handleImages = async () => {
      var status
      const combinedImages = {
        images: [...propimg.images, ...selectedImages.images],
        floorPlan: [...propimg.floorPlan, ...selectedImages.floorPlan],
      }

      if (
        Object.keys(combinedImages).every(
          key => combinedImages[key].length === 0
        )
      ) {
        alert("image is not uploaded!..")
      } else {
        // const extractFilenames = array => array.map(item => item)

        // const newObject = {
        //   images: extractFilenames(selectedImages.images),
        //   floorPlan: extractFilenames(selectedImages.floorPlan),
        // }
        const token = JSON.parse(localStorage.getItem("token"))
        const user_id = JSON.parse(localStorage.getItem("authUser"))
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        const data = {
          user_id: propData?.user_id || user_id._id,
          property_id: propertyId || property_id,
          propertyimg: combinedImages,
        }
        //   const data = {
        //     user_id: user_id._id,
        //     property_id: propertyId || property_id,
        //     propertyimg: userImages,
        //   }
        const url = `${HOST_URL}upload/uploadfile`
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            alert(resp.message)
            status = true
            //   console.log(resp)
          } else {
            alert(resp.message)
            status = false
          }
        })
        return status
      }
    }

    React.useImperativeHandle(ref, () => ({
      handleCimgupload: () => handleImages(),
    }))
    // console.log("selected images", selectedImages)
    return (
      <React.Fragment>
        <h5>Add Photos</h5>
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div className="mb-5">
                      <Form>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleImageUpload("images", acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone uploadgradient">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i
                                    className="mdi mdi-image-multiple-outline"
                                    style={{ fontSize: "50px", color: "grey" }}
                                  ></i>
                                </div>
                                <h4 style={{ color: "rgba(0, 102, 171, 1)" }}>
                                  Add Photo.
                                </h4>
                                <h6 style={{ color: "black" }}>
                                  Drag 'n' drop some files here, or click to
                                  select files
                                </h6>
                                <h6 style={{ color: "black" }}>
                                  Upload 50 photos of max size 10 mb in format
                                  jpg, jpeg & png.
                                </h6>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <Row className="mt-4">
                          <Label>Floor PLan</Label>
                          <Input
                            type="file"
                            name="image"
                            id="image"
                            onChange={e =>
                              handleImageUpload("floorPlan", e.target.files)
                            }
                          />
                        </Row>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          <Col
                            lg={12}
                            className="d-flex flex-wrap"
                            style={{ maxHeight: "200px", overflow: "auto" }}
                          >
                            {propimg.images &&
                              propimg.images.map(image =>
                                renderpreimage(image, "images")
                              )}
                            {propimg.floorPlan &&
                              propimg.floorPlan.map(image =>
                                renderpreimage(image, "floorPlan")
                              )}
                            {selectedImages.images &&
                              selectedImages.images.map(image =>
                                renderImageWithControls(image, "images")
                              )}
                            {selectedImages.floorPlan &&
                              selectedImages.floorPlan.map(image =>
                                renderImageWithControls(image, "floorPlan")
                              )}
                          </Col>
                          {Object.entries(uploadProgress).map(
                            ([fileName, progress]) => (
                              <Col
                                key={fileName}
                                style={{ textAlign: "center", margin: "20px" }}
                              >
                                <p>
                                  Upload Progress for {fileName}: {progress}%
                                </p>
                              </Col>
                            )
                          )}
                        </div>
                        {/* <div className="text-center mt-4">
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light commongradient"
                            onClick={handleImages}
                          >
                            Upload Photos
                          </button>
                        </div> */}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          {isImagesUploaded && (
            <div className="alert alert-success mt-3" role="alert">
              Images have been uploaded successfully!
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
)

export default ImageUpload
