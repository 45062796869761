const subscriptionEndDate = new Date("2024-12-30");
const isDateWithinSubscriptionPeriod = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to 00:00:00:00
  return today <= subscriptionEndDate;
};

const getRemainingDays = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const timeDifference = subscriptionEndDate - today;
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysRemaining;
};

export function ApiPostMethod(url, data, method = "POST") {
  if (!isDateWithinSubscriptionPeriod()) {
    localStorage.clear();
    return Promise.resolve({ error: true, message: "Due date has passed, Please renew.", isLogin:true });
  }

  return fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then(resp => resp.json())
    .then(function (result) {
      const data = {...result, remainingDays: getRemainingDays() }
      return data;
    });
}

export function ApiGetMethod(url, method = "GET") {
  if (!isDateWithinSubscriptionPeriod()) {
    localStorage.clear();
    return Promise.resolve({ error: true, message: "Due date has passed, Please renew.", isLogin:true });
  }

  return fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json" },
  })
    .then(resp => resp.json())
    .then(function (result) {
      return result;
    });
}
