import React, { useEffect, useState } from "react"
import classnames from "classnames"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Nav,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import "../Tables/datatables.scss"
import { Link, useNavigate } from "react-router-dom"
import { HOST_URL, NEXT_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import MUIDataTable from "mui-datatables"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import image from "../../assets/images/loader.gif"
import Select from "react-select"
import CloseIcon from "@mui/icons-material/Close"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Grid, IconButton } from "@mui/material"
import DeleteModal from "./deletemodal"
import { TableCell, TableRow } from "@material-ui/core"
import DateFilter from "pages/reportFilter/dateFilter"

const PropertyList = () => {
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem("token"))
  const user_id = JSON.parse(localStorage.getItem("authUser"))
  const [customActiveTab, setCustomActiveTab] = useState("1")
  const [allpropertyPending, setAllPropertyPending] = useState([])
  const [allpropertyApproved, setAllPropertyApproved] = useState([])
  const [allpropertyRejected, setAllPropertyRejected] = useState([])
  const [allpropertyLead, setAllPropertyLead] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [ttop, setttop] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const [selectoptions, setSelectoptions] = useState([])
  const [selectedUserId, setSelectedUserId] = useState("")
  const [selectedUserIderr, setSelectedUserIderr] = useState("")
  const [note, setNote] = useState("")
  const [showFilterModel, setShowFilterModel] = useState(false)
  const handleOpen = () => {
    setShowFilterModel(true)
  }
  const hideModel = () => {
    setShowFilterModel(false)
  }

  const handleSelection = async () => {
    setShowModal(true)
    const token = JSON.parse(localStorage.getItem("token"))
    const url = `${HOST_URL}user/viewAllUser`
    const data = {}

    await ApiPostMethodWithToken(url, data, token).then(resp => {
      if (resp.error === false) {
        const formattedData = resp.allUser.map(user => ({
          label: user.name,
          value: user._id,
        }))
        setSelectoptions(formattedData)
      } else if (resp.error === true && resp.message === "Invalid Token") {
        toast.info("Your session has expired, Login to continue!.", {
          position: "top-right",
          theme: "light",
        })
        handleCloseModal()
      }
    })
  }

  function textareachange(event) {
    setNote(event.target.value)
  }

  function handleSelectGroup(selectedGroup) {
    setSelectedUserId(selectedGroup.value)
    setSelectedUserIderr("")
  }
  const handleroute = async () => {
    if (selectedUserId.trim() == "") {
      setSelectedUserIderr("Please select one user to continue!.")
    } else {
      const url = `${HOST_URL}user/userActivePlan`
      const data = {
        user_id: selectedUserId,
      }
      await ApiPostMethodWithToken(url, data, token).then(resp => {
        if (resp.error === false) {
          localStorage.setItem("user_Id", selectedUserId)
          localStorage.setItem("note", note)
          localStorage.setItem("activeTab", 1)
          localStorage.removeItem("tabStatus")
          localStorage.removeItem("property_id")
          localStorage.removeItem("totalBeds")
          localStorage.removeItem("PropertyType2")
          navigate("/add-property")
          setSelectedUserId("")
        } else {
          toast.error(resp.message, {
            position: "top-right",
            theme: "light",
          })
        }
      })
    }
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  const formatDate = createdAt => {
    const userCreatedAt = new Date(createdAt)
    const formattedDate = userCreatedAt.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })

    return formattedDate
  }

  const fetchProperty = () => {
    setIsLoading(true)
    const url = `${HOST_URL}property/viewAllProperty`
    const data = {}

    ApiPostMethodWithToken(url, data, token)
      .then(data => {
        if (data.error === false) {
          const pendingProperties = data.propertyList
            .filter(property => property.status === "Pending")
            .map(property => {
              const listingStatus =
                property.slug && property.slug.trim() !== ""
                  ? "Edited/Review"
                  : "Fresh"

              return { ...property, ListingStatus: listingStatus }
            })
          const approvedProperties = data.propertyList.filter(
            property => property.status === "Approved"
          )
          const rejectedProperties = data.propertyList.filter(
            property => property.status === "Reject"
          )
          setAllPropertyPending(pendingProperties)
          setAllPropertyApproved(approvedProperties)
          setAllPropertyRejected(rejectedProperties)
          setIsLoading(false)

          // console.log("view all property response---", data)
        } else {
          setIsLoading(false)
        }
      })
      .catch(error => console.log(`Error: ${error}`))
  }

  useEffect(() => {
    fetchProperty()
  }, [])

  const columns = [
    {
      name: "sNo",
      label: "SN",
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return tableMeta.rowIndex + 1
        // },
      },
    },
    {
      name: "property_id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "propExpired",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "slug",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "status",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "isSpotlight",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      label: "Username",
      name: "username",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (user_id.role == "admin" && tableMeta.rowData[5] == true) {
            return (
              <span>
                {value}
                <br />
                <h6
                  style={{ fontWeight: 100, fontSize: "10px", margin: "0px" }}
                >
                  Spotlight Property
                </h6>
              </span>
            )
          } else {
            return value
          }
        },
      },
    },
    {
      label: "Property Type",
      name: "propertyType1",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Looking To",
      name: "lookingTo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Building Type",
      name: "propertyType2",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "LISTING STATUS",
      name: "ListingStatus",
      options: {
        filter: false,
        sort: false,
        display: customActiveTab === "1" && allpropertyPending.length > 0,
        customBodyRender: (value, tableMeta) => {
          return <span>{value}</span>
        },
      },
    },
    {
      label: "Price",
      name: "Price",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "LEAD",
      name: "leadCount",
      options: {
        filter: false,
        sort: false,
        display: customActiveTab === "2" && allpropertyApproved.length > 0,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleLeadClick(tableMeta)}
            >
              {value}
            </span>
          )
        },
      },
    },
    {
      label: "REASON",
      name: "rejectReason",
      options: {
        filter: false,
        sort: false,
        display: customActiveTab === "3",
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="reject-reason text-center">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{value}</Tooltip>}
              >
                <i className="mdi mdi-information-outline" id="TooltipTop"></i>
              </OverlayTrigger>
            </div>
          )
        },
      },
    },
    {
      label: "Locality",
      name: "searchCity",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <React.Fragment>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-top">View</Tooltip>}
              >
                <button
                  className="actionview me-1"
                  onClick={() => handleView(tableMeta)}
                >
                  <i className="mdi mdi-eye-outline"></i>
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}
              >
                <button
                  className="actionview me-1"
                  onClick={() => handleEdit(tableMeta)}
                >
                  <i className="mdi mdi-clipboard-edit-outline"></i>
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
              >
                <button
                  className="actionview"
                  onClick={() => showDeleteModal(tableMeta)}
                >
                  <i className="mdi mdi-delete-outline"></i>
                </button>
              </OverlayTrigger>
            </React.Fragment>
          )
        },
      },
    },
  ]

  const handleView = row => {
    var url
    if (row.rowData[3] === "") {
      url = `${NEXT_URL}property?${row.rowData[1]}`
    } else {
      url = `${NEXT_URL}property?${row.rowData[3]}`
    }
    window.open(url, "_blank")
    // console.log("View clicked for row:", row)
  }

  const handleEdit = row => {
    navigate(`/listing-status?property_id=${row.rowData[1]}`)
    // console.log("View clicked for row:", row)
  }

  const handleLeadClick = async row => {
    // console.log("Lead clicked for ID:", row.rowData[1])
    const url = `${HOST_URL}lead/viewproplead`
    const data = {
      property_id: row.rowData[1],
    }
    await ApiPostMethodWithToken(url, data, token).then(resp => {
      // console.log(resp)
      setAllPropertyLead(resp.leadsDate)
      toggleModal()
    })
  }

  const options = {
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        displayRows: "Of",
      },
    },
    selectableRows: "none",
    responsive: "standard",
    download: false,
    viewColumns: false,
    filter: true,
    print: true,
    customRowRender: (data, dataIndex, rowIndex) => {
      // console.log("data", data)
      const filteredData = data.filter(
        (_, index) => columns[index].options.display !== false
      )
      const propExpired = data[2]
      const rowStyle = propExpired === true ? { backgroundColor: "red" } : {}
      return (
        <TableRow style={rowStyle}>
          {filteredData.map((cellData, cellIndex) => (
            <TableCell key={cellIndex} style={{ padding: "10px" }}>
              {cellData}
            </TableCell>
          ))}
        </TableRow>
      )
    },
  }

  const [showModel, setShowModel] = useState(false)
  const [deletePropId, setDeletePropId] = useState("")

  const showDeleteModal = row => {
    setDeletePropId(row.rowData[1])
    setShowModel(true)
  }

  const hideDeleteModel = () => {
    setShowModel(false)
  }

  return (
    <React.Fragment>
      <div className="page-content property-list">
        {isLoading ? (
          <Row className="loaderClass">
            <img src={image} alt="" style={{ width: "100px" }} />
          </Row>
        ) : (
          <Row>
            <Col lg={12} className="pe-0 pt-2 pb-2">
              <Card className="border-right-top">
                <CardBody>
                  <Grid
                    className="page-title"
                    container
                    display="flex"
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <h5>Property List</h5>
                    </Grid>
                    <Grid item xs={6} className="text-end">
                      <Button
                        className="commongradient addbutton addbtn"
                        onClick={handleSelection}
                      >
                        Add Property
                      </Button>
                      {user_id.role == "admin" && (
                        <Link
                          onClick={handleOpen}
                          style={{ padding: "8px 30px" }}
                          className="commongradient addbutton addbtn btn btn-secondary ms-2"
                        >
                          Lead Report
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <Nav
                            tabs
                            className="nav-tabs-custom"
                            style={{ border: "none" }}
                          >
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                  toggleCustom("1")
                                }}
                              >
                                <span className="d-xs-block d-sm-block tabsProp">
                                  Pending
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                  toggleCustom("2")
                                }}
                              >
                                <span className="d-xs-block d-sm-block tabs">
                                  Approved
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: customActiveTab === "3",
                                })}
                                onClick={() => {
                                  toggleCustom("3")
                                }}
                              >
                                <span className="d-xs-block d-sm-block tabs">
                                  Rejected
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent
                            activeTab={customActiveTab}
                            className="p-0 text-muted"
                          >
                            <TabPane tabId="1">
                              <Row>
                                <Col sm="12" className="p-1 ps-0 pe-0">
                                  <MUIDataTable
                                    data={allpropertyPending}
                                    columns={columns}
                                    options={options}
                                    className="left-align-table"
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="2">
                              <Row>
                                <Col sm="12" className="p-1 ps-0 pe-0">
                                  <MUIDataTable
                                    data={allpropertyApproved}
                                    columns={columns}
                                    options={options}
                                    className="left-align-table"
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="3">
                              <Row>
                                <Col sm="12" className="p-1 ps-0 pe-0">
                                  <MUIDataTable
                                    data={allpropertyRejected}
                                    columns={columns}
                                    options={options}
                                    className="left-align-table"
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
      <Modal show={isModalOpen} onHide={toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Lead Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup style={{ maxHeight: "300px", overflow: "auto" }}>
            {allpropertyLead ? (
              allpropertyLead.map((user, i) => (
                <React.Fragment>
                  <ListGroupItem key={i} className="leadlist">
                    <Col lg={12} className="d-flex align-items-center">
                      <Col lg={1}>
                        <i
                          className="mdi mdi-account-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Col>
                      <Col lg={7} className="ms-2">
                        <h6>{user.userName.toUpperCase()}</h6>
                        <h6 className="m-0">{user.mobNo}</h6>
                      </Col>
                      <Col lg={4}>
                        <h6>Date:</h6>
                        <h6 className="m-0">{formatDate(user.createdAt)}</h6>
                      </Col>
                    </Col>
                  </ListGroupItem>
                </React.Fragment>
              ))
            ) : (
              <h6>Leads not generated</h6>
            )}
          </ListGroup>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <div style={{ padding: 10 }}>
          <div
            className="modal-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5>Select User for property!.</h5>
            <IconButton
              onClick={handleCloseModal}
              style={{ marginRight: "-10px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body">
            <Row>
              <Col md={12} className="text-start">
                <Row className="mb-3 d-block">
                  <div className="col-lg-12">
                    <label htmlFor="selectUser">User List</label>
                    <Select
                      id="selectUser"
                      name="selectUser"
                      // defaultValue={[searchCity]}
                      options={selectoptions && selectoptions}
                      classNamePrefix="select2-selection"
                      onChange={selectedValue => {
                        handleSelectGroup(selectedValue)
                      }}
                    />
                    {selectedUserIderr && (
                      <span className="error">{selectedUserIderr}</span>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="text-end">
                <Row className="mb-3 d-block">
                  <div className="col-lg-12">
                    <Input
                      type="textarea"
                      id="textarea"
                      className="btnNormal"
                      name="note"
                      onChange={e => {
                        textareachange(e)
                      }}
                      maxLength="2000"
                      rows="3"
                      placeholder="Please enter the note."
                      value={note}
                    />
                    {/* <div className="d-flex justify-content-between">
                                <span className="error">
                                  {rejectReasonError && rejectReasonError}
                                </span>
                                <span className="badgecount d-block badge-success text-end">
                                  {" "}
                                  {textcount} / 2000{" "}
                                </span>
                              </div> */}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-between">
              <Col lg={6} md={6} sm={6} xs={6} className="text-start">
                <Button
                  className="commongradient addbutton"
                  onClick={() => {
                    localStorage.removeItem("user_Id")
                    navigate("/users?adduser=true")
                  }}
                >
                  Add User
                </Button>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="text-end">
                <Button
                  className="commongradient addbutton"
                  onClick={handleroute}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <DeleteModal
        show={showModel}
        handleClose={hideDeleteModel}
        property_Id={deletePropId}
        ontoastclose={fetchProperty}
      />
      <DateFilter
        show={showFilterModel}
        handleClose={hideModel}
        type={"Lead"}
      />
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default PropertyList
