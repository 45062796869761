import {
  Breadcrumbs,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { Button, TextField } from "@mui/material"
import { Modal } from "react-bootstrap"
import Swal from "sweetalert2"
import WithdrawRequest from "pages/WithdrawRequest/WithdrawRequest"
import { TableCell, Typography } from "@material-ui/core"
import displayRazorpay from "helpers/payment"
import AddUserDetail from "./AddUserDetail"
import tempimg1 from "../../assets/images/tempimg1.jpeg"
import tempimg2 from "../../assets/images/tempimg2.jpeg"

const Wallet = ({ withdrawList }) => {
  const [userData, setUserData] = useState({})
  const [isRedemptionDone, setIsRedemptionDone] = useState(false)
  const [isRedeem2Clicked, setIsRedeem2Clicked] = useState(false)
  const token = JSON.parse(localStorage.getItem("token"))
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const location = useLocation()
  const logintoast = new URLSearchParams(location.search).get("user_id")
  const [errors, setErrors] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [bankDetailsModalOpen, setBankDetailsModalOpen] = useState(false)
  const [selectedBankDetails, setSelectedBankDetails] = useState({})
  const [withdrawListType, setWithdrawListType] = useState([])
  const [open, setOpen] = useState(false)

  const [formData, setFormData] = useState({
    AccHoldername: "",
    AccNo: "",
    IfscCode: "",
    bankName: "",
    walletPoints: "",
  })

  useEffect(() => {
    setFormData({
      user_id: logintoast,
      AccHoldername: "",
      AccNo: "",
      IfscCode: "",
      bankName: "",
      walletPoints: "",
    })
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const toggleModal = planAmount => {
    setFormData(prevState => ({
      ...prevState,
      walletPoints: planAmount,
    }))
    setIsModalOpen(!isModalOpen)
  }

  const toggleModal2 = withdrawType => {
    if (withdrawType == "Basic") {
      setWithdrawListType(userData?.basicWithdrawList)
    } else if (withdrawType == "Silver") {
      setWithdrawListType(userData?.silverWithdrawList)
    }
    setIsModalOpen2(!isModalOpen2)
  }

  const openBankDetailsModal = bankDetails => {
    setSelectedBankDetails(bankDetails)
    setBankDetailsModalOpen(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const handleSubmit = () => {
    const validationErrors = validateForm(formData)
    if (Object.keys(validationErrors).length === 0) {
      const url = `${HOST_URL}withdraw/addWithdraw`
      const data = formData
      ApiPostMethodWithToken(url, data, token)
        .then(data => {
          if (data.error === false) {
            toggleModal()
            Swal.fire({
              icon: "success",
              title: "Withdra Request",
              text: "Your withdraw request has bees submitted, kindly wait until Admin approve it!",
            })
            setIsRedemptionDone(true)
          }
        })
        .catch(error => console.log(`Error: ${error}`))
    } else {
      // Form is invalid, set validation errors
      setErrors(validationErrors)
      Swal.fire({
        icon: "info",
        title: "Redemption already done",
        text: "You have already redeemed your points.",
        showConfirmButton: false,
        timer: 2000,
      })
    }
  }

  const validateForm = data => {
    let errors = {}

    if (!data.AccHoldername.trim()) {
      errors.AccHoldername = "Account Holder Name is required"
    }

    if (!data.AccNo.trim()) {
      errors.AccNo = "Account Number is required"
    } else if (!/^\d+$/.test(data.AccNo.trim())) {
      errors.AccNo = "Account Number must contain only numbers"
    }

    if (!data.IfscCode.trim()) {
      errors.IfscCode = "IFSC Code is required"
    }

    if (!data.bankName.trim()) {
      errors.bankName = "Bank Name is required"
    }

    return errors
  }

  useEffect(() => {
    const url = `${HOST_URL}user/viewSingleUser`
    const data = {
      user_id: logintoast,
    }
    ApiPostMethodWithToken(url, data, token)
      .then(data => {
        if (data.error === false) {
          setUserData(data.userDetails)
        }
      })
      .catch(error => console.log(`Error: ${error}`))
  }, [])

  const handleDate = (date) => {
    const createdAt = new Date(date);
    const dd = String(createdAt.getDate()).padStart(2, '0');
    const mm = String(createdAt.getMonth() + 1).padStart(2, '0');
    const yyyy = createdAt.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div class="page-title-box">
            <h4 class="font-size-18">User's Profile</h4>
          </div>

          <Row>
            <Col xl={4} md={5} xs={12}>
              <div className="user-sidebar">
                <Card>
                  <CardBody>
                    <div className="mt-n4 position-relative">
                      <div className="text-center">
                        <div className="avatar-lg" style={{ margin: "auto" }}>
                          {/* <span className="avatar-title rounded-circle bg-primary text-white font-size-24">
                            {userData?.singleuser?.name.charAt(0).toUpperCase()}
                          </span> */}
                          {userData?.singleuser?.profileImage ? (
                            <img
                              src={`${HOST_URL}public/img/userimg/${userData?.singleuser?.profileImage}`}
                              alt=""
                              width={"100%"}
                              style={{
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          ) : userData?.singleuser?.gender == "Male" ? (
                            <img src={tempimg1} alt="" width={"100%"} />
                          ) : (
                            userData?.singleuser?.gender == "Female" && (
                              <img src={tempimg2} alt="" width={"100%"} />
                            )
                          )}
                        </div>

                        <div className="mt-3">
                          <h5 className="">{userData?.singleuser?.name}</h5>
                          <p>{userData?.singleuser?.email}</p>
                          <p>{userData?.singleuser?.mobno}</p>
                          {/* 
                                                    <div className="mt-4">
                                                        <Link to="#" className="btn btn-primary waves-effect waves-light btn-sm me-1">Send Message</Link>
                                                        <Link to="#" className="btn btn-primary waves-effect waves-light btn-sm">Email</Link>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="p-3 mt-3">
                      <Row className="text-center mb-4">
                        <Col className="border-end">
                          <div className="p-1">
                            {Array.isArray(userData?.propertydetails) && (
                              <>
                                <h5 className="mb-1">
                                  {userData?.propertydetails.length}
                                </h5>
                                <p className="text-muted mb-0">Property</p>
                              </>
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="p-1">
                            {Array.isArray(userData?.refereduser) && (
                              <>
                                <h5 className="mb-1">
                                  {userData?.refereduser.length}
                                </h5>
                                <p className="text-muted mb-0">Affiliate</p>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Button
                        onClick={() => handleOpen("Edit User")}
                        style={{ width: "100%", margin: "auto" }}
                        variant="outlined"
                      >
                        Edit Profile
                      </Button>
                    </div>
                  </CardBody>
                </Card>
                {/* <Card>
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs">
                          <div className="avatar-title rounded-circle bg-primary bg-gradient fs-4">
                            <i className="mdi mdi-chart-pie"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-1">Points</p>
                        <h5 className="mb-0">{userData?.walletPoints}</h5>
                      </div>

                      <div className="flex-shrink-0 align-self-end ms-2">
                        <div className="badge rounded-pill font-size-13 badge-soft-success">
                          {userData?.walletPoints === 0 ? (
                            // Condition 1: No points available
                            <Button variant="" disabled>
                              <small style={{ margin: "auto" }}>Redeem</small>
                            </Button>
                          ) : userData?.withdrawpoints >= 600 ? (
                            // Condition 2: Wallet points are less than 600 or withdraw points are greater than or equal to 600
                            <Button onClick={toggleModal} variant="">
                              <small style={{ margin: "auto" }}>Redeem</small>
                            </Button>
                          ) : (
                            // Condition 3: Other conditions
                            <Button onClick={toggleModal} variant="" disabled>
                              <small style={{ margin: "auto" }}>Redeem</small>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs">
                          <div className="avatar-title rounded-circle bg-primary bg-gradient fs-4">
                            <i className="mdi mdi-shopping"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-1">Withdraw</p>
                        <h5 className="mb-0">
                          &#x20B9;{userData?.withdrawpoints}
                        </h5>
                      </div>
                      <div className="flex-shrink-0 align-self-end ms-2">
                        <div className="badge rounded-pill font-size-13 badge-soft-danger">
                          <Button onClick={toggleModal2} variant="">
                            <small style={{ margin: "auto" }}>View</small>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card> */}
                <Card>
                  <CardBody>
                    <div className="p-3 mt-3">
                      <Row className="text-center">
                        <Col xs={6} className="border-end ">
                          <div className="p-1 sideBorder">
                            <h5 className="mb-1">
                              {userData?.BasicWalletPoints}
                            </h5>
                            <p className="text-muted mb-0">Points</p>
                            <div className="badge rounded-pill font-size-13 badge-soft-success mt-2 sideButton">
                              {userData?.activeplan?.planAmount == 600 &&
                              userData?.basicWithdraw === 0 &&
                              userData?.BasicWalletPoints >= 600 ? (
                                <Button
                                  onClick={() => toggleModal(600)}
                                  variant=""
                                >
                                  <small>Redeem</small>
                                </Button>
                              ) : userData?.activeplan?.planAmount >= 6000 &&
                                userData?.BasicWalletPoints >= 600 ? (
                                <Button
                                  onClick={() =>
                                    toggleModal(userData?.BasicWalletPoints)
                                  }
                                  variant=""
                                >
                                  <small>Redeem</small>
                                </Button>
                              ) : (
                                <Button
                                  onClick={toggleModal}
                                  variant=""
                                  disabled
                                >
                                  <small>Redeem</small>
                                </Button>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="p-1">
                            <h5 className="mb-1">₹{userData?.basicWithdraw}</h5>
                            <p className="text-muted mb-0">Withdraw</p>
                            <div className="badge rounded-pill font-size-13 badge-soft-danger mt-2">
                              <Button
                                onClick={() => toggleModal2("Basic")}
                                variant=""
                              >
                                <small>View</small>
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                {(userData?.activeplan?.planAmount === 6000 ||
                  userData?.activeplan?.planAmount === 6600) && (
                  <Card>
                    <CardBody>
                      <div className="p-3 mt-3">
                        <Row className="text-center">
                          <Col xs={6} className="border-end">
                            <div className="p-1 sideBorder">
                              <h5 className="mb-1">
                                {userData?.SilverWalletPoints}
                              </h5>
                              <p className="text-muted mb-0">Points</p>
                              <div className="badge rounded-pill font-size-13 badge-soft-success mt-2 sideButton">
                                {userData?.activeplan?.planAmount >= 6000 &&
                                userData?.silverWithdraw === 0 &&
                                userData?.SilverWalletPoints >= 6000 ? (
                                  <Button
                                    onClick={() => toggleModal(6000)}
                                    variant=""
                                  >
                                    <small>Redeem</small>
                                  </Button>
                                ) : userData?.activeplan?.planAmount >= 6000 &&
                                  userData?.SilverWalletPoints >= 6000 ? (
                                  <Button
                                    onClick={() => toggleModal(6000)}
                                    variant=""
                                  >
                                    <small>Redeem</small>
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => toggleModal()}
                                    variant=""
                                    disabled
                                  >
                                    <small>Redeem</small>
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="p-1">
                              <h5 className="mb-1">
                                ₹{userData?.silverWithdraw}
                              </h5>
                              <p className="text-muted mb-0">Withdraw</p>
                              <div className="badge rounded-pill font-size-13 badge-soft-danger mt-2">
                                <Button
                                  onClick={() => toggleModal2("Silver")}
                                  variant=""
                                >
                                  <small>View</small>
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                )}

                {userData?.activeplan?.planAmount === 600 && (
                  <React.Fragment>
                    <Card>
                      <CardBody className="text-center">
                        <p style={{ textAlign: "center" }}>
                          For more benefits like Basic Plan purchase our Add On
                          plan only ₹{userData?.planDetails?.planOfferAmount}/-
                        </p>
                        <Button
                          className="commongradient"
                          style={{
                            color: "white",
                            fontSize: "12px",
                            margin: "0px",
                          }}
                          onClick={() =>
                            displayRazorpay(authUser, userData?.planDetails)
                          }
                        >
                          Buy Plan
                        </Button>
                      </CardBody>
                    </Card>
                  </React.Fragment>
                )}
              </div>
            </Col>
            <Col xl={8} md={7} xs={12}>
              <Card>
                <div className="p-4 pb-0">
                  <h5 className="card-title mb-0">Referred Members</h5>
                </div>

                <CardBody style={{ maxHeight: "510px", overflow: "auto" }}>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <tbody>
                        {Array.isArray(userData?.refereduser) &&
                        userData.refereduser.length > 0 ? (
                          userData.refereduser.map((user, index) => (
                            <tr key={index}>
                              <td style={{ width: "50px" }}>
                                <div className="avatar-md">
                                  <span className="avatar-title rounded-circle bg-primary text-white font-size-24">
                                    {user.name.charAt(0).toUpperCase()}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <h5 className="font-size-14 m-0">
                                  <Link to="#" className="text-dark">
                                    {user.name}
                                  </Link>
                                </h5>
                                <p style={{ marginTop: "7px" }}>
                                  <span style={{ marginRight: "10px" }}>
                                    {user.mobno}
                                  </span>{" "}
                                  <span>{user.email}</span>
                                </p>
                              </td>
                              <td>
                                <div>
                                  <h6>Joined Date</h6>
                                  <p>
                                    {handleDate(user.createdAt)}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              No referred users available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Modal show={isModalOpen} onHide={toggleModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {userData?.withdrawpoints >= 600
                ? "Coming Soon"
                : "Add Bank Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {userData?.withdrawpoints >= 600 ? (
              <Typography variant="body1">
                This feature is coming soon.
              </Typography>
            ) : (
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={6} md={6} xs={12}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Account Holder Name"
                        name="AccHoldername"
                        value={formData.AccHoldername}
                        onChange={handleChange}
                        error={errors.AccHoldername ? true : false}
                        helperText={errors.AccHoldername}
                      />
                    </Col>
                    <Col lg={6} md={6} xs={12}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Account Number"
                        name="AccNo"
                        value={formData.AccNo}
                        onChange={handleChange}
                        error={errors.AccNo ? true : false}
                        helperText={errors.AccNo}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} xs={12}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="IFSC Code"
                        name="IfscCode"
                        value={formData.IfscCode}
                        onChange={handleChange}
                        error={errors.IfscCode ? true : false}
                        helperText={errors.IfscCode}
                      />
                    </Col>
                    <Col lg={6} md={6} xs={12}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Bank Name"
                        name="bankName"
                        value={formData.bankName}
                        onChange={handleChange}
                        error={errors.bankName ? true : false}
                        helperText={errors.bankName}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <input type="hidden" value={logintoast} name="user_id" />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Points"
                        name="walletPoints"
                        value={formData.walletPoints}
                        onWheel={e => e.target.blur()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 m-0 d-flex justify-content-between m-1">
              <Button
                onClick={toggleModal}
                variant="contained"
                className="commongradient addbutton"
                style={{ padding: "7px 20px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                className="commongradient addbutton"
                style={{ padding: "7px 20px" }}
              >
                Withdraw
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={isModalOpen2}
          onHide={toggleModal2}
          centered
          user_id={logintoast}
        >
          <Modal.Header closeButton>
            <Modal.Title>View Transaction Lists</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
            <div className="table-responsive">
              {withdrawListType && withdrawListType.length > 0 ? (
                <TableContainer component={Paper} style={{ width: "500px" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "550", textAlign: "left" }}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "550", textAlign: "left" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "550", textAlign: "left" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "550", textAlign: "left" }}
                        >
                          Bank Details
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "550", textAlign: "left" }}
                        >
                          Transferred Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {withdrawListType.map((withdraw, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ textAlign: "left" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {(date => {
                              const d = new Date(date)
                              const day = ("0" + d.getDate()).slice(-2)
                              const month = ("0" + (d.getMonth() + 1)).slice(-2)
                              const year = d.getFullYear()
                              return `${day}/${month}/${year}`
                            })(withdraw.createdAt)}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {withdraw.walletPoints}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            <Typography
                              onClick={() => openBankDetailsModal(withdraw)}
                            >
                              {withdraw.AccHoldername}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {(date => {
                              const d = new Date(date)
                              const day = ("0" + d.getDate()).slice(-2)
                              const month = ("0" + (d.getMonth() + 1)).slice(-2)
                              const year = d.getFullYear()
                              return `${day}/${month}/${year}`
                            })(withdraw.updatedAt)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body1">
                  No transaction has taken place yet{" "}
                </Typography>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={bankDetailsModalOpen}
          onHide={() => setBankDetailsModalOpen(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Bank Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "550" }}>
                      Account Holder Name:
                    </TableCell>
                    <TableCell style={{ fontWeight: "550" }}>
                      Account Number:
                    </TableCell>
                    <TableCell style={{ fontWeight: "550" }}>
                      IFSC Code:
                    </TableCell>
                    <TableCell style={{ fontWeight: "550" }}>
                      Bank Name:
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{selectedBankDetails.AccHoldername}</TableCell>
                    <TableCell>{selectedBankDetails.AccNo}</TableCell>
                    <TableCell>{selectedBankDetails.IfscCode}</TableCell>
                    <TableCell>{selectedBankDetails.bankName}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Modal.Body>
        </Modal>
      </div>
      <AddUserDetail
        open={open}
        handleClose={handleClose}
        operation={"Edit User"}
        userData={userData && userData}
      />
    </React.Fragment>
  )
}

export default Wallet
