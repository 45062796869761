import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
} from "reactstrap"
import Select from "react-select"
import classnames from "classnames"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { ApiGetMethod, ApiPostMethod } from "helpers/withoutToken_helper"
import { HOST_URL } from "helpers/url_helper"
import { ToastContainer, toast } from "react-toastify"

const RentaddressdetailsEdit = React.forwardRef(
  ({ propertytype, lookingfor, propData, propertyId }, ref) => {
    var lookingto = localStorage.getItem("lookingFor")
    var PropertyType2 = localStorage.getItem("PropertyType2")
    const loggedUser = JSON.parse(localStorage.getItem("authUser"))

    const optionGroup = [
      {
        label: "Search City",
        options: [{ label: "Nagpur", value: "Nagpur" }],
      },
    ]
    const [cityGroup, setCityGroup] = useState([])
    const [stateGroup, setStateGroup] = useState([])
    const [authorityData, setAuthorityData] = useState([])
    
    const total_Floor = [
      {
        options: [
          { label: "Ground Floor", value: "Ground Floor" },
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" },
          { label: "5", value: "5" },
          { label: "6", value: "6" },
          { label: "7", value: "7" },
          { label: "8", value: "8" },
          { label: "9", value: "9" },
          { label: "10", value: "10" },
          { label: "11", value: "11" },
          { label: "12", value: "12" },
          { label: "13", value: "13" },
          { label: "14", value: "14" },
          { label: "15", value: "15" },
          { label: "16", value: "16" },
          { label: "17", value: "17" },
          { label: "18", value: "18" },
          { label: "19", value: "19" },
          { label: "20", value: "20" },
        ],
      },
    ]

    const [localities, setLocalities] = useState([])
    const [localityData, setLocalityData] = useState([]);
    const [rrentAddressDetails, setrrentAddressDetails] = useState({
      searchState: propData?.address?.searchState || "",
      searchCity: propData?.address?.searchCity || "",
      buildingProjects: propData?.address?.buildingProjects || "",
      locality: propData?.address?.locality || "",
      otherLocality: propData?.address?.otherLocality || "",
      flatNo: propData?.address?.flatNo || "",
      floorNo: propData?.address?.floorNo || "",
      totalFloor: propData?.address?.totalFloor || "",
      isYourPlotApprovedByAnAuthority:
        propData?.address?.isYourPlotApprovedByAnAuthority || "",
      approvingAuthorities: propData?.address?.approvingAuthorities || "",
    })
    const [rrentAddressError, setrrentAddressError] = useState({
      searchState: "",
      searchCity: "",
      buildingProjects: "",
      locality: "",
      otherLocality: "",
      floorNo: "",
      totalFloor: "",
    })
    // console.log("is localities---", localities)

    const fetchData = async () => {
      try {
        const postUrl = `${HOST_URL}api/viewLocalities` // Replace 'your_api_endpoint_here' with your actual API endpoint

        const response = await ApiGetMethod(postUrl)
        const localities = [...response[0].localities]
        localities.push("Other")
        setLocalityData(response)
        // setLocalities(localities)
        const State = response.map(st => ({ label: st.state, value: st.state }))
        .filter((value, index, self) => 
          self.findIndex(v => v.value === value.value) === index
        );
        // const cities = response.map(cities => ({label:cities.city,value:cities._id}))
        setStateGroup(State)
        // setCityGroup(cities)
        if(rrentAddressDetails.searchCity){
          const locality = response.find(data=> data.city == rrentAddressDetails.searchCity)
          const localities1 = locality.localities.includes("Other") ? locality.localities : ["Other", ...locality.localities];
          setLocalities(localities1)
        }
      } catch (error) {
        console.error("Error:", error)
      }
    }
    useEffect(() => {
      fetchData()
      handleFetchAuthority(propData?.address?.searchState)
    }, [rrentAddressDetails.searchCity, propData])

    function handleSelectState(selectedGroup) {
      if(rrentAddressDetails.searchState == selectedGroup.label){
        setrrentAddressDetails(prevDetails => ({
          ...prevDetails,
          searchState: selectedGroup.label,
        }))
      }else{
        setrrentAddressDetails(prevDetails => ({
          ...prevDetails,
          searchState: selectedGroup.label,
          searchCity:''
        }))
      }
      setrrentAddressError(prevErrors => ({
        ...prevErrors,
        searchState: "",
      }))
      const city = localityData.filter(data=> data.state == selectedGroup.value)
      console.log("city",city);
      
      const localities1 = city.map(cities => ({label:cities.city,value:cities._id}))
      setCityGroup(localities1)

      if(rrentAddressDetails.isYourPlotApprovedByAnAuthority == "Yes"){
        handleFetchAuthority(selectedGroup.label)
      }
    }

    function handleSelectGroup(selectedGroup) {
      fetchData()
      setrrentAddressDetails(prevDetails => ({
        ...prevDetails,
        searchCity: selectedGroup.label,
      }))
      setrrentAddressError(prevErrors => ({
        ...prevErrors,
        searchCity: "",
      }))
    }

    function handlefloor(name, selectedGroup) {
      setrrentAddressDetails(prevDetails => ({
        ...prevDetails,
        [name]: selectedGroup.value,
      }))
      setrrentAddressError(prevErrors => ({
        ...prevErrors,
        [name]: "",
      }))
      if (
        name == "totalFloor" &&
        selectedGroup.value < rrentAddressDetails.floorNo
      ) {
        setrrentAddressDetails(prevDetails => ({
          ...prevDetails,
          floorNo: "",
        }))
      }
    }

    function handleLocalityGroup(selectedGroup) {
      setrrentAddressDetails(prevDetails => ({
        ...prevDetails,
        locality: selectedGroup.value,
      }))
      setrrentAddressError(prevErrors => ({
        ...prevErrors,
        locality: "",
      }))
    }

    async function handleFetchAuthority(state){
      try {
        const postUrl = `${HOST_URL}authority/fetchAuthority`
        const data = {
          state:state
        }
        const response = await ApiPostMethod(postUrl, data)
        if(response.error == false){
          console.log("response.authorities", response.authorities.authorities);
          setAuthorityData(response.authorities.authorities)
        }else{
          toast.error(response.message, {
              position: "top-right",
              theme: "light",
            })
        }
      } catch (error) {
          toast.error(error, {
            position: "top-right",
            theme: "light",
          })
      }
    }

    const handleChange = e => {
      const { name, value } = e.target
      setrrentAddressDetails(prevDetails => ({
        ...prevDetails,
        [name]: value,
      }))
      setrrentAddressError(prevErrors => ({
        ...prevErrors,
        [name]: "",
      }))
    }
    const handle_Change = (name, value) => {
      // const { name, value } = e.target;
      if(name == "isYourPlotApprovedByAnAuthority"){
        if(rrentAddressDetails.searchState == ""){
          setrrentAddressError({
            searchState: "Select State First!!",
          })
        }else if(name == "isYourPlotApprovedByAnAuthority" && value == "Yes"){
          setrrentAddressDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
          }))
          handleFetchAuthority(rrentAddressDetails?.searchState)
        }else if(name == "isYourPlotApprovedByAnAuthority" && value == "No"){
          setrrentAddressDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
          }))
        }
      }else{
        setrrentAddressDetails(prevDetails => ({
          ...prevDetails,
          [name]: value,
        }))
      }
    }

    const handleLocality = async () => {
      if (rrentAddressDetails.otherLocality == "") {
        setrrentAddressError({
          otherLocality: "Locality is required",
        })
      } else {
        const url = `${HOST_URL}api/addLocality`
        const payload = {
          city: rrentAddressDetails.searchCity,
          locality: rrentAddressDetails.otherLocality,
        }
        await ApiPostMethod(url, payload).then(response => {
          if (response.error === false) {
            fetchData()
            setrrentAddressDetails(prevDetails => ({
              ...prevDetails,
              otherLocality: "",
              locality: response.locality,
            }))
            toast.success(response.message, {
              position: "top-right",
              theme: "light",
            })
          } else {
            toast.error(response.message, {
              position: "top-right",
              theme: "light",
            })
          }
        })
      }
    }

    function generateFloorOptions(optionGroup, totalFloors) {
      const options = []

      // Add fixed options (-2, -1, Ground) regardless of totalFloors
      options.push({ value: "-2", label: "-2" })
      options.push({ value: "-1", label: "-1" })
      options.push({ value: "Ground Floor", label: "Ground Floor" })

      // Add dynamic options based on totalFloors
      for (let i = 1; i <= totalFloors; i++) {
        options.push({ value: `${i}`, label: `${i}` })
      }

      return options
    }

    const handleSubmit = async () => {
      const newErrors = {}

      if (rrentAddressDetails.searchCity.trim() === "") {
        newErrors.searchCity = "Search city is required"
      }
      if(!propData?.propertyDetails?.propertyType2 == "Plot" || !propData?.propertyDetails?.propertyType2 == "Agricultural Land"){
        if (rrentAddressDetails.buildingProjects.trim() === "") {
          newErrors.buildingProjects = "Building/Project is required"
        }
      }
      if (rrentAddressDetails.locality === "") {
        newErrors.locality = "Locality is required"
      }
      if (
        propData?.propertyDetails?.lookingTo == "Rent" &&
        (propData?.propertyDetails?.propertyType2 == "Apartment" ||
          propData?.propertyDetails?.propertyType2 == "Independent Floor")
      ) {
        if (rrentAddressDetails.floorNo.trim() === "") {
          newErrors.floorNo = "Floor no is required"
        }
        if (rrentAddressDetails.totalFloor.trim() === "") {
          newErrors.totalFloor = "Total floor is required"
        }
      }

      setrrentAddressError(newErrors)
      if (Object.values(newErrors).every(error => error === "")) {
        var status
        const token = JSON.parse(localStorage.getItem("token"))
        const user_id = JSON.parse(localStorage.getItem("authUser"))
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        let data = {
          address: { ...rrentAddressDetails },
          user_id: propData?.user_id,
          property_id: propertyId || property_id,
        }
        const url = `${HOST_URL}property/addressdetails`
        var response
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            status = true
            response = resp
          } else {
            status = false
            response = resp
          }
        })
        return { status: status, message: response.message }
      } else {
        return { status: false, message: formatValidationMessage(newErrors) }
      }
    }

    function formatValidationMessage(errors) {
      const keys = Object.keys(errors).map(key => 
          errors[key].replace(' is required', '')
      );
      const joinedKeys = keys.length > 1 
          ? keys.slice(0, -1).join(', ') + ' and ' + keys[keys.length - 1] 
          : keys[0];

      return `${joinedKeys} is required.`;
    }

    React.useImperativeHandle(ref, () => ({
      handleRentAdd: () => handleSubmit(),
    }))
    return (
      <React.Fragment>
        <h5>Add Address</h5>
        <Form>
          <Row>
            <Col md={12}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="ageOfProperty"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  State
                </label>
                <div className="col-lg-12">
                  <Select
                    id="searchState"
                    name="searchState"
                    value={{
                      label: rrentAddressDetails?.searchState,
                      value: rrentAddressDetails?.searchState,
                    }}
                    onChange={selectedValue => {
                      handleSelectState(selectedValue)
                    }}
                    options={stateGroup}
                    classNamePrefix="select2-selection"
                  />
                  {rrentAddressError.searchState && (
                    <span className="error">
                      {rrentAddressError.searchState}
                    </span>
                  )}
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="ageOfProperty"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Search City
                </label>
                <div className="col-lg-12">
                  <Select
                    id="searchCity"
                    name="searchCity"
                    // defaultValue={[rrentAddressDetails.searchCity]}
                    value={{
                      label: rrentAddressDetails.searchCity,
                      value: rrentAddressDetails.searchCity,
                    }}
                    onChange={selectedValue => {
                      handleSelectGroup(selectedValue)
                    }}
                    options={cityGroup}
                    classNamePrefix="select2-selection"
                  />
                  {rrentAddressError.searchCity && (
                    <span className="error">
                      {rrentAddressError.searchCity}
                    </span>
                  )}
                </div>
              </Row>
            </Col>
            {(!PropertyType2 == "Plot" || !PropertyType2 == "Agricultural Land") ? (
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="ageOfProperty"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Building/Project/Society
                  </label>
                  <div className="col-lg-12">
                    <Input
                      id="buildingProjects"
                      name="buildingProjects"
                      type="text"
                      className={`form-control`}
                      // placeholder=""
                      value={rrentAddressDetails.buildingProjects}
                      onChange={handleChange}
                    />
                    {rrentAddressError.buildingProjects && (
                      <span className="error">
                        {rrentAddressError.buildingProjects}
                      </span>
                    )}
                  </div>
                </Row>
              </Col>
            ):''}
          </Row>
          <Row>
            {propData?.propertyDetails?.lookingTo == "Sell" &&
            (propData?.propertyDetails?.propertyType2 == "Plot" ||
              propData?.propertyDetails?.propertyType2 ==
                "Agricultural Land") ? (
              <React.Fragment>
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label font-weight-bold"
                    >
                      Is your plot approved by an authority?
                    </label>
                    <div className="col-lg-12">
                      <ButtonGroup className="w-100">
                        <Button
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            rrentAddressDetails.isYourPlotApprovedByAnAuthority ===
                            "Yes"
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handle_Change(
                              "isYourPlotApprovedByAnAuthority",
                              "Yes"
                            )
                          }
                        >
                          <Input
                            type="radio"
                            name="isYourPlotApprovedByAnAuthority"
                            value="Yes"
                            onChange={() =>
                              handle_Change(
                                "isYourPlotApprovedByAnAuthority",
                                "Yes"
                              )
                            }
                            checked={
                              rrentAddressDetails.isYourPlotApprovedByAnAuthority ===
                              "Yes"
                            }
                            style={{ opacity: 0 }}
                          />
                          Yes
                        </Button>
                        <Button
                          style={{ borderRadius: "5px" }}
                          className={
                            rrentAddressDetails.isYourPlotApprovedByAnAuthority ===
                            "No"
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handle_Change(
                              "isYourPlotApprovedByAnAuthority",
                              "No"
                            )
                          }
                        >
                          <Input
                            type="radio"
                            name="isYourPlotApprovedByAnAuthority"
                            value="No"
                            onChange={() =>
                              handle_Change(
                                "isYourPlotApprovedByAnAuthority",
                                "No"
                              )
                            }
                            checked={
                              rrentAddressDetails.isYourPlotApprovedByAnAuthority ===
                              "No"
                            }
                            style={{ opacity: 0 }}
                          />
                          No
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Row>
                </Col>
                {/* {rrentAddressDetails.isYourPlotApprovedByAnAuthority ==
                "Yes" ? (
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="txtFirstNameBilling"
                        className="col-lg-12 col-form-label font-weight-bold"
                      >
                        Approving Authorities
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100">
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              rrentAddressDetails.approvingAuthorities === "AMC"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() =>
                              handle_Change("approvingAuthorities", "AMC")
                            }
                          >
                            <Input
                              type="radio"
                              name="approvingAuthorities"
                              value="AMC"
                              onChange={() =>
                                handle_Change("approvingAuthorities", "AMC")
                              }
                              checked={
                                rrentAddressDetails.approvingAuthorities ===
                                "AMC"
                              }
                              style={{ opacity: 0 }}
                            />
                            AMC
                          </Button>
                          <Button
                            style={{ borderRadius: "5px" }}
                            className={
                              rrentAddressDetails.approvingAuthorities ===
                              "AUDA"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() =>
                              handle_Change("approvingAuthorities", "AUDA")
                            }
                          >
                            <Input
                              type="radio"
                              name="approvingAuthorities"
                              value="AUDA"
                              onChange={() =>
                                handle_Change("approvingAuthorities", "AUDA")
                              }
                              checked={
                                rrentAddressDetails.approvingAuthorities ===
                                "AUDA"
                              }
                              style={{ opacity: 0 }}
                            />
                            AUDA
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                ) : (
                  ""
                )} */}
              </React.Fragment>
            ) : (
              ""
            )}
            <Col md={6}>
              <Row className="mb-3 d-block">
                <label
                  htmlFor="ageOfProperty"
                  className="col-lg-12 col-form-label requiredfield font-weight-bold"
                >
                  Locality
                </label>
                <Select
                  id="locality"
                  name="locality"
                  // defaultValue={[rrentAddressDetails.locality]}
                  value={{
                    label: rrentAddressDetails.locality,
                    value: rrentAddressDetails.locality,
                  }}
                  onChange={selectedValue => {
                    handleLocalityGroup(selectedValue)
                  }}
                  options={localities.map(locality => ({
                    value: locality,
                    label: locality,
                  }))}
                  classNamePrefix="select2-selection"
                />
                {rrentAddressError.locality && (
                  <span className="error">{rrentAddressError.locality}</span>
                )}
              </Row>
            </Col>
            {rrentAddressDetails.isYourPlotApprovedByAnAuthority ==
              "Yes" ? (
                <Col md={12}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label font-weight-bold"
                    >
                      Approving Authorities
                    </label>
                    <div className="col-lg-12">
                      <ButtonGroup className="w-100">
                        {authorityData.map((authority, i) => (
                          <Button
                            key={i}
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              rrentAddressDetails.approvingAuthorities === authority
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() =>
                              handle_Change("approvingAuthorities", authority)
                            }
                          >
                            <Input
                              type="radio"
                              name="approvingAuthorities"
                              value="AMC"
                              onChange={() =>
                                handle_Change("approvingAuthorities", authority)
                              }
                              checked={
                                rrentAddressDetails.approvingAuthorities === authority
                              }
                              style={{ opacity: 0 }}
                            />
                            {authority}
                          </Button>
                        ))}
                        {/* <Button
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            rrentAddressDetails.approvingAuthorities === "NIT"
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handle_Change("approvingAuthorities", "NIT")
                          }
                        >
                          <Input
                            type="radio"
                            name="approvingAuthorities"
                            value="AMC"
                            onChange={() =>
                              handle_Change("approvingAuthorities", "NIT")
                            }
                            checked={
                              rrentAddressDetails.approvingAuthorities ===
                              "NIT"
                            }
                            style={{ opacity: 0 }}
                          />
                          NIT
                        </Button>
                        <Button
                          style={{ borderRadius: "5px" }}
                          className={
                            rrentAddressDetails.approvingAuthorities ===
                            "NMRDA"
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handle_Change("approvingAuthorities", "NMRDA")
                          }
                        >
                          <Input
                            type="radio"
                            name="approvingAuthorities"
                            value="NMRDA"
                            onChange={() =>
                              handle_Change("approvingAuthorities", "NMRDA")
                            }
                            checked={
                              rrentAddressDetails.approvingAuthorities ===
                              "NMRDA"
                            }
                            style={{ opacity: 0 }}
                          />
                          NMRDA
                        </Button> */}
                      </ButtonGroup>
                    </div>
                  </Row>
                </Col>
              ) 
              : 
              ("")
            }
            {rrentAddressDetails.locality == "Other" ? (
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="otherLocality"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Your Locality
                  </label>
                  <div className="col-lg-12 d-flex">
                    <Input
                      id="otherLocality"
                      name="otherLocality"
                      type="text"
                      className={`form-control`}
                      value={rrentAddressDetails.otherLocality}
                      onChange={handleChange}
                    />
                    {loggedUser.role == "manager" ||
                      (loggedUser.role == "admin" && (
                        <Button
                          className="commongradient"
                          style={{ color: "white", marginLeft: "5px" }}
                          onClick={handleLocality}
                        >
                          Add
                        </Button>
                      ))}
                  </div>
                  {rrentAddressError.otherLocality && (
                    <span className="error">
                      {rrentAddressError.otherLocality}
                    </span>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}
            {propData?.propertyDetails?.lookingTo == "Rent" ? (
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="ageOfProperty"
                    className="col-lg-12 col-form-label  font-weight-bold"
                  >
                    Flat No / Plat No
                  </label>
                  <div className="col-lg-12">
                    <Input
                      id="flatNo"
                      name="flatNo"
                      type="text"
                      className={`form-control`}
                      // placeholder=""
                      value={rrentAddressDetails.flatNo}
                      onChange={handleChange}
                    />
                    {rrentAddressError.flatNo && (
                      <span className="error">{rrentAddressError.flatNo}</span>
                    )}
                  </div>
                </Row>
              </Col>
            ) : propData?.propertyDetails?.lookingTo == "Sell" &&
              (propData?.propertyDetails?.propertyType2 == "Apartment" ||
                propData?.propertyDetails?.propertyType2 ==
                  "Independent Floor") ? (
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="ageOfProperty"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Flat No / Plot No
                  </label>
                  <div className="col-lg-12">
                    <Input
                      id="flatNo"
                      name="flatNo"
                      type="text"
                      className={`form-control`}
                      // placeholder=""
                      value={rrentAddressDetails.flatNo}
                      onChange={handleChange}
                    />
                    {rrentAddressError.flatNo && (
                      <span className="error">{rrentAddressError.flatNo}</span>
                    )}
                  </div>
                </Row>
              </Col>
            ) : (
              ""
            )}
          </Row>
          {propData?.propertyDetails?.lookingTo == "Rent" ? (
            <Row>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="ageOfProperty"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Total floor
                  </label>
                  <div className="col-lg-12">
                    {/* <Input
                      id="totalFloor"
                      name="totalFloor"
                      type="number"
                      className={`form-control`}
                      // placeholder=""
                      value={rrentAddressDetails.totalFloor}
                      onChange={handleChange}
                      onWheel={e => e.target.blur()}
                      min={0}
                    /> */}
                    <Select
                      id="totalFloor"
                      name="totalFloor"
                      value={{
                        label: rrentAddressDetails.totalFloor,
                        value: rrentAddressDetails.totalFloor,
                      }}
                      onChange={selectedValue => {
                        handlefloor("totalFloor", selectedValue)
                      }}
                      options={total_Floor}
                      classNamePrefix="select2-selection"
                    />
                    {rrentAddressError.totalFloor && (
                      <span className="error">
                        {rrentAddressError.totalFloor}
                      </span>
                    )}
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="ageOfProperty"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Floor No
                  </label>
                  <div className="col-lg-12">
                    <Select
                      id="floorNo"
                      name="floorNo"
                      value={{
                        label: rrentAddressDetails.floorNo,
                        value: rrentAddressDetails.floorNo,
                      }}
                      onChange={selectedValue => {
                        handlefloor("floorNo", selectedValue)
                      }}
                      options={generateFloorOptions(
                        optionGroup,
                        rrentAddressDetails.totalFloor
                      )}
                      classNamePrefix="select2-selection"
                    />
                    {rrentAddressError.floorNo && (
                      <span className="error">{rrentAddressError.floorNo}</span>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          ) : propData?.propertyDetails?.lookingTo == "Sell" &&
            (propData?.propertyDetails?.propertyType2 == "Apartment" ||
              propData?.propertyDetails?.propertyType2 ==
                "Independent Floor") ? (
            <Row>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="ageOfProperty"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Total floor
                  </label>
                  <div className="col-lg-12">
                    <Select
                      id="totalFloor"
                      name="totalFloor"
                      value={{
                        label: rrentAddressDetails.totalFloor,
                        value: rrentAddressDetails.totalFloor,
                      }}
                      onChange={selectedValue => {
                        handlefloor("totalFloor", selectedValue)
                      }}
                      options={total_Floor}
                      classNamePrefix="select2-selection"
                    />
                    {rrentAddressError.totalFloor && (
                      <span className="error">
                        {rrentAddressError.totalFloor}
                      </span>
                    )}
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="ageOfProperty"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Floor No
                  </label>
                  <div className="col-lg-12">
                    <Select
                      id="floorNo"
                      name="floorNo"
                      value={{
                        label: rrentAddressDetails.floorNo,
                        value: rrentAddressDetails.floorNo,
                      }}
                      onChange={selectedValue => {
                        handlefloor("floorNo", selectedValue)
                      }}
                      options={generateFloorOptions(
                        optionGroup,
                        rrentAddressDetails.totalFloor
                      )}
                      classNamePrefix="select2-selection"
                    />
                    {rrentAddressError.floorNo && (
                      <span className="error">{rrentAddressError.floorNo}</span>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Form>
      </React.Fragment>
    )
  }
)

export default RentaddressdetailsEdit
