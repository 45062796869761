import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
} from "reactstrap"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import Select from "react-select"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"

const PGRoomDetailsEdit = React.forwardRef(
  ({ propertytype, lookingfor, propData, propertyId }, ref) => {
    const user_id = JSON.parse(localStorage.getItem("authUser"))
    const user_role = JSON.parse(localStorage.getItem("userRole"))

    const facilityarray = [
      { id: 1, value: "AC" },
      { id: 2, value: "TV in Room" },
      { id: 3, value: "Personal Cupboard" },
      { id: 4, value: "Table Chair" },
      { id: 5, value: "Attched Balcony" },
      { id: 6, value: "Attached Bathroom" },
      { id: 7, value: "Meals Included" },
    ]
    // console.log("propData", propData?.roomDetails)
    const [roomCount, setRoomCount] = useState(
      propData && propData.roomDetails
        ? Object.keys(propData?.roomDetails).length
        : 1
    )
    const [pgRoomDetails, setPgRoomDetails] = useState(
      propData && propData.roomDetails
        ? propData.roomDetails
        : {
            "room-0": {
              roomType: "",
              totalBedsInThisRoom: "",
              pgRent: "",
              securityDeposit: "",
              facilitiesOffered: [],
              bathroomStyle: "",
            },
          }
    )

    console.log("pgRoomDetails--", pgRoomDetails)

    const handleChange = (roomIndex, name, value) => {
      setPgRoomDetails(prevDetails => {
        let updatedDetails

        if (name === "facilitiesOffered") {
          const facilitiesOffered = [
            ...prevDetails[`room-${roomIndex}`].facilitiesOffered,
          ]
          const index = facilitiesOffered.indexOf(value)

          if (index === -1) {
            facilitiesOffered.push(value)
          } else {
            facilitiesOffered.splice(index, 1)
          }
          updatedDetails = {
            ...prevDetails,
            [`room-${roomIndex}`]: {
              ...prevDetails[`room-${roomIndex}`],
              [name]: facilitiesOffered,
            },
          }
        } else {
          updatedDetails = {
            ...prevDetails,
            [`room-${roomIndex}`]: {
              ...prevDetails[`room-${roomIndex}`],
              [name]: value,
            },
          }
        }
        return updatedDetails
      })
    }

    // console.log("facilitiesOffered", pgRoomDetails)

    const handleAddRoom = () => {
      setRoomCount(prevCount => prevCount + 1)
      setPgRoomDetails(prevDetails => ({
        ...prevDetails,
        [`room-${roomCount}`]: {
          roomType: "",
          totalBedsInThisRoom: "",
          pgRent: "",
          securityDeposit: "",
          facilitiesOffered: [],
          bathroomStyle: "",
        },
      }))
    }

    const handleDeleteRoom = roomIndex => {
      if (roomCount === 1) {
        alert("At least one block is required.")
      } else {
        setPgRoomDetails(prevDetails => {
          const updatedRoomDetails = { ...prevDetails }
          const roomKeyToDelete = `room-${roomIndex}`

          if (updatedRoomDetails.hasOwnProperty(roomKeyToDelete)) {
            delete updatedRoomDetails[roomKeyToDelete]
            const updatedDetails = Object.keys(updatedRoomDetails).reduce(
              (acc, key, index) => {
                const newKey = `room-${index}`
                acc[newKey] = updatedRoomDetails[key]
                return acc
              },
              {}
            )

            setRoomCount(prevCount => prevCount - 1)
            return updatedDetails
          } else {
            console.warn(`Room with key ${roomKeyToDelete} does not exist.`)
            return prevDetails
          }
        })
      }
    }

    const handleSubmit = async () => {
      var status
      var response
      const url = `${HOST_URL}property/roomdetailsPG`
      const token = JSON.parse(localStorage.getItem("token"))
      const totalBeds = parseInt(localStorage.getItem("totalBeds"))
      const property_id = JSON.parse(localStorage.getItem("property_id"))
      // console.log(totalBeds)
      let totalBedsInRoomDetails = 0
      var total_beds =
        totalBeds || parseInt(propData?.pgPropDetails?.pgdetails?.totalBeds)
      for (const roomKey in pgRoomDetails) {
        if (pgRoomDetails.hasOwnProperty(roomKey)) {
          totalBedsInRoomDetails += parseInt(
            pgRoomDetails[roomKey].totalBedsInThisRoom
          )
        }
      }
      console.log("totalBedsInRoomDetails", totalBedsInRoomDetails)

      if (total_beds) {
        if (total_beds == totalBedsInRoomDetails) {
          const data = {
            user_id: propData?.user_id,
            property_id: propertyId || property_id,
            roomDetails: pgRoomDetails,
          }
          await ApiPostMethodWithToken(url, data, token).then(resp => {
            if (resp.error === false) {
              status = true
              response = resp
            } else {
              status = false
              response = resp
            }
          })
        } else {
          status = false
          response = {
            error: true,
            message:
              "Rooms bed count mismatch with the total beds count provided in property details tab",
          }
        }
      } else {
        status = false
        response = {
          error: true,
          message:
            "Rooms bed count mismatch with the total beds count provided in property details tab",
        }
      }
      return { status: status, message: response.message }
    }

    const formatAmount = amount => {
      if (amount < 1000) {
        return amount
      } else if (amount >= 1000 && amount < 100000) {
        return (amount / 1000).toFixed(1) + "k"
      } else {
        return (amount / 100000).toFixed(1) + "L"
      }
    }

    React.useImperativeHandle(ref, () => ({
      handlePgRoomD: () => handleSubmit(),
    }))

    return (
      <React.Fragment>
        <h5>Add Room Details</h5>
        {Array.from({ length: roomCount }, (_, index) => (
          <Col
            lg={12}
            id={`roomDetails-${index}`}
            className="roomdetails mb-2"
            key={`room-${index}`}
          >
            <Row className="roomDHeader m-0 align-items-center">
              <Col lg={6} className="">
                Room {index + 1}
              </Col>
              <Col lg={6} className="text-end">
                {/* <Button
                  className="btnNormal p-0"
                  style={{ border: "none" }}
                  onClick={() => handleDeleteRoom(index)}
                >
                  <i
                    className="mdi mdi-delete-circle"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                </Button>
                {index === 0 && (
                  <Button
                    className="btnNormal p-0 ms-2"
                    style={{ border: "none" }}
                    onClick={handleAddRoom}
                  >
                    <i
                      className="mdi mdi-plus-circle-outline"
                      style={{ color: "#0066AB", fontSize: "25px" }}
                    ></i>
                  </Button>
                )} */}
                {/* {index === roomCount - 1 ? (
                  <Button
                    className="btnNormal p-0 ms-2"
                    style={{ border: "none" }}
                    onClick={handleAddRoom}
                  >
                    <i
                      className="mdi mdi-plus-circle-outline"
                      style={{ color: "#0066AB", fontSize: "25px" }}
                    ></i>
                  </Button>
                ) : (
                  <Button
                    className="btnNormal p-0"
                    style={{ border: "none" }}
                    onClick={() => handleDeleteRoom(index)}
                  >
                    <i
                      className="mdi mdi-delete-circle"
                      style={{ color: "red", fontSize: "25px" }}
                    ></i>
                  </Button>
                )} */}
                {roomCount > 1 && (
                  <Button
                    className="btnNormal p-0"
                    style={{ border: "none" }}
                    onClick={() => handleDeleteRoom(index)}
                  >
                    <i
                      className="mdi mdi-delete-circle"
                      style={{ color: "red", fontSize: "25px" }}
                    ></i>
                  </Button>
                )}
                {index === roomCount - 1 && (
                  <Button
                    className="btnNormal p-0 ms-2"
                    style={{ border: "none" }}
                    onClick={handleAddRoom}
                  >
                    <i
                      className="mdi mdi-plus-circle-outline"
                      style={{ color: "#0066AB", fontSize: "25px" }}
                    ></i>
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="roomDBody m-0">
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Room Type
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          pgRoomDetails[`room-${index}`].roomType ===
                          "Private Room"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange(index, "roomType", "Private Room")
                        }
                      >
                        <Input
                          type="radio"
                          name={`roomType-${index}`}
                          value="Private Room"
                          onChange={() =>
                            handleChange(index, "roomType", "Private Room")
                          }
                          checked={
                            pgRoomDetails[`room-${index}`].roomType ===
                            "Private Room"
                          }
                          style={{ opacity: 0 }}
                        />
                        Private Room
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          pgRoomDetails[`room-${index}`].roomType ===
                          "Double Sharing"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange(index, "roomType", "Double Sharing")
                        }
                      >
                        <Input
                          type="radio"
                          name={`roomType-${index}`}
                          value="No"
                          onChange={() =>
                            handleChange(index, "roomType", "Double Sharing")
                          }
                          checked={
                            pgRoomDetails[`room-${index}`].roomType ===
                            "Double Sharing"
                          }
                          style={{ opacity: 0 }}
                        />
                        Double Sharing
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          pgRoomDetails[`room-${index}`].roomType ===
                          "Triple Sharing"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange(index, "roomType", "Triple Sharing")
                        }
                      >
                        <Input
                          type="radio"
                          name={`roomType-${index}`}
                          value="No"
                          onChange={() =>
                            handleChange(index, "roomType", "Triple Sharing")
                          }
                          checked={
                            pgRoomDetails[`room-${index}`].roomType ===
                            "Triple Sharing"
                          }
                          style={{ opacity: 0 }}
                        />
                        Triple Sharing
                      </Button>
                      <Button
                        style={{ borderRadius: "5px" }}
                        className={
                          pgRoomDetails[`room-${index}`].roomType ===
                          "3+Sharing"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange(index, "roomType", "3+Sharing")
                        }
                      >
                        <Input
                          type="radio"
                          name={`roomType-${index}`}
                          value="No"
                          onChange={() =>
                            handleChange(index, "roomType", "3+Sharing")
                          }
                          checked={
                            pgRoomDetails[`room-${index}`].roomType ===
                            "3+Sharing"
                          }
                          style={{ opacity: 0 }}
                        />
                        3+Sharing
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor=""
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Total Beds in this Room (Optional)
                  </label>
                  <div className="col-lg-12">
                    <Input
                      id="totalBedsInThisRoom"
                      name={`totalBedsInThisRoom-${index}`}
                      type="number"
                      className={`form-control`}
                      placeholder=""
                      value={pgRoomDetails[`room-${index}`].totalBedsInThisRoom}
                      onChange={e =>
                        handleChange(
                          index,
                          "totalBedsInThisRoom",
                          e.target.value
                        )
                      }
                      onWheel={e => e.target.blur()}
                      min={0}
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor=""
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Rent
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="pgRent"
                      name={`pgRent-${index}`}
                      type="number"
                      className={`form-control`}
                      placeholder=""
                      value={pgRoomDetails[`room-${index}`].pgRent}
                      onChange={e =>
                        handleChange(index, "pgRent", e.target.value)
                      }
                      onWheel={e => e.target.blur()}
                      min={0}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text font-weight-bold">
                        ₹{formatAmount(pgRoomDetails[`room-${index}`].pgRent)}
                      </span>
                    </span>
                  </div>
                </Row>
              </Col>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor=""
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Security Deposit
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="securityDeposit"
                      name={`securityDeposit-${index}`}
                      type="number"
                      className={`form-control`}
                      placeholder=""
                      value={pgRoomDetails[`room-${index}`].securityDeposit}
                      onChange={e =>
                        handleChange(index, "securityDeposit", e.target.value)
                      }
                      onWheel={e => e.target.blur()}
                      min={0}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text font-weight-bold">
                        ₹
                        {formatAmount(
                          pgRoomDetails[`room-${index}`].securityDeposit
                        )}
                      </span>
                    </span>
                  </div>
                </Row>
              </Col>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Facilities Offered
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 d-flex flex-wrap">
                      {facilityarray.map(face => (
                        <Button
                          key={face.id}
                          className={
                            pgRoomDetails[`room-${index}`].facilitiesOffered &&
                            pgRoomDetails[
                              `room-${index}`
                            ].facilitiesOffered.includes(face.value)
                              ? "btnActive facilitybtn"
                              : "btnNormal facilitybtn"
                          }
                          onClick={() =>
                            handleChange(index, "facilitiesOffered", face.value)
                          }
                        >
                          <Input
                            type="checkbox"
                            className="me-2"
                            name="facilitiesOffered"
                            value={face.value}
                            onChange={() =>
                              handleChange(
                                index,
                                "facilitiesOffered",
                                face.value
                              )
                            }
                            checked={
                              pgRoomDetails[`room-${index}`]
                                .facilitiesOffered &&
                              pgRoomDetails[
                                `room-${index}`
                              ].facilitiesOffered.includes(face.value)
                            }
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                    {/* <ButtonGroup className="w-100 d-flex flex-wrap">
                    <Button
                      style={{
                        marginRight: "5px",
                        borderRadius: "5px",
                        width: "20%",
                        marginBottom: "5px",
                      }}
                      className={
                        pgRoomDetails[`room-${index}`].facilitiesOffered ===
                        "AC"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() =>
                        handleChange(index, "facilitiesOffered", "AC")
                      }
                    >
                      <Input
                        type="checkbox"
                        name={`facilitiesOffered-${index}`}
                        className="me-2"
                        value="AC"
                        onChange={() =>
                          handleChange(index, "facilitiesOffered", "AC")
                        }
                        checked={
                          pgRoomDetails[`room-${index}`].facilitiesOffered ===
                          "AC"
                        }
                      />
                      AC
                    </Button>
                    <Button
                      style={{
                        marginRight: "5px",
                        borderRadius: "5px",
                        width: "20%",
                        marginBottom: "5px",
                      }}
                      className={
                        pgRoomDetails[`room-${index}`].facilitiesOffered ===
                        "TV in Room"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() =>
                        handleChange(index, "facilitiesOffered", "TV in Room")
                      }
                    >
                      <Input
                        type="checkbox"
                        name={`facilitiesOffered-${index}`}
                        className="me-2"
                        value="No"
                        onChange={() =>
                          handleChange(index, "facilitiesOffered", "TV in Room")
                        }
                        checked={
                          pgRoomDetails[`room-${index}`].facilitiesOffered ===
                          "TV in Room"
                        }
                      />
                      TV in Room
                    </Button>
                    <Button
                      style={{
                        marginRight: "5px",
                        borderRadius: "5px",
                        width: "20%",
                        marginBottom: "5px",
                      }}
                      className={
                        pgRoomDetails[`room-${index}`].facilitiesOffered ===
                        "Personal Cop..."
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() =>
                        handleChange(
                          index,
                          "facilitiesOffered",
                          "Personal Cop..."
                        )
                      }
                    >
                      <Input
                        type="checkbox"
                        name={`facilitiesOffered-${index}`}
                        className="me-2"
                        value="No"
                        onChange={() =>
                          handleChange(
                            index,
                            "facilitiesOffered",
                            "Personal Cop..."
                          )
                        }
                        checked={
                          pgRoomDetails[`room-${index}`].facilitiesOffered ===
                          "Personal Cop..."
                        }
                      />
                      Personal Cop...
                    </Button>
                    <Button
                      style={{
                        borderRadius: "5px",
                        width: "20%",
                        marginBottom: "5px",
                      }}
                      className={
                        pgRoomDetails[`room-${index}`].facilitiesOffered ===
                        "Table Chair"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() =>
                        handleChange(index, "facilitiesOffered", "Table Chair")
                      }
                    >
                      <Input
                        type="checkbox"
                        name={`facilitiesOffered-${index}`}
                        className="me-2"
                        value="No"
                        onChange={() =>
                          handleChange(
                            index,
                            "facilitiesOffered",
                            "Table Chair"
                          )
                        }
                        checked={
                          pgRoomDetails[`room-${index}`].facilitiesOffered ===
                          "Table Chair"
                        }
                      />
                      Table Chair
                    </Button>
                    <Button
                      style={{
                        marginRight: "5px",
                        borderRadius: "5px",
                        width: "20%",
                        marginBottom: "5px",
                      }}
                      className={
                        pgRoomDetails[`room-${index}`].facilitiesOffered ===
                        "Attched Balcony"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() =>
                        handleChange(
                          index,
                          "facilitiesOffered",
                          "Attched Balcony"
                        )
                      }
                    >
                      <Input
                        type="checkbox"
                        name={`facilitiesOffered-${index}`}
                        className="me-2"
                        value="No"
                        onChange={() =>
                          handleChange(
                            index,
                            "facilitiesOffered",
                            "Attched Balcony"
                          )
                        }
                        checked={
                          pgRoomDetails[`room-${index}`].facilitiesOffered ===
                          "Attched Balcony"
                        }
                      />
                      Attched Balcony
                    </Button>
                    <Button
                      style={{
                        marginRight: "5px",
                        borderRadius: "5px",
                        width: "20%",
                        marginBottom: "5px",
                      }}
                      className={
                        pgRoomDetails[`room-${index}`].facilitiesOffered ===
                        "Attached Bathroom"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() =>
                        handleChange(
                          index,
                          "facilitiesOffered",
                          "Attached Bathroom"
                        )
                      }
                    >
                      <Input
                        type="checkbox"
                        name={`facilitiesOffered-${index}`}
                        className="me-2"
                        value="No"
                        onChange={() =>
                          handleChange(
                            index,
                            "facilitiesOffered",
                            "Attached Bathroom"
                          )
                        }
                        checked={
                          pgRoomDetails[`room-${index}`].facilitiesOffered ===
                          "Attached Bathroom"
                        }
                      />
                      Attached Bath..
                    </Button>
                    <Button
                      style={{
                        borderRadius: "5px",
                        width: "20%",
                        marginBottom: "5px",
                      }}
                      className={
                        pgRoomDetails[`room-${index}`].facilitiesOffered ===
                        "Meals Included"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() =>
                        handleChange(
                          index,
                          "facilitiesOffered",
                          "Meals Included"
                        )
                      }
                    >
                      <Input
                        type="checkbox"
                        name={`facilitiesOffered-${index}`}
                        className="me-2"
                        value="No"
                        onChange={() =>
                          handleChange(
                            index,
                            "facilitiesOffered",
                            "Meals Included"
                          )
                        }
                        checked={
                          pgRoomDetails[`room-${index}`].facilitiesOffered ===
                          "Meals Included"
                        }
                      />
                      Meals Included
                    </Button>
                  </ButtonGroup> */}
                  </div>
                </Row>
              </Col>
              {pgRoomDetails[`room-${index}`].facilitiesOffered.includes(
                "Attached Bathroom"
              ) ? (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label font-weight-bold"
                    >
                      Bathroom Style
                    </label>
                    <div className="col-lg-12">
                      <ButtonGroup className="w-100">
                        <Button
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            pgRoomDetails[`room-${index}`].bathroomStyle ===
                            "Western"
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange(index, "bathroomStyle", "Western")
                          }
                        >
                          <Input
                            type="radio"
                            name={`bathroomStyle-${index}`}
                            className="me-2"
                            value="Western"
                            onChange={() =>
                              handleChange(index, "bathroomStyle", "Western")
                            }
                            checked={
                              pgRoomDetails[`room-${index}`].bathroomStyle ===
                              "Western"
                            }
                          />
                          Western
                        </Button>
                        <Button
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            pgRoomDetails[`room-${index}`].bathroomStyle ===
                            "Indian"
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange(index, "bathroomStyle", "Indian")
                          }
                        >
                          <Input
                            type="radio"
                            name={`bathroomStyle-${index}`}
                            className="me-2"
                            value="No"
                            onChange={() =>
                              handleChange(index, "bathroomStyle", "Indian")
                            }
                            checked={
                              pgRoomDetails[`room-${index}`].bathroomStyle ===
                              "Indian"
                            }
                          />
                          Indian
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Row>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Col>
        ))}
      </React.Fragment>
    )
  }
)

export default PGRoomDetailsEdit
