import React from "react"
import {
  Breadcrumbs,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { Button, CardBody, Col, Row } from "reactstrap"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"
import CloseIcon from "@mui/icons-material/Close"
import displayRazorpay from "helpers/payment"
import { Link } from "react-router-dom"

const PlanModel = ({ show, closeModel, planList, userDetails }) => {
  const handleBuyPlan = plan => {
    displayRazorpay(userDetails, plan, closeModel)
  }

  return (
    <React.Fragment>
      <Modal show={show} onHide={closeModel} className="modal-lg planListModel">
        <Modal.Header closeButton>
          <Modal.Title>Plan List</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-evenly " style={{overflowY:"auto", maxHeight:"73vh"}}>
        <Container>
      <Row>
        {planList.map(plan => (
          <Col xl={4} lg={6} md={12} sm={12} xs={12} key={plan.id} style={{marginBottom:"20px"}}> {/* Make sure each plan has a unique key */}
            <Card className="pricing-box" style={{ width: "100%", height: "450px" }}>
              <CardBody className="p-4">
                <div className="d-flex mt-2">
                  <div className="flex-shrink-0 align-self-center">
                    <i style={{ fontSize: "25px" }} className="mdi mdi-home-city-outline"></i>
                  </div>
                  <div className="flex-grow-1 ms-auto text-end">
                    <h4>{plan.planName}</h4>
                  </div>
                </div>
                <div className="pricing-features mt-5 pt-2">
                  <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                    <h6>Actual Amount :</h6>
                    <span>{plan.planActualAmount}</span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                    <h6>Offer Amount :</h6>
                    <span>{plan.planOfferAmount}</span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                    <h6>Duration :</h6>
                    <span>{plan.planDuration.split(" ")[0]} Days</span>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <h4 className="mb-0">
                    <sup><small><IconButton><CurrencyRupeeIcon style={{ color: "black" }}/></IconButton></small></sup>
                    {plan.planOfferAmount}/
                    <span className="font-size-16">{plan.planDuration.split(" ")[0]} Days</span>
                  </h4>
                </div>
                <div className="d-grid mt-5">
                  <Link to="#" className="btn commongradient addbutton btn-block waves-effect waves-light" onClick={() => handleBuyPlan(plan)}>
                    Buy Plan
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default PlanModel
