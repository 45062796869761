import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
// users
import user1 from "../../../assets/images/users/user-4.jpg"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import CopyToClipboard from "react-copy-to-clipboard"
import { HOST_URL, REFER_URL } from "helpers/url_helper"
import referimg from "../../../assets/images/referimg.jpg"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const navigate = useNavigate()
  const [menu, setMenu] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)

  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    setIsCopied(true)
    // You can perform additional actions after copying if needed
  }
  const [referUrl, setReferUrl] = useState("")
  var referId
  const handleShowModal = () => {
    referId = JSON.parse(localStorage.getItem("authUser"))
    setReferUrl(`${REFER_URL}#/registerpage?referCode=${referId.referCode}`)
    setShowModal(true)
  }

  const [username, setusername] = useState("Admin")
  var role = JSON.parse(localStorage.getItem("authUser"))
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.name)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.name)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="mdi mdi-account-circle font-size-17 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="#">
            {" "}
            <i className="mdi mdi-cog font-size-17 align-middle me-1" />
            {props.t("Settings")}{" "}
            <span className="badge bg-success ms-auto">17</span>
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="mdi mdi-lock-open-outline font-size-17 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          {role.role == "user" ? (
            <>
              <DropdownItem tag="a" href="#">
                {" "}
                <i className="mdi mdi-wallet font-size-17 align-middle me-1" />
                <Link
                  to={`/wallet?user_id=${role._id}`}
                  style={{ color: "black" }}
                >
                  {props.t("My Wallet")}{" "}
                </Link>
              </DropdownItem>
              <DropdownItem
                tag="a"
                href="javascript:void(0)"
                onClick={handleShowModal}
              >
                <i className="mdi mdi-account-arrow-right font-size-17 align-middle me-1" />
                {props.t("Refer a friend")}
              </DropdownItem>
              <div className="dropdown-divider" />
            </>
          ) : (
            <></>
          )}

          <Link to="/logout" className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-17 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Invite Friend</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={referimg} alt="" width={"300px"} />
          <div className="mt-3 text-center">
            <Button
              variant="success"
              className="mr-2"
              onClick={() => {
                const encodedReferUrl = encodeURIComponent(referUrl)
                const whatsappUrl = `https://wa.me/${role.mobno}?text=Hey%20Use%20my%20referral%20code%20${role.referCode}%20or%20use%20this%20link%20${encodedReferUrl}%20to%20register%20in%20FREELIFEHOUSING.COM!`
                window.open(whatsappUrl, "_blank")
              }}
            >
              WhatsApp
            </Button>
            <Button
              variant="info"
              className="mr-2"
              onClick={() => {
                var subject = "Referral Code"
                var body =
                  "Hey Use my referral code " +
                  role.referCode +
                  " or use this link " +
                  referUrl +
                  " to register in FREELIFEHOUSING.COM!"
                var mailtoLink =
                  "mailto:?subject=" +
                  encodeURIComponent(subject) +
                  "&body=" +
                  encodeURIComponent(body)
                window.open(mailtoLink, "_blank")
              }}
            >
              Email
            </Button>
            <CopyToClipboard text={referUrl} onCopy={handleCopy}>
              <Button variant="primary">
                {isCopied ? "Copied!" : "Copy to Clipboard"}
              </Button>
            </CopyToClipboard>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
