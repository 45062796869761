import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabPane,
  Table,
} from "reactstrap"
import Modal from "react-bootstrap/Modal"
import MUIDataTable from "mui-datatables"
import AddUserDetail from "./AddUserDetail"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import {
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"
import DateFilter from "pages/reportFilter/dateFilter"

const User = ({ users }) => {
  const location = useLocation()
  const adduser = new URLSearchParams(location.search).get("adduser")
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [userList, setUserList] = useState([])
  const [userData, setUserData] = useState([])
  const [editUser, setEditUser] = useState({})
  const [operation, setOperation] = useState()
  const token = JSON.parse(localStorage.getItem("token"))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [userName, setUserName] = useState("")
  const [postedProperty, setPostedProperty] = useState([])
  const [showModel, setShowModel] = useState(false)

  useEffect(() => {
    if (adduser === "true") {
      handleOpen("Add User")
    }
  }, [])

  // const tableref = useRef();

  const fetchData = async () => {
    const url = `${HOST_URL}user/viewAllUser`
    const data = {}
    try {
      const response = await ApiPostMethodWithToken(url, data, token)
      if (response.error === false) {
        const sortedUserList = response.allUser.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        setUserList(sortedUserList)
      } else {
        console.error("Error fetching user list:", response.message)
      }
    } catch (error) {
      console.error("Error fetching user list:", error)
    }
  }

  var tableref

  useEffect(() => {
    fetchData()
    // const searchParams = new URLSearchParams(location.search);
    // const name = searchParams.get('name');
    // console.log("name", name);
    // tableref = React.createRef();
    // if (name) {
    //   setUserName(decodeURIComponent(name));// Set search value to update MUIDataTable's search bar
    //   // tableref.current.filterData('Rutuja');
    // }
  }, []) // Fetch data when userList state changes
  var serch_Value
  useEffect(() => {
    // Function to extract name parameter from URL
    const getParameterByName = (name, url) => {
      if (!url) url = window.location.href
      name = name.replace(/[[]]/g, "\\$&")
      const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
      const results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ""
      return decodeURIComponent(results[2].replace(/\+/g, " "))
    }

    // Get the name parameter from the URL
    const nameFromUrl = getParameterByName("name", location.search)

    // Set the search value to the name parameter
    setSearchValue(nameFromUrl)
    serch_Value = nameFromUrl
    // console.log("p-p-p-p", serch_Value)
  }, [location.search])

  const handleSearch = event => {
    setSearchValue(event.target.value)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const toggleModal2 = () => {
    setIsModalOpen2(!isModalOpen2)
  }

  const handleOpen = (operation, row) => {
    setOperation(operation)
    if (operation === "Add User") {
      setEditUser({})
      setOpen(true)
    } else {
      const url = `${HOST_URL}user/viewSingleUser`
      const data = {
        user_id: row.rowData[1],
      }

      ApiPostMethodWithToken(url, data, token)
        .then(data => {
          if (data.error === false) {
            setEditUser(data)
            setOpen(true)
          }
        })
        .catch(error => console.log(`Error: ${error}`))
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleView = row => {
    const url = `${HOST_URL}user/viewSingleUser`
    const data = {
      user_id: row.rowData[1],
    }
    ApiPostMethodWithToken(url, data, token)
      .then(data => {
        if (data.error === false) {
          setUserData(data.userDetails)
          setIsModalOpen(true)
        }
      })
      .catch(error => console.log(`Error: ${error}`))
  }

  const handleDelete = async row => {
    // Show confirmation dialog
    Swal.fire({
      title: "Are you sure want to delete?",
      text: "You will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        // User confirmed deletion, proceed with delete operation
        // console.log("Delete clicked for row:", row.rowData[1])
        const url = `${HOST_URL}user/deleteUser`
        const data = {
          user_id: row.rowData[1],
        }

        try {
          const response = await ApiPostMethodWithToken(url, data, token)
          if (response.error === false) {
            // Check if isBlocked is 0 before updating userList
            fetchData()
            if (response.isBlocked === 0) {
              const updatedUserList = userList.filter(
                user => user._id !== row.rowData[0]
              )
              setUserList(updatedUserList)
            }
            toast.success(response.message, {
              position: "top-right",
              theme: "light",
            })
          } else {
            toast.error(response.message, {
              position: "top-right",
              theme: "light",
            })
          }
        } catch (error) {
          // console.log("Error deleting user:", error)
          toast.error("Error deleting user", {
            position: "top-right",
            theme: "light",
          })
        }
      }
    })
  }

  const handlePropertyClick = async row => {
    // console.log("User clicked for ID:", row.rowData[1])
    const url = `${HOST_URL}user/viewSingleUser` //here i have to
    const data = {
      user_id: row.rowData[1],
    }
    await ApiPostMethodWithToken(url, data, token)
      .then(resp => {
        // console.log(resp)
        setPostedProperty(resp.userDetails.propertydetails) // Assuming leadsDate contains registered users' data// i have to write uesrList array instead of leadDate
        // console.log("Posted Property:", registeredUsers);

        toggleModal2()
      })
      .catch(error => console.log(`Error: ${error}`))
  }

  const columns = [
    {
      name: "SN",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1
        },
      },
    },
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "planName",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobno",
      label: "Mobile No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Account Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "planStatus",
      label: "Plan Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (value == "Inactive") {
            return value
          } else {
            return (
              <span>
                Name: {tableMeta.rowData[2]} <br />
                Status: {value}
              </span>
            )
          }
        },
      },
    },
    {
      name: "userpropcount",
      label: "Property Posted",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{
                cursor: "pointer",
                display: "block",
                width: "100%",
              }}
              onClick={() => handlePropertyClick(tableMeta)}
            >
              {value}
            </span>
          )
        },
      },
    },
    {
      name: "registeredBy",
      label: "REGISTERED BY",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="View">
                <button className="actionview me-1">
                  <Link to={`/wallet?user_id=${tableMeta.rowData[1]}`}>
                    <i
                      className="mdi mdi-eye-outline"
                      style={{ color: "black" }}
                    ></i>
                  </Link>
                </button>
              </Tooltip>
              <Tooltip title="Edit">
                <button
                  className="actionview me-1"
                  onClick={() => {
                    handleOpen("Edit User", tableMeta)
                  }}
                >
                  <i className="mdi mdi-clipboard-edit-outline"></i>
                </button>
              </Tooltip>
              <Tooltip title="Delete">
                <button
                  className="actionview"
                  onClick={() => handleDelete(tableMeta)}
                >
                  <i className="mdi mdi-delete-outline"></i>
                </button>
              </Tooltip>
            </>
          )
        },
      },
    },
  ]

  const options = {
    filter: true,
    //filterType: 'checkbox',
    selectableRows: "none",
    responsive: "standard",
    searchText: searchValue,
    download: false,
    viewColumns: false,
    print: false,
  }

  const handleModelOpen = () => {
    setShowModel(true)
  }

  const hideModel = () => {
    setShowModel(false)
  }

  const handleDate = (date) => {
    const createdAt = new Date(date);
    const dd = String(createdAt.getDate()).padStart(2, '0');
    const mm = String(createdAt.getMonth() + 1).padStart(2, '0');
    const yyyy = createdAt.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content user-list">
          <Row>
            <Col lg={12} className="pe-0 pt-2 pb-2">
              <Card className="border-right-top">
                <CardBody className="pt-3">
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item xs={2}>
                      <h5>User</h5>
                    </Grid>
                    <Grid item xs={10} container justifyContent="flex-end">
                      <Link
                        onClick={() => handleOpen("Add User")}
                        style={{ padding: "8px 36px", marginTop: "3px" }}
                        className="commongradient addbutton addbtn"
                      >
                        Add User
                      </Link>
                      <Link
                        onClick={handleModelOpen}
                        style={{
                          padding: "8px 30px",
                          marginTop: "3px",
                          marginLeft: "10px",
                        }}
                        className="commongradient addbutton addbtn"
                      >
                        User Report
                      </Link>
                    </Grid>
                  </Grid>

                  <TabPane tabId="2" style={{ margin: "0px 20px" }}>
                    <Row>
                      <Col sm="12" className="p-1 ps-0 pe-0">
                        <MUIDataTable
                          title=""
                          data={userList}
                          columns={columns}
                          options={options}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal show={isModalOpen} onHide={toggleModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <TableRow>
                <TableCell>
                  <strong>Name:</strong> {userData?.singleuser?.name}
                </TableCell>
                <TableCell>
                  <strong>Email:</strong> {userData?.singleuser?.email}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Mobile No.:</strong>
                  {userData?.singleuser?.mobno}
                </TableCell>
                <TableCell>
                  <strong>Gender.:</strong>
                  {userData?.singleuser?.gender}
                </TableCell>
              </TableRow>
            </Table>
          </Modal.Body>
        </Modal>
        {editUser && (
          <AddUserDetail
            open={open}
            handleClose={handleClose}
            operation={operation}
            fetchData={fetchData}
            userData={editUser && editUser.userDetails}
          />
        )}

        <Modal show={isModalOpen2} onHide={toggleModal2} centered>
          <Modal.Header closeButton>
            <Modal.Title>Posted Property Details</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "600px", overflowY: "auto" }}>
            {postedProperty && postedProperty.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr. No.</TableCell>
                      <TableCell>Property Type</TableCell>
                      <TableCell>Looking To</TableCell>
                      <TableCell>createdAt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {postedProperty.map((property, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <TableRow>
                            {property.propertyDetails.propertyType1}
                          </TableRow>
                          <TableRow>
                            {property.propertyDetails.propertyType2}
                          </TableRow>
                        </TableCell>
                        <TableCell>
                          {property.propertyDetails.lookingTo}
                        </TableCell>
                        <TableCell>
                          {handleDate(property.createdAt)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1">
                Posted property not available
              </Typography>
            )}
          </Modal.Body>
        </Modal>
        <DateFilter show={showModel} handleClose={hideModel} type={"User"} />
      </React.Fragment>
    </div>
  )
}

export default User
