import { HOST_URL, REFER_URL } from "./url_helper"


const subscriptionEndDate = new Date("2024-12-30");
const isDateWithinSubscriptionPeriod = () => {
  const today = new Date();
today.setHours(0, 0, 0, 0); // Set time to 00:00:00:00
return today <= subscriptionEndDate;
};

const getRemainingDays = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const timeDifference = subscriptionEndDate - today;
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysRemaining;
};

export function ApiPostMethodWithToken(url, data, token, method = "POST") {
  if (!isDateWithinSubscriptionPeriod()) {
    localStorage.clear();
    return Promise.resolve({ error: true, message: "Due date has passed, Please renew.", isLogin:true });
  }

  return fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json", Authorization: token },
    body: JSON.stringify(data),
  })
    .then(resp => resp.json())
    .then(function (result) {
      if (
        (result.message == "Invalid Token" ||
          result.message == "Unauthorized") &&
        result.islogin === true
      ) {
        alert("Your session has expired!, Please login to continue")
        localStorage.clear()
        window.location.href = `${REFER_URL}#/Login`
      } else {
        const data = {...result, remainingDays: getRemainingDays() }
        return data;
      }
    })
}

export function ApiGetMethodWithToken(url, data, token, method = "GET") {
  if (!isDateWithinSubscriptionPeriod()) {
    localStorage.clear();
    return Promise.resolve({ error: true, message: "Due date has passed, Please renew.", isLogin:true });
  }

  return fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json", Authorization: token },
  })
    .then(resp => resp.json())
    .then(function (result) {
      const data = {...result, remainingDays: getRemainingDays() }
      return data;
    })
}
