import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { userRoutes, authRoutes } from "./routes/allRoutes"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import fakeBackend from "./helpers/AuthType/fakeBackend"
import "./assets/scss/custom.css"
import { HOST_URL, NEXT_URL } from "helpers/url_helper"
import Swal from "sweetalert2"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import displayRazorpay from "helpers/payment"
import { ToastContainer } from "react-toastify"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)
const App = () => {
  const router = useNavigate()
  const location = useLocation()
  const userRole = JSON.parse(localStorage.getItem("userRole"))
  const [shownotify, setShowNotify] = useState(
    localStorage.getItem("notify") || true
  )
  useEffect(() => {
    if (location.pathname == "/Login") {
      if (localStorage.getItem("authUser")) {
        if (userRole == "admin" || userRole == "manager") {
          router("/Dashboard")
        } else {
          router("/Dashboard")
        }
      }
    } else {
      if (
        !localStorage.getItem("authUser") &&
        !location.pathname == "/registerpage"
      ) {
        router("/Login")
      }
    }
  }, [])

  const showPlanStatus = async () => {
    try {
      const authUser = JSON.parse(localStorage.getItem("authUser"))
      const token = JSON.parse(localStorage.getItem("token"))
      if (authUser.role == "user") {
        const url = `${HOST_URL}user/userActivePlan`
        const data = {
          user_id: authUser._id,
        }
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false && resp.notify === true) {
            Swal.fire({
              title: "Plan Expiry Reminder!",
              text: resp.message,
              icon: "info",
              showCloseButton: true,
            })
            localStorage.setItem("notify", false)
          } else if (resp.error === true && resp.expired === true) {
            Swal.fire({
              title: "Plan Expiry Reminder!",
              text: resp.message,
              icon: "info",
              showCloseButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Renew",
            }).then(async result => {
              if (result.isConfirmed) {
                router("/plans")
                // displayRazorpay(authUser, resp.plan)
              }
            })
            localStorage.setItem("notify", false)
          } else if (resp.error === true && resp.purchase === true) {
            Swal.fire({
              title: resp.message,
              text: "Please purchase plan to post properties or access other benefits!...",
              icon: "info",
              showCloseButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Buy Plan",
            }).then(async result => {
              if (result.isConfirmed) {
                router("/plans")
                // displayRazorpay(authUser, resp.plan)
              }
            })
            localStorage.setItem("notify", false)
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (shownotify === true) {
      showPlanStatus()
    }
  }, [])

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<Authmiddleware>{route.component}</Authmiddleware>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
      <ToastContainer />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
