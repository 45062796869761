import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
} from "reactstrap"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import Swal from "sweetalert2"
import { ToastContainer, toast } from "react-toastify"

const DeleteModal = ({ show, handleClose, property_Id, ontoastclose }) => {
  // console.log(property_Id)
  const [deletionReason, setDeletionReason] = useState("")
  const [deletionReasonError, setDeletionReasonError] = useState("")
  const handleInputChange = e => {
    const { name, value } = e.target
    setDeletionReason(value)
    setDeletionReasonError("")
  }
  const handleModalClose = () => {
    setDeletionReason("")
    setDeletionReasonError("")
    handleClose()
  }
  const handleDelete = async () => {
    try {
      if (deletionReason.trim() === "") {
        setDeletionReasonError("Please Select a Reason to Delete Listing!..")
      } else {
        const token = JSON.parse(localStorage.getItem("token"))
        const url = `${HOST_URL}property/deleteProp`
        const data = {
          property_id: property_Id,
          deletionReason: deletionReason,
        }
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            handleClose()
            toast.success(resp.message, {
              position: "top-right",
              theme: "light",
              autoClose: 500,
              onClose: () => {
                ontoastclose()
              },
            })
          }
        })
      }
    } catch (error) {
      console.error("filter error", error)
    }
  }
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleModalClose}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3 d-block">
            <label
              htmlFor="txtFirstNameBilling"
              className="col-lg-12 col-form-label font-weight-bold"
            >
              <h4 className="text-center">
                Select a reason to delete your listing
              </h4>
            </label>
            <Col lg={12}>
              <label
                htmlFor="deleR-1"
                className="d-flex align-items-center p-2 pt-0 pb-0"
              >
                <Input
                  type="radio"
                  id="deleR-1"
                  name="deletionReason"
                  value="Owner is not interested to Rent/Sell"
                  onChange={handleInputChange}
                  className="loginRegInput form-control"
                />
                <h5 className="m-0 ps-2">
                  Owner is not interested to Rent/Sell
                </h5>
              </label>
              <label
                htmlFor="deleR-2"
                className="d-flex align-items-center p-2 pt-0 pb-0"
              >
                <Input
                  type="radio"
                  id="deleR-2"
                  name="deletionReason"
                  value="Rented/Sold Out"
                  onChange={handleInputChange}
                  className="loginRegInput form-control"
                />
                <h5 className="m-0 ps-2">Rented/Sold Out</h5>
              </label>
              <label
                htmlFor="deleR-3"
                className="d-flex align-items-center p-2 pt-0 pb-0"
              >
                <Input
                  type="radio"
                  id="deleR-3"
                  name="deletionReason"
                  value="Not Dealing in this Property"
                  onChange={handleInputChange}
                  className="loginRegInput form-control"
                />
                <h5 className="m-0 ps-2">Not Dealing in this Property</h5>
              </label>
              <label
                htmlFor="deleR-4"
                className="d-flex align-items-center p-2 pt-0 pb-0"
              >
                <Input
                  type="radio"
                  id="deleR-4"
                  name="deletionReason"
                  value="Bulk Deletion"
                  onChange={handleInputChange}
                  className="loginRegInput form-control"
                />
                <h5 className="m-0 ps-2">Bulk Deletion</h5>
              </label>
            </Col>
            {deletionReasonError && (
              <span className="error">{deletionReasonError}</span>
            )}
          </Row>
          <Row
            className="justify-content-between"
            style={{ padding: "0px 12px" }}
          >
            <Button
              className="commongradient addbutton addbtn"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default DeleteModal
